import PT from 'prop-types'
import React, {Component} from 'react'
import {Col, Row, Rows} from 'arena-components'
import {Form} from '../../lib/components/arena-plugin-form'
import {
  boolPT,
  emptyObjectPT,
  funcPT,
  numberPT,
  stringPT,
} from 'arena-prop-types'
import Assignment from '../Assignment'
import Comments from '../Comments'
import Loader from '../Form/Loader'
import FormSection from '../Form/Section'
import {ASSSIGNMENT_DOES_NOT_EXIST} from '../../constants/validationMessages'
import {sectionItemsPT} from '../../constants/propTypes/sectionItems'
import {formSectionsPT} from '../../constants/propTypes/formSections'
import {assignmentPT} from '../../constants/propTypes/assignments'
import {resourcesPT} from '../../constants/propTypes/resources'
import {PT_CURRENCY_DEFAULTS} from '../../lib/constants/propTypes'
import {getCountry} from '../../utils/resources'

class ViewAssignment extends Component {
  componentDidMount() {
    const {
      assignment,
      fetchAssignment,
      fetchAssignmentOptionsIfNeeded,
      fetchCompaniesIfNeeded,
      fetchFormSectionsIfNeeded,
      fetchPositionToken,
      fetchResourcesIfNeeded,
      id,
    } = this.props
    const assignmentExists = assignment ? id in assignment : false

    fetchFormSectionsIfNeeded()
    fetchResourcesIfNeeded()
    fetchAssignmentOptionsIfNeeded(id, true, true)
    fetchPositionToken(id)
    fetchCompaniesIfNeeded()

    if (!assignmentExists) fetchAssignment(id)
  }

  render() {
    const {
      approverComments,
      assignment,
      currencyDefaults,
      formName,
      formSections,
      id,
      initialValues,
      isFetching,
      isMobile,
      positionToken,
      resources,
      sectionItems,
      updateApprovalStatus,
    } = this.props

    if (isFetching) return <Loader blue />

    if (!assignment) {
      return (
        <div className="ViewAssignment-null">
          {ASSSIGNMENT_DOES_NOT_EXIST(id)}
        </div>
      )
    }

    const {
      anticipatedEndDate,
      anticipatedStartDate,
      employeeId,
      firstName,
      homeCountryId,
      hostCountryId,
      lastName,
      title,
    } = assignment
    const hostCountry = getCountry(resources.countries, hostCountryId)
    const hostCountryCode = hostCountry ? hostCountry.value : null
    const hostCountryName = hostCountry ? hostCountry.label : null
    const homeCountry = getCountry(resources.countries, homeCountryId)
    const homeCountryCode = homeCountry ? homeCountry.value : null
    const homeCountryName = homeCountry ? homeCountry.label : null

    const renderedFormSections = []
    for (let i = 4; i < Object.keys(formSections).length; i += 1) {
      const {code, formFields, name} = formSections[i]

      renderedFormSections.push(
        <div key={i}>
          <FormSection
            code={code}
            columnCount={4}
            formFields={formFields}
            formSections={formSections}
            name={name}
            resources={resources}
          />
        </div>
      )
    }

    return (
      <div className="ViewAssignment">
        <Form initialValues={initialValues} name={formName}>
          <Assignment
            approve={() =>
              updateApprovalStatus(id, employeeId, 'Approved', approverComments)
            }
            currencyDefaults={currencyDefaults}
            employeeId={employeeId}
            endDate={anticipatedEndDate}
            firstName={firstName}
            homeCountryCode={homeCountryCode}
            homeCountryName={homeCountryName}
            hostCountryCode={hostCountryCode}
            hostCountryName={hostCountryName}
            isMobile={isMobile}
            lastName={lastName}
            positionToken={positionToken}
            reject={() =>
              updateApprovalStatus(id, employeeId, 'Rejected', approverComments)
            }
            sectionItems={sectionItems}
            startDate={anticipatedStartDate}
            title={title}
          />
          <Rows>
            <Row>
              <Col>
                <Comments />
              </Col>
            </Row>
          </Rows>
          {renderedFormSections}
        </Form>
      </div>
    )
  }
}

ViewAssignment.defaultProps = {
  approverComments: null,
  assignment: null,
  initialValues: null,
  positionToken: null,
}

ViewAssignment.propTypes = {
  approverComments: stringPT(),
  assignment: assignmentPT(),
  currencyDefaults: PT_CURRENCY_DEFAULTS.isRequired,
  fetchAssignment: funcPT(true),
  fetchAssignmentOptionsIfNeeded: funcPT(true),
  fetchFormSectionsIfNeeded: funcPT(true),
  fetchPositionToken: funcPT(true),
  fetchResourcesIfNeeded: funcPT(true),
  formName: stringPT(true),
  formSections: formSectionsPT(true),
  id: PT.oneOfType([numberPT(), stringPT()]).isRequired,
  initialValues: emptyObjectPT(),
  isFetching: boolPT(true),
  isMobile: boolPT(true),
  positionToken: emptyObjectPT(),
  resources: resourcesPT(true),
  sectionItems: sectionItemsPT(true),
  updateApprovalStatus: funcPT(true),
}

export default ViewAssignment
