import {normalize} from 'normalizr'
import {RSAA, getJSON} from 'redux-api-middleware'
import {positionTokenSchema} from '../constants/schemas'
import {POSITION_TOKEN_URL} from '../constants/api'
import {
  FETCH_POSITION_TOKEN_FAILURE,
  FETCH_POSITION_TOKEN_REQUEST,
  FETCH_POSITION_TOKEN_SUCCESS,
} from '../constants/actionTypes'

export const fetchPositionToken = positionId => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: POSITION_TOKEN_URL(positionId),
      method: 'GET',
      types: [
        FETCH_POSITION_TOKEN_REQUEST,
        {
          type: FETCH_POSITION_TOKEN_SUCCESS,
          payload: (action, currentState, res) =>
            getJSON(res).then(json => ({
              ...normalize(json, positionTokenSchema),
            })),
        },
        FETCH_POSITION_TOKEN_FAILURE,
      ],
    },
  })
