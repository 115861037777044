import React from 'react'
import pure from 'recompose/pure'
import styled from 'styled-components'
import {cellWidthProps} from '../../constants/propTypes'
import prepareDefaultResources from '../../utils/prepareDefaultResources'
import RowTemplate from '../RowTemplate'
import {headerRowDefaultProps, headerRowPropTypes} from './propTypes'

const Wrap = styled.div`
  background-color: white;
  backface-visibility: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.125);
  left: 0;
  padding-bottom: 0.625em;
  padding-top: 0.625em;
  position: absolute;
  top: 0;
  transform: translateY(${props => (props.isVisible ? 0 : '-110%')});
  transition: transform 0.5s;
  transition-timing-function: linear;
  z-index: 1;
`

const HeaderRow = props => {
  const resources = props.showAllResources
    ? props.resources
    : prepareDefaultResources(props.resources)

  return (
    <Wrap isVisible={props.isVisible}>
      <RowTemplate
        {...cellWidthProps(props)}
        duration={props.duration}
        isHeader
        resources={resources}
        startYear={props.startYear}
        title={props.name}
      />
    </Wrap>
  )
}

HeaderRow.defaultProps = headerRowDefaultProps
HeaderRow.propTypes = headerRowPropTypes

export default pure(HeaderRow)
