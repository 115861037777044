import PT from 'prop-types'
import {commonPropTypes} from '../../propTypes'

export const amountsDefaultProps = {
  isHovering: false,
}

export const amountsPropTypes = {
  ...commonPropTypes,
  activeYears: PT.shape({
    first: PT.bool.isRequired,
    last: PT.bool.isRequired,
    remaining: PT.bool.isRequired,
  }),
  change: PT.func.isRequired,
  code: PT.string.isRequired,
  enableAutofill: PT.bool.isRequired,
  enableFormatting: PT.bool.isRequired,
  formName: PT.string.isRequired,
  formValues: PT.shape({}).isRequired,
  isLocked: PT.bool,
  isBulkEditingRow: PT.bool.isRequired,
  isHovering: PT.bool,
  isManuallyShowingResouces: PT.bool.isRequired,
  showAllResources: PT.bool.isRequired,
  type: PT.string.isRequired,
  useSingleAmountInsteadOfYears: PT.bool.isRequired,
}
