import {combineReducers} from 'redux'
import {
  DOWNLOAD_PROGRESS,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_FAILURE,
  DOWNLOAD_REQUEST,
  RESET_PROGRESS,
} from '../constants/actionTypes'

const progress = (state = 0, action) => {
  switch (action.type) {
    case DOWNLOAD_PROGRESS:
      return action.payload || 0
    case DOWNLOAD_SUCCESS:
      return 100
    case DOWNLOAD_FAILURE:
      return 0
    case DOWNLOAD_REQUEST:
      return 1
    case RESET_PROGRESS:
      return 0
    default:
      return state
  }
}

const isStarting = (state = false, action) => {
  switch (action.type) {
    case DOWNLOAD_PROGRESS:
    case DOWNLOAD_SUCCESS:
    case DOWNLOAD_FAILURE:
    case RESET_PROGRESS:
      return false
    case DOWNLOAD_REQUEST:
      return true
    default:
      return state
  }
}

const exportProgress = combineReducers({
  progress,
  isStarting,
})

export default exportProgress
