import React from 'react'
import {RSAA, getJSON} from 'redux-api-middleware'
import {normalize} from 'normalizr'
import {sendFileToBrowser} from 'arena-redux'
import {
  ADD_APPROVER_FAILURE,
  ADD_APPROVER_REQUEST,
  ADD_APPROVER_SUCCESS,
  EXECUTE_TASK_ACTION_FAILURE,
  EXECUTE_TASK_ACTION_REQUEST,
  EXECUTE_TASK_ACTION_SUCCESS,
  FETCH_TIMELINES_FAILURE,
  FETCH_TIMELINES_REQUEST,
  FETCH_TIMELINES_SUCCESS,
  FETCH_TIMELINE_FAILURE,
  FETCH_TIMELINE_REQUEST,
  FETCH_TIMELINE_SUCCESS,
  TOGGLE_ADD_APPROVER,
  TOGGLE_UPDATE_ASSIGNMENT_OPTIONS,
} from '../constants/actionTypes'
import {APPROVERS_URL, TASK_STATUS_URL, TIMELINES_URL} from '../constants/api'
import {fetchAssignment} from './assignments'
import {getTimelineIds} from '../selectors/timelines'
import {mapTimeline, mapTimelines} from '../utils/api'
import {timelineSchema} from '../constants/schemas'
import {isIE} from '../utils/browser'
import notification from 'antd/lib/notification'
import 'antd/lib/notification/style/index.css'
import {
  FAILURE_NOTIFICATION_TITLE,
  TASK_ACTION_NOTIFICATION_DURATION,
} from '../constants/notification'
import {getIsTaskProcessing} from '../selectors/timelines'
import {fetchChannelSummary} from './channels'
import {todaysDate} from '../utils/moment'
import {logEventTrigger} from '../lib/utils/analytics'
import {
  getLastSelectedCompanyId,
  getLastSelectedUserDetails,
} from '../selectors/company'
import {getGDrive, uploadToGDrive} from '../lib/utils/uploadToGDrive'
import {
  GOOGLE_DRIVE_AUTH_FAILED_MESSAGE,
  GOOGLE_DRIVE_AUTH_UNEXPECTED_ERROR_MESSAGE,
} from '../lib/constants/messages'

const setTimelinesQuery = items => {
  let ids = ''
  for (let i = 0; i < items.length; i += 1)
    ids += `${i === 0 ? '?' : '&'}ids=${items[i]}`

  return ids
}

export const fetchTimelinesIfNeeded = (assignmentIds, forceReload = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const timelines = getTimelineIds(state)
  const newAssignmentIds = assignmentIds.filter(id => !timelines.includes(id))
  const shouldFetchTimelines = newAssignmentIds.length || forceReload

  if (shouldFetchTimelines) {
    dispatch({
      [RSAA]: {
        endpoint: TIMELINES_URL(setTimelinesQuery(assignmentIds)),
        method: 'GET',
        types: [
          FETCH_TIMELINES_REQUEST,
          {
            type: FETCH_TIMELINES_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapTimelines(json), [timelineSchema])
              ),
          },
          FETCH_TIMELINES_FAILURE,
        ],
      },
    })
  }
}

export const fetchTimeline = (assignmentId, forceRefresh = true) => (
  dispatch,
  getState
) => {
  const state = getState()
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }

  if (forceRefresh || getIsTaskProcessing(state)) {
    dispatch({
      [RSAA]: {
        endpoint: TIMELINES_URL(setTimelinesQuery([assignmentId])),
        method: 'GET',
        headers,
        types: [
          FETCH_TIMELINE_REQUEST,
          {
            type: FETCH_TIMELINE_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapTimeline(json[0]), timelineSchema)
              ),
          },
          FETCH_TIMELINE_FAILURE,
        ],
      },
    })
  }
}
const updateTaskStatusIfNeeded = (
  shouldUpdateTaskStatus,
  taskId,
  assignmentId,
  taskDescription,
  dueDate
) => dispatch => {
  if (shouldUpdateTaskStatus) {
    dispatch(
      updateTask(
        taskId,
        assignmentId,
        taskDescription,
        shouldUpdateTaskStatus,
        dueDate < todaysDate()
      )
    )
  } else {
    // Refresh at 5 and 10 seconds
    // Refresh at 60 seconds
    dispatch(scheduleMultipleTimelineRefresh(assignmentId, 5, 2, 60, 1))
  }
}

export const executeTaskAction = (
  taskId,
  endpoint,
  taskDescription,
  method,
  assignmentId,
  shouldUpdateTaskStatus,
  dueDate
) => (dispatch, getState) =>
  dispatch({
    [RSAA]: {
      endpoint,
      method:
        !method ||
        method.toUpperCase() === 'FILE' ||
        method.toUpperCase() === 'GDRIVE'
          ? 'GET'
          : `${method}`,
      headers: {
        pragma: 'no-cache',
        'cache-control': 'no-cache, no-store, must-revalidate',
      },
      types: [
        {type: EXECUTE_TASK_ACTION_REQUEST, meta: {taskId}},
        {
          type: EXECUTE_TASK_ACTION_SUCCESS,
          payload: (_action, _currentState, res) => {
            if (method.toUpperCase() === 'FILE') {
              sendFileToBrowser(res)
            } else if (method.toUpperCase() === 'GDRIVE') {
              getGDrive(async auth2 => {
                if (auth2 === null) {
                  notification['error']({
                    message: GOOGLE_DRIVE_AUTH_FAILED_MESSAGE,
                    description: GOOGLE_DRIVE_AUTH_UNEXPECTED_ERROR_MESSAGE,
                    duration: 10,
                  })
                } else {
                  if (await uploadToGDrive(res)) {
                    dispatch(
                      updateTaskStatusIfNeeded(
                        shouldUpdateTaskStatus,
                        taskId,
                        assignmentId,
                        taskDescription,
                        dueDate
                      )
                    )
                  }
                }
              })
            }
            if (method.toUpperCase() !== 'GDRIVE') {
              dispatch(
                updateTaskStatusIfNeeded(
                  shouldUpdateTaskStatus,
                  taskId,
                  assignmentId,
                  taskDescription,
                  dueDate
                )
              )
            }
          },
        },
        {
          type: EXECUTE_TASK_ACTION_FAILURE,
          payload: (_action, _currentState, res) =>
            getJSON(res).then(json =>
              displayFailureNotification(taskDescription, json && json.message)
            ),
        },
      ],
    },
  })

export const updateTask = (
  taskId,
  assignmentId,
  taskDescription,
  newStatus,
  refreshSummary
) => dispatch => {
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }
  dispatch({
    [RSAA]: {
      endpoint: TASK_STATUS_URL(taskId, newStatus),
      method: 'PUT',
      types: [
        {type: EXECUTE_TASK_ACTION_REQUEST, meta: {taskId}},
        {
          type: EXECUTE_TASK_ACTION_SUCCESS,
          payload: (_action, _currentState, res) =>
            getJSON(res).then(json => {
              dispatch(fetchTimeline(assignmentId))
              if (refreshSummary) {
                dispatch(fetchChannelSummary())
              }
            }),
        },
        {
          type: EXECUTE_TASK_ACTION_FAILURE,
          payload: (_action, _currentState, res) =>
            displayFailureNotification(taskDescription),
        },
      ],
      headers: {'Content-Type': 'application/json', ...headers},
    },
  })
}

export const toggleAddApprover = positionId => ({
  type: TOGGLE_ADD_APPROVER,
  positionId,
})

export const toggleUpdateAssignmentOptions = (
  positionId,
  taskId,
  actionId
) => ({
  type: TOGGLE_UPDATE_ASSIGNMENT_OPTIONS,
  actionId,
  positionId,
  taskId,
})

export const addApprover = (approver, employeeId, positionId) => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: APPROVERS_URL(employeeId, positionId),
      method: 'POST',
      body: JSON.stringify(approver),
      types: [
        ADD_APPROVER_REQUEST,
        {
          type: ADD_APPROVER_SUCCESS,
          payload: (_action, _currentState, res) =>
            getJSON(res).then(() => dispatch(fetchTimeline(positionId))),
        },
        ADD_APPROVER_FAILURE,
      ],
      headers: {'Content-Type': 'application/json'},
    },
  })

export const scheduleTimelineRefresh = (
  id,
  secondsToWait,
  numberOfRefreshes,
  forceRefreshThresholdTime = 90
) => dispatch => {
  for (let i = 1; i <= numberOfRefreshes; i += 1) {
    const shouldForceRefresh = secondsToWait * i < forceRefreshThresholdTime
    setTimeout(
      () => dispatch(fetchTimeline(id, shouldForceRefresh)),
      secondsToWait * 1000 * i
    )
  }
}

export const scheduleMultipleTimelineRefresh = (
  id,
  firstDelay,
  firstNumberOfRefreshes,
  secondDelay,
  secondNumberOfRefreshes
) => dispatch => {
  dispatch(scheduleTimelineRefresh(id, firstDelay, firstNumberOfRefreshes))
  dispatch(scheduleTimelineRefresh(id, secondDelay, secondNumberOfRefreshes))
}

export const updateTaskIfNeeded = (
  assignmentId,
  taskId,
  newStatus,
  link,
  description,
  type,
  refreshAssignment,
  dueDate
) => (dispatch, getState) => {
  const shouldExecuteTaskAction = (link && type) || false
  const shouldUpdateTask = newStatus || false
  const state = getState()

  if (shouldExecuteTaskAction)
    dispatch(
      executeTaskAction(
        taskId,
        link,
        description,
        type,
        assignmentId,
        shouldUpdateTask,
        dueDate
      )
    )

  if (refreshAssignment)
    setTimeout(() => dispatch(fetchAssignment(assignmentId)), 5000)

  logEventTrigger(
    'TaskActionClick',
    description,
    {
      assignmentId,
      taskId,
      newStatus,
      link,
      description,
      type,
      refreshAssignment,
      dueDate,
    },
    {
      dimension1: getLastSelectedUserDetails(state),
      dimension2: getLastSelectedCompanyId(state),
    }
  )
}

export const fetchTimelineAndExecuteTaskAction = (
  assignmentId,
  taskId,
  actionId,
  dueDate
) => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: TIMELINES_URL(setTimelinesQuery([assignmentId])),
      method: 'GET',
      types: [
        FETCH_TIMELINE_REQUEST,
        {
          type: FETCH_TIMELINE_SUCCESS,
          payload: (_action, _currentState, res) =>
            getJSON(res).then(json => {
              const data = normalize(mapTimeline(json[0]), timelineSchema)
              const task = data.entities.tasks[taskId]
              const action = task.actions.find(a => a.id === actionId)
              const {newStatus, link, description, type} = action

              dispatch(
                updateTaskIfNeeded(
                  assignmentId,
                  taskId,
                  newStatus,
                  link,
                  description,
                  type,
                  false,
                  dueDate
                )
              )

              return data
            }),
        },
        FETCH_TIMELINE_FAILURE,
      ],
    },
  })

const displayFailureNotification = (failedAction, additionalErrorText = null) =>
  notification['error']({
    message: FAILURE_NOTIFICATION_TITLE,
    description:
      !additionalErrorText || !additionalErrorText.includes('\\n') ? (
        `${failedAction} has failed ${
          additionalErrorText ? '- ' + additionalErrorText : ''
        }`
      ) : (
        <>
          <div>
            <span>
              {`${failedAction} has failed:`}
              <br />
            </span>
            {additionalErrorText.split('\\n').map((item, key) => {
              return (
                <span key={key}>
                  <i>{item}</i>
                  <br />
                </span>
              )
            })}
          </div>
        </>
      ),
    duration: TASK_ACTION_NOTIFICATION_DURATION,
  })
