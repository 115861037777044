import {
  boolPT,
  emptyObjectPT,
  funcPT,
  stringPT,
  momentObjectOrStringPT,
} from 'arena-prop-types'
import React from 'react'
import AsssignmentApproval from './Approval'
import Token from '../Token'
import {Banner, Tooltip} from '../../lib/components/core'
import {COUNTRY_BANNER_SRC} from '../../constants/images'
import {PT_CURRENCY_DEFAULTS} from '../../lib/constants/propTypes'
import {formatDate} from '../../utils/moment'
import {sectionItemsPT} from '../../constants/propTypes/sectionItems'

const Assignment = ({
  approve,
  currencyDefaults,
  employeeId,
  endDate,
  firstName,
  homeCountryName,
  hostCountryCode,
  hostCountryName,
  isMobile,
  lastName,
  positionToken,
  reject,
  sectionItems,
  startDate,
  title,
}) => {
  const src = (suffix = '') =>
    COUNTRY_BANNER_SRC.replace(':id', hostCountryCode + suffix)
  const mainSrc = src()
  const thumbSrc = src('_thumb')
  const fullName = `${firstName} ${lastName}`

  const assignmentDetails = [
    {
      id: 'level',
      label: 'Level',
      value: title,
    },
    {
      id: 'employee',
      label: 'Employee',
      value: <Tooltip title={employeeId}>{fullName}</Tooltip>,
    },
    {
      id: 'home',
      label: 'Home',
      value: homeCountryName,
    },
    {
      id: 'host',
      label: 'Host',
      value: hostCountryName,
    },
    {
      id: 'startDate',
      label: 'Start date',
      value: formatDate(startDate),
    },
    {
      id: 'endDate',
      label: 'End date',
      value: formatDate(endDate),
    },
  ]

  return (
    <div className="Assignment">
      <Banner
        backgroundColor="rgba(72, 73, 74, .75)"
        className="Assignment-banner"
        mainSrc={mainSrc}
        thumbSrc={thumbSrc}
      >
        {assignmentDetails.length ? (
          <div className="Assignment-details">
            {assignmentDetails.map(item => (
              <div key={item.id}>
                <div className="Assignment-label">{item.label}</div>
                <div className="Assignment-value">{item.value}</div>
              </div>
            ))}
          </div>
        ) : null}
        <AsssignmentApproval
          isMobile={isMobile}
          approve={approve}
          reject={reject}
        />
      </Banner>
      {positionToken && (
        <Token
          currencySettings={currencyDefaults}
          isMobile={isMobile}
          items={sectionItems}
        />
      )}
    </div>
  )
}

Assignment.defaultProps = {
  employeeId: null,
  endDate: null,
  firstName: null,
  homeCountryName: null,
  hostCountryCode: null,
  hostCountryName: null,
  lastName: null,
  positionToken: null,
  startDate: null,
}

Assignment.propTypes = {
  approve: funcPT(true),
  currencyDefaults: PT_CURRENCY_DEFAULTS.isRequired,
  employeeId: stringPT(),
  endDate: stringPT(),
  firstName: stringPT(),
  homeCountryName: stringPT(),
  hostCountryCode: stringPT(),
  hostCountryName: stringPT(),
  isMobile: boolPT(true),
  lastName: stringPT(),
  positionToken: emptyObjectPT(),
  reject: funcPT(true),
  sectionItems: sectionItemsPT(true),
  startDate: momentObjectOrStringPT(),
  title: stringPT(true),
}

export default Assignment
