import PT from 'prop-types'
import React from 'react'
import ImageLoader from './ImageLoader'

const defaultProps = {
  backgroundColor: null,
  className: '',
  thumbSrc: null,
}

const propTypes = {
  backgroundColor: PT.string,
  children: PT.node.isRequired,
  className: PT.string,
  mainSrc: PT.string.isRequired,
  thumbSrc: PT.string,
}

const Banner = ({backgroundColor, children, className, mainSrc, thumbSrc}) => (
  <div data-component="Banner" className={className}>
    {backgroundColor && (
      <div className="Banner-overlay overlay" style={{backgroundColor}} />
    )}
    <ImageLoader
      className="Banner-image overlay"
      mainSrc={mainSrc}
      thumbSrc={thumbSrc}
    />
    <div className="Banner-content">{children}</div>
  </div>
)

Banner.defaultProps = defaultProps
Banner.propTypes = propTypes

export default Banner
