import React from 'react'
import styled from 'styled-components'
import SearchInput from '../SeachInput'
import {Icon as AntIcon} from 'antd'

const Outer = styled.div`
  color: rgba(255, 255, 255, 0.8);
  position: relative;
  &:hover,
  &:focus-within {
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 1;
  }
`

const IconWrap = styled.div`
  color: inherit;
  cursor: text;
  display: inline-block;
  left: 20px;
  position: absolute;
  top: 12px;
  font-size: 1em;
  line-height: 1;
`

const Searchbar = props => {
  return (
    <Outer>
      <IconWrap>
        <AntIcon type="search"></AntIcon>
      </IconWrap>
      <SearchInput
        isSearchResultOpen={props.isSearchResultOpen}
        onKeyUp={props.handleSearch}
        onChange={props.handleChange}
        value={props.value}
      />
    </Outer>
  )
}

export default Searchbar
