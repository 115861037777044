import {normalize} from 'normalizr'
import {locationSchema} from '../constants/schemas'

export const filterLocaionsByEnabledFor = (locations, enabledFor) =>
  normalize(
    Object.values(locations).filter(
      location =>
        location.additionalProperties &&
        location.additionalProperties.enabledFor &&
        location.additionalProperties.enabledFor.includes(enabledFor)
    ),
    [locationSchema]
  ).entities.locations
