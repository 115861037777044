import PT from 'prop-types'
import {numberOrStringPT} from 'arena-prop-types'
import {alertsProp, alignProp, linksProp, routeProp} from '../propTypes'

export const headerDefaultProps = {
  activeTab: null,
  login: null,
  logout: null,
  onLogoClick: null,
  renderAdvancedSearch: null,
  renderSearchResults: null,
  search: null,
  searchString: '',
  searchOnChange: null,
  searchWidth: null,
  sendReport: null,
  toggleAdvancedSearchIsOpen: null,
  toggleSearchResultsIsOpen: null,
}

export const headerPropTypes = {
  activeTab: PT.number,
  advancedSearchIsOpen: PT.bool.isRequired,
  alerts: alertsProp.isRequired,
  currentRoute: routeProp.isRequired,
  defaultAction: PT.func.isRequired,
  disableAuthentication: PT.bool.isRequired,
  dropdownOptions: PT.array,
  dropdownDefaultValue: PT.shape({}),
  headerAlign: alignProp,
  headerBackgroundColor: PT.string.isRequired,
  headerHeight: numberOrStringPT(true),
  isAuthenticated: PT.bool.isRequired,
  links: linksProp.isRequired,
  login: PT.func,
  logoSrc: PT.string.isRequired,
  logout: PT.func,
  onLogoClick: PT.oneOfType([PT.func, PT.shape({}), PT.string]),
  renderAdvancedSearch: PT.node,
  renderSearchResults: PT.node,
  search: PT.func,
  typeahead: PT.func,
  searchString: PT.string,
  searchResults: PT.arrayOf(PT.shape({})),
  searchResultsFetching: PT.bool,
  searchOnChange: PT.func,
  searchResultsIsOpen: PT.bool.isRequired,
  searchValue: PT.string,
  searchWidth: numberOrStringPT(),
  sendReport: PT.func,
  subHeaderAlign: alignProp,
  subHeaderHeight: numberOrStringPT(true),
  toggleAdvancedSearchIsOpen: PT.func,
  toggleSearchResultsIsOpen: PT.func,
  user: PT.string.isRequired,
  zIndex: PT.number.isRequired,
}
