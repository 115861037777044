import PT from 'prop-types'
import {isRequired} from 'arena-prop-types'

const {arrayOf, shape} = PT

export const sectionItemProps = {}

export const sectionItemPT = is => isRequired(shape(sectionItemProps), is)

export const sectionItemsPT = is => isRequired(arrayOf(sectionItemPT), is)
