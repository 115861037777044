import PT from 'prop-types'
import {numberOrStringPT} from 'arena-prop-types'

export const userDefaultProps = {
  login: null,
  logout: null,
  sendReport: null,
}

export const userPropTypes = {
  disableAuthentication: PT.bool.isRequired,
  defaultAction: PT.func.isRequired,
  height: numberOrStringPT(true),
  login: PT.func,
  logout: PT.func,
  sendReport: PT.func,
  user: PT.string.isRequired,
}
