import React from 'react'
import {connect} from 'react-redux'
import {initEnvironment, initRouter} from 'arena-redux'
import IdleMonitor from 'react-simple-idle-monitor'
import {displayIdleNotification} from '../../actions/idle'
import {downloadFile} from '../../actions/downloadFile'
import {INACTIVE_TIMEOUT_DURATION} from '../../constants/notification'
import AuthCallbackContainer from '../AuthCallback'
import CreateAssignmentContainer from '../CreateAssignment'
import EditAssignmentContainer from '../EditAssignment'
import OverviewContainer from '../Overview'
import Root from '../../components/Root'
import ViewAssignmentContainer from '../ViewAssignment'
import {
  ASSIGNMENTS_PATH,
  AUTHENTICATION_CALLBACK_PATH,
  CREATE_ASSIGNMENT_PATH,
  EDIT_ASSIGNMENT_PATH,
  INDEX_PATH,
  VIEW_ASSIGNMENT_PATH,
} from '../../constants/router'
import {
  getChannel,
  getChannelFilters,
  getChannelsInfo,
  getLastActiveChannel,
  getChannelFetching,
  getAutoCompleteAssignments,
  getAutoCompleteAssignmentsNext,
} from '../../selectors/channels'
import {
  getAutoCompleteAssignmentsIsFetching,
  getAutoCompleteAssignmentsNextIsFetching,
} from '../../selectors/assignments'
import {getIsAuthenticated, getSessionUser} from '../../selectors/session'
import {getRouter, getSearch} from '../../selectors/router'
import {login, logout} from '../../actions/session'
import {
  fetchAssignmentsIfNeeded,
  updateChannelFilters,
  searchAssignments,
  fetchAutoCompleteAssignments,
  fetchAutocompleteAssignmentsNext,
  updateLastActiveChannel,
  fetchChannelSummary,
} from '../../actions/channels'
import {sendMail} from '../../actions/mail'
import {updateLastSelectedCompany} from '../../actions/companies'
import {
  getCompanies,
  getLastSelectedCompany,
  getLastSelectedCompanyFormRecordTitle,
  getLastSelectedCompanyMessages,
} from '../../selectors/company'
import {SWITCH_COMPANY_FORM} from '../../constants/form'
import {getAssignmentFormValues} from '../../selectors/form'
import {navigateTo} from '../../actions/navigation'
import {getIsMobile} from '../../selectors/environment'
import {
  getExportProgressPercentage,
  getExportProgressIsStarting,
} from '../../selectors/exportProgress'

const RootContainer = props => (
  <IdleMonitor
    events={[
      'keydown',
      'mousedown',
      'MSPointerDown',
      'touchmove',
      'touchstart',
    ]}
    onIdle={props.displayIdleNotification}
    reduxActionPrefix="Idle_monitor"
    timeout={INACTIVE_TIMEOUT_DURATION}
  >
    <Root {...props} />
  </IdleMonitor>
)

const mapStateToProps = state => ({
  autocompleteAssignmentsList: getAutoCompleteAssignments(state),
  autocompleteAssignmentsListIsFetching: getAutoCompleteAssignmentsIsFetching(
    state
  ),
  autocompleteAssignmentsNext: getAutoCompleteAssignmentsNext(state),
  autoCompleteAssignmentsNextIsFetching: getAutoCompleteAssignmentsNextIsFetching(
    state
  ),
  channel: getChannel(state),
  channelFilters: getChannelFilters(state),
  channelsInfo: getChannelsInfo(state),
  channelFetching: getChannelFetching(state),
  companies: getCompanies(state),
  currentValues: getAssignmentFormValues(state, {
    formName: SWITCH_COMPANY_FORM,
    fieldsToGet: ['company'],
  }),
  exportProgressPercentage: getExportProgressPercentage(state),
  exportProgressIsStarting: getExportProgressIsStarting(state),
  isAuthenticated: getIsAuthenticated(state),
  isMobile: getIsMobile(state),
  lastActiveChannel: getLastActiveChannel(state),
  lastSelectedCompany: getLastSelectedCompany(state),
  paths: [
    ASSIGNMENTS_PATH,
    AUTHENTICATION_CALLBACK_PATH,
    CREATE_ASSIGNMENT_PATH,
    EDIT_ASSIGNMENT_PATH,
    INDEX_PATH,
    VIEW_ASSIGNMENT_PATH,
  ],
  router: getRouter(state),
  routes: {
    [ASSIGNMENTS_PATH]: OverviewContainer,
    [AUTHENTICATION_CALLBACK_PATH]: AuthCallbackContainer,
    [CREATE_ASSIGNMENT_PATH]: CreateAssignmentContainer,
    [EDIT_ASSIGNMENT_PATH]: EditAssignmentContainer,
    [INDEX_PATH]: OverviewContainer,
    [VIEW_ASSIGNMENT_PATH]: ViewAssignmentContainer,
  },
  search: getSearch(state),
  user: getSessionUser(state),
  formTitle: getLastSelectedCompanyFormRecordTitle(state),
  alertMessage: getLastSelectedCompanyMessages(state),
})

export default connect(mapStateToProps, {
  downloadFile,
  displayIdleNotification,
  fetchAssignmentsIfNeeded,
  initEnvironment,
  initRouter,
  login,
  logout,
  navigateTo,
  searchAssignments,
  fetchAutoCompleteAssignments,
  fetchAutocompleteAssignmentsNext,
  sendMail,
  updateChannelFilters,
  updateLastActiveChannel,
  fetchChannelSummary,
  updateLastSelectedCompany,
})(RootContainer)
