export const getCountry = (countries, id) =>
  countries && Object.keys(countries).length > 0 && id
    ? Object.values(countries)
        .filter(c => c.id === id)
        .reduce(c => c.value)
    : null

export const getCountryCode = (countries, id) => {
  const country = getCountry(countries, id)

  return country ? country.value : null
}

export const getLocationId = (locations, name) => {
  const location = getLocationByName(locations, name)
  if (location && location.length > 0) {
    return location.reduce(c => c.id).id
  } else return -1
}

export const getLocationCountryCode = (locations, name) => {
  const location = getLocationByName(locations, name)
  if (location && location.length > 0) {
    return location.reduce(c => c.code).code
  } else return '-1'
}
const getPlanTypeAdditionalProperties = (policies, name) => {
  const policy = getPolicyByName(policies, name)
  if (policy && policy.length > 0)
    return JSON.parse(
      JSON.stringify(policy.reduce(c => c.id).additionalProperties)
    )
  return {}
}

export const getPlanTypeAdditionalPropertiesByKeys = (
  policies,
  keys,
  planTypeCode
) => {
  return keys.map(key => {
    if (key === 'hostHypoCompEnabled' || key === 'hostHypoTaxEnabled') {
      return {
        [key]: getPlanTypeAdditionalPropertiesByKey(
          policies,
          planTypeCode,
          key,
          false
        ),
      }
    }
    return {
      [key]: getPlanTypeAdditionalPropertiesByKey(policies, planTypeCode, key),
    }
  })
}
const getPlanTypeAdditionalPropertiesByKey = (
  policies,
  name,
  key,
  defaultValue = true
) => {
  const policyAdditionalProperties = getPlanTypeAdditionalProperties(
    policies,
    name
  )
  if (Object.keys(policyAdditionalProperties).length > 0) {
    return JSON.parse(policyAdditionalProperties[key])
  }
  return defaultValue
}

const getLocationByName = (locations, name) => {
  return locations && Object.keys(locations).length > 0 && name
    ? Object.values(locations).filter(c => c.value === name)
    : null
}
const getPolicyByName = (policies, name) => {
  return policies && Object.keys(policies).length > 0 && name
    ? Object.values(policies).filter(c =>
        typeof name === 'string' ? c.value === name : c.value === name.value
      )
    : null
}
