import Modal from 'antd/lib/modal'
import 'antd/lib/modal/style/index.css'
import React, {useCallback} from 'react'
import PT from 'prop-types'
import cn from 'classnames'
import {
  boolPT,
  funcPT,
  numberPT,
  stringPT,
  emptyObjectPT,
} from 'arena-prop-types'
import UpdateAssignmentDetails from './UpdateAssignmentDetails'
import {Button, Icon, Tooltip} from '../../lib/components/core'
import {TASK_IN_PROGRESS, TASK_NOT_STARTED} from '../../constants/tasks'
import {formFieldPT} from '../../constants/propTypes/formFields'
import {taskActionPT} from '../../constants/propTypes/taskActions'
import {taskStatusProp} from '../../constants/propTypes/taskStatus'
import _ from 'lodash'
import {isIE} from '../../utils/browser'

const TaskAction = props => {
  const {
    description,
    download,
    icon,
    id,
    isDisabled,
    label,
    link,
    missingFields,
    newStatus,
    target,
    type,
  } = props.action
  const {
    assignmentId,
    errors,
    fetchFormSectionsIfNeeded,
    formFields,
    refreshCardOnComplete,
    status,
    taskBeingExecuted,
    taskId,
    toggleUpdateAssignmentOptions,
    updateAssignmentOptionsAndExecuteTask,
    updateTaskIfNeeded,
    updatingAssignmentOptions,
    assignmentOptionIsUpdating,
    dueDate,
  } = props

  const isExecuting = taskId === taskBeingExecuted
  const isUpdating = assignmentOptionIsUpdating
  const taskIsActive = TASK_IN_PROGRESS(status) || TASK_NOT_STARTED(status)
  const taskIsInProgress = TASK_IN_PROGRESS(status)
  const buttonCss = cn(
    'Task-action round smaller',
    {blue: taskIsInProgress},
    {solid: taskIsActive}
  )
  const iconCss = cn(
    'Task-action-icon',
    {'rotate-90': icon === 'arrow'},
    {'rotate-270': icon === 'up-arrow'},
    {inlineSVG: taskIsActive},
    {white: taskIsActive}
  )

  const debounceInterval = isIE ? 3500 : 1500
  const debouncedActionButtonClick = useCallback(
    _.debounce(
      () =>
        updateTaskIfNeeded(
          assignmentId,
          taskId,
          newStatus,
          link,
          description,
          type,
          refreshCardOnComplete,
          dueDate
        ),
      debounceInterval,
      {leading: true, trailing: false}
    ),
    [link]
  )

  const buttonProps = {
    className: cn(buttonCss, {isExecuting}),
    download,
    target,
    isDisabled: isDisabled || isExecuting,
    onClick: debouncedActionButtonClick,
  }

  const shouldShowUpdateAssignmentDetails =
    `${assignmentId}_${taskId}_${id}` === updatingAssignmentOptions

  return (
    // TODO: Replace key with a unique ID.
    <div className="Task-action-wrap" key={`${taskId}_${id}`}>
      {missingFields && missingFields.length > 0 ? (
        <span>
          <Tooltip title={label}>
            <span>
              <Button
                {...buttonProps}
                onClick={() =>
                  toggleUpdateAssignmentOptions(assignmentId, taskId, id)
                }
              >
                <Icon alt={icon} className={iconCss} inlineSVG type={icon} />
              </Button>
            </span>
          </Tooltip>
          <Modal
            visible={shouldShowUpdateAssignmentDetails}
            onCancel={() => toggleUpdateAssignmentOptions()}
            footer={null}
          >
            <UpdateAssignmentDetails
              errors={errors}
              fetchFormSectionsIfNeeded={fetchFormSectionsIfNeeded}
              formFields={formFields}
              label={description}
              missingFields={missingFields}
              onSubmit={options =>
                updateAssignmentOptionsAndExecuteTask(
                  assignmentId,
                  options,
                  taskId,
                  id,
                  dueDate
                )
              }
              onSubmitSuccess={() => toggleUpdateAssignmentOptions()}
              isSubmitting={isUpdating}
            />
          </Modal>
        </span>
      ) : (
        <span>
          <Tooltip title={label}>
            <span>
              <Button {...buttonProps}>
                <Icon alt={icon} className={iconCss} inlineSVG type={icon} />
              </Button>
            </span>
          </Tooltip>
        </span>
      )}
    </div>
  )
}

TaskAction.defaultProps = {
  formFields: {},
  status: null,
  taskBeingExecuted: null,
  updatingAssignmentOptions: null,
}

TaskAction.propTypes = {
  action: taskActionPT(true),
  assignmentId: numberPT(true),
  errors: emptyObjectPT(),
  fetchFormSectionsIfNeeded: funcPT(true),
  formFields: PT.objectOf(formFieldPT(true)),
  refreshCardOnComplete: boolPT(true),
  status: taskStatusProp,
  taskBeingExecuted: numberPT(),
  taskId: numberPT(true),
  toggleUpdateAssignmentOptions: funcPT(true),
  updateAssignmentOptionsAndExecuteTask: funcPT(true),
  updateTaskIfNeeded: funcPT(),
  updatingAssignmentOptions: stringPT(),
}

export default TaskAction
