import {RSAA} from 'redux-api-middleware'

export default store => next => action => {
  const callApi = action[RSAA]
  const state = store.getState()
  if (state.oidc.user) {
    const token = state.oidc.user.access_token || ''

    if (callApi)
      callApi.headers = {...callApi.headers, Authorization: `Bearer ${token}`}
  }

  return next(action)
}
