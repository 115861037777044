import {formatMoney} from 'accounting'
import {stringPT} from 'arena-prop-types'
import React from 'react'
import {PT_CURRENCY_DEFAULTS} from '../../lib/constants/propTypes'

const TokenField = ({currencySettings, label, value, valueSuffix}) => {
  const {decimal, thousand} = currencySettings

  return (
    <div>
      <div className="Assignment-label">{label}</div>
      <div className="Assignment-value">
        {formatMoney(value, '', 0, thousand, decimal)} {valueSuffix}
      </div>
    </div>
  )
}

TokenField.defaultProps = {
  valueSuffix: '',
}

TokenField.propTypes = {
  currencySettings: PT_CURRENCY_DEFAULTS.isRequired,
  label: stringPT(true),
  value: stringPT(true),
  valueSuffix: stringPT(),
}

export default TokenField
