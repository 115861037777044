import Button from 'arena-button'
import React from 'react'
import timer from 'react-timer-hoc'
import styled from 'styled-components'
import {INACTIVE_WARNING_DURATION} from '../../constants/notification'

const Buttons = styled.div`
  button {
    margin-right: 0.5em;
    padding: 4px;
    background: #1cdbbc;
    border: 1px solid #1cdbbc;
    border-radius: 24px;
    color: #000000;
    cursor: pointer;
    &:hover {
      background: #8bf5e3;
      border: 1px solid #8bf5e3;
    }
  }
  padding-top: 0.5em;
`
const StyledTimeRemaining = styled.div`
  font-size: 2.5em;
  font-weight: 40;
`

const IdlePopover = props => {
  const {dismissIdleNotification, logout} = props

  const Countdown = ({timer}) => {
    const minutesRemaining = Math.floor(
      (INACTIVE_WARNING_DURATION - timer.tick) / 60
    )
    const secondsRemaining = ((INACTIVE_WARNING_DURATION - timer.tick) % 60)
      .toString()
      .padStart(2, '0')

    const timeRemaining = `${minutesRemaining}:${secondsRemaining}`

    if (timer.tick === INACTIVE_WARNING_DURATION) {
      dismissIdleNotification()
      logout()
    }

    return <StyledTimeRemaining>{timeRemaining}</StyledTimeRemaining>
  }

  const Timer = timer(1000)(Countdown)

  return (
    <div>
      <div>You will be logged out automatically in</div>
      <Timer />
      <Buttons>
        <Button onClick={dismissIdleNotification} type="primary">
          Stay logged in
        </Button>
        <Button
          onClick={() => {
            dismissIdleNotification()
            logout()
          }}
        >
          Log out now
        </Button>
      </Buttons>
    </div>
  )
}

export default IdlePopover
