import PT from 'prop-types'
import {commonPropTypes} from '../../propTypes'

export const dataLabelDefaultProps = {
  isTitle: false,
}

export const dataLabelPropTypes = {
  ...commonPropTypes,
  children: PT.node.isRequired,
  isTitle: PT.bool,
}
