import Collapsible from 'react-collapsible'
import PT from 'prop-types'
import React from 'react'
import {Button} from 'arena-components'
import {boolPT, emptyObjectPT} from 'arena-prop-types'
import FormSection from '../Form/Section'
import SubSectionName from '../Form/SubSectionName'
import {formSectionPT} from '../../constants/propTypes/formSections'

const FamilyMembers = props => {
  const {
    fields,
    customFormSectionData: {familySection, isLocked},
    errors,
    helpText,
  } = props
  // This needs a rewrite, the onClick handler is a mess.
  return (
    <div>
      <FormSection
        columnCount={1}
        formFields={[]}
        helpText={helpText}
        name={
          <>
            <span>Family Members</span>{' '}
            {!isLocked && (
              <Button
                type="button"
                onClick={() =>
                  fields.push({
                    lastName: `${(fields &&
                      fields.get(0) &&
                      fields.get(0).lastName) ||
                      ''}`,
                  })
                }
              >
                Add family member
              </Button>
            )}
          </>
        }
      >
        {fields.map((familyMember, index) => {
          const familyMemberDetails = fields && fields.get(index)
          const relationshipType =
            familyMemberDetails && familyMemberDetails.relationshipType
          const firstName = familyMemberDetails && familyMemberDetails.firstName
          const lastName = familyMemberDetails && familyMemberDetails.lastName

          const hasAssigneeDetails = `${relationshipType}` === 'Assignee'
          const isAssignee = index === 0 && hasAssigneeDetails

          const familyMemberSectionTitle =
            relationshipType && firstName && lastName
              ? `${relationshipType} - ${firstName} ${lastName}`
              : `Family Member #${hasAssigneeDetails ? index : index + 1}`

          const relationshipTypeOptionsForFamilyMembers = JSON.parse(
            JSON.stringify(familySection.formFields[2].options)
          ).filter(item => item.value && item.value !== 'Assignee')

          // Update index of the `familyMemberRequests` array as it's always 0 otherwise.
          const familyFormFields = familySection.formFields.map(
            familyFormField => {
              if (
                index === 0 &&
                familyFormField.code.split('.')[1] === 'relationshipType'
              ) {
                return {
                  ...familyFormField,
                  code: familyFormField.code.replace('[0]', `[${index}]`),
                  disabled: true,
                }
              }
              if (
                index > 0 &&
                familyFormField.code.split('.')[1] === 'relationshipType'
              ) {
                return {
                  ...familyFormField,
                  code: familyFormField.code.replace('[0]', `[${index}]`),
                  options: relationshipTypeOptionsForFamilyMembers,
                }
              }
              return {
                ...familyFormField,
                code: familyFormField.code.replace('[0]', `[${index}]`),
              }
            }
          )
          return (
            <Collapsible
              key={familyMember}
              trigger={
                <SubSectionName>
                  <span>
                    &#9663; {isAssignee ? 'Assignee' : familyMemberSectionTitle}
                  </span>{' '}
                  {!isLocked && fields && fields.length > 1 && !isAssignee ? (
                    <Button type="button" onClick={() => fields.remove(index)}>
                      Remove family member
                    </Button>
                  ) : null}
                </SubSectionName>
              }
              triggerWhenOpen={
                <SubSectionName>
                  <span>
                    &#9653; {isAssignee ? 'Assignee' : familyMemberSectionTitle}
                  </span>{' '}
                  {!isLocked && fields && fields.length > 1 && !isAssignee ? (
                    <Button type="button" onClick={() => fields.remove(index)}>
                      Remove family member
                    </Button>
                  ) : null}
                </SubSectionName>
              }
              open={fields && fields.length - 1 === index}
              overflowWhenOpen="visible"
            >
              <FormSection
                columnCount={2}
                errors={errors}
                formFields={familyFormFields}
                isLocked={isLocked}
                resources={props.resources}
              />
            </Collapsible>
          )
        })}
      </FormSection>
    </div>
  )
}

FamilyMembers.propTypes = {
  customFormSectionData: PT.shape({
    familySection: formSectionPT(true),
    isLocked: boolPT(true),
  }).isRequired,
  fields: emptyObjectPT(true),
  resources: emptyObjectPT(true),
}

export default FamilyMembers
