import React from 'react'
import styled from 'styled-components'
import {Field} from '../../lib/components/arena-plugin-form'
import {TEXT_AREA_FIELD} from 'arena-config'

const Wrapper = styled.div`
  margin: 0 45px;
`

const Comments = () => (
  <Wrapper>
    <h2>Comments</h2>
    <Field name="Comments" type={TEXT_AREA_FIELD} />
  </Wrapper>
)

export default Comments
