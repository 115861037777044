/* eslint-disable sort-keys */
import React from 'react'
import {IdlePopover} from '../components/IdlePopover'
import {
  IDLE_NOTIFICATION_IS_VISIBLE,
  NOTIFICATION_IDLE,
} from '../constants/notification'
import {executeNotification} from '../utils/idle'
import {logout} from '../actions/session'
import {getIdleNotificationIsVisible} from '../selectors/idle'

const setIdleNotificationIsVisible = isVisible => ({
  type: IDLE_NOTIFICATION_IS_VISIBLE,
  isVisible,
})

export const displayIdleNotification = () => (dispatch, getState) => {
  const state = getState()
  const idleNotificationIsVisible = getIdleNotificationIsVisible(state)

  if (!idleNotificationIsVisible) {
    dispatch(setIdleNotificationIsVisible(true))
    executeNotification[NOTIFICATION_IDLE].open(
      'Are you still there?',
      <IdlePopover
        dismissIdleNotification={() => {
          dispatch(setIdleNotificationIsVisible(false))
          executeNotification[NOTIFICATION_IDLE].close()
        }}
        logout={() => dispatch(logout())}
      />,
      () => dispatch(setIdleNotificationIsVisible(false))
    )
  }
}
