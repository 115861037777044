import PT from 'prop-types'
import React, {Component} from 'react'
import {getBaseUrl} from 'arena-redux'
import Callback from '../components/Callback'
import userManager from '../../utils/userManager'
import {Spin} from 'antd'
import styled from 'styled-components'

const FlyerWrapper = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`
const Spinner = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #0a838a;
  }
  .ant-spin-text {
    color: #0a838a;
    font-size: 1.5rem;
    font-weight: 200;
  }
`
class AuthCallback extends Component {
  successCallback = user => {
    this.props.dispatch(this.props.loginSuccess(user.id_token))
    this.props.dispatch(this.props.navigateTo(user.state.router.route))
  }

  errorCallback = error => {
    console.error(
      `There was an error handling the token callback: ${error.message}`
    )
    this.props.dispatch(() => (window.location = getBaseUrl()))
  }

  render() {
    const route = window.location.href
      .split('?')
      .slice(1)
      .join('?')
    // just redirect to '/' in both cases
    return (
      <Callback
        errorCallback={this.errorCallback}
        query={route}
        successCallback={this.successCallback}
        userManager={userManager}
      >
        <FlyerWrapper>
          <Spinner
            size="large"
            tip="Secure login successful, welcome back..."
          />
        </FlyerWrapper>
      </Callback>
    )
  }
}

AuthCallback.propTypes = {
  navigateTo: PT.func.isRequired,
}

export default AuthCallback
