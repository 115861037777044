export default resources => {
  const result = {}
  const keys = Object.keys(resources)

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i]
    const value = resources[key]

    if (value.showResourceByDefault) {
      result[key] = value
    }
  }

  return result
}
