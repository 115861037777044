import * as c from 'arena-config'
import React from 'react'
import styled from 'styled-components'
import {bodyDefaultProps, bodyPropTypes} from './propTypes'

const calculatePaddingTop = props =>
  props.hasHeader
    ? c.px(
        props.headerHeight + (props.hasHeaderBottom ? props.subHeaderHeight : 0)
      )
    : 0

const Wrap = styled.div`
  background-color: #f7f8fa;
  background-repeat: repeat;
  background-size: auto 140px;
  margin-bottom: ${props => props.headerHeight + 'px'};
  left: 0;
  line-height: 1.5;
  overflow: auto;
  position: relative;
  right: 0;
  margin-top: ${props => 'calc(' + props.headerHeight + 'px + 10px)'};

  ${c.FOR_DESKTOP} {
    margin-top: ${props => calculatePaddingTop(props)};
    margin-bottom: 0;
    height: calc(100% - 80px);
  }
`

const Body = props => (
  <Wrap
    hasHeader={props.hasHeader}
    hasHeaderBottom={props.hasHeaderBottom}
    headerHeight={props.headerHeight}
    subHeaderHeight={props.subHeaderHeight}
    shouldHideDropdwon={props.shouldHideDropdwon}
  >
    {props.children}
  </Wrap>
)

Body.defaultProps = bodyDefaultProps
Body.propTypes = bodyPropTypes

export default Body
