import {navigateTo as arenaReduxNavigateTo} from 'arena-redux'
import {clearErrorsOnNavigate} from './errors'
import {
  getLastSelectedCompanyId,
  getLastSelectedUserDetails,
} from '../selectors/company'
import {getHasError} from '../selectors/errors'
import {logPageView} from '../lib/utils/analytics'

export const navigateTo = params => (dispatch, getState) => {
  const state = getState()
  const shouldClearPreviousError = getHasError(state)
  dispatch(arenaReduxNavigateTo(params))
  if (shouldClearPreviousError) {
    dispatch(clearErrorsOnNavigate())
  }

  // log the page view
  // design note: dimension2 is a predefined custom dimension already configured on our analytics instance for storing companyId
  logPageView(params, {
    dimension1: getLastSelectedUserDetails(state),
    dimension2: getLastSelectedCompanyId(state),
  })
}
