import PT from 'prop-types'
import React from 'react'
import {emptyObjectPT} from 'arena-prop-types'
import FormSection from '../Form/Section'
import {resourcesPT} from '../../constants/propTypes/resources'
import {calculationSectionsPT} from '../../constants/propTypes/calculationSections'
import {NO_CALCULATION_SECTIONS_MESSAGE} from '../../constants/validationMessages'

const loadingMessage = 'Loading relevant calculation options, please wait...'

const hasLocationsSelected = currentValues =>
  currentValues &&
  currentValues.homeLocation &&
  currentValues.homeLocation.value &&
  currentValues.hostLocation &&
  currentValues.hostLocation.value &&
  currentValues.planTypeCode &&
  currentValues.planTypeCode.value

const shouldDisplaySection = (section, currentValues) =>
  section && hasLocationsSelected(currentValues)

const shouldDisplayLoading = customFormSectionData => {
  return customFormSectionData &&
    customFormSectionData.calculationSections &&
    customFormSectionData.calculationSections.length > 0
    ? null
    : loadingMessage
}

const noDataPlaceholderMessage = (customFormSectionData, currentValues) =>
  !hasLocationsSelected(currentValues)
    ? NO_CALCULATION_SECTIONS_MESSAGE
    : shouldDisplayLoading(customFormSectionData)

const CalculationOptions = ({
  customFormSectionData,
  resources,
  currentValues,
  errors,
  helpText,
}) => {
  return (
    <div className="CalculationOptions" key="CalculationOptionsSection">
      {hasLocationsSelected(currentValues) &&
      customFormSectionData.calculationSections &&
      customFormSectionData.calculationSections.length > 0 ? (
        customFormSectionData.calculationSections.map(section => {
          return (
            shouldDisplaySection(section, currentValues) && (
              <FormSection
                code={section.code}
                columnCount={section.columnCount}
                formFields={section.calculationFields}
                isLocked={customFormSectionData.isLocked}
                key={section.code}
                name={`${section.name}`}
                noDataPlaceholderMessage={NO_CALCULATION_SECTIONS_MESSAGE}
                resources={resources}
                errors={errors}
              />
            )
          )
        })
      ) : (
        <FormSection
          code="CalculationOptionsSection"
          columnCount={1}
          formFields={[]}
          helpText={helpText}
          isLocked={customFormSectionData.isLocked}
          key="CalculationOptionsSection"
          name="Calculation Options"
          noDataPlaceholderMessage={noDataPlaceholderMessage(
            customFormSectionData,
            currentValues
          )}
          resources={resources}
          errors={errors}
        />
      )}
    </div>
  )
}

CalculationOptions.propTypes = {
  currentValues: emptyObjectPT(true),
  customFormSectionData: PT.shape({
    calculationSections: calculationSectionsPT(true),
  }).isRequired,
  resources: resourcesPT(true),
}

export default CalculationOptions
