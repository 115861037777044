import {Flex} from 'arena-components'
import deepEqual from 'deep-equal'
import React from 'react'
import styled from 'styled-components'
import * as c from 'arena-config'
import HeaderLink from '../HeaderLink'
import {headerLinksDefaultProps, headerLinksPropTypes} from './propTypes'
import {showMessage} from '../../Message'
import {Badge} from 'antd'
import {useSelector} from 'react-redux'
import {getChannelSummaryCount} from '../../../../selectors/channelSummary'

const prepareAlignString = alignString => {
  switch (alignString) {
    case 'left':
      return 'flex-start'
    case 'right':
      return 'flex-end'
    default:
      return 'center'
  }
}
const StyledFlex = styled(Flex)`
  flex-direction: ${props => props.styles && props.styles.flexDirection};
  flex-grow: 1;
  width: 100%;
  ${c.FOR_DESKTOP} {
    width: auto;
  }
`
const Badgewrapper = styled.span`
  position: relative;
  top: -2px;
  padding-left: 3px;
`

const HeaderLinks = props => {
  const getIsActive = link =>
    (link.to && deepEqual(props.currentRoute, link.to)) ||
    (link.tab !== null && link.tab === props.activeTab)
  const channelCount = useSelector(state => getChannelSummaryCount(state))
  const badgeColour = '#e86153'
  const standerdLinks = props.links.map((link, key) => {
    return (
      !link.customRenderer && (
        <HeaderLink
          customRenderer={link.customRenderer}
          handleClick={() => {
            try {
              if (link.to) {
                props.defaultAction(link.to)
              }
              if (link.onClick) {
                if (link.shouldCloseMobileMenu) {
                  link.onClick()
                  props.onMobileMenuIconClick()
                } else {
                  link.onClick()
                }
              }
            } catch (error) {
              showMessage(
                'Sorry, an unexpected error occurred while processing your request.',
                'error'
              )
              console.error(error)
            }
          }}
          height={props.height}
          id={link.id}
          isActive={getIsActive(link)}
          key={link.id}
          to={link.to}
          white={props.white}
          hasProgress={link.hasProgress}
          progress={link.progress}
          isStarting={link.isStarting}
        >
          {link.icon}
          {link.title || link.id}
          {link.title === 'Past Due' ? (
            <Badgewrapper>
              <Badge
                count={channelCount}
                style={{backgroundColor: badgeColour}}
              ></Badge>
            </Badgewrapper>
          ) : (
            ''
          )}
        </HeaderLink>
      )
    )
  })

  const cusTomLinks = props.links.map((link, key) => {
    return (
      link.customRenderer && (
        <HeaderLink
          customRenderer={link.customRenderer}
          handleClick={() => {
            try {
              if (link.to) {
                props.defaultAction(link.to)
              }
              if (link.onClick) {
                if (link.shouldCloseMobileMenu) {
                  link.onClick()
                  props.onMobileMenuIconClick()
                } else {
                  link.onClick()
                }
              }
            } catch (error) {
              showMessage(
                'Sorry, an unexpected error occurred while processing your request.',
                'error'
              )
              console.error(error)
            }
          }}
          height={props.height}
          id={link.id}
          isActive={getIsActive(link)}
          key={link.id}
          to={link.to}
          white={props.white}
        >
          {link.icon}
          {link.title || link.id}
        </HeaderLink>
      )
    )
  })

  return (
    <Flex>
      <StyledFlex
        justifyContent={prepareAlignString(props.align)}
        styles={props.styles}
      >
        {standerdLinks}
      </StyledFlex>
      <StyledFlex justifyContent="flex-end" styles={props.styles}>
        {cusTomLinks}
      </StyledFlex>
    </Flex>
  )
}

HeaderLinks.defaultProps = headerLinksDefaultProps
HeaderLinks.propTypes = headerLinksPropTypes

export default HeaderLinks
