import PT from 'prop-types'
import React from 'react'
import {randomRange} from '../../utils/number'

const defaultProps = {
  className: '',
  maxCount: 1,
  maxLength: 4,
  minCount: 1,
  minLength: 4,
}

const propTypes = {
  className: PT.string,
  maxCount: PT.number.isRequired,
  maxLength: PT.number.isRequired,
  minCount: PT.number.isRequired,
  minLength: PT.number.isRequired,
}

const Blokk = ({className, maxCount, maxLength, minLength, minCount}) => {
  const randomCount = randomRange(minCount, maxCount)
  const results = []

  for (let i = 0; i < randomCount; i += 1) {
    const randomLength = randomRange(minLength, maxLength)
    const word = []

    for (let j = 0; j < randomLength; j++) {
      word.push(1)
    }

    if (i < randomCount - 1) {
      word.push(0)
    }

    results.push(...word)
  }

  return (
    <span data-component="Blokk" className={className}>
      {results.map((result, key) => {
        const isDash = result === 1
        const css = `blokk-${isDash ? 'dash' : 'space'}`
        return (
          <span className={css} key={key}>
            {isDash && '-'}
          </span>
        )
      })}
    </span>
  )
}

Blokk.defaultProps = defaultProps
Blokk.propTypes = propTypes

export default Blokk
