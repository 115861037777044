import {navigateTo} from '../../actions/navigation'
import React from 'react'
import {connect} from 'react-redux'
import AuthCallback from '../../lib/components/AuthCallback'
import {loginSuccess} from '../../actions/session'

const AuthCallbackContainer = props => <AuthCallback {...props} />

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  loginSuccess,
  navigateTo,
  dispatch,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthCallbackContainer)
