import PT from 'prop-types'
import React from 'react'
import Masonry from 'react-masonry-component'

const defaultProps = {
  className: '',
  enableOnMobile: false,
  transitionDuration: 0,
}

const propTypes = {
  children: PT.arrayOf(PT.node).isRequired,
  className: PT.string,
  enableOnMobile: PT.bool,
  isMobile: PT.bool.isRequired,
  transitionDuration: PT.number,
}

const Gallery = ({
  children,
  className,
  enableOnMobile,
  isMobile,
  transitionDuration,
}) => {
  const options = {
    transitionDuration,
  }

  return isMobile || !enableOnMobile ? (
    <div className={className}>{children}</div>
  ) : (
    <Masonry className={className} options={options}>
      {children}
    </Masonry>
  )
}

Gallery.defaultProps = defaultProps
Gallery.propTypes = propTypes

export default Gallery
