import styled from 'styled-components'

const Tab = styled.div`
  font-size: 0.875em;
  padding: 0.75em 0;
  position: relative;
  display: ${props => (props.sectionShouldHide ? 'none' : 'block')};
`

export default Tab
