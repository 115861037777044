import React from 'react'
import styled from 'styled-components'
import {Absolute, Icon, Tabs} from 'arena-components'
import {
  FONT_WEIGHT_4,
  LARGE,
  LARGEST,
  MEDIUM,
  RED,
  SMALL,
  SMALLER,
} from 'arena-config'
import {Form, Submit} from '../../lib/components/arena-plugin-form'
import {
  boolPT,
  emptyObjectPT,
  funcPT,
  numberPT,
  stringPT,
} from 'arena-prop-types'
import FormSection from './Section'
import Tab from './Tab'
import ErrorMessage from './ErrorMessage'
import customFormSections from '../customFormSections'
import {
  ASSIGNMENT_IS_LOCKED,
  GENERIC_ERROR_MESSAGE,
} from '../../constants/validationMessages'
import {accountSectionsPT} from '../../constants/propTypes/accountSections'
import {assignmentAuditLogsPT} from '../../constants/propTypes/assignmentAuditLogs'
import {formSectionsPT} from '../../constants/propTypes/formSections'
import {resourcesPT} from '../../constants/propTypes/resources'
import {taxSectionsPT} from '../../constants/propTypes/taxSections'
import {todaysDate} from '../../utils/moment'
import {filterFields} from '../../utils/api'
import Loader from './Loader'

const TabWrapper = styled.div`
  > div > div > div > div:first-child {
    border-right: solid 1px grey;
  }

  ul.react-tabs__tab-list,
  .react-tabs__tab-panel {
    border: 0;
  }

  ul.react-tabs__tab-list {
    li[role='tab'][aria-selected='true'] {
      color: teal;
    }
  }
`

const Footer = styled.div`
  padding: ${MEDIUM}px ${LARGE}px ${LARGEST}px;
  text-align: center;
  color: white;
`

const LinksHeader = styled.div`
  font-size: 0.85em;
  font-weight: ${FONT_WEIGHT_4};
  letter-spacing: 1px;
  padding: ${SMALL}px ${SMALLER}px 1em;
  text-transform: uppercase;
`

const TabIcon = styled(Absolute)`
  color: ${RED};
  font-size: 1.25em;
  right: 0.75em;
`

const TabLink = styled.div`
  font-size: 1.125em;
`

const TabName = styled.div`
  padding-left: ${SMALLER}px;
  padding-right: ${MEDIUM}px;
`

const AssignmentForm = ({
  accountSections,
  assignmentAuditLogs,
  assignmentAuditLogsIsFetching,
  assignmentAuditLogsSummary,
  assignmentId,
  change,
  columnCount,
  currentValues,
  calculationSections,
  environmentHeight,
  errors,
  fetchAssignmentAuditLog,
  formIsFetching,
  formIsSubmitting,
  formName,
  formSections,
  formTabs,
  formValidationError,
  formSubmissionError,
  initialValues,
  isLocked,
  linksHeaderTitle,
  loadingMessage,
  onSubmit,
  resources,
  submitFailed,
  submitMessage,
  submittingMessage,
  syncErrors,
  taxSections,
  updatePreviousFormTabErrors,
}) => {
  const sections = []

  if (formIsFetching) {
    return (
      <Loader
        blue
        message={formIsSubmitting ? submittingMessage : loadingMessage}
      />
    )
  }

  const planTypeCode =
    (currentValues &&
      currentValues.planTypeCode &&
      currentValues.planTypeCode.value) ||
    null

  const filteredFields = filterFields(planTypeCode, [...formSections])

  for (let i = 0; i < Object.keys(filteredFields).length; i += 1) {
    const {
      code,
      component,
      componentType,
      formFields,
      name,
      helpText,
      additionalProperties,
    } = filteredFields[i]

    const componentIsCustom = component in customFormSections
    const componentIsStandard = componentType !== 'Custom'
    let customSection = ''
    let standardSection = ''

    const commonSectionProps = {errors, resources}

    const sectionShouldHide =
      additionalProperties && additionalProperties.isHidden
        ? additionalProperties.isHidden
        : false

    if (componentIsStandard && formFields.length) {
      standardSection = (
        <FormSection
          {...commonSectionProps}
          code={code}
          helpText={helpText}
          columnCount={columnCount}
          formFields={formFields}
          isLocked={isLocked}
          name={name}
        />
      )
    }

    if (componentIsCustom) {
      const CustomSection = customFormSections[component]

      const familySection = filteredFields[4] // standardFormSections.json or standardFormSectionsForEdit.json

      const customFormSectionData = {
        accountSections: [...accountSections],
        assignmentAuditLogs,
        assignmentAuditLogsIsFetching,
        assignmentAuditLogsSummary,
        assignmentId,
        change,
        calculationSections: calculationSections,
        familySection: {...familySection},
        fetchAssignmentAuditLog,
        formName,
        isLocked,
        startDate:
          (currentValues.startDate && currentValues.startDate.value) ||
          todaysDate(),
        taxSections: [...taxSections],
      }

      customSection = (
        <CustomSection
          {...commonSectionProps}
          code={code}
          helpText={helpText}
          currentValues={currentValues}
          customFormSectionData={customFormSectionData}
          environmentHeight={environmentHeight}
          name={name}
        />
      )
    }

    const hasErrors =
      formTabs && formTabs[code] ? formTabs[code].hasErrors : false

    const link = (
      <Tab sectionShouldHide={sectionShouldHide}>
        <TabName>{name}</TabName>
        {hasErrors && (
          <TabIcon vertical>
            <Icon red type="caution" />
          </TabIcon>
        )}
      </Tab>
    )

    const panel = (
      <div>
        {standardSection}
        {customSection}
      </div>
    )
    sections.push({link, panel})
  }

  const linksHeader = <LinksHeader>{linksHeaderTitle}</LinksHeader>

  const submit = <Submit name={formName}>{submitMessage}</Submit>

  const lockedAssignmentMessage = (
    <div
      style={{color: 'black', fontSize: '1rem', fontWeight: '400'}}
      className="text-center"
    >
      {ASSIGNMENT_IS_LOCKED}
    </div>
  )

  const handleSelect = (index, lastIndex) => {
    try {
      return updatePreviousFormTabErrors(
        syncErrors,
        formSections[index].code,
        formSections[lastIndex].code
      )
    } catch (error) {
      console.error(error)
    }
  }

  const validator = submitFailed && formValidationError && (
    <ErrorMessage validationErrorMessage={formValidationError} />
  )
  const submissionError = formSubmissionError && (
    <ErrorMessage
      submissionErrorMessage={
        formSubmissionError.startsWith('4') ||
        formSubmissionError.startsWith('5')
          ? GENERIC_ERROR_MESSAGE
          : formSubmissionError
      }
    />
  )

  return (
    <>
      <Loader
        visible={formIsSubmitting}
        message={formIsSubmitting ? submittingMessage : loadingMessage}
        bodyStyle={{height: '225px'}}
        isModal={true}
      />
      <Form initialValues={initialValues} name={formName} onSubmit={onSubmit}>
        <TabWrapper>
          <Tabs
            customLink={props => <TabLink {...props} />}
            items={sections}
            onSelect={handleSelect}
            padded
            sidebarWidth={2}
            title={linksHeader}
          />
        </TabWrapper>
      </Form>
      <Footer>
        {isLocked ? lockedAssignmentMessage : submit}
        {validator}
        {submissionError}
      </Footer>
    </>
  )
  // }
}

AssignmentForm.defaultProps = {
  accountSections: [],
  assignmentAuditLogs: [],
  assignmentAuditLogsIsFetching: false,
  assignmentAuditLogsSummary: {},
  assignmentId: null,
  columnCount: 2,
  currentValues: {},
  fetchAssignmentAuditLog: null,
  formSections: [],
  formTabs: {},
  formValidationError: '',
  initialValues: {},
  loadingMessage: '',
  submitFailed: false,
  submitMessage: '',
  submittingMessage: '',
  taxSections: [],
  calculationSections: [],
}

AssignmentForm.propTypes = {
  accountSections: accountSectionsPT(),
  assignmentAuditLogs: assignmentAuditLogsPT(),
  assignmentAuditLogsIsFetching: boolPT(true),
  assignmentAuditLogsSummary: emptyObjectPT(),
  assignmentId: numberPT(),
  change: funcPT(true),
  columnCount: numberPT(),
  currentValues: emptyObjectPT(),
  environmentHeight: numberPT(true),
  errors: emptyObjectPT(true),
  fetchAssignmentAuditLog: funcPT(),
  formIsFetching: boolPT(true),
  formIsSubmitting: boolPT(true),
  formName: stringPT(true),
  formSections: formSectionsPT(),
  formTabs: emptyObjectPT(),
  formValidationError: stringPT(),
  formSubmissionError: stringPT(),
  initialValues: emptyObjectPT(),
  isLocked: boolPT(true),
  linksHeaderTitle: stringPT(true),
  loadingMessage: stringPT(),
  onSubmit: funcPT(true),
  resources: resourcesPT(true),
  submitFailed: boolPT(true),
  submitMessage: stringPT(),
  submittingMessage: stringPT(),
  syncErrors: emptyObjectPT(true),
  taxSections: taxSectionsPT(),
  updatePreviousFormTabErrors: funcPT(true),
}

export default AssignmentForm
