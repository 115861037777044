import React from 'react'
import formValueSelector from 'redux-form/es/formValueSelector'
import {connect} from 'react-redux'
import {View} from '../../lib/components/core'
import {getIsMobile} from '../../selectors/environment'
import {getCurrencyDefaults} from '../../selectors/locale'
import {getId} from '../../selectors/router'
import {
  fetchAssignment,
  fetchAssignmentOptionsIfNeeded,
} from '../../actions/assignments'
import {fetchFormSectionsIfNeeded} from '../../actions/form'
import {fetchResourcesIfNeeded} from '../../actions/resource'
import {fetchPositionToken} from '../../actions/token'
import ViewAssignment from '../../components/ViewAssignment/index'
import {REVIEW_ASSIGNMENT_FORM} from '../../constants/form'
import AuthContainer from '../Auth/index'
import {getAssignment} from '../../selectors/assignments'
import {
  getFormSections,
  getViewAssignmentFormInitialValues,
  getViewFormIsFetching,
} from '../../selectors/form'
import {getResources} from '../../selectors/resources'
import {getPositionToken, getSectionItems} from '../../selectors/tokens'
import {updateApprovalStatus} from '../../actions/approval'
import {fetchCompaniesIfNeeded} from '../../actions/companies'
import {getCompanies, getLastSelectedCompany} from '../../selectors/company'
import ErrorBoundaryContainer from '../../containers/ErrorBoundary'

const ViewAssignmentContainer = props => (
  <ErrorBoundaryContainer>
    <View authContainer={AuthContainer} name="Initiations">
      <ViewAssignment {...props} />
    </View>
  </ErrorBoundaryContainer>
)

const mapStateToProps = state => {
  const id = getId(state)
  const formName = REVIEW_ASSIGNMENT_FORM
  const selector = formValueSelector(REVIEW_ASSIGNMENT_FORM)

  return {
    approverComments: selector(state, 'Comments'),
    assignment: getAssignment(state),
    companies: getCompanies(state),
    currencyDefaults: getCurrencyDefaults(state),
    formName,
    formSections: getFormSections(state),
    id,
    initialValues: getViewAssignmentFormInitialValues(state, {positionId: id}),
    isFetching: getViewFormIsFetching(state),
    isMobile: getIsMobile(state),
    lastSelectedCompany: getLastSelectedCompany(state),
    positionToken: getPositionToken(state),
    resources: getResources(state),
    sectionItems: getSectionItems(state),
  }
}

export default connect(
  mapStateToProps,
  {
    fetchAssignment, // TODO: ifNeeded
    fetchAssignmentOptionsIfNeeded,
    fetchCompaniesIfNeeded,
    fetchFormSectionsIfNeeded,
    fetchPositionToken, // TODO: ifNeeded
    fetchResourcesIfNeeded,
    updateApprovalStatus,
  }
)(ViewAssignmentContainer)
