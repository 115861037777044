import PT from 'prop-types'
import React, {memo} from 'react'
import styled from 'styled-components'
import {DATE_FORMAT, MEDIUM} from 'arena-config'
import {Field} from '../../lib/components/arena-plugin-form'
import {boolPT, funcPT, stringPT, numberPT} from 'arena-prop-types'
import {fieldOptionsPT} from '../../constants/propTypes/fieldOptions'
import {isNullOrUndefined} from 'util'

const FieldWrap = styled.div`
  margin-bottom: ${MEDIUM}px;
`
const makeProps = ({visibility, ...rest}) => <div {...rest} />
const VisibleField = styled(makeProps)`
  visibility: ${props => (props.visibility ? 'visible' : 'hidden')};
`

const FormField = ({
  creatable,
  disabled,
  label,
  locked,
  maxLength,
  name,
  suffix,
  options,
  required,
  type,
  validate,
  validationMessage,
  visibility,
}) => {
  if (!isNullOrUndefined(visibility)) {
    return (
      <VisibleField visibility={visibility}>
        <FieldWrap>
          <Field
            creatable={creatable}
            dateFormat={DATE_FORMAT}
            disabled={disabled || locked}
            label={label}
            maxLength={maxLength}
            name={name}
            suffix={suffix}
            options={options}
            required={required}
            timeFormat=""
            type={type}
            validate={validate}
            validationMessage={validationMessage}
          />
        </FieldWrap>
      </VisibleField>
    )
  }
  return (
    <FieldWrap>
      <Field
        creatable={creatable}
        dateFormat={DATE_FORMAT}
        disabled={disabled || locked}
        label={label}
        maxLength={maxLength}
        name={name}
        suffix={suffix}
        options={options}
        required={required}
        timeFormat=""
        type={type}
        validate={validate}
        validationMessage={validationMessage}
      />
    </FieldWrap>
  )
}

FormField.defaultProps = {
  creatable: false,
  disabled: false,
  locked: false,
  maxLength: 255,
  required: false,
  validate: [],
  validationMessage: '',
}

FormField.propTypes = {
  creatable: boolPT(),
  disabled: boolPT(),
  label: stringPT(true),
  locked: boolPT(true),
  name: stringPT(true),
  options: fieldOptionsPT(true),
  required: boolPT(),
  maxLength: numberPT(),
  timeFormat: PT.oneOfType([boolPT(), stringPT()]),
  type: stringPT(true),
  validate: PT.oneOfType([funcPT(), PT.arrayOf(funcPT())]),
  validationMessage: stringPT(),
}

export default memo(FormField)
