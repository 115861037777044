import {combineReducers} from 'redux'
import {
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_LAST_SELECTED_COMPANY,
  UPDATE_SELECTED_COMPANY_REQUEST,
  UPDATE_SELECTED_COMPANY_SUCCESS,
  UPDATE_SELECTED_COMPANY_FAILURE,
} from '../constants/actionTypes'

const companiesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_SUCCESS:
      return action.payload.entities.companies || state
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_REQUEST:
      return true
    case UPDATE_SELECTED_COMPANY_REQUEST:
      return true
    case FETCH_COMPANIES_SUCCESS:
      return false
    case FETCH_COMPANIES_FAILURE:
      return false
    case UPDATE_SELECTED_COMPANY_SUCCESS:
      return false
    case UPDATE_SELECTED_COMPANY_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_FAILURE:
      return action.payload.message
    case UPDATE_SELECTED_COMPANY_FAILURE:
      return action.payload.message
    case FETCH_COMPANIES_REQUEST:
    case FETCH_COMPANIES_SUCCESS:
      return null
    default:
      return state
  }
}

const lastSelectedCompany = (state = null, action) => {
  switch (action.type) {
    case FETCH_LAST_SELECTED_COMPANY:
      return {
        company: action.company,
        formId: action.formId,
        formRecordTitle: action.formRecordTitle,
        messages: action.messages,
        isCopyAssignmentDisabled: action.isCopyAssignmentDisabled,
      }
    case UPDATE_SELECTED_COMPANY_SUCCESS:
    default:
      return state
  }
}

const companies = combineReducers({
  byId: companiesById,
  isFetching,
  errorMessage,
  lastSelectedCompany,
})

export default companies
