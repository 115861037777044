import {DATE_TIME_FIELD, px, SELECT_FIELD} from 'arena-config'
import {borderRadiusStyleProp, getStyleProp} from 'arena-button'

export const menuAlignStyleProp = side => ({styleProps}) => {
  const menuAlign = getStyleProp('menuAlign')({styleProps})
  const items = Array.isArray(menuAlign) ? menuAlign : [menuAlign]

  if (!items.includes('bottom') && !items.includes('top')) {
    items.push('top')
  }

  if (!items.includes('left') && !items.includes('right')) {
    items.push('left')
  }

  switch (side) {
    case 'bottom':
      return items.includes('bottom') ? '100%' : 'auto'
    case 'left':
      return items.includes('left') ? 0 : 'auto'
    case 'right':
      return items.includes('right') ? 0 : 'auto'
    case 'top':
      return items.includes('top') ? '100%' : 'auto'
    default:
      return undefined
  }
}

export const menuMarginStyleProp = side => ({styleProps}) => {
  const {menuAlign} = styleProps
  return menuAlign === side || menuAlign.includes(side) ? '-1px' : 0
}

export const menuPositionStyleProp = () => ({styleProps, ...rest}) => {
  return styleProps.menuPositionStyle || 'absolute'
}

export const menuWidthStyleProp = type => ({styleProps}) => {
  const menuWidth = getStyleProp('menuWidth')({styleProps})
  const widthFallback = fallback => (menuWidth ? px(menuWidth) : fallback)

  switch (type) {
    case DATE_TIME_FIELD:
      return widthFallback('auto')
    case SELECT_FIELD:
      return widthFallback('100%')
    default:
      return menuWidth
  }
}

export const menuStyles = (props, type) => `
background-color: ${getStyleProp('backgroundColorActive')(props)};
border-color: ${getStyleProp('borderColorHover')(props)};
border-radius: ${px(borderRadiusStyleProp()(props))};
border-style: ${getStyleProp('borderStyle')(props)};
border-width: ${px(getStyleProp('borderWidth')(props))};
bottom: ${menuAlignStyleProp('bottom')(props)};
box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
left: ${menuAlignStyleProp('left')(props)};
margin-bottom: ${menuMarginStyleProp('bottom')(props)};
margin-top: ${menuMarginStyleProp('top')(props)};
position: ${menuPositionStyleProp()(props)};
right: ${menuAlignStyleProp('right')(props)};
top: ${menuAlignStyleProp('top')(props)};
width: ${menuWidthStyleProp(type)(props)};
z-index: ${getStyleProp('menuZIndex')(props)}`

export const menuOptionPadding = () => `
padding: .25em .5em`
