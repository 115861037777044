const ENV = process.env

export const IS_LOCAL = ENV.NODE_ENV === 'development'
export const API_URL = ENV.REACT_APP_INITIATIONS_API_URL
export const IDENTITY_SERVER_API_URL = ENV.REACT_APP_IDENTITY_SERVER_API_URL

export const AUTH0_DOMAIN = ENV.REACT_APP_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = ENV.REACT_APP_AUTH0_CLIENT_ID
export const AUTH0_AUDIENCE = ENV.REACT_APP_AUTH0_AUDIENCE
export const AUTH0_SCOPE = ENV.REACT_APP_AUTH0_SCOPE
export const APP_BASE_NAME = ENV.REACT_APP_BASE_NAME

export const ACCOUNT_SECTIONS_BY_POLICY_TYPE_URL = planTypeCode =>
  `${API_URL}/DataLists/Hierarchical/AssignmentType/Code/${planTypeCode}`
export const ACCOUNT_SECTIONS_URL = `${API_URL}/DataLists/Hierarchical/AccountCategory`
export const APPROVAL_STATUS_URL = (
  employeeId,
  positionId,
  newStatus,
  approverComments
) =>
  `${API_URL}/Employees/${employeeId}/Positions/${positionId}/ApprovalStatus/${newStatus}?approverComments=${approverComments}`
export const APPROVERS_URL = (employeeId, positionId) =>
  `${API_URL}/Employees/${employeeId}/Positions/${positionId}/PositionApprovers`
export const ASSIGNMENTS_SEARCH_URL = (searchTerm, pageNumber, pageSize) =>
  `${API_URL}/Search/Assignment/${searchTerm}?pageNumber=${pageNumber}&pageSize=${pageSize}`
export const ASSIGNMENTS_URL = (id = '') => `${API_URL}/Assignments/${id}`
export const ASSIGNMENT_AUDIT_LOG_URL = (id = '') =>
  `${API_URL}/Assignments/${id}/AuditLogs`
export const ASSIGNMENT_FAMILY_MEMBERS_URL = id =>
  `${API_URL}/Assignments/${id}/FamilyMembers`
export const ASSIGNMENT_OPTIONS_URL = (
  id,
  includeDefaults = false,
  includeCalculationResults = false
) =>
  `${API_URL}/Assignments/${id}/Options?includeDefaults=${includeDefaults}${
    includeCalculationResults ? '' : ASSIGNMENT_OPTIONS_FILTER_FRAGMENT_URL
  }`
export const ASSIGNMENT_OPTIONS_FILTER_FRAGMENT_URL =
  '&optionTypes=General&optionTypes=CustomField&optionTypes=CompensationItem&optionTypes=Family&optionTypes=TaxOption&optionTypes=TaxOptionHypo&optionTypes=Other'
export const ASSIGNMENT_URL = id => `${API_URL}/Assignments/${id}`
export const CHANNELS_URL = `${API_URL}/Channels/Assignments`
export const CHANNEL_ASSIGNMENTS_PAGED_URL = (id, pageNumber, pageSize) =>
  `${API_URL}/Channels/${id}/Assignments?pageNumber=${pageNumber}&pageSize=${pageSize}`
export const CHANNEL_ASSIGNMENTS_URL = id =>
  `${API_URL}/Channels/${id}/Assignments`
export const EXPORT_ASSIGNMENTS_URL = `${API_URL}/Downloads/Export/Assignments`
export const FAMILY_FORM_FIELDS_URL = `${API_URL}/DataLists/Hierarchical/FamilyUDFField`
export const FORM_SECTIONS_URL = `${API_URL}/DataLists/Hierarchical/AssignmentUDFSection`
export const POSITION_TOKEN_URL = id =>
  `${API_URL}/Tokens/Position/${id}?tokenFormat=FormFormatted`
export const RESOURCES_BASE_URL = type => `${API_URL}/DataLists/${type}`
export const RESOURCES_COUNTRIES_URL = RESOURCES_BASE_URL('Country')
export const RESOURCES_CURRENCIES_URL = RESOURCES_BASE_URL('Currency')
export const RESOURCES_EMPLOYEES_URL = `${API_URL}/Employees`
export const RESOURCES_ENTITIES_URL = RESOURCES_BASE_URL('Entity')
export const RESOURCES_FILING_STATUS_URL = code =>
  `${API_URL}/DataLists/Hierarchical/Location/Code/${code}?childType=FilingStatus`
export const RESOURCES_JOB_TITLES_URL = RESOURCES_BASE_URL('AssignmentLevel')
export const RESOURCES_HOME_LOCATIONS_URL = RESOURCES_BASE_URL('HomeLocation')
export const RESOURCES_HOST_LOCATIONS_URL = RESOURCES_BASE_URL('HostLocation')
export const RESOURCES_POLICIES_URL = RESOURCES_BASE_URL('AssignmentType')
export const RESOURCES_SUB_ENTITIES_URL = RESOURCES_BASE_URL('SubEntity')
export const TASK_STATUS_URL = (id, newStatus) =>
  `${API_URL}/Tasks/${id}/${newStatus}`
export const TAX_QUESTIONS_URL = `${API_URL}/DataLists/Hierarchical/TaxQuestions`
export const TAX_OPTIONS_URL = (homeLocation, hostLocation, policyType) =>
  `${API_URL}/DataLists/Hierarchical/TaxOptionsByIds/${homeLocation}/${hostLocation}${policyType &&
    '?policyType='}${policyType || ''}`
export const TIMELINES_URL = (ids = '') =>
  `${API_URL}/Positions/Timelines/${ids}`
export const MAIL_URL = `${API_URL}/Notifications/Send`
export const RESOURCES_COMPANIES_URL = RESOURCES_BASE_URL('Company')
export const IDENTITY_BASE_URL = `${API_URL}/Identity`
export const IDENTITY_FETCH_COMPANIES_URL = `${IDENTITY_BASE_URL}/UsersCompanies`
export const IDENTITY_SELECT_COMPANY_URL = id =>
  `${IDENTITY_BASE_URL}/SelectCompany/${id}`
export const FORM_DESIGN_URL = (id, isEditMode) =>
  `${API_URL}/Workflow/Forms/${id}/DesignAndBusinessRules?isEditMode=${isEditMode}`
export const GET_USER_FAVORITES_URL = () => `${API_URL}/Users/Favorites`
export const SET_REMOVE_USER_FAVORITE_URL = assignmentId =>
  `${GET_USER_FAVORITES_URL()}/${assignmentId}`
export const CHANNELS_SUMMARY = id => `${API_URL}/channels/${id}/summary`
export const GET_DOCUMENTS_FOR_ASSIGNMENT = id =>
  `${API_URL}/Assignments/${id}/documents`
export const GET_SINGLE_DOCUMENT_BY_ID = (id, documentId) =>
  `${API_URL}/Assignments/${id}/documents/${documentId}`
export const CREATE_DOCUMENT = id => `${API_URL}/Assignments/${id}/documents`
export const DELETE_DOCUMENT = id => `${API_URL}/Assignments/documents/${id}`
export const CREATE_COMMENT = positionId =>
  `${API_URL}/PositionComments/${positionId}`
export const GET_COMMENTS_FOR_ASSIGNMENT = positionId =>
  `${API_URL}/Assignments/${positionId}/Comments`
export const DELETE_COMMENT = id => `${API_URL}/PositionComments/Delete/${id}`
export const TAGGED_USERS = query => `${API_URL}/Search/Users/${query}`
export const GET_DOCUMENT_CATEGORY_AND_SUBCATEGORY = `${API_URL}/DataLists/Hierarchical/AssignmentDocumentCategory?childType=AssignmentDocumentSubcategory`
export const googleDriveScope = 'https://www.googleapis.com/auth/drive.file'
export const googleDiscoveryUrl =
  'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
export const googleApiUrl =
  'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id'
export const googleApiJs = 'https://apis.google.com/js/api.js'

export const googleOpenDocUrl = (documentId, authUser) =>
  `https://docs.google.com/document/u/${authUser}/d/${documentId}/edit`
export const googleOpenSpreadSheetUrl = (documentId, authUser) =>
  `https://docs.google.com/spreadsheets/u/${authUser}/d/${documentId}/edit`
export const googleOpenFileUrl = (documentId, authUser) =>
  `https://drive.google.com/file/u/${authUser}/d/${documentId}/view?`
