export const ASSIGNMENT_IS_LOCKED = 'This record is locked and is read-only.'
export const ASSSIGNMENT_DOES_NOT_EXIST = id =>
  `Assignment ${id} doesn't exist.`
export const DELETE_ASSIGNMENT_CONFIRMATION_MESSAGE =
  'Are you sure you want to delete this assignment?  Please note, this record and all related information will be removed permanently and can not be recovered.'
export const REJECT_OR_DELETE_ASSIGNMENT_CONFIRMATION_TITLE = (
  employeeId,
  firstName,
  hostCountryName,
  lastName,
  rejectOrDelete
) =>
  `${rejectOrDelete} assignment for ${firstName} ${lastName} (${employeeId}) to ${hostCountryName}?`

export const REJECT_ASSIGNMENT_CONFIRMATION_MESSAGE =
  'Are you sure you want to reject this assignment?'
export const GENERIC_ERROR_MESSAGE =
  'Oops... Something went wrong, please check your entries and try again.'
export const NO_TAX_FIELDS_MESSAGE =
  'No additional questions need to be answered for this jurisdiction.'
export const NO_CALCULATION_SECTIONS_MESSAGE =
  'Please select a policy and a home and host location on the Demographics tab and then return to this tab.'
export const NO_TAX_SECTIONS_MESSAGE =
  'Please select a home or a host tax location on the Calculation Options tab and then return to this tab.'
