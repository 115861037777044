import * as c from 'arena-config'
import {numberOrStringPT} from 'arena-prop-types'
import PT from 'prop-types'
import {sectionsPropType} from './constants/propTypes'

export const hasValidResources = (props, propName, componentName) => {
  const {resources} = props

  if (typeof resources !== 'object') {
    return new Error(
      `Invalid prop 'resources' given to ${componentName} (must be type object)`
    )
  }
}

export const commonPropTypes = {
  amountFieldWidth: numberOrStringPT(),
  delimiter: PT.string,
  duration: PT.number,
  resourceFieldWidth: numberOrStringPT(),
  sections: sectionsPropType,
  titleWidth: numberOrStringPT(),
}

export const defaultProps = {
  amountFieldWidth: 135,
  children: null,
  delimiter: '_',
  duration: 3,
  emptyBulkEditButtonWidth: c.SMALLER,
  enableAutofill: true,
  enableFormatting: false,
  formValues: {},
  isLocked: false,
  resourceFieldWidth: 135,
  resources: {},
  sections: [],
  showAllResourcesButton: true,
  showContentBeforeSections: false,
  showResourcesByDefault: false,
  titleWidth: 360,
  useSingleAmountInsteadOfYears: false,
}

export const propTypes = {
  ...commonPropTypes,
  change: PT.func.isRequired,
  children: PT.node,
  emptyBulkEditButtonWidth: PT.number,
  enableAutofill: PT.bool,
  enableFormatting: PT.bool,
  environmentHeight: numberOrStringPT(true),
  formName: PT.string.isRequired,
  formValues: PT.shape({}),
  isLocked: PT.bool,
  resources: hasValidResources,
  showAllResourcesButton: PT.bool,
  showContentBeforeSections: PT.bool,
  showResourcesByDefault: PT.bool,
  startYear: PT.number.isRequired,
  useSingleAmountInsteadOfYears: PT.bool,
}
