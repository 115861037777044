import {numberOrStringPT} from 'arena-prop-types'
import PT from 'prop-types'

export const bodyDefaultProps = {}

export const bodyPropTypes = {
  children: PT.node.isRequired,
  hasHeader: PT.bool.isRequired,
  hasHeaderBottom: PT.bool.isRequired,
  headerHeight: numberOrStringPT(true),
  subHeaderHeight: numberOrStringPT(true),
}
