import {denormalize, normalize} from 'normalizr'
import {createSelector} from 'reselect'
import {
  accountSectionSchema,
  currentValuesSchema,
  formSectionSchema,
  taxSectionSchema,
  calculationSectionSchema,
} from '../constants/schemas'
import {formatDate, nextYear, todaysDate} from '../utils/moment'
import moment from 'moment'
import {
  getAssignmentById,
  getAssignmentFamilyMembersByPositionId,
  getAssignmentFamilyMembersIsFetching,
  getAssignmentOptionsByPositionId,
  getAssignmentOptionsIsFetching,
  getAssignmentsIsFetching,
  getAssignmentDetailsIsFetching,
  getCloneAssignmentId,
} from './assignments'
import {getCountriesById, getResourcesIsFetching} from './resources'
import {getCountryCode} from '../utils/resources'
import {getPositionTokensIsFetching} from './tokens'
import {getForm, getFormFieldsValue} from '../lib/selectors/FormSelectors'
import {
  CREATE_ASSIGNMENT_FORM,
  EDIT_ASSIGNMENT_FORM,
  UPDATE_ASSIGNMENT_OPTIONS_FORM,
} from '../constants/form'

const getForms = state => state.initiations.forms

export const getStandardFormSections = createSelector(
  [getForms],
  forms =>
    forms.formDesign &&
    forms.formDesign.standardFormSections &&
    forms.formDesign.standardFormSections.form
)

export const getBusinessRules = createSelector(
  [getForms],
  forms =>
    forms.formDesign &&
    forms.formDesign.standardFormSections &&
    forms.formDesign.standardFormSections.businessrules
)

export const getStandardFormSectionsIsFetching = createSelector(
  [getForms],
  forms => forms.formDesign && forms.formDesign.isFetching
)

export const getAccountSectionsById = state =>
  getForms(state).accountSections.byId
export const getTaxSectionsById = state => getForms(state).taxSections.byId
export const getCalculationSectionsById = state =>
  getForms(state).calculationSections.byId
export const getFamilyCustomFormFieldsById = state =>
  getForms(state).formFields.familyCustomFormFieldsById
export const getTaxOptionsFormFieldsById = state =>
  getForms(state).taxFields.taxOptionsFormFieldsById
const getFormSectionsWithoutFamilyById = state =>
  getForms(state).formSections.byId
const getCustomFamilySectionFormFieldIds = state =>
  getForms(state).formSections.customFamilySectionFormFieldIds

export const getFormSectionsById = createSelector(
  [getFormSectionsWithoutFamilyById, getCustomFamilySectionFormFieldIds],
  (formSections, familyCustomFormFieldIds) => {
    if (formSections.Family) {
      return {
        ...formSections,
        Family: {
          ...formSections.Family,
          formFields: [
            ...formSections.Family.formFields,
            ...familyCustomFormFieldIds,
          ],
        },
      }
    }

    return formSections
  }
)

const getAccountFieldsById = state => getForms(state).accountFields.byId
const getTaxFieldsById = state => getForms(state).taxFields.byId
const getCalculationFieldsById = state => getForms(state).calculationFields.byId
export const getFormFieldsById = state => getForms(state).formFields.byId

const getAccountSectionIds = state => getForms(state).accountSections.ids
const getFormSectionIds = state => getForms(state).formSections.ids
export const getTaxSectionIds = state => getForms(state).taxSections.ids

export const getCalculationSectionIds = state =>
  getForms(state).calculationSections.ids
export const getAccountSectionsIsFetching = state =>
  getForms(state).accountSections.isFetching
export const getTaxSectionsIsFetching = state =>
  getForms(state).taxSections && getForms(state).taxSections.isFetching
export const getCalculationSectionsIsFetching = state =>
  getForms(state).calculationSections &&
  getForms(state).calculationSections.isFetching
export const getFamilyFormFieldsIsFetching = state =>
  getForms(state).formSections.familyFormFieldsIsFetching
export const getFormSectionsIsFetching = state =>
  getForms(state).formSections.isFetching

export const getAccountSectionsLastFilter = state =>
  getForms(state).accountSections.lastFilter

export const getInitialValues = state => getForms(state).initialValues.values

export const getAssignmentFormValues = (state, props) => {
  const {formName, fieldsToGet} = props
  const fieldValues =
    formName &&
    fieldsToGet &&
    getFormFieldsValue(state, {formName, fieldsToGet})

  const results = fieldValues
    ? normalize(
        fieldsToGet.map(fieldName => {
          if (
            fieldName.split('.')[0] === 'options' &&
            fieldValues['options'] !== undefined
          ) {
            return {
              name: fieldName,
              ...fieldValues['options'][fieldName.split('.')[1]],
            }
          }
          return {
            name: fieldName,
            value: fieldValues && fieldValues[fieldName],
          }
        }),
        [currentValuesSchema]
      ).entities.currentValues
    : {}
  return results
}

export const getFormSections = createSelector(
  [getFormFieldsById, getFormSectionIds, getFormSectionsById],
  (formFields, formSectionIds, formSections) =>
    denormalize(formSectionIds, [formSectionSchema], {formFields, formSections})
)

export const getAccountSections = createSelector(
  [getAccountFieldsById, getAccountSectionIds, getAccountSectionsById],
  (accountFields, accountSectionIds, accountSections) =>
    denormalize(accountSectionIds, [accountSectionSchema], {
      accountFields,
      accountSections,
    })
)

export const getTaxSections = createSelector(
  [getTaxFieldsById, getTaxSectionIds, getTaxSectionsById],
  (taxFields, taxSectionIds, taxSections) =>
    denormalize(taxSectionIds, [taxSectionSchema], {taxFields, taxSections})
)

export const getCalculationSections = createSelector(
  [
    getCalculationFieldsById,
    getCalculationSectionIds,
    getCalculationSectionsById,
  ],
  (calculationFields, calculationSectionIds, calculationSections) =>
    denormalize(calculationSectionIds, [calculationSectionSchema], {
      calculationFields,
      calculationSections,
    })
)

export const getFormIsFetching = createSelector(
  [
    getFamilyFormFieldsIsFetching,
    getFormSectionsIsFetching,
    getResourcesIsFetching,
    getStandardFormSectionsIsFetching,
  ],
  (
    familyFormFieldsIsFetching,
    formSectionsIsFetching,
    resourcesIsFetching,
    standardFormSectionsIsFetching
  ) =>
    familyFormFieldsIsFetching ||
    formSectionsIsFetching ||
    resourcesIsFetching ||
    standardFormSectionsIsFetching
)

export const getViewFormIsFetching = state =>
  getAssignmentOptionsIsFetching(state) ||
  getAssignmentsIsFetching(state) ||
  getFormIsFetching(state) ||
  getPositionTokensIsFetching(state)

export const getEditFormIsFetching = state =>
  getAssignmentFamilyMembersIsFetching(state) ||
  getAssignmentOptionsIsFetching(state) ||
  getAssignmentsIsFetching(state) ||
  getAssignmentDetailsIsFetching(state) ||
  getFormIsFetching(state)

export const getCreateFormIsFetching = createSelector(
  [getCloneAssignmentId, getFormIsFetching, getEditFormIsFetching],
  (cloneAssignment, isCreateFetching, isEditFetching) =>
    cloneAssignment ? isEditFetching : isCreateFetching
)

export const getViewAssignmentFormInitialValues = createSelector(
  [getAssignmentOptionsByPositionId],
  assignmentOptions => (assignmentOptions ? {options: assignmentOptions} : null)
)

export const getCreateAssignmentFormInitialValues = createSelector(
  [getFormFieldsById],
  fields => {
    const result = {
      startDate: `${todaysDate()}`,
      endDate: `${nextYear()}`,
      familyMemberRequests: [
        {relationshipType: 'Assignee', isOnAssignment: true},
      ],
      options: {},
    }

    setDefaults(fields, result, true)

    return result
  }
)

export const getCopyAssignmentFormInitialValues = createSelector(
  [
    getAssignmentById,
    getAssignmentFamilyMembersByPositionId,
    getAssignmentOptionsByPositionId,
    getCountriesById,
    getFormFieldsById,
    getEditFormIsFetching,
  ],
  (
    assignment,
    assignmentFamilyMembers,
    assignmentOptions,
    countries,
    fields,
    formIsFetching
  ) => {
    if (
      formIsFetching ||
      !assignment ||
      !(countries && Object.keys(countries).length > 0) ||
      !(assignmentOptions && Object.keys(assignmentOptions).length > 0) ||
      !(fields && Object.keys(fields).length > 0) ||
      !assignmentFamilyMembers
    )
      return null

    const {anticipatedEndDate, homeCountryId, homeLocation, title} = assignment

    // Build the listing of family members to copy over by transforming the assignmentFamilyMembers object
    let familyMemberRequests = assignmentFamilyMembers
      ? Object.values(assignmentFamilyMembers)
      : []

    // If there are family members to copy over, clear out the familyMemberId (so it's not treated as an update to the source assignment's family),
    // and do some additional mappings, e.g. map citizenship of family members to the appropriate country, format date of birth, etc.
    // If there are not family members to copy over, set the defaults, i.e. relationship and isOnAssignment for the default Assignee family member
    familyMemberRequests =
      familyMemberRequests && familyMemberRequests.length > 0
        ? familyMemberRequests.map(familyMember => ({
            ...familyMember,
            familyMemberId: null,
            citizenship:
              familyMember.citizenship &&
              getCountryCode(countries, familyMember.citizenship.id),
            dateOfBirth: formatDate(familyMember.dateOfBirth),
          }))
        : [{relationshipType: 'Assignee', isOnAssignment: true}]

    // Build the basic initialValues object to copy the assignment
    // Clear out host based fields, e.g. hostCountryCode and Location
    // Increment start and end dates to start the day after the original assignment ends
    const result = {
      ...assignment,
      startDate: formatDate(moment(anticipatedEndDate).add(1, 'days')),
      familyMemberRequests,
      homeCountryCode: getCountryCode(countries, homeCountryId),
      homeLocation: homeLocation && homeLocation.name,
      options: assignmentOptions,
      positionName: title,
      endDate: formatDate(
        moment(anticipatedEndDate)
          .add(1, 'days')
          .add(1, 'years')
      ),
      hostCountryCode: null,
      hostLocation: null,
    }

    // Define the listing of fields that should never be cleared out
    const fieldNamesToPreserve = [
      'General__HomeEntity__Text',
      'General__HomeSubEntity__Text',
      'General__HomeCompensationLocation__List',
      'General__HomeIncomeTaxLocation__List',
      'General__HomeSocialTaxLocation__List',
      'General__HomeFilingStatus__List',
      'General__HypoCompensationLocation__List',
      'General__HypoIncomeTaxLocation__List',
      'General__HypoSocialTaxLocation__List',
      'General__HypoFilingStatus__List',
    ]

    // Define the prefixes of fields that should never be cleared out
    // If a field name starts with one of these prefixes then it won't be cleared out
    const fieldPrefixesToPreserve = ['CompensationItem_']

    // Define the func that determines if a field should be copied over
    const fieldShouldBePreserved = name =>
      fieldNamesToPreserve.includes(name) ||
      fieldPrefixesToPreserve.filter(x => name.startsWith(x)).length > 0

    // Loop over the Options and compare them against the fields on the form to determine what should be copied over
    Object.keys(result.options).forEach(x => {
      // Try and find the field that relates to the Option
      const field = fields[`options.${x}.value`]

      // Get the field's isVisible setting to see if it's hidden or not
      const isHidden =
        (field &&
          field.additionalProperties &&
          field.additionalProperties.isVisible) === false

      // Clear out the field if:
      // 1. It doesn't exist on the form
      // 2. It exists on the form but is hidden from the user
      // 3. It doesn't match any of the fields to preserve or field prefixes to preserve listings
      if ((!field || isHidden) && !fieldShouldBePreserved(x)) {
        delete result.options[x]
      }
    })

    // Loop over all initialValues props and compare them against the fields on the form to determine what should be copied over
    // Similar to the above loop which is just for Options
    Object.keys(result).forEach(x => {
      // If the prop is an object (e.g. Options, FamilyMemberRequests, etc.), skip it
      if (typeof result[x] != 'object') {
        // Try and find the field that relates to the prop
        const field = fields[x]

        // Get the field's isVisible setting to see if it's hidden or not
        const isHidden =
          (field &&
            field.additionalProperties &&
            field.additionalProperties.isVisible) === false

        // Clear out the field if:
        // 1. It doesn't exist on the form
        // 2. It exists on the form but is hidden from the user
        if (!field || isHidden) {
          delete result[x]
        }
      }
    })

    // Now that we've copied over the values from the source assignment (the assignment selected for copy), set any
    // default values for fields that weren't set on the source assignment, note the overwrite = false param, we don't
    // want to overwrite the values we've copied over, only set the field to its default value if it wasn't copied over
    setDefaults(fields, result, false)

    return result
  }
)

export const getEditAssignmentFormInitialValues = createSelector(
  [
    getAssignmentById,
    getAssignmentFamilyMembersByPositionId,
    getAssignmentOptionsByPositionId,
    getCountriesById,
  ],
  (assignment, assignmentFamilyMembers, assignmentOptions, countries) => {
    if (!assignment || Object.keys(countries).length === 0) return null

    const {
      anticipatedEndDate,
      anticipatedStartDate,
      homeCountryId,
      hostCountryId,
      homeLocation,
      hostLocation,
      title,
    } = assignment

    let familyMemberRequests = assignmentFamilyMembers
      ? Object.values(assignmentFamilyMembers)
      : []
    // Map citizenship of all family members to the appropriate country.
    familyMemberRequests = familyMemberRequests.map(familyMember => ({
      ...familyMember,
      citizenship:
        familyMember.citizenship &&
        getCountryCode(countries, familyMember.citizenship.id),
      dateOfBirth: formatDate(familyMember.dateOfBirth),
    }))

    return {
      ...assignment,
      endDate: formatDate(anticipatedEndDate),
      familyMemberRequests,
      homeCountryCode: getCountryCode(countries, homeCountryId),
      homeLocation: homeLocation && homeLocation.name,
      hostCountryCode: getCountryCode(countries, hostCountryId),
      hostLocation: hostLocation && hostLocation.name,
      options: assignmentOptions,
      positionName: title,
      startDate: formatDate(anticipatedStartDate),
    }
  }
)

const getCreateForm = createSelector(getForm, form => {
  const formName = CREATE_ASSIGNMENT_FORM
  const formExists = formName in form

  return formExists ? form[formName] : {}
})

export const getCreateSubmitFailed = createSelector(
  getCreateForm,
  form => form.submitFailed
)
export const getCreateSyncErrors = createSelector(
  getCreateForm,
  form => form.syncErrors
)
export const getCreateAnyTouched = createSelector(
  getCreateForm,
  form => form.anyTouched
)

export const getCreateErrors = createSelector(
  getCreateSubmitFailed,
  getCreateSyncErrors,
  (submitFailed, syncErrors) => (submitFailed && syncErrors ? syncErrors : {})
)

const getEditForm = createSelector(getForm, form => {
  const formName = EDIT_ASSIGNMENT_FORM
  const formExists = formName in form

  return formExists ? form[formName] : {}
})

export const getEditSubmitFailed = createSelector(
  getEditForm,
  form => form.submitFailed
)
export const getEditSyncErrors = createSelector(
  getEditForm,
  form => form.syncErrors
)
export const getEditAnyTouched = createSelector(
  getEditForm,
  form => form.anyTouched
)

export const getEditErrors = createSelector(
  getEditSubmitFailed,
  getEditSyncErrors,
  (submitFailed, syncErrors) => (submitFailed && syncErrors ? syncErrors : {})
)

const getAccountFieldIds = createSelector(
  getAccountFieldsById,
  accountFieldsById => Object.keys(accountFieldsById)
)
const getAssignmentFormTabs = state => getForms(state).formSections.formTabs
// Returns all form sections with their form fields
const getFormFieldsBySectionId = createSelector(
  [getAccountFieldIds, getFormSectionsById],
  (accountFields, sections) => {
    const sectionIds = Object.keys(sections)
    if (!sections || sectionIds.length === 0) return null

    let formFieldsBySectionId = {}
    let sectionId = null
    for (
      let sectionIndex = 0;
      sectionIndex < sectionIds.length;
      sectionIndex += 1
    ) {
      sectionId = sectionIds[sectionIndex]
      formFieldsBySectionId = {
        ...formFieldsBySectionId,
        [sectionId]: sections[sectionId].formFields,
      }
    }

    // Add compensation fields to the object because they are fetched separately.
    formFieldsBySectionId.Compensation = [...accountFields]

    return formFieldsBySectionId
  }
)

export const getCreateAssignmentFormTabs = createSelector(
  [
    getAssignmentFormTabs,
    getFormFieldsBySectionId,
    getCreateErrors,
    getCreateAnyTouched,
  ],
  (formTabs, formSections, errors, anyTouched) => {
    if (!formTabs || !formSections) return null

    const errorKeys = errors ? Object.keys(errors) : []
    const formTabNames = Object.keys(formTabs)
    const formSectionsHasErrors = {...formTabs}

    for (let tabIndex = 0; tabIndex < formTabNames.length; tabIndex += 1) {
      // If the form hasn't been 'touched', start with hasErrors = false to clear out previous
      // values.
      if (!anyTouched)
        formSectionsHasErrors[formTabNames[tabIndex]].hasErrors = false

      for (
        let errorsIndex = 0;
        errorsIndex < errorKeys.length;
        errorsIndex += 1
      ) {
        if (
          formSections[formTabNames[tabIndex]].includes(errorKeys[errorsIndex])
        ) {
          formSectionsHasErrors[formTabNames[tabIndex]].hasErrors = true
          break
        }
      }
    }

    return formSectionsHasErrors
  }
)

export const getCreateAllErrors = createSelector(
  [getCreateAssignmentFormTabs, getCreateErrors],
  (createAssignmentFormTabs, createErrors) => {
    const formTabs = {...createAssignmentFormTabs}
    const formTabNames = Object.keys(formTabs)
    let allCreateFormErrors = {}
    let errorMessage = ''

    for (let tabIndex = 0; tabIndex < formTabNames.length; tabIndex += 1) {
      if (formTabs[formTabNames[tabIndex]].errorCount > 0) {
        errorMessage += `- ${formTabNames[tabIndex]} tab has ${
          formTabs[formTabNames[tabIndex]].errorCount
        } error(s)\n`
      }
      allCreateFormErrors = {
        ...allCreateFormErrors,
        ...formTabs[formTabNames[tabIndex]].errors,
      }
    }
    if (errorMessage.length > 0) {
      return {
        errorMessage: errorMessage,
        ...createErrors,
        ...allCreateFormErrors,
      }
    }
    return {
      ...createErrors,
      ...allCreateFormErrors,
    }
  }
)

export const getCreateValidationErrorMessage = createSelector(
  getCreateAllErrors,
  allErrors => allErrors.errorMessage
)

export const getEditAssignmentFormTabs = createSelector(
  [
    getAssignmentFormTabs,
    getFormFieldsBySectionId,
    getEditErrors,
    getEditAnyTouched,
  ],
  (formTabs, formSections, errors, anyTouched) => {
    if (!formTabs || !formSections) return null

    const errorKeys = errors ? Object.keys(errors) : []
    const formTabNames = Object.keys(formTabs)
    const formSectionsHasErrors = {...formTabs}

    for (let tabIndex = 0; tabIndex < formTabNames.length; tabIndex += 1) {
      // If the form hasn't been 'touched', start with hasErrors = false to clear out previous
      // values.
      if (!anyTouched)
        formSectionsHasErrors[formTabNames[tabIndex]].hasErrors = false

      for (
        let errorsIndex = 0;
        errorsIndex < errorKeys.length;
        errorsIndex += 1
      ) {
        if (
          formSections[formTabNames[tabIndex]].includes(errorKeys[errorsIndex])
        ) {
          formSectionsHasErrors[formTabNames[tabIndex]].hasErrors = true
          break
        }
      }
    }

    return formSectionsHasErrors
  }
)

export const getEditAllErrors = createSelector(
  [getEditAssignmentFormTabs, getEditErrors],
  (editAssignmentFormTabs, editErrors) => {
    const formTabs = {...editAssignmentFormTabs}
    const formTabNames = Object.keys(formTabs)
    let allEditFormErrors = {}
    let errorMessage = ''

    for (let tabIndex = 0; tabIndex < formTabNames.length; tabIndex += 1) {
      if (formTabs[formTabNames[tabIndex]].errorCount > 0) {
        errorMessage += `- ${formTabNames[tabIndex]} tab has ${
          formTabs[formTabNames[tabIndex]].errorCount
        } error(s)\n`
      }
      allEditFormErrors = {
        ...allEditFormErrors,
        ...formTabs[formTabNames[tabIndex]].errors,
      }
    }
    if (errorMessage.length > 0) {
      return {
        errorMessage: errorMessage,
        ...editErrors,
        ...allEditFormErrors,
      }
    }
    return {
      ...editErrors,
      ...allEditFormErrors,
    }
  }
)

export const getEditValidationErrorMessage = createSelector(
  getEditAllErrors,
  allErrors => allErrors.errorMessage
)

const getUpdateAssignmentOptionsForm = createSelector(getForm, form => {
  const formName = UPDATE_ASSIGNMENT_OPTIONS_FORM
  const formExists = formName in form

  return formExists ? form[formName] : {}
})
export const getUpdateAssignmentOptionsSubmitFailed = createSelector(
  getUpdateAssignmentOptionsForm,
  form => form.submitFailed
)
export const getUpdateAssignmentOptionsSyncErrors = createSelector(
  getUpdateAssignmentOptionsForm,
  form => form.syncErrors
)

export const getUpdateAssignmentOptionsErrors = createSelector(
  getUpdateAssignmentOptionsSubmitFailed,
  getUpdateAssignmentOptionsSyncErrors,
  (submitFailed, syncErrors) => (submitFailed && syncErrors ? syncErrors : {})
)
const setDefaults = (fields, result, overwrite) => {
  const fieldNames = Object.keys(fields)
  for (let i = 0; i < fieldNames.length; i += 1) {
    const fieldName = fieldNames[i]
    const field = fields[fieldName]
    const value =
      field &&
      field.additionalProperties &&
      field.additionalProperties.defaultValue
    if (value) {
      if (fieldName.includes('options.')) {
        // If the field is an Option, set its value
        const name = fieldName.replace('options.', '').replace('.value', '')
        const currentValue =
          result.options && result.options[name] && result.options[name].value
        if (overwrite || !currentValue) {
          result.options[name] = {name, value}
        }
      } else if (!fieldName.includes('.')) {
        // If it's just a standard field without a dot-notation naming convention, just set the value
        const currentValue = result[fieldName]
        if (overwrite || !currentValue) {
          result[fieldName] = value
        }
      } else {
        // Do nothing, not yet supported - the field is not a standard field and is a non-Option complex child object field
      }
    }
  }
}
