import {Button, Icon} from 'arena-components'
import * as c from 'arena-config'
import React from 'react'
import pure from 'recompose/pure'
import styled from 'styled-components'
import BulkEditPill from '../BulkEditPill'
import {bulkEditDefaultProps, bulkEditPropTypes} from './propTypes'
import PT from 'prop-types'

const emptyBulkEditButtonStyles = {
  borderColor: 'transparent',
  cursor: 'pointer',
  display: 'block',
  height: '1em',
  padding: 0,
  width: '1em',
}

const ButtonWrap = styled.div`
  ${c.absoluteCenter(false, true)};
  font-size: 3em;
  height: 1em;
  right: ${c.SMALLEST}px;
  width: 1em;

  ${c.FOR_DESKTOP} {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
`

const IconWrap = styled.div`
  line-height: 1;
`

const Title = styled.div`
  display: inline-block;
  font-size: 1.25em;
  font-weight: ${c.FONT_WEIGHT_1};
  margin-left: 0.375em;
  margin-right: 1em;
  opacity: 0.5;
`

const BulkEdit = props => {
  if (!props.rowsWithBulkEdit.length) {
    return null
  }
  const isFixPostionCss = () => {
    switch (props.fixedPosition) {
      case 'bottom':
        return `position: fixed !important;
           bottom: 0;
          `
      default:
        return ''
    }
  }
  const Wrap = styled.div`
    ${c.overlay()};
    background-color: ${c.WHITE};
    box-shadow: 0 1px 3px ${c.rgbaBlack(0.1)};
    padding: 1em 5em 1em 1em;
    top: auto;
    z-index: 1;
    ${isFixPostionCss()};
  `

  return (
    <Wrap>
      <Title>Bulk editing</Title>
      {props.rowsWithBulkEdit.map(row => (
        <BulkEditPill
          code={row}
          key={row}
          name={props.fieldMap[row]}
          onClick={() => props.toggleRowsWithBulkEdit(row)}
        />
      ))}
      <ButtonWrap>
        <Button
          onClick={props.emptyBulkEdit}
          preventDefault
          reset
          style={emptyBulkEditButtonStyles}
        >
          <IconWrap>
            <Icon thin type="x" />
          </IconWrap>
        </Button>
      </ButtonWrap>
    </Wrap>
  )
}

BulkEdit.defaultProps = {
  fixedPosition: null,
  ...bulkEditDefaultProps,
}
BulkEdit.propTypes = {
  fixedPosition: PT.string,
  ...bulkEditPropTypes,
}

export default pure(BulkEdit)
