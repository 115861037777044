import PT from 'prop-types'
import React from 'react'
import createReduxForm from 'redux-form/es/reduxForm'

export const ReduxForm = ({children, onSubmit}) => (
  <form onSubmit={onSubmit}>{children}</form>
)

const defaultProps = {
  onSubmit: null,
}

const propTypes = {
  children: PT.node.isRequired,
  onSubmit: PT.func,
}

ReduxForm.defaultProps = defaultProps
ReduxForm.propTypes = propTypes

export default createReduxForm()(ReduxForm)
