import {isRequired} from 'arena-prop-types'
import PT from 'prop-types'

const {arrayOf, shape} = PT

export const timelineProps = {}

export const timelinePT = is => isRequired(shape(timelineProps), is)

export const timelinesPT = is => isRequired(arrayOf(timelinePT), is)
