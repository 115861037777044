import {combineReducers} from 'redux'
import {
  FETCH_ENTITIES_FAILURE,
  FETCH_ENTITIES_REQUEST,
  FETCH_ENTITIES_SUCCESS,
} from '../../../constants/actionTypes'

const entitiesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ENTITIES_SUCCESS:
      return action.payload.entities.entities || {}
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_ENTITIES_REQUEST:
      return true
    case FETCH_ENTITIES_SUCCESS:
    case FETCH_ENTITIES_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_ENTITIES_FAILURE:
      return action.payload.message
    case FETCH_ENTITIES_REQUEST:
    case FETCH_ENTITIES_SUCCESS:
      return null
    default:
      return state
  }
}

const entities = combineReducers({
  byId: entitiesById,
  isFetching,
  errorMessage,
})

export default entities
