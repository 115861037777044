import {
  FETCH_INITIAL_VALUES_REQUEST,
  FETCH_INITIAL_VALUES_SUCCESS,
} from '../../../constants/actionTypes'

const initialState = {
  isFetching: false,
  values: {},
}

const initialValues = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INITIAL_VALUES_REQUEST:
      return {...state, isFetching: true}
    case FETCH_INITIAL_VALUES_SUCCESS:
      return {...state, isFetching: false, values: action.values}
    default:
      return state
  }
}

export default initialValues
