import {notification} from 'antd'
import {NOTIFICATION_IDLE} from '../constants/notification'

const prepareClose = key => notification['close'](key)
const prepareOpen = data => notification['open'](data)

const openIdle = (title, description, onClose) => ({
  description,
  duration: 0,
  key: NOTIFICATION_IDLE,
  message: title,
  onClose,
})

export const executeNotification = {
  [NOTIFICATION_IDLE]: {
    close: () => prepareClose(NOTIFICATION_IDLE),
    open: (title, description, onClose) =>
      prepareOpen(openIdle(title, description, onClose)),
  },
}
