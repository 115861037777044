import {numberOrStringPT} from 'arena-prop-types'
import PT from 'prop-types'
import {alignProp, routeProp} from '../propTypes'

export const headerBottomDefaultProps = {}

export const headerBottomPropTypes = {
  align: alignProp,
  currentRoute: routeProp.isRequired,
  defaultAction: PT.func.isRequired,
  height: numberOrStringPT(true),
  paddingLeft: numberOrStringPT(true),
}
