export const LOCATION_TYPE_HOME = 'HOME'
export const LOCATION_TYPE_HOST = 'HOST'
export const LOCATION_TYPE_HYPO = 'HYPO'
export const LOCATION_TYPE_HOST_HYPO = 'HOST_HYPO'
export const LOCATION_TYPE_HOME_HOST_DEMOGRAPHY = 'DEMOG'
export const LOCATION_TYPE_HOME_HOST_COMPENSATION = 'COMP'
export const LOCATION_TYPE_HOME_HOST_INCOMETAX = 'INCOME'
export const LOCATION_TYPE_HOME_HOST_SOCIALTAX = 'SOCIAL'
export const LOCATION_TYPE_HYPO_COMPENSATION = 'HYPO_COMP'
export const LOCATION_TYPE_HYPO_INCOMETAX = 'HYPO_INCOME'
export const LOCATION_TYPE_HYPO_SOCIALTAX = 'HYPO_SOCIAL'
