import PT from 'prop-types'
import {commonPropTypes, hasValidResources} from '../../propTypes'

export const headerRowDefaultProps = {
  showAllResources: false,
}

export const headerRowPropTypes = {
  ...commonPropTypes,
  isVisible: PT.bool.isRequired,
  name: PT.string.isRequired,
  resources: hasValidResources,
  showAllResources: PT.bool,
  startYear: PT.number.isRequired,
}
