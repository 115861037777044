import AntNotification from 'antd/lib/notification'
import 'antd/lib/notification/style'
import React from 'react'
import {boolPT, funcPT, nodePT, numberPT, stringPT} from 'arena-prop-types'

const TOOLTIP_PLACEMENT_TOP = 'top'

const TOOLTIP_TRIGGER_CLICK = 'click'

const defaultProps = {
  arrowPointAtCenter: false,
  autoAdjustOverflow: true,
  defaultVisible: false,
  mouseEnterDelay: 0,
  mouseLeaveDelay: 0.1,
  onVisibleChange: null,
  placement: TOOLTIP_PLACEMENT_TOP,
  title: '',
  trigger: TOOLTIP_TRIGGER_CLICK,
}

const propTypes = {
  arrowPointAtCenter: boolPT(),
  autoAdjustOverflow: boolPT(),
  defaultVisible: boolPT(),
  getPopupContainer: funcPT(),
  mouseEnterDelay: numberPT(),
  mouseLeaveDelay: numberPT(),
  onVisibleChange: funcPT(),
  placement: stringPT(),
  title: nodePT(),
  trigger: stringPT(),
}
const Notification = props => <AntNotification {...props} />

Notification.defaultProps = defaultProps
Notification.propTypes = propTypes

export default Notification
