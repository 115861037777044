import React from 'react'
import styled from 'styled-components'
import {Icon as AntIcon} from 'antd'
import SearchResultItem from '../SearchResultItem'

const List = styled.ul`
  background-color: rgba(255, 255, 255, 1);
  max-height: 15em;
  overflow-y: auto;
  padding-bottom: 0px;
  padding-top: 0px;
  position: absolute;
  box-sizing: border-box;
  width: inherit;
  min-width: 232px;
  z-index: 2;
  box-shadow: 0 8px 16px 0px rgba(0, 0, 0, 0.02);
  color: #777879;
  max-width: 400px;
`

const LoadMore = styled.button`
  color: #3498db;
  border: none;
  border-top: 1px dashed rgba(0, 0, 0, 0.0875);
  width: 100%;
  padding: 5px;
  cursor: pointer;
  font-size: 0.9em;
`

const Iconwrap = styled.span`
  vertical-align: text-bottom;
  margin-left: 5px;
  margin-right: 5px;
  i {
    font-size: 0.8em;
  }
`

const SearchResultSet = props => {
  const showLoader = props.searchResultsFetching
  const loadingItem = {
    id: -1,
    title: 'Loading...',
  }
  const noResultItem = {
    id: -1,
    title: 'No Results',
  }
  if (showLoader) {
    return (
      <List>
        <SearchResultItem
          highlight=""
          key={-1}
          item={loadingItem}
        ></SearchResultItem>
      </List>
    )
  }
  return (
    <List>
      {props.searchResults.length === 0 && (
        <SearchResultItem
          highlight=""
          key={-1}
          item={noResultItem}
        ></SearchResultItem>
      )}
      {props.searchResults.map(item => {
        return (
          <SearchResultItem
            isLoading={showLoader}
            key={item.id}
            item={item}
            highlight={props.searchString}
            handleChange={props.handleChange}
            handleSearchOnSelect={props.handleSearchOnSelect}
            toggleSearchResult={props.toggleSearchResult}
          ></SearchResultItem>
        )
      })}
      {props.nextUrl && props.nextUrl !== '' && (
        <LoadMore onClick={() => props.loadMoreResults(props.nextUrl)}>
          {props.loadMoreResultsLoading ? (
            <>
              <Iconwrap>
                <AntIcon type="loading"></AntIcon>
              </Iconwrap>
              Loading...
            </>
          ) : (
            <>
              Load more
              <Iconwrap>
                <AntIcon type="down"></AntIcon>
              </Iconwrap>
            </>
          )}
        </LoadMore>
      )}
    </List>
  )
}

export default SearchResultSet
