export const whiteListedExtentions = [
  'xls',
  'xlsx',
  'csv',
  'doc',
  'docx',
  'pdf',
  'jpeg',
  'jpg',
  'png',
  'gif',
  'bmp',
  'txt',
  'zip',
  'rdl',
  'msg',
]

export const DOCUMENT_MODAL_HEADER = (
  firstName,
  lastName,
  employeeId,
  country
) => `Documents for ${firstName} ${lastName} (${employeeId}) to ${country}`

export const DELETE_CONFIRMATION = fileName =>
  `Are you sure you want to delete "${fileName}" ?`

export const DELETE_DOCUMENT_CONFIRMATION = 'Delete document confirmation'
export const UNSUPPORTED_FILE_TYPE_UPLOAD_MESSAGE = fileType =>
  `${fileType} extension is not supported.`
export const SUPPORTED_FILE_TYPE = `Supported extensions: ${whiteListedExtentions.join(
  ','
)}`
