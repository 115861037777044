import React from 'react'
import {change} from 'redux-form'
import {connect} from 'react-redux'
import AuthContainer from '../Auth/index'
import Edit from '../../components/EditAssignment/index'
import {EDIT_ASSIGNMENT_FORM} from '../../constants/form'
import {View} from '../../lib/components/core'
import {getHeight} from '../../selectors/environment'
import {
  fetchAssignment,
  fetchAssignmentAuditLog,
  fetchAssignmentFamilyMembersIfNeeded,
  fetchAssignmentOptionsIfNeeded,
  updateAssignment,
} from '../../actions/assignments'
import {
  fetchAccountSectionsIfNeeded,
  fetchFormSectionsIfNeeded,
  fetchTaxSectionsIfNeeded,
  updatePreviousFormTabErrors,
  executeBusinessRulesIfNeeded,
  fetchCalculationSectionsIfNeeded,
} from '../../actions/form'
import {
  fetchResourcesIfNeeded,
  fetchFilingStatusOnLocationChange,
} from '../../actions/resource'
import {
  getAccountSections,
  getAssignmentFormValues,
  getEditSyncErrors,
  getEditAssignmentFormTabs,
  getEditAssignmentFormInitialValues,
  getEditErrors,
  getEditFormIsFetching,
  getEditSubmitFailed,
  getFormSections,
  getTaxSections,
  getCalculationSections,
  getEditAllErrors,
  getEditValidationErrorMessage,
} from '../../selectors/form'
import {
  getAssignmentsAuditLogsIsFetching,
  getAssignmentAuditLogsSummary,
  getAssignmentIsLocked,
  getAssignmentsIsUpdating,
  getCurrentAssignmentAuditLogs,
  getAssignmentsErrorMessage,
} from '../../selectors/assignments'
import {getId} from '../../selectors/router'
import {getResources} from '../../selectors/resources'
import {fetchCompaniesIfNeeded} from '../../actions/companies'
import {
  getCompanies,
  getLastSelectedCompany,
  getLastSelectedCompanyFormId,
  getLastSelectedCompanyFormRecordTitle,
} from '../../selectors/company'
import ErrorBoundaryContainer from '../../containers/ErrorBoundary'

const EditAssignmentContainer = props => (
  <ErrorBoundaryContainer>
    <View authContainer={AuthContainer} name="Initiations">
      <Edit {...props} />
    </View>
  </ErrorBoundaryContainer>
)

const mapStateToProps = state => {
  const id = getId(state)

  return {
    accountSections: getAccountSections(state),
    assignmentAuditLogs: getCurrentAssignmentAuditLogs(state, {positionId: id}),
    assignmentAuditLogsIsFetching: getAssignmentsAuditLogsIsFetching(state),
    assignmentAuditLogsSummary: getAssignmentAuditLogsSummary(state, {
      positionId: id,
    }),
    companies: getCompanies(state),
    currentValues: getAssignmentFormValues(state, {
      formName: EDIT_ASSIGNMENT_FORM,
      fieldsToGet: [
        'firstName',
        'homeLocation',
        'hostLocation',
        'lastName',
        'planTypeCode',
        'startDate',
        'options.General__HomeCompensationLocation__List.value',
        'options.General__HostCompensationLocation__List.value',
        'options.General__HypoCompensationLocation__List.value',
        'options.General__HostHypoCompensationLocation__List.value',
        'options.General__HomeIncomeTaxLocation__List.value',
        'options.General__HostIncomeTaxLocation__List.value',
        'options.General__HypoIncomeTaxLocation__List.value',
        'options.General__HostHypoIncomeTaxLocation__List.value',
        'options.General__HomeSocialTaxLocation__List.value',
        'options.General__HostSocialTaxLocation__List.value',
        'options.General__HypoSocialTaxLocation__List.value',
        'options.General__HostHypoSocialTaxLocation__List.value',
      ],
    }),
    environmentHeight: getHeight(state),
    errors: getEditErrors(state),
    errorsFromAllTabs: getEditAllErrors(state),
    formIsFetching: getEditFormIsFetching(state),
    formIsSubmitting: getAssignmentsIsUpdating(state),
    formName: EDIT_ASSIGNMENT_FORM,
    formSections: getFormSections(state),
    formTabs: getEditAssignmentFormTabs(state),
    formValidationError: getEditValidationErrorMessage(state),
    formSubmissionError: getAssignmentsErrorMessage(state),
    id,
    initialValues: getEditAssignmentFormInitialValues(state, {positionId: id}),
    isLocked: getAssignmentIsLocked(state, {positionId: id}),
    lastSelectedCompany: getLastSelectedCompany(state),
    lastSelectedCompanyFormId: getLastSelectedCompanyFormId(state),
    resources: getResources(state),
    submitFailed: getEditSubmitFailed(state),
    syncErrors: getEditSyncErrors(state),
    taxSections: getTaxSections(state),
    calculationSections: getCalculationSections(state),
    formTitle: getLastSelectedCompanyFormRecordTitle(state),
  }
}

export default connect(mapStateToProps, {
  change,
  executeBusinessRulesIfNeeded,
  fetchAccountSectionsIfNeeded,
  fetchAssignment,
  fetchAssignmentAuditLog,
  fetchAssignmentFamilyMembersIfNeeded,
  fetchAssignmentOptionsIfNeeded,
  fetchFormSectionsIfNeeded,
  fetchResourcesIfNeeded,
  fetchCalculationSectionsIfNeeded,
  fetchTaxSectionsIfNeeded,
  updateAssignment,
  updatePreviousFormTabErrors,
  fetchFilingStatusOnLocationChange,
  fetchCompaniesIfNeeded,
})(EditAssignmentContainer)
