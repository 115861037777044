import PT from 'prop-types'
import {
  DATE_TIME_FIELD,
  NUMBER_FIELD,
  SELECT_FIELD,
  TEXT_AREA_FIELD,
  TEXT_FIELD,
} from 'arena-config'
import {CURRENCY_FIELD} from '../../constants/form'

export const PT_CURRENCY_DEFAULTS = PT.shape({
  decimal: PT.string.isRequired,
  fixedTo: PT.number.isRequired,
  symbol: PT.string.isRequired,
  thousand: PT.string.isRequired,
})

export const PT_FIELD_TYPE = props => {
  const {type} = props
  if (
    type &&
    ![
      DATE_TIME_FIELD,
      NUMBER_FIELD,
      CURRENCY_FIELD,
      SELECT_FIELD,
      TEXT_AREA_FIELD,
      TEXT_FIELD,
    ].includes(type)
  ) {
    return new Error(`${type} is not a valid Field type`)
  }
}
