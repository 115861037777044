import {emptyObject, funcPT, isRequired, stringPT} from 'arena-prop-types'
import PT from 'prop-types'

const {number, oneOfType, shape, string} = PT

export const currentValuePT = is =>
  isRequired(oneOfType([emptyObject, number, string]), is)

export const inputPropTypes = {
  name: stringPT(true),
  onBlur: funcPT(),
  onChange: funcPT(true),
  onDragStart: funcPT(),
  onDrop: funcPT(),
  onFocus: funcPT(true),
  value: currentValuePT(true),
}

export const inputPT = is =>
  isRequired(
    shape({
      ...inputPropTypes,
    }),
    is
  )
