import {combineReducers} from 'redux'
import {IDLE_NOTIFICATION_IS_VISIBLE} from '../constants/notification'

const notificationIsVisible = (state = false, action) => {
  switch (action.type) {
    case IDLE_NOTIFICATION_IS_VISIBLE:
      return action.isVisible
    default:
      return state
  }
}

const idle = combineReducers({notificationIsVisible})
export default idle
