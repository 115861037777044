import {isRequired, stringPT} from 'arena-prop-types'
import PT from 'prop-types'
import {PT_FIELD_TYPE} from '../../lib/constants/propTypes'
import {fieldOptionsPT} from './fieldOptions'

const {arrayOf, bool, shape, string} = PT

export const formFieldProps = {
  code: stringPT(true),
  creatable: bool,
  description: props => PT_FIELD_TYPE(props),
  label: stringPT(true),
  options: fieldOptionsPT(),
  optionsType: string,
}

export const formFieldPT = is => isRequired(shape(formFieldProps), is)

export const formFieldsPT = is => isRequired(arrayOf(formFieldPT), is)
