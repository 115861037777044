import PT from 'prop-types'
import React from 'react'
import Isvg from 'react-inlinesvg'
import {X} from '../constants/icons'

const defaultProps = {
  iconSrc: null,
  message: null,
  title: null,
}

const propTypes = {
  children: PT.node.isRequired,
  iconSrc: PT.string,
  message: PT.string,
  onClose: PT.func.isRequired,
  title: PT.string,
}

const Modal = ({children, iconSrc, message, onClose, title}) => (
  <div data-component="Modal">
    <div className="Modal-close" onClick={onClose}>
      <Isvg src={X()} />
    </div>
    <div className="Modal-content absolute-center">
      <div className="display-flex">
        {iconSrc && <img alt="modal_icon" src={iconSrc} />}
      </div>
      {title && <div className="Modal-title">{title}</div>}
      {message && <div className="Modal-message">{message}</div>}
      {children}
    </div>
  </div>
)

Modal.defaultProps = defaultProps
Modal.propTypes = propTypes

export default Modal
