import React from 'react'
import onClickOutside from 'react-onclickoutside'
import Searchbar from './lib/components/Searchbar'
import SearchResultSet from './lib/components/SearchResultSet'

const Typeahead = props => {
  Typeahead.handleOnClickOutside = () => {
    props.toggleSearchResult(false)
  }

  return (
    <div>
      <Searchbar
        isSearchResultOpen={props.isSearchResultOpen}
        handleSearch={props.handleSearch}
        handleChange={props.handleChange}
        toggleSearchResult={() => props.toggleSearchResult(true)}
        value={props.value}
      ></Searchbar>
      {props.isSearchResultOpen && (
        <>
          <SearchResultSet
            handleChange={props.handleChange}
            handleSearchOnSelect={props.handleSearchOnSelect}
            searchString={props.value}
            searchResults={props.searchResults}
            searchResultsFetching={props.searchResultsFetching}
            toggleSearchResult={() => props.toggleSearchResult(false)}
            loadMoreResults={props.loadMoreResults}
            loadMoreResultsLoading={props.loadMoreResultsLoading}
            nextUrl={props.nextUrl}
          ></SearchResultSet>
        </>
      )}
    </div>
  )
}

const clickOutside = {
  handleClickOutside: () => Typeahead.handleOnClickOutside,
}

export default onClickOutside(Typeahead, clickOutside)
