import {combineReducers} from 'redux'
import {FETCH_CALCULATION_OPTIONS_SUCCESS} from '../../../constants/actionTypes'

const calculationFieldsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CALCULATION_OPTIONS_SUCCESS:
      return {...state, ...action.payload.entities.calculationFields}
    default:
      return state
  }
}

const calculationFields = combineReducers({
  byId: calculationFieldsById,
})

export default calculationFields
