import {isRequired, stringPT} from 'arena-prop-types'
import PT from 'prop-types'
import {formFieldsPT} from './formFields'

const {arrayOf, shape} = PT

export const calculationSectionProps = {
  calculationFields: formFieldsPT(true),
  code: stringPT(true),
  name: stringPT(true),
}

export const calculationSectionPT = is =>
  isRequired(shape(calculationSectionProps), is)

export const calculationSectionsPT = is =>
  isRequired(arrayOf(calculationSectionPT), is)
