import React, {Component} from 'react'
import PT from 'prop-types'
import styled from 'styled-components'
import {Button, Flex} from 'arena-components'
import {Alert, Icon} from './core'
import notification from 'antd/lib/notification'
import 'antd/lib/notification/style/index.css'

const defaultProps = {
  description: '',
  message: 'Sorry, an unexpected error occurred while processing your request.',
  setErrors: null,
  sendMail: null,
}

const propTypes = {
  children: PT.node.isRequired,
  description: PT.string,
  message: PT.string.isRequired,
  setErrors: PT.func,
  sendMail: PT.func,
}

const ErrorMessage = styled.span`
  font-size: 1.2rem;
`

const ErrorDescription = styled.div`
  font-size: 0.9 rem;
`

const ButtonInner = styled.div`
  color: #4c4e51;
  cursor: pointer;
  &:hover {
    color: #999b9c;
  }
`
const IconWrap = styled.div`
  line-height: 1;
  margin-right: 0.3em;
`
const LinkOuter = styled.div`
  display: flex;
  justify-content: center;
`

const Link = props => (
  <Button
    onClick={props.onClick}
    reset
    style={{
      border: 0,
      padding: 0,
      display: 'inline-block',
      margin: '0.5em 1em',
    }}
  >
    {props.children}
  </Button>
)

const iconProps = {
  className: 'gray',
  inlineSVG: true,
}

const renderErrorMessage = message => <ErrorMessage>{message}</ErrorMessage>

const renderDescription = error => <ErrorDescription>{error}</ErrorDescription>

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {hasError: false, hasSentLogs: false}
  }

  componentDidCatch(error, errorInfo) {
    this.errorCallBack(error, errorInfo, true)
    this.setState({hasError: true, hasSentLogs: true})
  }

  errorCallBack = (error, errorInfo, hasError) => {
    this.props.dispatch(this.props.setErrors(error, errorInfo, hasError))
  }

  handleSendMail = event => {
    event.preventDefault()
    this.props.dispatch(this.props.sendMail())
    notification['success']({
      message: 'Success',
      description: 'Logs sent to Application Support team.',
      duration: 5,
    })
    this.setState({hasSentLogs: false})
  }

  handleNavigateBack = event => {
    event.preventDefault()
    this.props.dispatch(this.props.navigateTo(this.props.onGoBack))
  }

  render() {
    const {children, message, error} = this.props
    const {hasError, hasSentLogs} = this.state

    if (hasError) {
      return (
        <>
          <Alert
            description={renderDescription(error)}
            showIcon
            message={renderErrorMessage(message)}
            type="error"
          ></Alert>
          <LinkOuter>
            {hasSentLogs && (
              <Link onClick={this.handleSendMail}>
                <ButtonInner>
                  <Flex>
                    <IconWrap>
                      <Icon {...iconProps} type="cog" />
                    </IconWrap>
                    <span>Send logs to Application Support</span>
                  </Flex>
                </ButtonInner>
              </Link>
            )}
            <Link onClick={this.handleNavigateBack}>
              <ButtonInner>
                <Flex>
                  <IconWrap>
                    <Icon {...iconProps} type="home" />
                  </IconWrap>
                  <span>Back to home</span>
                </Flex>
              </ButtonInner>
            </Link>
          </LinkOuter>
        </>
      )
    }
    return children
  }
}

ErrorBoundary.defaultProps = defaultProps
ErrorBoundary.prototypes = propTypes

export default ErrorBoundary
