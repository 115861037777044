import PT from 'prop-types'
import {numberOrStringPT} from 'arena-prop-types'

export const alertProp = PT.shape({
  actions: PT.arrayOf(
    PT.shape({
      label: PT.string.isRequired,
      onClick: PT.func.isRequired,
    })
  ),
  code: numberOrStringPT(true),
  id: numberOrStringPT(true),
  message: PT.string.isRequired,
})

export const alertsProp = PT.arrayOf(alertProp)

export const alignProp = PT.oneOf(['center', 'left', 'right'])

export const linkProp = PT.shape({
  customRenderer: PT.func,
  id: numberOrStringPT(true),
  onClick: PT.func,
  tab: PT.number,
  title: PT.string,
  to: PT.any,
})

export const linksProp = PT.arrayOf(linkProp)

export const routeProp = PT.shape({
  keys: PT.shape({}).isRequired,
  options: PT.shape({}).isRequired,
  path: PT.string.isRequired,
})

export const defaultProps = {
  activeTab: null,
  advancedSearchIsOpen: false,
  alerts: [],
  disableAuthentication: false,
  dropdownOptions: null,
  hasHeader: true,
  headerAlign: 'left',
  headerBackgroundColor: '#000000',
  headerHeight: 48,
  links: [],
  login: null,
  logout: null,
  notifications: [],
  onLogoClick: null,
  renderAdvancedSearch: null,
  renderSearchResults: null,
  search: null,
  searchString: '',
  searchOnChange: null,
  searchResultsIsOpen: false,
  searchValue: '',
  searchWidth: null,
  sendReport: null,
  subHeaderAlign: 'left',
  subHeaderHeight: 32,
  subLinks: [],
  toggleAdvancedSearchIsOpen: null,
  toggleSearchResultsIsOpen: null,
  user: '',
  zIndex: 2,
}

export const propTypes = {
  activeTab: PT.number,
  advancedSearchIsOpen: PT.bool,
  alerts: alertsProp,
  children: PT.node.isRequired,
  currentRoute: routeProp.isRequired,
  defaultAction: PT.func.isRequired,
  disableAuthentication: PT.bool,
  dropdownOptions: PT.array,
  dropdownDefaultValue: PT.shape({}),
  hasHeader: PT.bool,
  headerAlign: alignProp,
  headerBackgroundColor: PT.string,
  headerHeight: numberOrStringPT(),
  links: linksProp,
  login: PT.func,
  logoSrc: PT.string.isRequired,
  logout: PT.func,
  notifications: PT.arrayOf(PT.shape({})),
  onLogoClick: PT.oneOfType([PT.func, PT.shape({}), PT.string]),
  onSelect: PT.func,
  selectedItem: PT.oneOfType([PT.func, PT.shape({}), PT.string]),
  renderAdvancedSearch: PT.node,
  renderSearchResults: PT.node,
  search: PT.func,
  typeahead: PT.func,
  searchString: PT.string,
  searchResults: PT.arrayOf(PT.shape({})),
  searchResultsFetching: PT.bool,
  searchOnChange: PT.func,
  searchResultsIsOpen: PT.bool,
  searchValue: PT.string,
  searchWidth: numberOrStringPT(),
  sendReport: PT.func,
  subHeaderAlign: alignProp,
  subHeaderHeight: numberOrStringPT(),
  subLinks: linksProp,
  toggleAdvancedSearchIsOpen: PT.func,
  toggleSearchResultsIsOpen: PT.func,
  user: PT.string,
  zIndex: PT.number,
}
