import PT from 'prop-types'
import React from 'react'
import ReduxFieldArray from 'redux-form/es/FieldArray'

const FieldArray = props => (
  <ReduxFieldArray
    component={props.component}
    customFormSectionData={props.customFormSectionData}
    errors={props.errors}
    name={props.name}
    helpText={props.helpText}
    resources={props.resources}
  />
)

FieldArray.propTypes = {
  component: PT.oneOfType([PT.func, PT.shape({})]).isRequired,
  customFormSectionData: PT.shape({}).isRequired,
  errors: PT.shape({}),
  name: PT.string.isRequired,
  resources: PT.shape({}).isRequired,
}

export default FieldArray
