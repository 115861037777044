import PT from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {FONT_2_WEIGHT_2, SELECT_FIELD} from 'arena-config'
import {
  boolPT,
  emptyObjectPT,
  nodePT,
  numberPT,
  stringPT,
} from 'arena-prop-types'
import FormField from './Field'
import SectionName from './SectionName'
import StyledFlex from './StyledFlex'
import {Conditional} from '../../lib/components/core'
import {formFieldsPT} from '../../constants/propTypes/formFields'
import {getValidationFunctions} from '../../utils/validation'
import {resourcesPT} from '../../constants/propTypes/resources'
import {PrepareValidationMessage} from '../../utils/validationMessage'

const NoDataPlaceholder = styled.div`
  font-size: 16px;
  font-weight: ${FONT_2_WEIGHT_2};
`

const FormSection = ({
  children,
  columnCount,
  errors,
  formFields,
  helpText,
  isLocked,
  name,
  noDataPlaceholderMessage,
  resources,
}) => (
  <>
    {name && <SectionName helpText={helpText}>{name}</SectionName>}
    {formFields && formFields.length > 0 ? (
      <StyledFlex columnCount={columnCount}>
        {formFields.map(field => {
          const resourceExists = field.optionsType in resources

          const shouldUpdateFieldOptions = resourceExists
            ? field.optionsType || !field.options
            : false

          const fieldOptions = shouldUpdateFieldOptions
            ? resources[field.optionsType]
            : field.options

          const validate = field.validate
            ? getValidationFunctions(field.validate)
            : []

          // Do NOT display dropdowns that do not allow new values and have no options.
          const shouldDisplaySelectField = !(
            fieldOptions.length < 1 && !field.creatable
          )

          const shouldDisplayField =
            field.type === SELECT_FIELD ? shouldDisplaySelectField : true

          const isVisible =
            field.additionalProperties && field.additionalProperties.isVisible

          return (
            <Conditional condition={shouldDisplayField} key={field.code}>
              <FormField
                creatable={field.creatable}
                disabled={field.disabled}
                label={field.label}
                locked={isLocked}
                name={field.code}
                suffix={field.suffix}
                options={fieldOptions}
                required={field.required}
                maxLength={field.maxLength}
                type={field.type}
                validate={validate}
                validationMessage={
                  PrepareValidationMessage(field.code, errors) || ''
                }
                visibility={isVisible}
              />
            </Conditional>
          )
        })}
      </StyledFlex>
    ) : (
      <StyledFlex columnCount={1}>
        <NoDataPlaceholder>{noDataPlaceholderMessage}</NoDataPlaceholder>
      </StyledFlex>
    )}
    {children}
  </>
)

FormSection.defaultProps = {
  children: null,
  errors: {},
  isLocked: false,
  noDataPlaceholderMessage: '',
  resources: {},
}

FormSection.propTypes = {
  children: nodePT(),
  columnCount: numberPT(true),
  errors: emptyObjectPT(),
  formFields: formFieldsPT(true),
  isLocked: boolPT(),
  name: PT.oneOfType([nodePT(), stringPT()]),
  noDataPlaceholderMessage: stringPT(),
  resources: resourcesPT(),
}

export default FormSection
