import PT from 'prop-types'
import React from 'react'

const propTypes = {
  authContainer: PT.oneOfType([PT.func, PT.bool, PT.object]).isRequired,
  children: PT.node.isRequired,
  name: PT.string.isRequired,
}

const View = ({authContainer, children, name}) => {
  let authed = children

  const AuthNode = authContainer
  authed = <AuthNode>{children}</AuthNode>

  return (
    <div data-app={name} data-component="View">
      {authed}
    </div>
  )
}

View.propTypes = propTypes

export default View
