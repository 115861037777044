import {formValueSelector} from 'redux-form'

export const getForm = state => state.form

export const getFormSelector = formName => {
  return formName && formValueSelector(formName)
}

// More efficient than single method above, retrieves multiple fields at once
export const getFormFieldsValue = (state, props) => {
  if (props && props.formName && props.fieldsToGet) {
    return typeof getFormSelector(props.formName)(
      state,
      ...props.fieldsToGet
    ) === 'string'
      ? {
          [props.fieldsToGet[0]]: getFormSelector(props.formName)(
            state,
            ...props.fieldsToGet
          ),
        }
      : getFormSelector(props.formName)(state, ...props.fieldsToGet)
  }
  return null
}
