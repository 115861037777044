import makeFieldName from './makeFieldName'
import toggleArrayItem from './toggleArrayItem'

export default (props, state, setState) => {
  const emptyBulkEdit = () => setState({rowsWithBulkEdit: []})

  const toggleRowsWithBulkEdit = item =>
    setState({
      rowsWithBulkEdit: toggleArrayItem(state.rowsWithBulkEdit, item),
    })

  const toggleRowsWithOpenResources = item =>
    setState({
      rowsWithOpenResources: toggleArrayItem(state.rowsWithOpenResources, item),
    })

  const toggleShowAllResources = () =>
    setState({showAllResources: !state.showAllResources})

  const toggleStickyHeader = (item, isEntering) =>
    setState({
      stickyHeader: {
        ...state.stickyHeader,
        [item]: !!isEntering,
      },
    })

  // bulk edit

  const makeBulkCodes = suffix =>
    state.rowsWithBulkEdit.map(code =>
      makeFieldName(code, props.delimiter, suffix)
    )

  const handleBulkChange = (name, prefix) => (event, newValue) => {
    try {
      const suffix = name.replace(`${prefix}${props.delimiter}`, '')
      const bulkCodes = makeBulkCodes(suffix)

      for (let i = 0; i < bulkCodes.length; i += 1) {
        props.change(props.formName, bulkCodes[i], newValue)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    emptyBulkEdit,
    handleBulkChange,
    toggleRowsWithBulkEdit,
    toggleRowsWithOpenResources,
    toggleShowAllResources,
    toggleStickyHeader,
  }
}
