import {emptyObjectPT} from 'arena-prop-types'
import PT from 'prop-types'
import React from 'react'
import ReduxForm from './ReduxForm'

export const Form = props => <ReduxForm {...props} form={props.name} />

Form.defaultProps = {
  asyncValidate: null,
  asyncValidating: false,
  destroy: null,
  destroyOnUnmount: true,
  dirty: false,
  enableReinitialize: false,
  error: '',
  initialValues: {},
  onSubmit: () => console.warn('Form is missing onSubmit'),
  pristine: false,
  reset: null,
  submitting: false,
  touchOnChange: true,
}

Form.propTypes = {
  asyncValidate: PT.func,
  asyncValidating: PT.oneOfType([PT.string, PT.bool]),
  children: PT.node.isRequired,
  destroy: PT.func,
  destroyOnUnmount: PT.bool,
  dirty: PT.bool,
  enableReinitialize: PT.bool,
  error: PT.string,
  initialValues: emptyObjectPT(),
  name: PT.string.isRequired,
  onSubmit: PT.func,
  pristine: PT.bool,
  reset: PT.func,
  submitting: PT.bool,
  touchOnChange: PT.bool,
}

export default Form
