import {funcPT, stringPT} from 'arena-prop-types'
import React from 'react'
import {inputPropTypes} from '../../../constants/inputProps'
import fieldPropTypes, {makeFieldProps} from '../propTypes'
import Decorator from '../Decorator'
import StyledInput from './Styled'

const Input = props => {
  return (
    <Decorator
      customValidationMessage={props.customValidationMessage}
      fixedLabel={props.styleProps.fixedLabel}
      hasValue={props.hasValue}
      label={props.label}
      validationColor={props.validationColor}
      validationMessage={props.validationMessage}
      width={props.styleProps.width}
    >
      <StyledInput
        {...makeFieldProps(props)}
        className={props.hasValue ? 'hasValue' : ''}
        disabled={props.disabled}
        maxLength={props.maxLength}
        name={props.name}
        onBlur={props.onBlur}
        onChange={props.onChange}
        onDragStart={props.onDragStart}
        onDrop={props.onDrop}
        onFocus={props.onFocus}
        onKeyDown={props.onKeyDown}
        onKeyPress={props.onKeyPress}
        refId={props.refId}
        type={props.type}
        value={props.value}
      />
    </Decorator>
  )
}

Input.defaultProps = {
  onKeyDown: null,
  onKeyPress: null,
  type: 'text',
}

Input.propTypes = {
  ...fieldPropTypes,
  ...inputPropTypes,
  onChange: funcPT(true),
  onKeyDown: funcPT(),
  onKeyPress: funcPT(),
  type: stringPT(),
}

export default Input
