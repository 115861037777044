import {normalize} from 'normalizr'
import {RSAA, getJSON} from 'redux-api-middleware'
import {
  ASSIGNMENTS_URL,
  ASSIGNMENT_AUDIT_LOG_URL,
  ASSIGNMENT_FAMILY_MEMBERS_URL,
  ASSIGNMENT_OPTIONS_URL,
} from '../constants/api'
import {
  CREATE_ASSIGNMENT_FAILURE,
  CREATE_ASSIGNMENT_REQUEST,
  CREATE_ASSIGNMENT_SUCCESS,
  DELETE_ASSIGNMENT_DISABLE,
  DELETE_ASSIGNMENT_ENABLE,
  DELETE_ASSIGNMENT_FAILURE,
  DELETE_ASSIGNMENT_REQUEST,
  DELETE_ASSIGNMENT_SUCCESS,
  FETCH_ASSIGNMENT_AUDIT_LOGS_FAILURE,
  FETCH_ASSIGNMENT_AUDIT_LOGS_REQUEST,
  FETCH_ASSIGNMENT_AUDIT_LOGS_SUCCESS,
  FETCH_ASSIGNMENT_FAILURE,
  FETCH_ASSIGNMENT_FAMILY_MEMBERS_FAILURE,
  FETCH_ASSIGNMENT_FAMILY_MEMBERS_REQUEST,
  FETCH_ASSIGNMENT_FAMILY_MEMBERS_SUCCESS,
  FETCH_ASSIGNMENT_OPTIONS_FAILURE,
  FETCH_ASSIGNMENT_OPTIONS_REQUEST,
  FETCH_ASSIGNMENT_OPTIONS_SUCCESS,
  FETCH_ASSIGNMENT_REQUEST,
  FETCH_ASSIGNMENT_SUCCESS,
  UPDATE_ASSIGNMENT_FAILURE,
  UPDATE_ASSIGNMENT_OPTIONS_FAILURE,
  UPDATE_ASSIGNMENT_OPTIONS_REQUEST,
  UPDATE_ASSIGNMENT_OPTIONS_SUCCESS,
  UPDATE_ASSIGNMENT_REQUEST,
  UPDATE_ASSIGNMENT_SUCCESS,
  REJECT_ASSIGNMENT_DISABLE,
  REJECT_ASSIGNMENT_ENABLE,
  CLONE_ASSIGNMENT_ENABLE,
} from '../constants/actionTypes'
import {
  assignmentAuditLogSchema,
  assignmentFamilyMemberSchema,
  assignmentOptionSchema,
  assignmentSchema,
} from '../constants/schemas'
import {
  fetchTimeline,
  fetchTimelineAndExecuteTaskAction,
  scheduleMultipleTimelineRefresh,
} from './timeline'
import {
  getAssignmentFamilyMembersIsFetching,
  getAssignmentOptionsIsFetching,
} from '../selectors/assignments'
import {getChannel} from '../selectors/channels'
import {
  mapAssignment,
  mapAssignmentAuditLog,
  mapAssignmentFamilyMembers,
  mapAssignmentOptions,
  prepareNewAssignment,
  transformAssignmentOptions,
} from '../utils/api'
import {isIE} from '../utils/browser'
import {navigateToDefaultChannel, fetchChannelSummary} from './channels'

export const disableDeleteAssignment = () => ({type: DELETE_ASSIGNMENT_DISABLE})

export const enableDeleteAssignment = id => ({
  type: DELETE_ASSIGNMENT_ENABLE,
  id,
})

export const disableRejectAssignment = () => ({type: REJECT_ASSIGNMENT_DISABLE})

export const enableRejectAssignment = id => ({
  type: REJECT_ASSIGNMENT_ENABLE,
  id,
})

export const setCopyAssignmentId = id => ({
  type: CLONE_ASSIGNMENT_ENABLE,
  id,
})

export const fetchAssignment = id => dispatch => {
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }

  dispatch({
    [RSAA]: {
      endpoint: ASSIGNMENTS_URL(id),
      method: 'GET',
      headers,
      types: [
        FETCH_ASSIGNMENT_REQUEST,
        {
          type: FETCH_ASSIGNMENT_SUCCESS,
          payload: (_action, _currentState, res) =>
            getJSON(res).then(json =>
              normalize(mapAssignment(json), assignmentSchema)
            ),
        },
        FETCH_ASSIGNMENT_FAILURE,
      ],
    },
  })
}

export const fetchAssignmentOptionsIfNeeded = (
  positionId,
  includeDefaults = false,
  includeCalculationResults = false
) => (dispatch, getState) => {
  const state = getState()
  if (!getAssignmentOptionsIsFetching(state)) {
    let headers = {}
    if (isIE) {
      headers = {
        pragma: 'no-cache',
        'cache-control': 'no-cache',
      }
    }

    dispatch({
      [RSAA]: {
        endpoint: ASSIGNMENT_OPTIONS_URL(
          positionId,
          includeDefaults,
          includeCalculationResults
        ),
        method: 'GET',
        headers,
        types: [
          FETCH_ASSIGNMENT_OPTIONS_REQUEST,
          {
            type: FETCH_ASSIGNMENT_OPTIONS_SUCCESS,
            meta: {positionId},
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapAssignmentOptions(json), [assignmentOptionSchema])
              ),
          },
          FETCH_ASSIGNMENT_OPTIONS_FAILURE,
        ],
      },
    })
  }
}

export const fetchAssignmentFamilyMembersIfNeeded = positionId => (
  dispatch,
  getState
) => {
  const state = getState()
  if (!getAssignmentFamilyMembersIsFetching(state)) {
    let headers = {}
    if (isIE) {
      headers = {
        pragma: 'no-cache',
        'cache-control': 'no-cache',
      }
    }

    dispatch({
      [RSAA]: {
        endpoint: ASSIGNMENT_FAMILY_MEMBERS_URL(positionId),
        method: 'GET',
        headers,
        types: [
          FETCH_ASSIGNMENT_FAMILY_MEMBERS_REQUEST,
          {
            type: FETCH_ASSIGNMENT_FAMILY_MEMBERS_SUCCESS,
            meta: {positionId},
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapAssignmentFamilyMembers(json), [
                  assignmentFamilyMemberSchema,
                ])
              ),
          },
          FETCH_ASSIGNMENT_FAMILY_MEMBERS_FAILURE,
        ],
      },
    })
  }
}

export const createAssignment = assignment => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: ASSIGNMENTS_URL(),
      method: 'POST',
      body: JSON.stringify(prepareNewAssignment(assignment)),
      types: [
        CREATE_ASSIGNMENT_REQUEST,
        {
          type: CREATE_ASSIGNMENT_SUCCESS,
          payload: (_action, _state, res) =>
            getJSON(res).then(json => {
              dispatch(fetchTimeline(json.position.id))
              // Return to the default channel and reset the view to see the updated card
              dispatch(navigateToDefaultChannel())
              // Schedule extra refresh of new assignment card to give calcs time to run.
              dispatch(
                scheduleMultipleTimelineRefresh(json.position.id, 15, 1, 30, 4)
              )

              const normalizedData = normalize(
                mapAssignment(json),
                assignmentSchema
              )

              return {
                ...normalizedData,
                entities: {
                  ...normalizedData.entities,
                  employees: {
                    [json.employee.employeeId]: json.employee,
                  },
                },
              }
            }),
        },
        CREATE_ASSIGNMENT_FAILURE,
      ],
      headers: {'Content-Type': 'application/json'},
    },
  })

export const updateAssignment = (positionId, updatedAssignment) => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: ASSIGNMENTS_URL(positionId),
      method: 'PUT',
      body: JSON.stringify(prepareNewAssignment(updatedAssignment)),
      types: [
        UPDATE_ASSIGNMENT_REQUEST,
        {
          type: UPDATE_ASSIGNMENT_SUCCESS,
          payload: (_action, _state, res) =>
            getJSON(res).then(json => {
              dispatch(fetchTimeline(json.position.id))
              // Return to the default channel and reset the view to see the updated card
              dispatch(navigateToDefaultChannel())
              // Schedule extra refresh of new assignment card to give calcs time to run.
              dispatch(
                scheduleMultipleTimelineRefresh(json.position.id, 15, 1, 30, 4)
              )
              const normalizedData = normalize(
                mapAssignment(json),
                assignmentSchema
              )

              return {
                ...normalizedData,
                entities: {
                  ...normalizedData.entities,
                  employees: {
                    [json.employee.employeeId]: json.employee,
                  },
                },
              }
            }),
        },
        UPDATE_ASSIGNMENT_FAILURE,
      ],
      headers: {'Content-Type': 'application/json'},
    },
  })

export const deleteAssignment = positionId => (dispatch, getState) => {
  const state = getState()
  const channel = getChannel(state)

  dispatch(disableDeleteAssignment())
  dispatch(fetchChannelSummary())

  return dispatch({
    [RSAA]: {
      endpoint: ASSIGNMENTS_URL(positionId),
      method: 'DELETE',
      types: [
        DELETE_ASSIGNMENT_REQUEST,
        {type: DELETE_ASSIGNMENT_SUCCESS, meta: {channel, positionId}},
        DELETE_ASSIGNMENT_FAILURE,
      ],
    },
  })
}

export const updateAssignmentOptionsAndExecuteTask = (
  positionId,
  options,
  taskId,
  actionId,
  dueDate
) => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: ASSIGNMENT_OPTIONS_URL(positionId),
      method: 'PUT',
      body: JSON.stringify(transformAssignmentOptions(options)),
      types: [
        UPDATE_ASSIGNMENT_OPTIONS_REQUEST,
        {
          type: UPDATE_ASSIGNMENT_OPTIONS_SUCCESS,
          payload: (_action, _state, res) =>
            getJSON(res).then(() =>
              dispatch(
                fetchTimelineAndExecuteTaskAction(
                  positionId,
                  taskId,
                  actionId,
                  dueDate
                )
              )
            ),
        },
        UPDATE_ASSIGNMENT_OPTIONS_FAILURE,
      ],
      headers: {'Content-Type': 'application/json'},
    },
  })

export const fetchAssignmentAuditLog = assignmentId => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: ASSIGNMENT_AUDIT_LOG_URL(assignmentId),
      method: 'GET',
      types: [
        FETCH_ASSIGNMENT_AUDIT_LOGS_REQUEST,
        {
          type: FETCH_ASSIGNMENT_AUDIT_LOGS_SUCCESS,
          meta: {assignmentId},
          payload: (_action, _currentState, res) =>
            getJSON(res).then(json =>
              normalize(mapAssignmentAuditLog(json), [assignmentAuditLogSchema])
            ),
        },
        FETCH_ASSIGNMENT_AUDIT_LOGS_FAILURE,
      ],
    },
  })
