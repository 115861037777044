import {isRSAA, RSAA} from 'redux-api-middleware'

const normalizeTypeDescriptor = type => {
  if (typeof type === 'string' || typeof type === 'symbol') {
    type = {type}
  }
  return type
}

const progressMiddleware = store => next => action => {
  if (!isRSAA(action)) {
    return next(action)
  }

  const {fetch, types} = action[RSAA]

  if (typeof fetch !== 'function' && types.length === 4) {
    const downloadProgressType = types.pop()

    const state = store.getState()
    const token =
      (state.oidc && state.oidc.user && state.oidc.user.access_token) || ''

    action[RSAA].fetch = (url, options) =>
      new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()

        xhr.onprogress = function(event) {
          if (event.lengthComputable) {
            store.dispatch({
              ...normalizeTypeDescriptor(downloadProgressType),
              payload: (event.loaded / event.total) * 100,
            })
          }
        }

        xhr.onerror = function() {
          reject(new TypeError('Network request failed'))
        }

        xhr.onload = function() {
          resolve(
            new Response(
              new Blob([this.response], {
                type: this.getResponseHeader('Content-Type'),
              }),
              {
                status: this.status,
                statusText: this.statusText,
                headers: Object.assign(
                  {},
                  ...this.getAllResponseHeaders()
                    .trim()
                    .split('\r\n')
                    .map(header => {
                      const [key, ...rest] = header.split(':')
                      const value = rest.join(':').trim()
                      return {[key]: value}
                    })
                ),
              }
            )
          )
        }

        xhr.open(options.method, url)
        xhr.setRequestHeader('Authorization', `Bearer ${token}`)
        xhr.responseType = 'arraybuffer'

        xhr.send(options.body)
      })

    action[RSAA].types = types
  }

  return next(action)
}

export default progressMiddleware
