import PT from 'prop-types'
import {commonPropTypes} from '../../propTypes'

export const rowTitleDefaultProps = {
  children: null,
}

export const rowTitlePropTypes = {
  ...commonPropTypes,
  children: PT.node,
  code: PT.string.isRequired,
  isActive: PT.bool.isRequired,
  name: PT.string.isRequired,
  toggleRowsWithBulkEdit: PT.func.isRequired,
}
