import PT from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {submit} from 'redux-form'
import styled from 'styled-components'

const Submitbutton = styled.button`
  background: #1cdbbc;
  color: black;
  padding: 10px 10px;
  border-radius: 24px;
  border: #1cdbbc;
  width: 15rem;
  cursor: pointer;
  font-size: medium;
  font-weight: 500;
  &:hover {
    background: #8bf5e3;
    border: #8bf5e3;
  }
`

export const Submit = props => {
  return (
    <Submitbutton
      {...props}
      isDisabled={props.isSubmitting}
      onClick={() => props.dispatch(submit(props.name))}
    />
  )
}

Submit.defaultProps = {
  children: 'Submit',
  preventDefault: true,
}

Submit.propTypes = {
  children: PT.node,
  dispatch: PT.func.isRequired,
  name: PT.string.isRequired,
  preventDefault: PT.bool,
  isSubmitting: PT.bool,
}

export default connect()(Submit)
