import {
  DATE_TIME_FIELD,
  NUMBER_FIELD,
  SELECT_FIELD,
  TEXT_AREA_FIELD,
} from 'arena-config'
import {CURRENCY_FIELD} from '../../../../constants/form'
import DateTime from '../components/Field/DateTime'
import Number from '../components/Field/Number'
import Select from '../components/Field/Select'
import Text from '../components/Field/Text'
import TextArea from '../components/Field/TextArea'
import Currency from '../components/Field/Currency'

export default type => {
  switch (type) {
    case DATE_TIME_FIELD:
      return DateTime
    case NUMBER_FIELD:
      return Number
    case SELECT_FIELD:
      return Select
    case TEXT_AREA_FIELD:
      return TextArea
    case CURRENCY_FIELD:
      return Currency
    default:
      return Text
  }
}
