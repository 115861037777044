import PT from 'prop-types'
import {commonPropTypes, hasValidResources} from '../../propTypes'
import {numberOrStringPT} from 'arena-prop-types'

export const sectionsDefaultProps = {
  children: null,
}

export const sectionsPropTypes = {
  ...commonPropTypes,
  change: PT.func.isRequired,
  children: PT.node,
  enableAutofill: PT.bool.isRequired,
  enableFormatting: PT.bool.isRequired,
  environmentHeight: numberOrStringPT(true),
  formName: PT.string.isRequired,
  formValues: PT.shape({}).isRequired,
  handleBulkChange: PT.func.isRequired,
  isLocked: PT.bool,
  resources: hasValidResources,
  rowsWithBulkEdit: PT.arrayOf(PT.string).isRequired,
  rowsWithOpenResources: PT.arrayOf(PT.string).isRequired,
  showAllResources: PT.bool,
  startYear: PT.number.isRequired,
  stickyHeader: PT.shape({}).isRequired,
  toggleRowsWithBulkEdit: PT.func.isRequired,
  toggleRowsWithOpenResources: PT.func.isRequired,
  toggleStickyHeader: PT.func.isRequired,
  useSingleAmountInsteadOfYears: PT.bool.isRequired,
}
