import {combineReducers} from 'redux'
import accountFields from './accountFields'
import accountSections from './accountSections'
import formDesign from './formDesigns'
import formFields from './formFields'
import formSections from './formSections'
import initialValues from './initialValues'
import taxFields from './taxFields'
import taxSections from './taxSections'
import calculationFields from './calculationFields'
import calculationSections from './calculationSections'

export default combineReducers({
  accountFields,
  accountSections,
  formDesign,
  formFields,
  formSections,
  initialValues,
  taxFields,
  taxSections,
  calculationFields,
  calculationSections,
})
