import {isNullOrUndefined} from 'util'

export const filteredCalculationOptionsFieldsWithColumnCount = (
  fields,
  homeCompEnabled,
  homeTaxEnabled,
  hostCompEnabled,
  hostTaxEnabled,
  homeHypoCompEnabled,
  homeHypoTaxEnabled,
  hostHypoCompEnabled,
  hostHypoTaxEnabled
) => {
  const homeEnabled = homeCompEnabled || homeTaxEnabled
  const hostEnabled = hostCompEnabled || hostTaxEnabled
  const homeHypoEnabled = homeHypoCompEnabled || homeHypoTaxEnabled
  const hostHypoEnabled = hostHypoCompEnabled || hostHypoTaxEnabled

  let homeFields = []
  let hostFields = []
  let homeHypoFields = []
  let hostHypoFields = []

  let columnCount = 0
  let homeCompensationField = null
  let hostCompensationField = null
  let homeHypoCompensationField = null
  let hostHypoCompensationField = null
  let homeIncomeTaxField = null
  let hostIncomeTaxField = null
  let homeHypoIncomeTaxField = null
  let hostHypoIncomeTaxField = null
  let homeSocialTaxField = null
  let hostSocialTaxField = null
  let homeHypoSocialTaxField = null
  let hostHypoSocialTaxField = null
  let homeFilingStatusField = null
  let hostFilingStatusField = null
  let homeHypoFilingStatusField = null
  let hostHypoFilingStatusField = null

  if (homeEnabled) {
    homeFields = fields.filter(
      item => item.additionalProperties.homeEnabled === homeEnabled
    )
    columnCount++
    homeCompensationField = setFieldVisibility(
      filterField(homeFields, 'HomeCompensationLocation'),
      homeCompEnabled
    )

    homeIncomeTaxField = setFieldVisibility(
      filterField(homeFields, 'HomeIncomeTaxLocation'),
      homeTaxEnabled
    )
    homeSocialTaxField = setFieldVisibility(
      filterField(homeFields, 'HomeSocialTaxLocation'),
      homeTaxEnabled
    )
    homeFilingStatusField = setFieldVisibility(
      filterField(homeFields, 'HomeFilingStatus'),
      homeTaxEnabled
    )
  }
  if (hostEnabled) {
    hostFields = fields.filter(
      item => item.additionalProperties.hostEnabled === hostEnabled
    )
    columnCount++
    hostCompensationField = setFieldVisibility(
      filterField(hostFields, 'HostCompensationLocation'),
      hostCompEnabled
    )
    hostIncomeTaxField = setFieldVisibility(
      filterField(hostFields, 'HostIncomeTaxLocation'),
      hostTaxEnabled
    )
    hostSocialTaxField = setFieldVisibility(
      filterField(hostFields, 'HostSocialTaxLocation'),
      hostTaxEnabled
    )
    hostFilingStatusField = setFieldVisibility(
      filterField(hostFields, 'HostFilingStatus'),
      hostTaxEnabled
    )
  }
  if (homeHypoEnabled) {
    homeHypoFields = fields.filter(
      item => item.additionalProperties.homeHypoEnabled === homeHypoEnabled
    )
    columnCount++

    homeHypoCompensationField = setFieldVisibility(
      filterField(homeHypoFields, 'HypoCompensationLocation'),
      homeHypoCompEnabled
    )

    homeHypoIncomeTaxField = setFieldVisibility(
      filterField(homeHypoFields, 'HypoIncomeTaxLocation'),
      homeHypoTaxEnabled
    )
    homeHypoSocialTaxField = setFieldVisibility(
      filterField(homeHypoFields, 'HypoSocialTaxLocation'),
      homeHypoTaxEnabled
    )
    homeHypoFilingStatusField = setFieldVisibility(
      filterField(homeHypoFields, 'HypoFilingStatus'),
      homeHypoTaxEnabled
    )
  }
  if (hostHypoEnabled) {
    hostHypoFields = fields.filter(
      item => item.additionalProperties.hostHypoEnabled === hostHypoEnabled
    )
    columnCount++
    hostHypoCompensationField = setFieldVisibility(
      filterField(hostHypoFields, 'HostHypoCompensationLocation'),
      hostHypoCompEnabled
    )

    hostHypoIncomeTaxField = setFieldVisibility(
      filterField(hostHypoFields, 'HostHypoIncomeTaxLocation'),
      hostHypoTaxEnabled
    )
    hostHypoSocialTaxField = setFieldVisibility(
      filterField(hostHypoFields, 'HostHypoSocialTaxLocation'),
      hostHypoTaxEnabled
    )
    hostHypoFilingStatusField = setFieldVisibility(
      filterField(hostHypoFields, 'HostHypoFilingStatus'),
      hostHypoTaxEnabled
    )
  }
  return {
    columnCount,
    formFields: [
      homeCompensationField,
      hostCompensationField,
      homeHypoCompensationField,
      hostHypoCompensationField,
      homeIncomeTaxField,
      hostIncomeTaxField,
      homeHypoIncomeTaxField,
      hostHypoIncomeTaxField,
      homeSocialTaxField,
      hostSocialTaxField,
      homeHypoSocialTaxField,
      hostHypoSocialTaxField,
      homeFilingStatusField,
      hostFilingStatusField,
      homeHypoFilingStatusField,
      hostHypoFilingStatusField,
    ].filter(item => !isNullOrUndefined(item)),
  }
}

const filterField = (fields, filterBy) =>
  fields.filter(field => {
    const fieldName = field.code.split('.')[1]
    return fieldName.includes(filterBy)
  })[0]

const setFieldVisibility = (field, isVisible) => {
  if (field) {
    field.additionalProperties.isVisible = isVisible
  }
  return field
}

export const getAllCalculationFields = sections => {
  return sections
    .map(section => {
      const fields = section.calculationFields
      return fields.map(field => {
        return {
          isVisible: field.additionalProperties.isVisible,
          code: field.code,
        }
      })
    })
    .reduce((acc, val) => acc.concat(val), [])
}

export const getAllCalculationFieldNames = sections => {
  const fields =
    sections &&
    sections[2] &&
    sections[2].formFields &&
    sections[2].formFields
      .map(x => x.options)
      .reduce((acc, val) => acc.concat(val), [])
      .filter(item => !isNullOrUndefined(item))

  return fields && fields.map(x => x.value)
}
