export const channelData = () => {}

export const buildChannelNextUrl = (
  url,
  currentPageNumber = 1,
  hasNextPage = false
) =>
  !hasNextPage
    ? ''
    : url.replace(
        `pageNumber=${currentPageNumber}`,
        `pageNumber=${currentPageNumber + 1}`
      )
