import {combineReducers} from 'redux'
import {
  FETCH_POSITION_TOKEN_REQUEST,
  FETCH_POSITION_TOKEN_SUCCESS,
} from '../../constants/actionTypes'

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_POSITION_TOKEN_REQUEST:
      return true
    case FETCH_POSITION_TOKEN_SUCCESS:
      return false
    default:
      return state
  }
}

const positionTokensById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_POSITION_TOKEN_SUCCESS:
      return {...state, ...action.payload.entities.positionTokens}
    default:
      return state
  }
}

const positionTokens = combineReducers({
  byId: positionTokensById,
  isFetching,
})

export default positionTokens
