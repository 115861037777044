export const PrepareValidationMessage = (code, errors) => {
  // eslint-disable-next-line
  const evaluateFieldCode = code.match(/[^\]\[.]+/g)
  const isNestedField = evaluateFieldCode.length > 1
  if (Object.keys(errors).length > 0 && isNestedField) {
    return evaluateFieldCode.reduce((accumulator, currentValue) => {
      return (accumulator && accumulator[currentValue]) || ''
    }, errors)
  }
  return errors[code] || ''
}
