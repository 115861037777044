import PT from 'prop-types'
import React from 'react'
import cn from 'classnames'
import {showMessage} from './Message'

const defaultProps = {
  className: '',
  download: false,
  href: '',
  isDisabled: false,
  onClick: () => console.warn('Button component is missing an onClick handler'),
  preventDefault: false,
  size: '',
  style: null,
  target: null,
}

const propTypes = {
  className: PT.string,
  children: PT.node.isRequired,
  download: PT.bool,
  href: PT.string,
  isDisabled: PT.bool,
  name: PT.string,
  preventDefault: PT.bool,
  onClick: PT.func,
  size: PT.string,
  style: PT.object,
  target: PT.string,
}

const Button = ({
  children,
  className,
  download,
  href,
  isDisabled,
  name,
  onClick,
  preventDefault,
  size,
  style,
  target,
}) => {
  const css = cn(className, size, {isDisabled})
  let handleClick = onClick

  if (preventDefault) {
    handleClick = e => {
      try {
        e.preventDefault()
        onClick()
      } catch (error) {
        showMessage(
          'Sorry, an unexpected error occurred while processing your request.',
          'error'
        )
        console.error(error)
      }
    }
  }

  const buttonProps = {
    'data-component': 'Button',
    className: css,
    download,
    disabled: isDisabled,
  }

  if (href) {
    return (
      <a
        {...buttonProps}
        href={href}
        onClick={handleClick}
        target={target}
        style={style}
        name={name}
      >
        {children}
      </a>
    )
  }

  return (
    <button {...buttonProps} onClick={handleClick} style={style} name={name}>
      {children}
    </button>
  )
}

Button.defaultProps = defaultProps
Button.propTypes = propTypes

export default Button
