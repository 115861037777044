import {isRequired, numberPT, stringPT} from 'arena-prop-types'
import PT from 'prop-types'
import {formFieldsPT} from './formFields'

const {arrayOf, shape} = PT

export const accountSectionProps = {
  accountFields: formFieldsPT(),
  code: stringPT(true),
  id: numberPT(true),
  name: stringPT(true),
}

export const accountSectionPT = is => isRequired(shape(accountSectionProps), is)

export const accountSectionsPT = is => isRequired(arrayOf(accountSectionPT), is)
