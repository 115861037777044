import {funcPT, stringPT} from 'arena-prop-types'
import moment from 'moment'
import React from 'react'
import {momentObj} from 'react-moment-proptypes'
import styled from 'styled-components'
import {selectedDatePT} from '../propTypes'

const Wrap = styled(
  ({
    cellHeight,
    currentDate,
    isActiveDate,
    isActiveDayOfWeek,
    selectedDate,
    ...rest
  }) => <div {...rest} />
)``

const DateTimeDay = props => {
  const date = moment(props.currentDate).date()
  return (
    <td>
      <Wrap {...props}>{date}</Wrap>
    </td>
  )
}

DateTimeDay.defaultProps = {
  className: '',
  onClick: null,
  selectedDate: {},
}

DateTimeDay.propTypes = {
  className: stringPT(),
  currentDate: momentObj.isRequired,
  onClick: funcPT(),
  selectedDate: selectedDatePT(),
}

export default DateTimeDay
