import * as c from 'arena-config'
import React, {PureComponent} from 'react'
import {Avatar} from 'antd'
import styled from 'styled-components'
import {Flex, Icon} from 'arena-components'
import HeaderButton from '../HeaderButton'
import HeaderLink from '../HeaderLink'
import {userDefaultProps, userPropTypes} from './propTypes'
import notification from 'antd/lib/notification'
import 'antd/lib/notification/style/index.css'

const StyledFlex = styled(Flex)`
  ${c.FOR_DESKTOP} {
    position: absolute;
    right: 2rem;
    bottom: -1rem;
  }
`
const IconWrap = styled.div`
  margin: 0;
  ${c.FOR_DESKTOP} {
    margin-right: 0.2em;
    margin-top: -0.3em;
  }
`

const IconText = styled.div`
  word-break: break-word;
`

const MenuItem = styled.div`
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding: 12px 16px;
  position: absolute;
  top: 2.7em;
  right: 0;
  z-index: 3;
  font-size: smaller;
  width: fit-content;
  height: fit-content;
  ${c.FOR_DESKTOP} {
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 10px 10px;
    position: absolute;
    top: 1.5rem;
    right: 0;
    z-index: 3;
  }
`

const ButtonWrapper = styled.div`
  width: calc(100vw - 80px);
  ${c.FOR_DESKTOP} {
    width: 360px;
  }
`

const ButtonInner = styled.div`
  color: #4c4e51;
  cursor: pointer;
  line-height: 1;
  display: flex;
  align-items: center;
  ${c.FOR_DESKTOP} {
    height: 32px;
    line-height: 32px;
    &:hover {
      color: #999b9c;
    }
`

const Wrap = styled.div``

class User extends PureComponent {
  constructor() {
    super()

    this.state = {
      showMenu: false,
    }

    this.handleClickOnUserMenu = this.handleClickOnUserMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleSendReport = this.handleSendReport.bind(this)
  }

  handleClickOnUserMenu(event) {
    !this.state.showMenu ? event.stopPropagation() : event.preventDefault()
    this.setState({showMenu: true}, () => {
      document.addEventListener('click', this.closeMenu)
    })
  }

  user(param) {
    return (
      <Avatar
        style={{
          color: 'black',
          background: 'white',
          fontWeight: 700,
          fontSize: '16px',
        }}
      >
        {param
          .split('@')[0]
          .split('.')
          .map(word => word[0].toUpperCase())
          .join('')}
      </Avatar>
    )
  }

  closeMenu() {
    this.setState({showMenu: false}, () => {
      document.removeEventListener('click', this.closeMenu)
    })
  }

  handleLogout(event) {
    event.preventDefault()
    this.props.logout()
  }

  handleSendReport(event) {
    event.preventDefault()
    this.props.sendReport()
    notification['success']({
      message: 'Success',
      description: 'Logs sent to Application Support team.',
      duration: 5,
    })
  }

  render() {
    const {props} = this

    const userLink = (
      <ButtonWrapper>
        <HeaderLink
          defaultAction={props.defaultAction}
          handleClick={props.user ? this.handleClickOnUserMenu : props.login}
          height={props.height}
          id="settings"
          white
        >
          {props.user ? (
            <StyledFlex>{this.user(props.user)}</StyledFlex>
          ) : (
            <IconText>{'Log in'}</IconText>
          )}
        </HeaderLink>
      </ButtonWrapper>
    )
    const userDetailMenu = (
      <ButtonWrapper>
        <HeaderButton onClick={this.handleLogout}>
          <ButtonInner>
            <IconWrap>
              <Icon type="unlocked" verticalAlign="middle" />
            </IconWrap>
            <IconText>Log out, {props.user}</IconText>
          </ButtonInner>
        </HeaderButton>
      </ButtonWrapper>
    )

    const sendReportMenu = (
      <HeaderButton onClick={this.handleSendReport}>
        <ButtonInner>
          <IconWrap>
            <Icon type="cog" verticalAlign="middle" />
          </IconWrap>
          <IconText>Send logs to Application Support</IconText>
        </ButtonInner>
      </HeaderButton>
    )
    const userMenu = (
      <div>
        <MenuItem>
          {userDetailMenu}
          {props.sendReport ? sendReportMenu : null}
        </MenuItem>
      </div>
    )

    return (
      <Wrap>
        {!props.disableAuthentication && userLink}
        {this.state.showMenu && userMenu}
      </Wrap>
    )
  }
}

User.defaultProps = userDefaultProps
User.propTypes = userPropTypes

export default User
