export const ADD_APPROVER_REQUEST = 'ADD_APPROVER_REQUEST'
export const ADD_APPROVER_SUCCESS = 'ADD_APPROVER_SUCCESS'
export const ADD_APPROVER_FAILURE = 'ADD_APPROVER_FAILURE'

export const CREATE_ASSIGNMENT_REQUEST = 'CREATE_ASSIGNMENT_REQUEST'
export const CREATE_ASSIGNMENT_SUCCESS = 'CREATE_ASSIGNMENT_SUCCESS'
export const CREATE_ASSIGNMENT_FAILURE = 'CREATE_ASSIGNMENT_FAILURE'

export const DELETE_ASSIGNMENT_DISABLE = 'DELETE_ASSIGNMENT_DISABLE'
export const DELETE_ASSIGNMENT_ENABLE = 'DELETE_ASSIGNMENT_ENABLE'
export const DELETE_ASSIGNMENT_REQUEST = 'DELETE_ASSIGNMENT_REQUEST'
export const DELETE_ASSIGNMENT_SUCCESS = 'DELETE_ASSIGNMENT_SUCCESS'
export const DELETE_ASSIGNMENT_FAILURE = 'DELETE_ASSIGNMENT_FAILURE'

export const EXECUTE_TASK_ACTION_REQUEST = 'EXECUTE_TASK_ACTION_REQUEST'
export const EXECUTE_TASK_ACTION_SUCCESS = 'EXECUTE_TASK_ACTION_SUCCESS'
export const EXECUTE_TASK_ACTION_FAILURE = 'EXECUTE_TASK_ACTION_FAILURE'

export const FETCH_ACCOUNT_SECTIONS_REQUEST = 'FETCH_ACCOUNT_SECTIONS_REQUEST'
export const FETCH_ACCOUNT_SECTIONS_SUCCESS = 'FETCH_ACCOUNT_SECTIONS_SUCCESS'
export const FETCH_ACCOUNT_SECTIONS_FAILURE = 'FETCH_ACCOUNT_SECTIONS_FAILURE'

export const FETCH_ASSIGNMENT_REQUEST = 'FETCH_ASSIGNMENT_REQUEST'
export const FETCH_ASSIGNMENT_SUCCESS = 'FETCH_ASSIGNMENT_SUCCESS'
export const FETCH_ASSIGNMENT_FAILURE = 'FETCH_ASSIGNMENT_FAILURE'

export const FETCH_ASSIGNMENT_FAMILY_MEMBERS_REQUEST =
  'FETCH_ASSIGNMENT_FAMILY_MEMBERS_REQUEST'
export const FETCH_ASSIGNMENT_FAMILY_MEMBERS_SUCCESS =
  'FETCH_ASSIGNMENT_FAMILY_MEMBERS_SUCCESS'
export const FETCH_ASSIGNMENT_FAMILY_MEMBERS_FAILURE =
  'FETCH_ASSIGNMENT_FAMILY_MEMBERS_FAILURE'

export const FETCH_ASSIGNMENT_OPTIONS_REQUEST =
  'FETCH_ASSIGNMENT_OPTIONS_REQUEST'
export const FETCH_ASSIGNMENT_OPTIONS_SUCCESS =
  'FETCH_ASSIGNMENT_OPTIONS_SUCCESS'
export const FETCH_ASSIGNMENT_OPTIONS_FAILURE =
  'FETCH_ASSIGNMENT_OPTIONS_FAILURE'

export const FETCH_ASSIGNMENT_AUDIT_LOGS_REQUEST =
  'FETCH_ASSIGNMENT_AUDIT_LOGS_REQUEST'
export const FETCH_ASSIGNMENT_AUDIT_LOGS_SUCCESS =
  'FETCH_ASSIGNMENT_AUDIT_LOGS_SUCCESS'
export const FETCH_ASSIGNMENT_AUDIT_LOGS_FAILURE =
  'FETCH_ASSIGNMENT_AUDIT_LOGS_FAILURE'

export const FETCH_ASSIGNMENTS_REQUEST = 'FETCH_ASSIGNMENTS_REQUEST'
export const FETCH_ASSIGNMENTS_SUCCESS = 'FETCH_ASSIGNMENTS_SUCCESS'
export const FETCH_ASSIGNMENTS_FAILURE = 'FETCH_ASSIGNMENTS_FAILURE'

export const FETCH_AUTOCOMPLETE_ASSIGNMENTS_REQUEST =
  'FETCH_AUTOCOMPLETE_ASSIGNMENTS_REQUEST'
export const FETCH_AUTOCOMPLETE_ASSIGNMENTS_SUCCESS =
  'FETCH_AUTOCOMPLETE_ASSIGNMENTS_SUCCESS'
export const FETCH_AUTOCOMPLETE_ASSIGNMENTS_FAILURE =
  'FETCH_AUTOCOMPLETE_ASSIGNMENTS_FAILURE'

export const FETCH_CHANNELS_REQUEST = 'FETCH_CHANNELS_REQUEST'
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS'
export const FETCH_CHANNELS_FAILURE = 'FETCH_CHANNELS_FAILURE'

export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST'
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS'
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE'

export const FETCH_CURRENCIES_REQUEST = 'FETCH_CURRENCIES_REQUEST'
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS'
export const FETCH_CURRENCIES_FAILURE = 'FETCH_CURRENCIES_FAILURE'

export const FETCH_EMPLOYEES_REQUEST = 'FETCH_EMPLOYEES_REQUEST'
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS'
export const FETCH_EMPLOYEES_FAILURE = 'FETCH_EMPLOYEES_FAILURE'

export const FETCH_ENTITIES_REQUEST = 'FETCH_ENTITIES_REQUEST'
export const FETCH_ENTITIES_SUCCESS = 'FETCH_ENTITIES_SUCCESS'
export const FETCH_ENTITIES_FAILURE = 'FETCH_ENTITIES_FAILURE'

export const FETCH_FAMILY_FORM_FIELDS_REQUEST =
  'FETCH_FAMILY_FORM_FIELDS_REQUEST'
export const FETCH_FAMILY_FORM_FIELDS_SUCCESS =
  'FETCH_FAMILY_FORM_FIELDS_SUCCESS'
export const FETCH_FAMILY_FORM_FIELDS_FAILURE =
  'FETCH_FAMILY_FORM_FIELDS_FAILURE'

export const FETCH_FORM_SECTIONS_REQUEST = 'FETCH_FORM_SECTIONS_REQUEST'
export const FETCH_FORM_SECTIONS_SUCCESS = 'FETCH_FORM_SECTIONS_SUCCESS'
export const FETCH_FORM_SECTIONS_FAILURE = 'FETCH_FORM_SECTIONS_FAILURE'

export const FETCH_FILING_STATUS_REQUEST = locationType =>
  `FETCH_${locationType}_FILING_STATUS_REQUEST`
export const FETCH_FILING_STATUS_SUCCESS = locationType =>
  `FETCH_${locationType}_FILING_STATUS_SUCCESS`
export const FETCH_FILING_STATUS_FAILURE = locationType =>
  `FETCH_${locationType}_FILING_STATUS_FAILURE`

export const FETCH_HOME_FILING_STATUS_REQUEST =
  'FETCH_HOME_FILING_STATUS_REQUEST'
export const FETCH_HOME_FILING_STATUS_SUCCESS =
  'FETCH_HOME_FILING_STATUS_SUCCESS'
export const FETCH_HOME_FILING_STATUS_FAILURE =
  'FETCH_HOME_FILING_STATUS_FAILURE'

export const FETCH_HOST_FILING_STATUS_REQUEST =
  'FETCH_HOST_FILING_STATUS_REQUEST'
export const FETCH_HOST_FILING_STATUS_SUCCESS =
  'FETCH_HOST_FILING_STATUS_SUCCESS'
export const FETCH_HOST_FILING_STATUS_FAILURE =
  'FETCH_HOST_FILING_STATUS_FAILURE'

export const FETCH_HYPO_FILING_STATUS_REQUEST =
  'FETCH_HYPO_FILING_STATUS_REQUEST'
export const FETCH_HYPO_FILING_STATUS_SUCCESS =
  'FETCH_HYPO_FILING_STATUS_SUCCESS'
export const FETCH_HYPO_FILING_STATUS_FAILURE =
  'FETCH_HYPO_FILING_STATUS_FAILURE'

export const FETCH_HOST_HYPO_FILING_STATUS_FAILURE =
  'FETCH_HOST_HYPO_FILING_STATUS_FAILURE'
export const FETCH_HOST_HYPO_FILING_STATUS_REQUEST =
  'FETCH_HOST_HYPO_FILING_STATUS_REQUEST'
export const FETCH_HOST_HYPO_FILING_STATUS_SUCCESS =
  'FETCH_HOST_HYPO_FILING_STATUS_SUCCESS'

export const FETCH_INITIAL_VALUES_REQUEST = 'FETCH_INITIAL_VALUES_REQUEST'
export const FETCH_INITIAL_VALUES_SUCCESS = 'FETCH_INITIAL_VALUES_SUCCESS'

export const FETCH_JOB_TITLES_REQUEST = 'FETCH_JOB_TITLES_REQUEST'
export const FETCH_JOB_TITLES_SUCCESS = 'FETCH_JOB_TITLES_SUCCESS'
export const FETCH_JOB_TITLES_FAILURE = 'FETCH_JOB_TITLES_FAILURE'

export const FETCH_HOME_LOCATIONS_REQUEST = 'FETCH_HOME_LOCATIONS_REQUEST'
export const FETCH_HOME_LOCATIONS_SUCCESS = 'FETCH_HOME_LOCATIONS_SUCCESS'
export const FETCH_HOME_LOCATIONS_FAILURE = 'FETCH_HOME_LOCATIONS_FAILURE'

export const FETCH_HOST_LOCATIONS_REQUEST = 'FETCH_HOST_LOCATIONS_REQUEST'
export const FETCH_HOST_LOCATIONS_SUCCESS = 'FETCH_HOST_LOCATIONS_SUCCESS'
export const FETCH_HOST_LOCATIONS_FAILURE = 'FETCH_HOST_LOCATIONS_FAILURE'

export const FETCH_POLICIES_REQUEST = 'FETCH_POLICIES_REQUEST'
export const FETCH_POLICIES_SUCCESS = 'FETCH_POLICIES_SUCCESS'
export const FETCH_POLICIES_FAILURE = 'FETCH_POLICIES_FAILURE'

export const FETCH_POSITION_TOKEN_REQUEST = 'FETCH_POSITION_TOKEN_REQUEST'
export const FETCH_POSITION_TOKEN_SUCCESS = 'FETCH_POSITION_TOKEN_SUCCESS'
export const FETCH_POSITION_TOKEN_FAILURE = 'FETCH_POSITION_TOKEN_FAILURE'

export const FETCH_SUB_ENTITIES_REQUEST = 'FETCH_SUB_ENTITIES_REQUEST'
export const FETCH_SUB_ENTITIES_SUCCESS = 'FETCH_SUB_ENTITIES_SUCCESS'
export const FETCH_SUB_ENTITIES_FAILURE = 'FETCH_SUB_ENTITIES_FAILURE'

export const FETCH_TAX_OPTIONS_REQUEST = 'FETCH_TAX_OPTIONS_REQUEST'
export const FETCH_TAX_OPTIONS_SUCCESS = 'FETCH_TAX_OPTIONS_SUCCESS'
export const FETCH_TAX_OPTIONS_FAILURE = 'FETCH_TAX_OPTIONS_FAILURE'

export const FETCH_CALCULATION_OPTIONS_REQUEST =
  'FETCH_CALCULATION_OPTIONS_REQUEST'
export const FETCH_CALCULATION_OPTIONS_SUCCESS =
  'FETCH_CALCULATION_OPTIONS_SUCCESS'
export const FETCH_TIMELINE_REQUEST = 'FETCH_TIMELINE_REQUEST'
export const FETCH_TIMELINE_SUCCESS = 'FETCH_TIMELINE_SUCCESS'
export const FETCH_TIMELINE_FAILURE = 'FETCH_TIMELINE_FAILURE'

export const FETCH_TIMELINES_REQUEST = 'FETCH_TIMELINES_REQUEST'
export const FETCH_TIMELINES_SUCCESS = 'FETCH_TIMELINES_SUCCESS'
export const FETCH_TIMELINES_FAILURE = 'FETCH_TIMELINES_FAILURE'

export const TOGGLE_ADD_APPROVER = 'TOGGLE_ADD_APPROVER'
export const TOGGLE_UPDATE_ASSIGNMENT_OPTIONS =
  'TOGGLE_UPDATE_ASSIGNMENT_OPTIONS'

export const UPDATE_APPROVAL_STATUS_REQUEST = 'UPDATE_APPROVAL_STATUS_REQUEST'
export const UPDATE_APPROVAL_STATUS_SUCCESS = 'UPDATE_APPROVAL_STATUS_SUCCESS'
export const UPDATE_APPROVAL_STATUS_FAILURE = 'UPDATE_APPROVAL_STATUS_FAILURE'

export const UPDATE_APPROVER_STATUS_REQUEST = 'UPDATE_APPROVER_STATUS_REQUEST'
export const UPDATE_APPROVER_STATUS_SUCCESS = 'UPDATE_APPROVER_STATUS_SUCCESS'
export const UPDATE_APPROVER_STATUS_FAILURE = 'UPDATE_APPROVER_STATUS_FAILURE'

export const UPDATE_ASSIGNMENT_REQUEST = 'UPDATE_ASSIGNMENT_REQUEST'
export const UPDATE_ASSIGNMENT_SUCCESS = 'UPDATE_ASSIGNMENT_SUCCESS'
export const UPDATE_ASSIGNMENT_FAILURE = 'UPDATE_ASSIGNMENT_FAILURE'

export const UPDATE_ASSIGNMENT_OPTIONS_REQUEST =
  'UPDATE_ASSIGNMENT_OPTIONS_REQUEST'
export const UPDATE_ASSIGNMENT_OPTIONS_SUCCESS =
  'UPDATE_ASSIGNMENT_OPTIONS_SUCCESS'
export const UPDATE_ASSIGNMENT_OPTIONS_FAILURE =
  'UPDATE_ASSIGNMENT_OPTIONS_FAILURE'

export const UPDATE_LAST_ACTIVE_CHANNEL = 'UPDATE_LAST_ACTIVE_CHANNEL'

export const UPDATE_PREVIOUS_FORM_TAB_ERRORS = 'UPDATE_PREVIOUS_FORM_TAB_ERRORS'

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE'

export const UPDATE_CHANNEL_FILTERS = 'UPDATE_CHANNEL_FILTERS'

export const SEND_MAIL_REQUEST = 'SEND_MAIL_REQUEST'
export const SEND_MAIL_SUCCESS = 'SEND_MAIL_SUCCESS'
export const SEND_MAIL_FAILURE = 'SEND_MAIL_FAILURE'

export const SET_NEW_EMPLOYEE = 'SET_NEW_EMPLOYEE'
export const FETCH_COMPANIES_REQUEST = 'FETCH_COMPANIES_REQUEST'
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE'
export const FETCH_LAST_SELECTED_COMPANY = 'FETCH_LAST_SELECTED_COMPANY'
export const UPDATE_SELECTED_COMPANY_REQUEST = 'UPDATE_SELECTED_COMPANY_REQUEST'
export const UPDATE_SELECTED_COMPANY_SUCCESS = 'UPDATE_SELECTED_COMPANY_SUCCESS'
export const UPDATE_SELECTED_COMPANY_FAILURE = 'UPDATE_SELECTED_COMPANY_FAILURE'

export const ON_ERROR = 'ON_ERROR'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const REJECT_ASSIGNMENT_DISABLE = 'REJECT_ASSIGNMENT_DISABLE'
export const REJECT_ASSIGNMENT_ENABLE = 'REJECT_ASSIGNMENT_ENABLE'

export const DOWNLOAD_REQUEST = 'DOWNLOAD_REQUEST'
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS'
export const DOWNLOAD_FAILURE = 'DOWNLOAD_FAILURE'
export const DOWNLOAD_PROGRESS = 'DOWNLOAD_PROGRESS'
export const RESET_PROGRESS = 'RESET_PROGRESS'

export const FETCH_FORM_DESIGN_REQUEST = 'FETCH_FORM_DESIGN_REQUEST'
export const FETCH_FORM_DESIGN_SUCCESS = 'FETCH_FORM_DESIGN_SUCCESS'
export const FETCH_FORM_DESIGN_FAILURE = 'FETCH_FORM_DESIGN_FAILURE'

export const SET_USER_FAVORITE_REQUEST = 'SET_USER_FAVORITE_REQUEST'
export const SET_USER_FAVORITE_SUCCESS = 'SET_USER_FAVORITE_SUCCESS'
export const SET_USER_FAVORITE_FAILURE = 'SET_USER_FAVORITE_FAILURE'

export const REMOVE_USER_FAVORITE_REQUEST = 'REMOVE_USER_FAVORITE_REQUEST'
export const REMOVE_USER_FAVORITE_SUCCESS = 'REMOVE_USER_FAVORITE_SUCCESS'
export const REMOVE_USER_FAVORITE_FAILURE = 'REMOVE_USER_FAVORITE_FAILURE'

export const GET_USER_FAVORITES_REQUEST = 'GET_USER_FAVORITES_REQUEST'
export const GET_USER_FAVORITES_SUCCESS = 'GET_USER_FAVORITES_SUCCESS'
export const GET_USER_FAVORITES_FAILURE = 'GET_USER_FAVORITES_FAILURE'

export const REMOVE_USER_FAVORITE_CHANNEL_ITEM_SUCCESS =
  'REMOVE_USER_FAVORITE_CHANNEL_ITEM_SUCCESS'
export const ADD_USER_FAVORITE_CHANNEL_ITEM_SUCCESS =
  'ADD_USER_FAVORITE_CHANNEL_ITEM_SUCCESS'

export const FETCH_CHANNEL_SUMMARY_REQUEST = 'FETCH_CHANNEL_SUMMARY_REQUEST'
export const FETCH_CHANNEL_SUMMARY_SUCCESS = 'FETCH_CHANNEL_SUMMARY_SUCCESS'
export const FETCH_CHANNEL_SUMMARY_FAILURE = 'FETCH_CHANNEL_SUMMARY_FAILURE'

export const FETCH_DOCUMENTS_REQUEST = 'FETCH_DOCUMENTS_REQUEST'
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS'
export const FETCH_DOCUMENTS_FAILURE = 'FETCH_DOCUMENTS_FAILURE'

export const FETCH_COMMENTS_REQUEST = 'FETCH_COMMENTS_REQUEST'
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS'
export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE'

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'

export const CREATE_COMMENT_REQUEST = 'CREATE_COMMENT_REQUEST'
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS'
export const CREATE_COMMENT_FAILURE = 'CREATE_COMMENT_FAILURE'

export const FETCH_DOCUMENTS_BY_ID_REQUEST = 'FETCH_DOCUMENTS_BY_ID_REQUEST'
export const FETCH_DOCUMENTS_BY_ID_SUCCESS = 'FETCH_DOCUMENTS_BY_ID_SUCCESS'
export const FETCH_DOCUMENTS_BY_ID_FAILURE = 'FETCH_DOCUMENTS_BY_ID_FAILURE'

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST'
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS'
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE'

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST'
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS'
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE'

export const FETCH_DOCUMENT_CATEGORY_REQUEST = 'FETCH_DOCUMENT_CATEGORY_REQUEST'
export const FETCH_DOCUMENT_CATEGORY_SUCCESS = 'FETCH_DOCUMENT_CATEGORY_SUCCESS'
export const FETCH_DOCUMENT_CATEGORY_FAILURE = 'FETCH_DOCUMENT_CATEGORY_FAILURE'

export const FETCH_DOCUMENT_SUBCATEGORY_REQUEST =
  'FETCH_DOCUMENT_SUBCATEGORY_REQUEST'
export const FETCH_DOCUMENT_SUBCATEGORY_SUCCESS =
  'FETCH_DOCUMENT_SUBCATEGORY_SUCCESS'
export const FETCH_DOCUMENT_SUBCATEGORY_FAILURE =
  'FETCH_DOCUMENT_SUBCATEGORY_FAILURE'

export const CLONE_ASSIGNMENT_ENABLE = 'CLONE_ASSIGNMENT_ENABLE'
