import {Button} from 'arena-components'
import * as c from 'arena-config'
import React from 'react'
import styled from 'styled-components'
import {menuDefaultProps, menuPropTypes} from './propTypes'

const Wrap = styled.div`
  height: 48px;
  right: 0;
  position: absolute;
  z-index: 2;
  top: 0;

  img {
    cursor: pointer;
    height: 50%;
    margin: auto;
    filter: invert(1);
  }
  ${c.FOR_DESKTOP} {
    display: none;
    opacity: 0.75;
    transition: opacity 0.25s;

    &:hover {
      opacity: 1;
    }
  }
`

const MenuIcon = props => {
  // typeof props.onMenuIconClick !== 'function'
  //   ? () => props.defaultAction(props.onMenuIconClick)
  //   : props.onMenuIconClick || noPropWarning

  return (
    <Wrap>
      <Button
        onClick={props.onMobileMenuIconClick}
        reset
        style={{
          border: 0,
          height: '100%',
          width: '48px',
          padding: 0,
        }}
      >
        {props.isMobileMenuOpen ? (
          <img src={props.menuCloseSrc} alt="MenuIcon" />
        ) : (
          <img src={props.menuSrc} alt="MenuIcon" />
        )}
      </Button>
    </Wrap>
  )
}

MenuIcon.defaultProps = menuDefaultProps
MenuIcon.propTypes = menuPropTypes

export default MenuIcon
