import CompensationPlugin from '../../lib/components/arena-plugin-compensation'
import {
  funcPT,
  numberPT,
  stringPT,
  momentObjectOrStringPT,
  boolPT,
} from 'arena-prop-types'
import PT from 'prop-types'
import React from 'react'
import {accountSectionsPT} from '../../constants/propTypes/accountSections'
import {resourcesPT} from '../../constants/propTypes/resources'
import {getYearForDate} from '../../utils/moment'

const Compensation = props => {
  const {
    accountSections,
    change,
    isLocked,
    formName,
    startDate,
  } = props.customFormSectionData

  const mappedSections = accountSections.map(section => ({
    children: section.accountFields.map(child => ({
      code: child.code,
      name: child.label,
    })),
    code: section.code,
    name: section.name,
  }))

  const mappedResources = {
    currency: {name: 'Currency', options: props.resources.currencies},
  }

  return (
    <div className="Compensation">
      <CompensationPlugin
        amountFieldWidth={200}
        change={change}
        delimiter="."
        duration={1}
        enableFormatting
        environmentHeight={'auto'}
        formName={formName}
        isLocked={isLocked}
        resourceFieldWidth={200}
        resources={mappedResources}
        sections={mappedSections}
        showAllResourcesButton={false}
        showResourcesByDefault
        startDate={startDate}
        startYear={getYearForDate(startDate)}
        useSingleAmountInsteadOfYears
      />
    </div>
  )
}

Compensation.propTypes = {
  customFormSectionData: PT.shape({
    accountSections: accountSectionsPT(true),
    change: funcPT(true),
    formName: stringPT(true),
    isLocked: boolPT(),
    startDate: momentObjectOrStringPT(true),
  }).isRequired,
  environmentHeight: numberPT(true),
  resources: resourcesPT(true),
}

export default Compensation
