import React from 'react'
import {OidcProvider} from 'redux-oidc'
import {Provider} from 'react-redux'
import userManager from './utils/userManager'
import configureStore from './store/configureStore'
import RootContainer from './containers/Root'

const store = configureStore()

import('@fortawesome/fontawesome-svg-core').then(fontAwesome => {
  import('@fortawesome/free-solid-svg-icons').then(fasIcons => {
    import('@fortawesome/free-regular-svg-icons').then(farIcons => {
      fontAwesome.library.add(fasIcons.fas, farIcons.far)
    })
  })
})

const App = () => (
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <RootContainer store={store} />
    </OidcProvider>
  </Provider>
)

export default App
