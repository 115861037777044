import {isNullOrUndefined} from 'util'

const isBlank = value => value.length === 0

const isRequired = value =>
  !isNullOrUndefined(value) && !isBlank(value) ? undefined : 'Required'

export default (validate, required) => {
  if (!required && (!validate || !validate.length)) {
    return []
  }

  if (!required) {
    if (!validate || !validate.length) {
      return []
    }
    return validate
  }

  if (Array.isArray(validate)) {
    return [isRequired, ...validate]
  }

  return [isRequired, validate]
}
