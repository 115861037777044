import {LOGIN_SUCCESS, LOGOUT} from 'arena-redux'
import {navigateTo} from './navigation'
import userManager from '../utils/userManager'
import {makeRoute} from '../utils/router'
import {INDEX_PATH} from '../constants/router'

export const loginSuccess = oauthToken => ({type: LOGIN_SUCCESS, oauthToken})

export const login = () => async (dispatch, getState) => {
  const state = getState()
  userManager.signinRedirect({data: {router: state.router}})
}
export const doLogout = () => ({type: LOGOUT})
export const logout = () => (dispatch, getState) => {
  const state = getState()
  userManager.signoutRedirect({id_token_hint: state.oidc.user.id_token})
  userManager.removeUser()
  Promise.all([dispatch(doLogout())]).then(
    dispatch(navigateTo(makeRoute({path: INDEX_PATH})))
  )
}
