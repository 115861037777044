import {locales} from 'arena-config'
import {boolPT, numberOrStringPT, numberPT, stringPT} from 'arena-prop-types'
import React from 'react'
import NumberFormat from 'react-number-format'
import {inputPT} from '../../../constants/inputProps'
import fieldPropTypes, {makeFieldProps} from '../propTypes'
import Input from '../Input'

const {numericFormat} = locales(window.navigator.language)

const Number = props => {
  const handleNumberChange = event => {
    try {
      const eventTarget = e => e.target
      const target = eventTarget(event)
      const value = target[props.valueKey]
      const shouldUpdate = props.allowNegative || value > -1

      if (!shouldUpdate) {
        return event.preventDefault()
      }

      return props.input.onChange(event)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <NumberFormat
      {...props.input}
      {...makeFieldProps(props)}
      allowDecimal={props.allowDecimal}
      allowNegative={props.allowNegative}
      customInput={Input}
      decimalScale={props.decimalScale}
      decimalSeparator={
        props.decimalSeparator || numericFormat.decimalSeparator
      }
      disabled={props.disabled}
      onChange={handleNumberChange}
      prefix={props.numberPrefix}
      thousandSeparator={
        props.thousandSeparator || numericFormat.thousandSeparator
      }
    />
  )
}

Number.defaultProps = {
  decimalScale: undefined,
}

Number.propTypes = {
  ...fieldPropTypes,
  allowDecimal: boolPT(true),
  allowNegative: boolPT(true),
  decimalScale: numberPT(),
  decimalSeparator: stringPT(true),
  input: inputPT(true),
  numberPrefix: numberOrStringPT(true),
  thousandSeparator: stringPT(true),
}

export default Number
