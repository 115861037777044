import PT from 'prop-types'
import {commonPropTypes, hasValidResources} from '../../propTypes'

export const bulkEditDefaultProps = {}

export const bulkEditPropTypes = {
  ...commonPropTypes,
  change: PT.func.isRequired,
  emptyBulkEdit: PT.func.isRequired,
  fieldMap: PT.shape({}).isRequired,
  formName: PT.string.isRequired,
  resources: hasValidResources,
  rowsWithBulkEdit: PT.arrayOf(PT.string).isRequired,
  toggleRowsWithBulkEdit: PT.func.isRequired,
}
