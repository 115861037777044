import React, {useState, useEffect} from 'react'
import {isNullOrUndefined} from 'util'
import {useDispatch} from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import {whiteListedExtentions} from '../../../constants/documents'
import {fetchDocumentsById} from '../../../actions/document'

const DocumentTitle = styled.button`
  border: none !important;
  color: #e2776e;
  cursor: pointer;
`
const TaggedUser = styled.button`
  border: none !important;
  color: #3498db;
  cursor: pointer;
`
const CommentDisplay = styled.div`
  white-space: pre-wrap;
`

const CommentParagraph = ({comment, positionId}) => {
  const [text, setText] = useState('')
  const dispatch = useDispatch()

  const handleDownload = (positionId, documentId) => {
    dispatch(fetchDocumentsById(positionId, documentId))
  }

  useEffect(() => {
    if (!isNullOrUndefined(comment) && comment !== '') {
      let regex = /@\[.+?\]\(.+?\)/gm
      let displayRegex = /@\[.+?\]\(/g
      let idRegex = /\).+?\(/g
      let matches = comment.match(regex)
      let arr = []
      matches &&
        matches.forEach(m => {
          let id = m
            .split('')
            .reverse()
            .join('')
            .match(idRegex)[0]
            .replace(')', '')
            .replace('(', '')
          let display = m
            .match(displayRegex)[0]
            .replace('@[', '')
            .replace('](', '')

          arr.push({
            id: id
              .split('')
              .reverse()
              .join(''),
            display: display,
          })
        })
      let newComment = comment.split(regex)
      const output = []
      for (let i = 0; i < newComment.length; i++) {
        const c = newComment[i]
        if (i === newComment.length - 1) output.push(c)
        else if (
          _.some(whiteListedExtentions, extension =>
            arr[i].display.includes(extension)
          )
        ) {
          output.push(c)
          output.push(
            <DocumentTitle
              key={arr[i].id}
              onClick={() => handleDownload(positionId, arr[i].id)}
            >
              {arr[i].display}
            </DocumentTitle>
          )
        } else {
          output.push(c)
          output.push(<TaggedUser key={arr[i].id}>{arr[i].display}</TaggedUser>)
        }
      }
      setText(output)
    }
  }, [comment])

  return <CommentDisplay className="comment-div">{text}</CommentDisplay>
}
export default CommentParagraph
