import {combineReducers} from 'redux'
import assignmentAuditLogs from './assignmentAuditLogs'
import assignmentFamilyMembers from './assignmentFamilyMembers'
import assignmentOptions from './assignmentOptions'
import assignments from './assignments'
import channels from './channels'
import channelSummary from './channelSummary'
import forms from './forms'
import positionTokens from './positionTokens'
import resources from './resources'
import tasks from './tasks'
import timelines from './timelines'
import tokenSections from './tokenSections'
import user from './user'
import documents from './documents'

export default combineReducers({
  assignmentAuditLogs,
  assignmentFamilyMembers,
  assignmentOptions,
  assignments,
  channels,
  channelSummary,
  forms,
  positionTokens,
  resources,
  tasks,
  timelines,
  tokenSections,
  user,
  documents,
})
