import {RSAA, getJSON} from 'redux-api-middleware'
import {normalize} from 'normalizr'
import {
  FETCH_ASSIGNMENTS_FAILURE,
  FETCH_ASSIGNMENTS_REQUEST,
  FETCH_ASSIGNMENTS_SUCCESS,
  UPDATE_CHANNEL_FILTERS,
  UPDATE_LAST_ACTIVE_CHANNEL,
  FETCH_AUTOCOMPLETE_ASSIGNMENTS_REQUEST,
  FETCH_AUTOCOMPLETE_ASSIGNMENTS_SUCCESS,
  FETCH_AUTOCOMPLETE_ASSIGNMENTS_FAILURE,
  FETCH_CHANNEL_SUMMARY_REQUEST,
  FETCH_CHANNEL_SUMMARY_SUCCESS,
  FETCH_CHANNEL_SUMMARY_FAILURE,
} from '../constants/actionTypes'
import {ASSIGNMENTS_PATH} from '../constants/router'
import {
  ASSIGNMENTS_SEARCH_URL,
  CHANNEL_ASSIGNMENTS_PAGED_URL,
  CHANNELS_SUMMARY,
} from '../constants/api'
import {
  CHANNELS_PAGE_SIZE,
  DEFAULT_CHANNEL_ID,
  PAST_DUE_CHANNEL_ID,
  MY_PAST_DUE_CHANNEL_ID,
} from '../constants/channels'
import {assignmentSchema} from '../constants/schemas'
import {buildChannelNextUrl} from '../utils/channels'
import {fetchTimelinesIfNeeded} from './timeline'
import {getChannels, getChannelFilters} from '../selectors/channels'
import {mapAssignments} from '../utils/api'
import {isIE} from '../utils/browser'
import {navigateTo} from './navigation'

export const updateLastActiveChannel = channel => ({
  type: UPDATE_LAST_ACTIVE_CHANNEL,
  channel,
})

export const updateChannelFilters = (channel, filters) => ({
  type: UPDATE_CHANNEL_FILTERS,
  meta: {channel},
  filters,
})

export const fetchAssignments = (
  channel,
  url,
  isSearch = false,
  forceReload = false
) => (dispatch, getState) => {
  const state = getState()
  const {showUserAssignmentsOnly} = getChannelFilters(state)
  const channelName =
    showUserAssignmentsOnly && !isSearch && channel !== 'Search'
      ? `My${channel}`
      : channel
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }
  dispatch({
    [RSAA]: {
      endpoint: url.replace(channel, channelName),
      method: 'GET',
      headers,
      types: [
        {type: FETCH_ASSIGNMENTS_REQUEST, meta: {channel}},
        {
          type: FETCH_ASSIGNMENTS_SUCCESS,
          meta: {channel, isSearch},
          payload: (action, currentState, res) =>
            getJSON(res).then(json => {
              const nextUrl = buildChannelNextUrl(
                url,
                json.pageNumber,
                json.hasNextPage
              )
              const normalizedData = normalize(mapAssignments(json), [
                assignmentSchema,
              ])
              const assignmentIds = normalizedData.result
              dispatch(fetchTimelinesIfNeeded(assignmentIds, forceReload))

              return {...normalizedData, nextUrl}
            }),
        },
        FETCH_ASSIGNMENTS_FAILURE,
      ],
    },
  })
}

export const fetchAssignmentsIfNeeded = (channel, forceReload = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const channels = getChannels(state)
  const channelExists = channel in channels
  const channelIsFetching = channelExists ? channels[channel].isFetching : false
  const channelHasItems = channelExists
    ? Boolean(channels[channel].items.length)
    : false
  const shouldFetchAssignments =
    !channelExists || (!channelIsFetching && !channelHasItems)

  if (shouldFetchAssignments || forceReload) {
    const url = CHANNEL_ASSIGNMENTS_PAGED_URL(channel, 1, CHANNELS_PAGE_SIZE)
    dispatch(fetchAssignments(channel, url, false, forceReload))
  }
}

export const fetchAssignmentsNext = (channel, nextUrl) => (
  dispatch,
  getState
) => {
  const state = getState()
  const channels = getChannels(state)
  const channelExists = channel in channels
  const channelIsFetching = channelExists ? channels[channel].isFetching : false
  const shouldFetchAssignmentsNext =
    !channelExists || (!channelIsFetching && nextUrl)

  if (shouldFetchAssignmentsNext) dispatch(fetchAssignments(channel, nextUrl))
}
export const fetchAutocompleteAssignmentsNext = url => dispatch => {
  dispatch(autocompleteAssignments(url, true))
}
export const fetchAutoCompleteAssignments = (
  searchTerm,
  loadMore = false
) => dispatch => {
  const url = ASSIGNMENTS_SEARCH_URL(searchTerm, 1, CHANNELS_PAGE_SIZE)
  dispatch(autocompleteAssignments(url))
}
export const autocompleteAssignments = (url, loadMore = false) => (
  dispatch,
  getState
) => {
  // const url = ASSIGNMENTS_SEARCH_URL(searchTerm, 1, CHANNELS_PAGE_SIZE)
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }
  dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      headers,
      types: [
        {
          type: FETCH_AUTOCOMPLETE_ASSIGNMENTS_REQUEST,
          meta: {channel: 'AutoComplete', loadMore},
        },
        {
          type: FETCH_AUTOCOMPLETE_ASSIGNMENTS_SUCCESS,
          meta: {channel: 'AutoComplete', loadMore},
          payload: (action, currentState, res) =>
            getJSON(res).then(json => {
              const nextUrl = buildChannelNextUrl(
                url,
                json.pageNumber,
                json.hasNextPage
              )
              const normalizedData = normalize(mapAssignments(json), [
                assignmentSchema,
              ])
              const assignmentIds = normalizedData.result
              dispatch(fetchTimelinesIfNeeded(assignmentIds))
              return {...normalizedData, nextUrl}
            }),
        },
        FETCH_AUTOCOMPLETE_ASSIGNMENTS_FAILURE,
      ],
    },
  })
}

export const searchAssignments = searchTerm => (dispatch, getState) => {
  const state = getState()
  const channels = getChannels(state)
  const channelExists = 'Search' in channels
  const channelIsFetching = channelExists ? channels.Search.isFetching : false
  const url = ASSIGNMENTS_SEARCH_URL(searchTerm, 1, CHANNELS_PAGE_SIZE)
  const shouldSearchAssignments = !channelExists || (!channelIsFetching && url)

  if (shouldSearchAssignments) {
    dispatch(fetchAssignments('Search', url, true))
    dispatch(
      navigateTo({
        keys: {},
        path: ASSIGNMENTS_PATH,
        options: {channel: 'Search', q: searchTerm},
      })
    )
  }
}

export const navigateToDefaultChannel = () => dispatch => {
  const defaultChannel = DEFAULT_CHANNEL_ID
  dispatch(
    navigateTo({
      keys: {},
      options: {channel: defaultChannel},
      path: ASSIGNMENTS_PATH,
    })
  )
  dispatch(
    updateChannelFilters(defaultChannel, {
      showUserAssignmentsOnly: true,
    })
  )
  dispatch(fetchAssignmentsIfNeeded(defaultChannel))
}

export const fetchChannelSummary = () => (dispatch, getState) => {
  const url = getChannelFilters(getState()).showUserAssignmentsOnly
    ? CHANNELS_SUMMARY(MY_PAST_DUE_CHANNEL_ID)
    : CHANNELS_SUMMARY(PAST_DUE_CHANNEL_ID)
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }
  dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      headers,
      types: [
        {
          type: FETCH_CHANNEL_SUMMARY_REQUEST,
        },
        {
          type: FETCH_CHANNEL_SUMMARY_SUCCESS,
          payload: (action, currentState, res) =>
            getJSON(res).then(json => {
              return {...json}
            }),
        },
        FETCH_CHANNEL_SUMMARY_FAILURE,
      ],
    },
  })
}
