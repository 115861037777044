import PT from 'prop-types'
import React, {Component} from 'react'
import {compileHash} from '../../utils/router'

const defaultProps = {
  className: '',
  keys: {},
  onClick: null,
  options: {},
  title: '',
}

const propTypes = {
  children: PT.node.isRequired,
  className: PT.string,
  onClick: PT.func,
  navigateTo: PT.func.isRequired,
  keys: PT.shape({}),
  options: PT.shape({}),
  path: PT.string.isRequired,
  title: PT.string,
}

class Link extends Component {
  constructor() {
    super()
    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    e.preventDefault()
    const {keys, navigateTo, onClick, options, path} = this.props
    navigateTo({path, keys, options})
    if (typeof onClick === 'function') {
      onClick()
    }
  }

  render() {
    const {children, className, keys, options, path, title} = this.props

    return (
      <a
        className={className}
        href={`/${compileHash({path, keys, options})}`}
        onClick={this.onClick}
        title={title}
      >
        {children}
      </a>
    )
  }
}

Link.defaultProps = defaultProps
Link.propTypes = propTypes

export default Link
