import createOidcMiddleware from 'redux-oidc'
import thunkMiddleware from 'redux-thunk'
import {apiMiddleware} from 'redux-api-middleware'
import {applyMiddleware, compose, createStore} from 'redux'
import apiAuthInjector from '../middleware/AuthApiInjector'
import progressMiddleware from '../middleware/ProgressMiddleware'
import rootReducer from '../reducers'
import userManager from '../utils/userManager'

// create the middleware with the userManager
const oidcMiddleware = createOidcMiddleware(
  userManager,
  () => true,
  true,
  'initiations/callback?'
)

const middleware = [
  thunkMiddleware,
  oidcMiddleware,
  apiAuthInjector,
  progressMiddleware,
  apiMiddleware,
]
const enhancers = [applyMiddleware(...middleware)]

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose

export default function configureStore(initialState) {
  return createStore(rootReducer, initialState, composeEnhancers(...enhancers))
}
