import React from 'react'
import styled from 'styled-components'
import {FOR_DESKTOP, LARGE} from 'arena-config'
import {Flex} from 'arena-components'

const flexSpacing = `${LARGE}px`

const StyledFlex = styled(({columnCount, ...rest}) => <Flex {...rest} />)`
  padding: 0 ${flexSpacing};

  ${FOR_DESKTOP} {
    > div {
      padding-right: ${flexSpacing};
      width: ${props => Math.floor(100 / props.columnCount)}%;
    }
  }
`

export default StyledFlex
