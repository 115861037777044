import PT from 'prop-types'
import React from 'react'
import {funcPT, numberPT, stringPT, emptyObjectPT} from 'arena-prop-types'
import AssignmentCard from '../Assignment/Card'
import Timeline from '../Timeline'
import {Gallery, ScrollTop} from '../../lib/components/core'
import {assignmentsPT} from '../../constants/propTypes/assignments'
import {formFieldPT} from '../../constants/propTypes/formFields'
import {resourcePT} from '../../constants/propTypes/common'
import {timelinesPT} from '../../constants/propTypes/timelines'
import {getCountry} from '../../utils/resources'
import {favoritesPT} from '../../constants/propTypes/favorite'

const Assignments = ({
  addApprover,
  assignmentAddingApprover,
  assignments,
  canDeleteAssignmentId,
  canRejectAssignmentId,
  countries,
  deleteAssignment,
  disableDeleteAssignment,
  disableRejectAssignment,
  enableDeleteAssignment,
  enableRejectAssignment,
  setCopyAssignmentId,
  errors,
  favorites,
  fetchFormSectionsIfNeeded,
  formFields,
  isCopyAssignmentDisabled,
  isMobile,
  navigateTo,
  policies,
  updateApprovalStatus,
  taskBeingExecuted,
  timelines,
  timelinesAreFetching,
  toggleAddApprover,
  toggleUpdateAssignmentOptions,
  updateAssignmentOptionsAndExecuteTask,
  updateTaskIfNeeded,
  updatingAssignmentOptions,
  assignmentOptionIsUpdating,
}) => {
  const results = []
  for (let i = 0; i < Object.keys(assignments).length; i += 1) {
    const {
      anticipatedEndDate,
      anticipatedStartDate,
      employeeId,
      firstName,
      hasComments,
      hasDocuments,
      hostCountryId,
      id,
      isLocked,
      lastName,
      planTypeId,
      title,
    } = assignments[i]
    const cardTitle =
      (policies && policies[planTypeId] && policies[planTypeId].label) || title
    const timeline = timelines.filter(t => t.id === id)[0]
    const tasks = timeline ? timeline.items : []
    const timelineLoaded = tasks && tasks.length > 0
    const hostCountry = getCountry(countries, hostCountryId)

    results.push(
      <div className="Assignments-item" key={i}>
        <AssignmentCard
          addApprover={addApprover}
          assignmentAddingApprover={assignmentAddingApprover}
          canDeleteAssignmentId={canDeleteAssignmentId}
          canRejectAssignmentId={canRejectAssignmentId}
          deleteAssignment={deleteAssignment}
          disableDeleteAssignment={disableDeleteAssignment}
          disableRejectAssignment={disableRejectAssignment}
          employeeId={employeeId}
          enableDeleteAssignment={enableDeleteAssignment}
          enableRejectAssignment={enableRejectAssignment}
          setCopyAssignmentId={setCopyAssignmentId}
          endDate={anticipatedEndDate}
          favorites={favorites}
          firstName={firstName}
          hasComments={hasComments}
          hasDocuments={hasDocuments}
          hostCountry={hostCountry}
          id={id}
          isCopyAssignmentDisabled={isCopyAssignmentDisabled}
          isLocked={isLocked}
          lastName={lastName}
          navigateTo={navigateTo}
          planTypeId={planTypeId}
          updateApprovalStatus={updateApprovalStatus}
          startDate={anticipatedStartDate}
          timelinesAreFetching={timelinesAreFetching}
          timelineLoaded={timelineLoaded}
          title={cardTitle}
          toggleAddApprover={toggleAddApprover}
        >
          <Timeline
            assignmentId={id}
            errors={errors}
            fetchFormSectionsIfNeeded={fetchFormSectionsIfNeeded}
            formFields={formFields}
            taskBeingExecuted={taskBeingExecuted}
            tasks={tasks}
            timelinesAreFetching={timelinesAreFetching}
            timelineLoaded={timelineLoaded}
            toggleUpdateAssignmentOptions={toggleUpdateAssignmentOptions}
            updateAssignmentOptionsAndExecuteTask={
              updateAssignmentOptionsAndExecuteTask
            }
            updateTaskIfNeeded={updateTaskIfNeeded}
            updatingAssignmentOptions={updatingAssignmentOptions}
            assignmentOptionIsUpdating={assignmentOptionIsUpdating}
          />
        </AssignmentCard>
      </div>
    )
  }

  return (
    <div className="Assignments">
      {isMobile && <ScrollTop />}
      <Gallery isMobile={isMobile}>{results}</Gallery>
    </div>
  )
}

Assignments.defaultProps = {
  assignmentAddingApprover: null,
  assignments: [],
  canDeleteAssignmentId: null,
  canRejectAssignmentId: null,
  formFields: {},
  taskBeingExecuted: null,
  timelines: [],
  updatingAssignmentOptions: null,
}

Assignments.propTypes = {
  addApprover: funcPT(true),
  assignmentAddingApprover: numberPT(),
  assignments: assignmentsPT(),
  canDeleteAssignmentId: numberPT(),
  canRejectAssignmentId: numberPT(),
  countries: resourcePT(true),
  deleteAssignment: funcPT(true),
  updateApprovalStatus: funcPT(true),
  disableDeleteAssignment: funcPT(true),
  disableRejectAssignment: funcPT(true),
  enableDeleteAssignment: funcPT(true),
  enableRejectAssignment: funcPT(true),
  setCopyAssignmentId: funcPT(true),
  errors: emptyObjectPT(),
  favorites: favoritesPT(),
  fetchFormSectionsIfNeeded: funcPT(true),
  formFields: PT.objectOf(formFieldPT(true)),
  isMobile: PT.bool.isRequired,
  navigateTo: funcPT(true),
  policies: resourcePT(true),
  taskBeingExecuted: numberPT(),
  timelines: timelinesPT(),
  timelinesAreFetching: PT.bool.isRequired,
  timelineLoaded: PT.bool,
  toggleAddApprover: funcPT(true),
  toggleUpdateAssignmentOptions: funcPT(true),
  updateAssignmentOptionsAndExecuteTask: funcPT(true),
  updateTaskIfNeeded: funcPT(true),
  updatingAssignmentOptions: stringPT(),
}

export default Assignments
