import {combineReducers} from 'redux'
import {
  FETCH_POLICIES_FAILURE,
  FETCH_POLICIES_REQUEST,
  FETCH_POLICIES_SUCCESS,
} from '../../../constants/actionTypes'

const policiesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_POLICIES_SUCCESS:
      return action.payload.entities.policies || {}
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_POLICIES_REQUEST:
      return true
    case FETCH_POLICIES_SUCCESS:
    case FETCH_POLICIES_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_POLICIES_FAILURE:
      return action.payload.message
    case FETCH_POLICIES_REQUEST:
    case FETCH_POLICIES_SUCCESS:
      return null
    default:
      return state
  }
}

const policies = combineReducers({
  byId: policiesById,
  isFetching,
  errorMessage,
})

export default policies
