import {Button} from 'arena-components'
import * as c from 'arena-config'
import React from 'react'
import styled from 'styled-components'
import {WebsiteNameDefaultProps, WebsiteNamePropTypes} from './propTypes'

const Wrap = styled.div`
  height: 48px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 90px;
  padding-left: 5px;
  color: white;
  font-size: larger;
  ${c.FOR_DESKTOP} {
    left: 90px;
    height: 48px;
    transition: opacity 0.25s;
    color: white;
    pointer: cursor;
  }
`

const WebsiteName = () => {
  return (
    <Wrap>
      <Button
        onClick={null}
        reset
        style={{
          fontWeight: 700,
          height: '100%',
          margin: '-1.01px 8px',
          padding: '0px 4px',
          fontSize: '24px',
          border: 0,
        }}
      >
        myInitiations
      </Button>
    </Wrap>
  )
}

WebsiteName.defaultProps = WebsiteNameDefaultProps
WebsiteName.propTypes = WebsiteNamePropTypes

export default WebsiteName
