import {combineReducers} from 'redux'
import {
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENTS_REQUEST,
  FETCH_DOCUMENTS_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_REQUEST,
  FETCH_DOCUMENT_CATEGORY_FAILURE,
  FETCH_DOCUMENT_CATEGORY_SUCCESS,
  FETCH_DOCUMENT_CATEGORY_REQUEST,
} from '../../constants/actionTypes'

const documentReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_REQUEST:
      return {}
    case FETCH_DOCUMENTS_SUCCESS:
      return action.payload
    case DELETE_DOCUMENT_SUCCESS:
      const key = Object.keys(action.payload).find(
        key => action.payload[key].id === action.meta.id
      )
      const {[key]: omit, ...rest} = action.payload
      return rest
    case FETCH_DOCUMENTS_FAILURE:
      return {}
    default:
      return state
  }
}

const isStale = (state = false, action) => {
  switch (action.type) {
    case DELETE_DOCUMENT_SUCCESS:
      return true
    case DELETE_DOCUMENT_REQUEST:
      return false
    default:
      return state
  }
}

const documentCategory = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DOCUMENT_CATEGORY_REQUEST:
      return {}
    case FETCH_DOCUMENT_CATEGORY_SUCCESS:
      return action.payload
    case FETCH_DOCUMENT_CATEGORY_FAILURE:
      return {}
    default:
      return state
  }
}

const isDocumentFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_REQUEST:
      return true
    case FETCH_DOCUMENTS_SUCCESS:
      return false
    case FETCH_DOCUMENTS_FAILURE:
      return false
    default:
      return state
  }
}

const documents = combineReducers({
  documentReducer,
  isStale,
  documentCategory,
  isDocumentFetching,
})

export default documents
