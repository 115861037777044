import {combineReducers} from 'redux'
import {ON_ERROR, CLEAR_ERRORS} from '../constants/actionTypes'

const error = (state = '', action) => {
  switch (action.type) {
    case ON_ERROR:
    case CLEAR_ERRORS:
      return action.error.toString()
    default:
      return state
  }
}

const errorInfo = (state = {}, action) => {
  switch (action.type) {
    case ON_ERROR:
    case CLEAR_ERRORS:
      return action.errorInfo
    default:
      return state
  }
}

const hasError = (state = false, action) => {
  switch (action.type) {
    case ON_ERROR:
    case CLEAR_ERRORS:
      return action.hasError
    default:
      return state
  }
}

const errors = combineReducers({
  error,
  errorInfo,
  hasError,
})

export default errors
