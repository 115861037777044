import PT from 'prop-types'
import React from 'react'
import ProgressiveImage from 'react-progressive-image'

const defaultProps = {
  children: null,
  className: '',
  imageHandler: src => (
    <div
      className="image-cover overlay background-position-center background-size-cover"
      style={{backgroundImage: `url(${src})`}}
    />
  ),
  mainSrc: null,
  thumbSrc: null,
}

const propTypes = {
  className: PT.string,
  imageHandler: PT.func,
  mainSrc: PT.string,
  thumbSrc: PT.string,
}

const ImageLoader = ({className, imageHandler, mainSrc, thumbSrc}) => (
  <div data-component="ImageLoader" className={className}>
    {mainSrc && thumbSrc && (
      <ProgressiveImage src={mainSrc} placeholder={thumbSrc}>
        {imageHandler}
      </ProgressiveImage>
    )}
  </div>
)

ImageLoader.defaultProps = defaultProps
ImageLoader.propTypes = propTypes

export default ImageLoader
