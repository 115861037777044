import React, {useState} from 'react'
import styled from 'styled-components'
import {CREATE_DOCUMENT} from '../../../constants/api'
import {mapUploadForm} from '../../../utils/api'
import {
  whiteListedExtentions,
  UNSUPPORTED_FILE_TYPE_UPLOAD_MESSAGE,
  SUPPORTED_FILE_TYPE,
} from '../../../constants/documents'
import {fetchDocuments} from '../../../actions/document'
import {
  getDocumentCategoriesAndSubcategories,
  getUploadFrom,
} from '../../../selectors/document'
import {useDispatch, useSelector} from 'react-redux'
import {updateFieldValue} from '../../../actions/form'
import {DATE_FORMAT, FONT_WEIGHT_1} from 'arena-config'
import {formatDate, todaysDate} from '../../../utils/moment'
import {UPLOAD_FORM} from '../../../constants/form'
import {Upload, message, Input} from 'antd'
import AntDModal from 'antd/lib/modal'
import {Form, Field} from '../../../lib/components/arena-plugin-form'

const AttachDocument = ({
  shouldShowUploadModal,
  setShouldShowUploadModal,
  title,
  setTitle,
  assignmentId,
  details,
  setDetails,
  effectiveDate,
  setEffectiveDate,
  setinfo,
}) => {
  const token = useSelector(state => state.oidc.user.access_token || '')
  const [subcategories, setSubcategories] = useState([])
  const [isCategoriesSelected, setCategoriesSelected] = useState(false)
  const dispatch = useDispatch()
  const categoriesAndSubcategories = useSelector(state =>
    getDocumentCategoriesAndSubcategories(state)
  )
  const [draggerText, setDraggerText] = useState(
    'Click or drag file to this area to upload'
  )
  const uploadFileForm = useSelector(state => getUploadFrom(state))
  const activateUpload =
    uploadFileForm.Category && uploadFileForm.SubCategory && title

  const {Dragger} = Upload
  const {TextArea} = Input

  const Heading = styled.div`
    padding-bottom: 20px;
    font-weight: ${FONT_WEIGHT_1};
    font-size: 1.5em;
  `

  const props = {
    name: 'file',
    action: CREATE_DOCUMENT(assignmentId),
    data: {
      Title: title,
      CategoryCode: uploadFileForm.Category,
      SubCategoryCode: uploadFileForm.SubCategory,
      Details: details,
      EffectiveDate: formatDate(uploadFileForm.EffectiveDate || effectiveDate),
    },
    headers: {
      authorization: `Bearer ${token}`,
      'X-Requested-With': null,
    },
    beforeUpload(file) {
      if (
        whiteListedExtentions.indexOf(
          file.name
            .split('.')
            .pop()
            .toLowerCase()
        ) !== -1
      ) {
        setDraggerText('Uploading....')
        return true
      }
      message.error(
        <div>
          <div style={{textAlign: 'left'}}>
            {UNSUPPORTED_FILE_TYPE_UPLOAD_MESSAGE(
              file.name
                .split('.')
                .pop()
                .toLowerCase()
            )}
          </div>
          <div>{SUPPORTED_FILE_TYPE}</div>
        </div>
      )
      return false
    },
    onChange(info) {
      if (info.file.status === 'done') {
        setinfo(info)
        setDraggerText('Click or drag file to this area to upload')
        message.success(`${info.file.name} file uploaded successfully`)
        dispatch(fetchDocuments(assignmentId))
        setShouldShowUploadModal(false)
      } else if (info.file.status === 'error') {
        setDraggerText('Click or drag file to this area to upload')
        message.error(`${info.file.name} file upload failed.`)
      }
    },
  }

  const getSubcategories = categoryCode => {
    if (!categoryCode) {
      return []
    }
    const category = categoriesAndSubcategories.find(
      x => x.code === categoryCode
    )
    return category ? category.children : []
  }

  const handleCategoryChange = code => {
    setCategoriesSelected(code != null)
    setSubcategories(getSubcategories(code))
    dispatch(updateFieldValue(UPLOAD_FORM, 'SubCategory', null))
  }
  const handleDetails = e => {
    setDetails(e.target.value)
  }

  const handleTitle = e => {
    setTitle(e.target.value)
  }

  const handleEffectiveDate = e => {
    setEffectiveDate(e)
  }

  return (
    <div>
      <AntDModal
        visible={shouldShowUploadModal}
        onCancel={() => setShouldShowUploadModal(false)}
        destroyOnClose={true}
        footer={null}
      >
        <Form
          name={UPLOAD_FORM}
          initialValues={{EffectiveDate: `${todaysDate()}`}}
          onSubmit={() => {}}
        >
          <Heading>{'Upload Document'}</Heading>
          <div style={{marginBottom: '5px'}}>
            <Field
              name="Category"
              placeholder="Category*"
              options={mapUploadForm(categoriesAndSubcategories || [])}
              type="select"
              onChange={handleCategoryChange}
            />
          </div>
          <div style={{marginBottom: '5px'}}>
            <Field
              name="SubCategory"
              placeholder="Sub Category*"
              options={mapUploadForm(subcategories || [])}
              type="select"
              disabled={!isCategoriesSelected}
            />
          </div>
          <div style={{marginBottom: '5px'}}>
            <TextArea
              name="Title"
              placeholder="Title*"
              onChange={handleTitle}
              maxLength={255}
            />
          </div>
          <div style={{marginBottom: '5px'}}>
            <TextArea
              name="Details"
              placeholder="Details"
              rows={4}
              maxLength={255}
              onChange={handleDetails}
            />
          </div>
          <div style={{marginBottom: '5px'}}>
            <Field
              name="EffectiveDate"
              placeholder="Effective Date"
              type="dateTime"
              dateFormat={DATE_FORMAT}
              timeFormat=""
              onChange={handleEffectiveDate}
            />
          </div>

          <Dragger
            {...props}
            showUploadList={false}
            disabled={!activateUpload}
            style={{
              borderColor: activateUpload ? 'green' : 'red',
            }}
          >
            {draggerText}
          </Dragger>
          <div>
            Please fill in all required fields before selecting the file to
            upload.
          </div>
        </Form>
      </AntDModal>
    </div>
  )
}

export default AttachDocument
