import React from 'react'
import pure from 'recompose/pure'
import DataCell from '../DataCell'
import DataTable from '../DataTable/index'
import {amountsLabelDefaultProps, amountsLabelPropTypes} from './propTypes'

const AmountsLabel = props => {
  const results = []

  for (let i = 0; i < props.duration; i += 1) {
    results.push(
      <DataCell key={`AmountsLabel_${i}`} width={props.width}>
        {props.startYear + i}
      </DataCell>
    )
  }

  return <DataTable>{results}</DataTable>
}

AmountsLabel.defaultProps = amountsLabelDefaultProps
AmountsLabel.propTypes = amountsLabelPropTypes

export default pure(AmountsLabel)
