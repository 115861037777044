import {createSelector} from 'reselect'
import {denormalize} from 'normalizr'
import {assignmentSchema} from '../constants/schemas'
import {getAssignmentsById, getAutoCompleteResults} from './assignments'
import {getRouter} from './router'

export const getChannels = state => state.initiations.channels

export const getLastActiveChannel = state =>
  getChannels(state).lastActiveChannel

export const getChannelFilters = state => getChannels(state).filters

export const getChannel = state =>
  getRouter(state).route.options.channel || getLastActiveChannel(state)

export const getChannelsInfo = state => getChannels(state).info

export const getChannelFetching = createSelector(
  [getChannel, getChannels],
  (channel, channels) =>
    channel in channels ? channels[channel].isFetching : false
)

export const getChannelNextUrl = createSelector(
  [getChannel, getChannels],
  (channel, channels) => (channel in channels ? channels[channel].nextUrl : '')
)

export const getChannelAssignmentIds = createSelector(
  [getChannel, getChannels],
  (channel, channels) => (channel in channels ? channels[channel].items : [])
)

export const getChannelAssignments = createSelector(
  [getChannel, getChannels, getAssignmentsById],
  (channel, channels, assignmentsById) =>
    channel in channels
      ? denormalize(channels[channel].items, [assignmentSchema], {
          assignments: assignmentsById,
        })
      : []
)

export const getAutoCompleteAssignments = createSelector(
  [getChannels, getAutoCompleteResults],
  (channels, assignmentsById) => {
    return 'AutoComplete' in channels
      ? denormalize(channels['AutoComplete'].items, [assignmentSchema], {
          assignments: assignmentsById,
        })
      : []
  }
)

export const getAutoCompleteAssignmentsNext = createSelector(
  [getChannels],
  channels =>
    'AutoComplete' in channels ? channels['AutoComplete'].nextUrl : ''
)
