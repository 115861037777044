import {LOGIN_SUCCESS, LOGOUT} from 'arena-redux'
import {LOGOUT_MESSAGE} from '../lib/constants/messages'

const initialState = {
  oauthToken: null,
}

const session = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {...state, oauthToken: action.oauthToken}

    case LOGOUT:
      return {...initialState, message: LOGOUT_MESSAGE}

    default:
      return state
  }
}

export default session
