import {Button, Icon} from 'arena-components'
import React from 'react'
import pure from 'recompose/pure'
import {BulkEditPillDefaultProps, BulkEditPillPropTypes} from './propTypes'

const BulkEditPill = props => (
  <Button
    onClick={props.onClick}
    preventDefault
    style={{
      margin: '.5em',
      padding: '0 0.25em',
      display: 'inline-flex',
      justifyContent: 'center',
    }}
  >
    {props.name}
    &nbsp;&nbsp;&nbsp;
    <Icon type="x" style={{margin: 'auto'}} />
  </Button>
)

BulkEditPill.defaultProps = BulkEditPillDefaultProps
BulkEditPill.propTypes = BulkEditPillPropTypes

export default pure(BulkEditPill)
