import {RSAA, getJSON} from 'redux-api-middleware'
import {normalize} from 'normalizr'
import {isNullOrUndefined} from 'util'
import {
  IDENTITY_FETCH_COMPANIES_URL,
  IDENTITY_SELECT_COMPANY_URL,
} from '../constants/api'
import {
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_LAST_SELECTED_COMPANY,
  UPDATE_SELECTED_COMPANY_SUCCESS,
  UPDATE_SELECTED_COMPANY_REQUEST,
  UPDATE_SELECTED_COMPANY_FAILURE,
} from '../constants/actionTypes'
import {MY_PAST_DUE_CHANNEL_ID} from '../constants/channels'
import {companySchema} from '../constants/schemas'
import {mapCompanyList} from '../utils/api'
import {getCompaniesById} from '../selectors/company'
import {makeRoute} from '../utils/router'
import {INDEX_PATH} from '../constants/router'
import {navigateToDefaultChannel, fetchChannelSummary} from './channels'
import {navigateTo} from './navigation'

export const fetchCompaniesIfNeeded = () => dispatch => {
  dispatch({
    [RSAA]: {
      endpoint: IDENTITY_FETCH_COMPANIES_URL,
      method: 'GET',
      types: [
        FETCH_COMPANIES_REQUEST,
        {
          type: FETCH_COMPANIES_SUCCESS,
          payload: (_action, _currentState, res) =>
            getJSON(res).then(json => {
              const companyList = json
              const companies = normalize(mapCompanyList(json), [companySchema])

              let defaultSelectedCompany = ''
              let defaultFormId = null
              let defaultFormRecordTitle = ''
              let defaultMessages = null
              let defaultIsCopyAssignmentDisabled = false

              for (let company of companyList) {
                if (company.isActive) {
                  defaultSelectedCompany = company.companyCode
                  defaultFormId =
                    company.additionalProperties &&
                    company.additionalProperties.formId
                  defaultFormRecordTitle =
                    company.additionalProperties &&
                    company.additionalProperties.formRecordTitle
                  defaultMessages =
                    company.additionalProperties &&
                    company.additionalProperties.messages
                  defaultIsCopyAssignmentDisabled =
                    company.additionalProperties &&
                    company.additionalProperties.isCopyAssignmentDisabled
                  break
                }
              }
              if (defaultSelectedCompany === '') {
                defaultSelectedCompany = companyList[0].companyCode
                defaultFormId =
                  companyList[0].additionalProperties &&
                  companyList[0].additionalProperties.formId
                defaultFormRecordTitle =
                  companyList[0].additionalProperties &&
                  companyList[0].additionalProperties.formRecordTitle
                defaultMessages =
                  companyList[0].additionalProperties &&
                  companyList[0].additionalProperties.messages
                defaultIsCopyAssignmentDisabled =
                  companyList[0].additionalProperties &&
                  companyList[0].additionalProperties.isCopyAssignmentDisabled
              }

              dispatch(
                fetchSelectedCompany(
                  defaultSelectedCompany,
                  defaultFormId,
                  defaultFormRecordTitle,
                  defaultMessages,
                  defaultIsCopyAssignmentDisabled
                )
              )
              return companies
            }),
        },
        FETCH_COMPANIES_FAILURE,
      ],
    },
  })
}

export const fetchSelectedCompany = (
  company,
  formId,
  formRecordTitle,
  messages,
  isCopyAssignmentDisabled
) => ({
  type: FETCH_LAST_SELECTED_COMPANY,
  company,
  formId,
  formRecordTitle,
  messages,
  isCopyAssignmentDisabled,
})

export const updateLastSelectedCompany = (previousCompany, currentCompany) => (
  dispatch,
  getState
) => {
  const state = getState()
  const companies = getCompaniesById(state)
  const selectedCompany = companies[currentCompany]
  const selectedFormId =
    selectedCompany.additionalProperties &&
    selectedCompany.additionalProperties.formId
  const selectedFormRecordTitle =
    selectedCompany.additionalProperties &&
    selectedCompany.additionalProperties.formRecordTitle
  const selectedMessages =
    selectedCompany.additionalProperties &&
    selectedCompany.additionalProperties.messages
  const selectedIsCopyAssignmentDisabled =
    selectedCompany.additionalProperties &&
    selectedCompany.additionalProperties.isCopyAssignmentDisabled

  const hasCompanyChanged =
    previousCompany && previousCompany !== currentCompany

  dispatch({
    [RSAA]: {
      endpoint: IDENTITY_SELECT_COMPANY_URL(selectedCompany.id),
      method: 'POST',
      types: [
        {
          type: UPDATE_SELECTED_COMPANY_REQUEST,
          meta: {hasCompanyChanged},
        },
        {
          type: UPDATE_SELECTED_COMPANY_SUCCESS,
          payload: (_action, _currentState, res) =>
            getJSON(res).then(json => {
              const updatedCompany = json.companyCode
              dispatch(
                fetchSelectedCompany(
                  updatedCompany,
                  selectedFormId,
                  selectedFormRecordTitle,
                  selectedMessages,
                  selectedIsCopyAssignmentDisabled
                )
              )
              if (!isNullOrUndefined(previousCompany)) {
                dispatch(
                  navigateTo(
                    makeRoute({
                      path: INDEX_PATH,
                    })
                  )
                )
                if (
                  selectedCompany &&
                  selectedCompany.value !== updatedCompany
                ) {
                  dispatch(navigateToDefaultChannel())
                }
                dispatch(fetchChannelSummary(MY_PAST_DUE_CHANNEL_ID))
              }
            }),
        },
        UPDATE_SELECTED_COMPANY_FAILURE,
      ],
    },
  })
}
