import {combineReducers} from 'redux'
import {
  FETCH_SUB_ENTITIES_FAILURE,
  FETCH_SUB_ENTITIES_REQUEST,
  FETCH_SUB_ENTITIES_SUCCESS,
} from '../../../constants/actionTypes'

const subEntitiesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SUB_ENTITIES_SUCCESS:
      return action.payload.entities.subEntities || {}
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_SUB_ENTITIES_REQUEST:
      return true
    case FETCH_SUB_ENTITIES_SUCCESS:
    case FETCH_SUB_ENTITIES_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_SUB_ENTITIES_FAILURE:
      return action.payload.message
    case FETCH_SUB_ENTITIES_REQUEST:
    case FETCH_SUB_ENTITIES_SUCCESS:
      return null
    default:
      return state
  }
}

const subEntities = combineReducers({
  byId: subEntitiesById,
  isFetching,
  errorMessage,
})

export default subEntities
