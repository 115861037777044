import PT from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {FONT_WEIGHT_3, LARGE, SMALLEST} from 'arena-config'

const flexSpacing = `${LARGE}px`

export const StyledSubSectionName = styled.div`
  font-weight: ${FONT_WEIGHT_3};
  letter-spacing: 1px;
  padding: ${SMALLEST}px ${flexSpacing};
  text-transform: uppercase;
`

const SubSectionName = props => (
  <StyledSubSectionName>{props.children}</StyledSubSectionName>
)

SubSectionName.propTypes = {
  children: PT.oneOfType([PT.node, PT.string]).isRequired,
}

export default SubSectionName
