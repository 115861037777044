import PT from 'prop-types'

export const menuDefaultProps = {
  onClick: null,
}

export const menuPropTypes = {
  menuSrc: PT.string.isRequired,
  menuCloseSrc: PT.string.isRequired,
  onClick: PT.func,
}
