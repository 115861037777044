import {RSAA, getJSON} from 'redux-api-middleware'
import {
  CREATE_COMMENT_REQUEST,
  CREATE_COMMENT_SUCCESS,
  CREATE_COMMENT_FAILURE,
  FETCH_COMMENTS_FAILURE,
  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_SUCCESS,
  DELETE_COMMENT_FAILURE,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_REQUEST,
} from '../constants/actionTypes'
import {
  CREATE_COMMENT,
  DELETE_COMMENT,
  GET_COMMENTS_FOR_ASSIGNMENT,
  TAGGED_USERS,
} from '../constants/api'
import {isIE} from '../utils/browser'

export const createComment = (id, comment, callback) => dispatch => {
  const url = CREATE_COMMENT(id)
  let headers = ''
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }
  dispatch({
    [RSAA]: {
      endpoint: url,
      headers: {'Content-Type': 'application/json', ...headers},
      method: 'POST',
      body: JSON.stringify(comment),
      types: [
        CREATE_COMMENT_REQUEST,
        {
          type: CREATE_COMMENT_SUCCESS,
          payload: (action, currentState, res) => {
            getJSON(res).then(json => {
              callback(json)
            })
          },
        },
        CREATE_COMMENT_FAILURE,
      ],
    },
  })
}

export const fetchComments = (id, callback) => dispatch => {
  const url = GET_COMMENTS_FOR_ASSIGNMENT(id)
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }
  dispatch({
    [RSAA]: {
      endpoint: url,
      headers,
      method: 'GET',
      types: [
        FETCH_COMMENTS_REQUEST,
        {
          type: FETCH_COMMENTS_SUCCESS,
          payload: (action, currentState, res) =>
            getJSON(res).then(json => {
              return callback(json)
            }),
        },
        FETCH_COMMENTS_FAILURE,
      ],
    },
  })
}

export const deleteComment = id => dispatch => {
  const url = DELETE_COMMENT(id)
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }
  dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'DELETE',
      headers,
      types: [
        DELETE_COMMENT_REQUEST,
        {
          type: DELETE_COMMENT_SUCCESS,
          payload: {id},
        },
        DELETE_COMMENT_FAILURE,
      ],
    },
  })
}

export const fetchUsers = (query, callback) => dispatch => {
  const url = TAGGED_USERS(query)
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }
  dispatch({
    [RSAA]: {
      endpoint: url,
      headers,
      method: 'GET',
      types: [
        FETCH_USERS_REQUEST,
        {
          type: FETCH_USERS_SUCCESS,
          payload: (action, currentState, res) =>
            getJSON(res).then(json => {
              return callback(json)
            }),
        },
        FETCH_USERS_FAILURE,
      ],
    },
  })
}
