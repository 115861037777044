import {funcPT, numberPT} from 'arena-prop-types'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import {selectedDatePT} from '../propTypes'

const Wrap = styled(({month, selectedDate, year, ...rest}) => (
  <div {...rest} />
))``

const DateTimeMonth = props => {
  const monthCode = moment(props.month + 1, 'M')
    .format('MMMM')
    .substr(0, 3)
  return (
    <td>
      <Wrap {...props}>{monthCode}</Wrap>
    </td>
  )
}

DateTimeMonth.defaultProps = {
  onClick: null,
  selectedDate: {},
}

DateTimeMonth.propTypes = {
  month: numberPT(true),
  onClick: funcPT(),
  selectedDate: selectedDatePT(),
  year: numberPT(true),
}

export default DateTimeMonth
