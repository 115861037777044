import React, {Component} from 'react'
import {
  boolPT,
  emptyObjectPT,
  funcPT,
  numberPT,
  stringPT,
  momentObjectOrStringPT,
} from 'arena-prop-types'
import debounce from 'lodash.debounce'
import {isNullOrUndefined} from 'util'
import AssignmentForm from '../Form'
import {accountSectionsPT} from '../../constants/propTypes/accountSections'
import {formSectionsPT} from '../../constants/propTypes/formSections'
import {resourcesPT} from '../../constants/propTypes/resources'
import {taxSectionsPT} from '../../constants/propTypes/taxSections'
import {calculationSectionsPT} from '../../constants/propTypes/calculationSections'
import {
  LOCATION_TYPE_HOME,
  LOCATION_TYPE_HOST,
  LOCATION_TYPE_HYPO,
  LOCATION_TYPE_HOST_HYPO,
} from '../../constants/locationTypes'
import {validateAndSubmit} from '../../utils/validation'
class CreateAssignment extends Component {
  debouncedfetchTaxSectionsIfNeeded = debounce(() => {
    const {currentValues, fetchTaxSectionsIfNeeded} = this.props
    fetchTaxSectionsIfNeeded(currentValues)
  }, 1000)

  componentDidMount() {
    const {
      cloneAssignmentId,
      currentValues,
      fetchFormSectionsIfNeeded,
      fetchResourcesIfNeeded,
      fetchCompaniesIfNeeded,
      fetchAssignment,
      fetchAssignmentFamilyMembersIfNeeded,
      fetchAssignmentOptionsIfNeeded,
      formName,
    } = this.props
    fetchFormSectionsIfNeeded(currentValues, formName)
    fetchResourcesIfNeeded()
    fetchCompaniesIfNeeded()
    if (cloneAssignmentId > 0) {
      fetchAssignment(cloneAssignmentId)
      fetchAssignmentOptionsIfNeeded(cloneAssignmentId)
      fetchAssignmentFamilyMembersIfNeeded(cloneAssignmentId)
    }
  }
  componentDidUpdate(prevProps) {
    const {
      currentValues,
      executeBusinessRulesIfNeeded,
      executeEmployeeAutofillIfNeeded,
      fetchFormSectionsIfNeeded,
      fetchAccountSectionsIfNeeded,
      fetchCalculationSectionsIfNeeded,
      fetchFilingStatusOnLocationChange,
      updateEmployeesIfNeeded,
      formName,
      lastSelectedCompany,
      lastSelectedCompanyFormId,
      fetchResourcesIfNeeded,
    } = this.props
    const prevValues = prevProps.currentValues

    const shouldFetchFormSections =
      (!isNullOrUndefined(prevProps.lastSelectedCompanyFormId) &&
        prevProps.lastSelectedCompanyFormId) !==
      (!isNullOrUndefined(lastSelectedCompanyFormId) &&
        lastSelectedCompanyFormId)

    const shouldRefreshResources =
      !isNullOrUndefined(prevProps.lastSelectedCompany) &&
      prevProps.lastSelectedCompany &&
      prevProps.lastSelectedCompany.company !==
        (lastSelectedCompany && lastSelectedCompany.company)

    if (shouldFetchFormSections) {
      fetchFormSectionsIfNeeded(currentValues, formName)
    }

    if (shouldRefreshResources) {
      fetchResourcesIfNeeded(true)
    }

    const currentValueHasChanged = key =>
      (currentValues && currentValues[key] && currentValues[key].value) !==
      (prevValues && prevValues[key] && prevValues[key].value)

    const currentValuesHaveChanged = keys => {
      let result = false
      keys.forEach(x => {
        if (currentValueHasChanged(x)) {
          result = true
          return
        }
      })

      return result
    }

    const hasPolicyChanged = currentValueHasChanged('planTypeCode')

    const hasCompensationHomeHypoLocationChanged = currentValueHasChanged(
      'options.General__HypoCompensationLocation__List.value'
    )

    const hasCompensationHostHypoLocationChanged = currentValueHasChanged(
      'options.General__HostHypoCompensationLocation__List.value'
    )

    const hasIncomeTaxHomeLocationChanged = currentValueHasChanged(
      'options.General__HomeIncomeTaxLocation__List.value'
    )

    const hasIncomeTaxHostLocationChanged = currentValueHasChanged(
      'options.General__HostIncomeTaxLocation__List.value'
    )

    const hasIncomeTaxHomeHypoLocationChanged = currentValueHasChanged(
      'options.General__HypoIncomeTaxLocation__List.value'
    )

    const hasIncomeTaxHostHypoLocationChanged = currentValueHasChanged(
      'options.General__HostHypoIncomeTaxLocation__List.value'
    )

    const hasSocialTaxHomeLocationChanged = currentValueHasChanged(
      'options.General__HomeSocialTaxLocation__List.value'
    )

    const hasSocialTaxHostLocationChanged = currentValueHasChanged(
      'options.General__HostSocialTaxLocation__List.value'
    )

    const hasSocialTaxHomeHypoLocationChanged = currentValueHasChanged(
      'options.General__HypoSocialTaxLocation__List.value'
    )

    const hasSocialTaxHostHypoLocationChanged = currentValueHasChanged(
      'options.General__HostHypoSocialTaxLocation__List.value'
    )

    const couldFetchTaxSections =
      hasIncomeTaxHomeLocationChanged ||
      hasIncomeTaxHostLocationChanged ||
      hasIncomeTaxHomeHypoLocationChanged ||
      hasIncomeTaxHostHypoLocationChanged ||
      hasSocialTaxHomeLocationChanged ||
      hasSocialTaxHostLocationChanged ||
      hasSocialTaxHomeHypoLocationChanged ||
      hasSocialTaxHostHypoLocationChanged ||
      hasPolicyChanged

    const couldFetchCalculationSections = currentValuesHaveChanged([
      'homeLocation',
      'hostLocation',
      'planTypeCode',
    ])

    const couldExecuteBusinessRules = currentValueHasChanged('employeeId')

    const couldExecuteDynamicBusinessRules = currentValuesHaveChanged([
      'firstName',
      'lastName',
      'homeLocation',
      'hostLocation',
      'planTypeCode',
    ])

    const shouldFetchHomeFilingStatusOptions =
      hasIncomeTaxHomeLocationChanged &&
      currentValues['options.General__HomeIncomeTaxLocation__List.value']
        .value !== undefined

    const shouldFetchHostFilingStatusOptions =
      hasIncomeTaxHostLocationChanged &&
      currentValues['options.General__HostIncomeTaxLocation__List.value']
        .value !== undefined

    const shouldFetchHypoFilingStatusOptions =
      hasIncomeTaxHomeHypoLocationChanged &&
      currentValues['options.General__HypoIncomeTaxLocation__List.value']
        .value !== undefined

    const shouldFetchHostHypoFilingStatusOptions =
      hasIncomeTaxHostHypoLocationChanged &&
      currentValues['options.General__HostHypoIncomeTaxLocation__List.value']
        .value !== undefined

    if (shouldFetchHomeFilingStatusOptions) {
      fetchFilingStatusOnLocationChange(
        currentValues['options.General__HomeIncomeTaxLocation__List.value']
          .value,
        LOCATION_TYPE_HOME,
        formName
      )
    }
    if (shouldFetchHostFilingStatusOptions) {
      fetchFilingStatusOnLocationChange(
        currentValues['options.General__HostIncomeTaxLocation__List.value']
          .value,
        LOCATION_TYPE_HOST,
        formName
      )
    }
    if (shouldFetchHypoFilingStatusOptions) {
      fetchFilingStatusOnLocationChange(
        currentValues['options.General__HypoIncomeTaxLocation__List.value']
          .value,
        LOCATION_TYPE_HYPO,
        formName
      )
    }
    if (shouldFetchHostHypoFilingStatusOptions) {
      fetchFilingStatusOnLocationChange(
        currentValues['options.General__HostHypoIncomeTaxLocation__List.value']
          .value,
        LOCATION_TYPE_HOST_HYPO,
        formName
      )
    }

    if (couldExecuteBusinessRules) {
      executeEmployeeAutofillIfNeeded(prevValues, currentValues)
      updateEmployeesIfNeeded(prevValues, currentValues)
    }

    if (
      couldExecuteDynamicBusinessRules ||
      hasCompensationHomeHypoLocationChanged ||
      hasCompensationHostHypoLocationChanged
    )
      executeBusinessRulesIfNeeded(prevValues, currentValues, formName)

    if (hasPolicyChanged) {
      fetchAccountSectionsIfNeeded(currentValues)
    }

    if (couldFetchCalculationSections) {
      fetchCalculationSectionsIfNeeded(currentValues)
    }

    if (couldFetchTaxSections) {
      this.debouncedfetchTaxSectionsIfNeeded()
    }
  }
  render() {
    return (
      <AssignmentForm
        accountSections={this.props.accountSections}
        change={this.props.change}
        currentValues={this.props.currentValues}
        environmentHeight={this.props.environmentHeight}
        errors={this.props.errors}
        formIsFetching={this.props.formIsFetching}
        formIsSubmitting={this.props.formIsSubmitting}
        formName={this.props.formName}
        formSections={this.props.formSections}
        formTabs={this.props.formTabs}
        formValidationError={this.props.formValidationError}
        formSubmissionError={this.props.formSubmissionError}
        initialValues={this.props.initialValues}
        isLocked={false}
        linksHeaderTitle={
          this.props.formTitle ? `Create ${this.props.formTitle}` : 'Create'
        }
        loadingMessage=""
        onSubmit={values =>
          validateAndSubmit(
            values,
            this.props.errorsFromAllTabs,
            this.props.createAssignment
          )
        }
        resources={this.props.resources}
        startDate={this.props.startDate}
        submitFailed={this.props.submitFailed}
        submitMessage={
          this.props.formTitle ? `Create` : 'Create this record now'
        }
        submittingMessage="Saving..."
        syncErrors={this.props.syncErrors}
        taxSections={this.props.taxSections}
        calculationSections={this.props.calculationSections}
        updatePreviousFormTabErrors={this.props.updatePreviousFormTabErrors}
      />
    )
  }
}

CreateAssignment.defaultProps = {
  currentValues: {},
  formTabs: {},
  formValidationError: null,
  initialValues: null,
  startDate: null,
  syncErrors: {},
}

CreateAssignment.propTypes = {
  accountSections: accountSectionsPT(true),
  change: funcPT(true),
  createAssignment: funcPT(true),
  currentValues: emptyObjectPT(),
  environmentHeight: numberPT(true),
  errors: emptyObjectPT(true),
  executeBusinessRulesIfNeeded: funcPT(true),
  executeEmployeeAutofillIfNeeded: funcPT(true),
  fetchAccountSectionsIfNeeded: funcPT(true),
  fetchFormSectionsIfNeeded: funcPT(true),
  fetchResourcesIfNeeded: funcPT(true),
  fetchTaxSectionsIfNeeded: funcPT(true),
  fetchCalculationSectionsIfNeeded: funcPT(true),
  formIsFetching: boolPT(true),
  formIsSubmitting: boolPT(true),
  formName: stringPT(true),
  formSections: formSectionsPT(true),
  formTabs: emptyObjectPT(),
  formValidationError: stringPT(),
  formSubmissionError: stringPT(),
  initialValues: emptyObjectPT(),
  resources: resourcesPT(true),
  startDate: momentObjectOrStringPT(),
  syncErrors: emptyObjectPT(),
  submitFailed: boolPT(),
  taxSections: taxSectionsPT(true),
  calculationSections: calculationSectionsPT(true),
  updatePreviousFormTabErrors: funcPT(true),
  updateEmployeesIfNeeded: funcPT(true),
  formTitle: stringPT(),
}

export default CreateAssignment
