import React from 'react'
import Waypoint from 'react-waypoint'
import pure from 'recompose/pure'
import styled from 'styled-components'
import HeaderRow from '../HeaderRow'
import Section from '../Section'
import {cellWidthProps} from '../../constants/propTypes'
import {sectionsDefaultProps, sectionsPropTypes} from './propTypes'

const Inner = styled.div`
  display: inline-block;
  height: ${props => props.height || '100vh'}px;
  overflow-x: hidden;
  overflow-y: auto;
  vertical-align: top;
`

const Outer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
`

const InlineBlock = styled.div`
  display: inline-block;
  min-width: 100%;
`

const Sections = props => (
  <Outer>
    <Inner height={props.environmentHeight}>
      {props.showContentBeforeSections && props.children}
      <div>
        <InlineBlock>
          {props.sections.map(section => (
            <InlineBlock key={section.code}>
              <HeaderRow
                {...cellWidthProps(props)}
                duration={props.duration}
                isVisible={!!props.stickyHeader[section.code]}
                name={section.name}
                resources={props.resources}
                showAllResources={props.showAllResources}
                startYear={props.startYear}
              />
              <Waypoint
                bottomOffset={`${props.environmentHeight - 50}px`}
                // onEnter={() => props.toggleStickyHeader(section.code, true)}
                // onLeave={() => props.toggleStickyHeader(section.code)}
              >
                <div>
                  <Section
                    {...cellWidthProps(props)}
                    amountFieldWidth={props.amountFieldWidth}
                    change={props.change}
                    delimiter={props.delimiter}
                    duration={props.duration}
                    enableAutofill={props.enableAutofill}
                    enableFormatting={props.enableFormatting}
                    formName={props.formName}
                    formValues={props.formValues}
                    handleBulkChange={props.handleBulkChange}
                    isLocked={props.isLocked}
                    key={section.code}
                    name={section.name}
                    resources={props.resources}
                    rows={section.children}
                    rowsWithBulkEdit={props.rowsWithBulkEdit}
                    rowsWithOpenResources={props.rowsWithOpenResources}
                    showAllResources={props.showAllResources}
                    startYear={props.startYear}
                    stickyHeader={props.stickyHeader}
                    toggleRowsWithBulkEdit={props.toggleRowsWithBulkEdit}
                    toggleRowsWithOpenResources={
                      props.toggleRowsWithOpenResources
                    }
                    useSingleAmountInsteadOfYears={
                      props.useSingleAmountInsteadOfYears
                    }
                  />
                </div>
              </Waypoint>
            </InlineBlock>
          ))}
        </InlineBlock>
      </div>
      {!props.showContentBeforeSections && props.children}
    </Inner>
  </Outer>
)

Sections.defaultProps = sectionsDefaultProps
Sections.propTypes = sectionsPropTypes

export default pure(Sections)
