import PT from 'prop-types'
import React from 'react'
import Members from './Members'
import {FieldArray} from '../../lib/components/core'
import {formSectionPT} from '../../constants/propTypes/formSections'
import {resourcesPT} from '../../constants/propTypes/resources'

const Family = ({customFormSectionData, errors, resources, helpText}) => {
  return (
    <FieldArray
      component={Members}
      customFormSectionData={customFormSectionData}
      name="familyMemberRequests"
      helpText={helpText}
      resources={resources}
      errors={errors}
    />
  )
}

Family.propTypes = {
  customFormSectionData: PT.shape({
    familySection: formSectionPT(true),
  }).isRequired,
  resources: resourcesPT(true),
}

export default Family
