import React from 'react'
import {connect} from 'react-redux'
import {ErrorBoundary} from '../../lib/components/core'
import {setErrors} from '../../actions/errors'
import {sendMail} from '../../actions/mail'
import {navigateTo} from '../../actions/navigation'
import {getError, getErrorInfo, getHasError} from '../../selectors/errors'
import {makeRoute} from '../../utils/router'
import {INDEX_PATH} from '../../constants/router'

const ErrorBoundaryContainer = props => <ErrorBoundary {...props} />

const mapStateToProps = state => ({
  error: getError(state),
  errorInfo: getErrorInfo(state),
  hasError: getHasError(state),
  onGoBack: makeRoute({path: INDEX_PATH}),
})

const mapDispatchToProps = dispatch => ({
  dispatch,
  setErrors,
  sendMail,
  navigateTo,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorBoundaryContainer)
