import {RSAA, getJSON} from 'redux-api-middleware'
import {normalize} from 'normalizr'
import {
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_FAILURE,
  FETCH_POLICIES_SUCCESS,
  FETCH_POLICIES_REQUEST,
  FETCH_POLICIES_FAILURE,
  FETCH_CURRENCIES_SUCCESS,
  FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENCIES_FAILURE,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEES_FAILURE,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITIES_REQUEST,
  FETCH_ENTITIES_FAILURE,
  FETCH_JOB_TITLES_SUCCESS,
  FETCH_JOB_TITLES_REQUEST,
  FETCH_JOB_TITLES_FAILURE,
  FETCH_HOME_LOCATIONS_SUCCESS,
  FETCH_HOME_LOCATIONS_REQUEST,
  FETCH_HOME_LOCATIONS_FAILURE,
  FETCH_HOST_LOCATIONS_SUCCESS,
  FETCH_HOST_LOCATIONS_REQUEST,
  FETCH_HOST_LOCATIONS_FAILURE,
  FETCH_SUB_ENTITIES_SUCCESS,
  FETCH_SUB_ENTITIES_REQUEST,
  FETCH_SUB_ENTITIES_FAILURE,
  FETCH_FILING_STATUS_REQUEST,
  FETCH_FILING_STATUS_SUCCESS,
  FETCH_FILING_STATUS_FAILURE,
  SET_NEW_EMPLOYEE,
} from '../constants/actionTypes'
import {
  RESOURCES_COUNTRIES_URL,
  RESOURCES_CURRENCIES_URL,
  RESOURCES_EMPLOYEES_URL,
  RESOURCES_ENTITIES_URL,
  RESOURCES_JOB_TITLES_URL,
  RESOURCES_HOME_LOCATIONS_URL,
  RESOURCES_HOST_LOCATIONS_URL,
  RESOURCES_POLICIES_URL,
  RESOURCES_SUB_ENTITIES_URL,
  RESOURCES_FILING_STATUS_URL,
} from '../constants/api'
import {
  countrySchema,
  currencySchema,
  employeeSchema,
  entitySchema,
  jobTitleSchema,
  locationSchema,
  policySchema,
  subEntitySchema,
  filingStatusSchema,
} from '../constants/schemas'
import {
  getCountriesIsFetching,
  getCurrenciesIsFetching,
  getEmployeesIsFetching,
  getEntitiesIsFetching,
  getJobTitlesIsFetching,
  getHomeLocationsIsFetching,
  getHostLocationsIsFetching,
  getPoliciesIsFetching,
  getResources,
  getSubEntitiesIsFetching,
  getHomeLocationsById,
  getHostLocationsById,
  getEmployeesById,
} from '../selectors/resources'
import {
  mapDataList,
  mapLocations,
  mapFilingStatuses,
  mapPolicyList,
} from '../utils/api'
import {getLocationCountryCode} from '../utils/resources'
import {
  LOCATION_TYPE_HOME,
  LOCATION_TYPE_HOST,
  LOCATION_TYPE_HYPO,
  LOCATION_TYPE_HOST_HYPO,
} from '../constants/locationTypes'
import {isNullOrUndefined} from 'util'
import {setDefaultFilingStatusIfNeeded} from './form'
import {CREATE_ASSIGNMENT_FORM} from '../constants/form'

export const fetchCountriesIfNeeded = () => (dispatch, getState) => {
  const state = getState()
  const resources = getResources(state)
  if (
    !(
      getCountriesIsFetching(state) ||
      Object.keys(resources.countries).length > 0
    )
  ) {
    dispatch({
      [RSAA]: {
        endpoint: RESOURCES_COUNTRIES_URL,
        method: 'GET',
        types: [
          FETCH_COUNTRIES_REQUEST,
          {
            type: FETCH_COUNTRIES_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapDataList(json.entries), [countrySchema])
              ),
          },
          FETCH_COUNTRIES_FAILURE,
        ],
      },
    })
  }
}

export const fetchPoliciesIfNeeded = (forceReload = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const resources = getResources(state)
  if (
    !(
      getPoliciesIsFetching(state) || Object.keys(resources.policies).length > 0
    ) ||
    forceReload
  ) {
    dispatch({
      [RSAA]: {
        endpoint: RESOURCES_POLICIES_URL,
        method: 'GET',
        // NOTE: Disabling cache for the policies fetch to mitigate caching issue where
        // the policy field wouldn't show because the cached policies would be an empty array.
        headers: {
          pragma: 'no-cache',
          'cache-control': 'no-cache',
        },
        types: [
          FETCH_POLICIES_REQUEST,
          {
            type: FETCH_POLICIES_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapPolicyList(json.entries), [policySchema])
              ),
          },
          FETCH_POLICIES_FAILURE,
        ],
      },
    })
  }
}

export const fetchCurrenciesIfNeeded = () => (dispatch, getState) => {
  const state = getState()
  const resources = getResources(state)
  if (
    !(
      getCurrenciesIsFetching(state) ||
      Object.keys(resources.currencies).length > 0
    )
  ) {
    dispatch({
      [RSAA]: {
        endpoint: RESOURCES_CURRENCIES_URL,
        method: 'GET',
        types: [
          FETCH_CURRENCIES_REQUEST,
          {
            type: FETCH_CURRENCIES_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapDataList(json.entries), [currencySchema])
              ),
          },
          FETCH_CURRENCIES_FAILURE,
        ],
      },
    })
  }
}

export const fetchEmployeesIfNeeded = (forceReload = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const resources = getResources(state)
  if (
    !(
      getEmployeesIsFetching(state) ||
      Object.keys(resources.employees).length > 0
    ) ||
    forceReload
  ) {
    dispatch({
      [RSAA]: {
        endpoint: RESOURCES_EMPLOYEES_URL,
        method: 'GET',
        types: [
          FETCH_EMPLOYEES_REQUEST,
          {
            type: FETCH_EMPLOYEES_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json => normalize(json, [employeeSchema])),
          },
          FETCH_EMPLOYEES_FAILURE,
        ],
      },
    })
  }
}

export const fetchEntitiesIfNeeded = (forceReload = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const resources = getResources(state)
  if (
    !(
      getEntitiesIsFetching(state) || Object.keys(resources.entities).length > 0
    ) ||
    forceReload
  ) {
    dispatch({
      [RSAA]: {
        endpoint: RESOURCES_ENTITIES_URL,
        method: 'GET',
        types: [
          FETCH_ENTITIES_REQUEST,
          {
            type: FETCH_ENTITIES_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapDataList(json.entries), [entitySchema])
              ),
          },
          FETCH_ENTITIES_FAILURE,
        ],
      },
    })
  }
}

export const fetchJobTitlesIfNeeded = (forceReload = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const resources = getResources(state)
  if (
    !(
      getJobTitlesIsFetching(state) ||
      Object.keys(resources.jobTitles).length > 0
    ) ||
    forceReload
  ) {
    dispatch({
      [RSAA]: {
        endpoint: RESOURCES_JOB_TITLES_URL,
        method: 'GET',
        types: [
          FETCH_JOB_TITLES_REQUEST,
          {
            type: FETCH_JOB_TITLES_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapDataList(json.entries), [jobTitleSchema])
              ),
          },
          FETCH_JOB_TITLES_FAILURE,
        ],
      },
    })
  }
}

export const fetchHomeLocationsIfNeeded = (forceReload = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const resources = getResources(state)
  if (
    !(
      getHomeLocationsIsFetching(state) ||
      Object.keys(resources.homeLocations).length > 0
    ) ||
    forceReload
  ) {
    dispatch({
      [RSAA]: {
        endpoint: RESOURCES_HOME_LOCATIONS_URL,
        method: 'GET',
        types: [
          FETCH_HOME_LOCATIONS_REQUEST,
          {
            type: FETCH_HOME_LOCATIONS_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapLocations(json.entries), [locationSchema])
              ),
          },
          FETCH_HOME_LOCATIONS_FAILURE,
        ],
      },
    })
  }
}

export const fetchHostLocationsIfNeeded = (forceReload = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const resources = getResources(state)
  if (
    !(
      getHostLocationsIsFetching(state) ||
      Object.keys(resources.hostLocations).length > 0
    ) ||
    forceReload
  ) {
    dispatch({
      [RSAA]: {
        endpoint: RESOURCES_HOST_LOCATIONS_URL,
        method: 'GET',
        types: [
          FETCH_HOST_LOCATIONS_REQUEST,
          {
            type: FETCH_HOST_LOCATIONS_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapLocations(json.entries), [locationSchema])
              ),
          },
          FETCH_HOST_LOCATIONS_FAILURE,
        ],
      },
    })
  }
}

export const fetchSubEntitiesIfNeeded = (forceReload = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const resources = getResources(state)
  if (
    !(
      getSubEntitiesIsFetching(state) ||
      Object.keys(resources.subEntities).length > 0
    ) ||
    forceReload
  ) {
    dispatch({
      [RSAA]: {
        endpoint: RESOURCES_SUB_ENTITIES_URL,
        method: 'GET',
        types: [
          FETCH_SUB_ENTITIES_REQUEST,
          {
            type: FETCH_SUB_ENTITIES_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapDataList(json.entries), [subEntitySchema])
              ),
          },
          FETCH_SUB_ENTITIES_FAILURE,
        ],
      },
    })
  }
}

const fetchFilingStatusIfNeeded = (location, locationType, form) => (
  dispatch,
  getState
) => {
  const state = getState()
  let locations = []

  switch (locationType) {
    case LOCATION_TYPE_HOME:
      locations = getHomeLocationsById(state)
      break
    case LOCATION_TYPE_HOST:
      locations = getHostLocationsById(state)
      break
    case LOCATION_TYPE_HYPO:
      locations = getHomeLocationsById(state)
      break
    case LOCATION_TYPE_HOST_HYPO:
      locations = getHostLocationsById(state)
      break
    default:
      locations = []
  }
  const countryCode = getLocationCountryCode(locations, location)
  Promise.all([
    dispatch({
      [RSAA]: {
        endpoint: RESOURCES_FILING_STATUS_URL(countryCode),
        method: 'GET',
        types: [
          {
            type: FETCH_FILING_STATUS_REQUEST(locationType),
            meta: {locationType: locationType},
          },
          {
            type: FETCH_FILING_STATUS_SUCCESS(locationType),
            meta: {locationType: locationType},
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(mapFilingStatuses(json.children || []), [
                  filingStatusSchema,
                ])
              ),
          },
          {
            type: FETCH_FILING_STATUS_FAILURE(locationType),
            meta: {locationType: locationType},
          },
        ],
      },
    }),
  ]).then(() => {
    dispatch(setDefaultFilingStatusIfNeeded(locationType, form))
  })
}

export const fetchFilingStatusOnLocationChange = (
  location,
  locationType,
  form = CREATE_ASSIGNMENT_FORM
) => dispatch =>
  dispatch(fetchFilingStatusIfNeeded(location, locationType, form))

export const setTemporaryEmployee = employee => {
  return {
    type: SET_NEW_EMPLOYEE,
    meta: {
      employee: {[employee.employeeId]: employee},
    },
  }
}

export const updateEmployeesIfNeeded = (previousValues, currentValues) => (
  dispatch,
  getState
) => {
  const state = getState()
  const selectedEmployee = {
    employeeId: currentValues.employeeId.value,
    nameAndId: currentValues.employeeId.value,
    firstName: '',
    lastName: '',
    fullName: '',
    companyId: undefined,
    homeLocation: undefined,
  }
  const employee = getEmployeesById(state)[currentValues.employeeId.value]
  const isNewEmployee = isNullOrUndefined(employee)
  const isCurrentValueUndefined = isNullOrUndefined(
    currentValues.employeeId.value
  )
  if (isNewEmployee && !isCurrentValueUndefined)
    dispatch(setTemporaryEmployee(selectedEmployee))
}

export const fetchResourcesIfNeeded = (forceReload = false) => dispatch => {
  dispatch(fetchCountriesIfNeeded())
  dispatch(fetchCurrenciesIfNeeded())
  dispatch(fetchEmployeesIfNeeded(forceReload))
  dispatch(fetchEntitiesIfNeeded(forceReload))
  dispatch(fetchJobTitlesIfNeeded(forceReload))
  dispatch(fetchHomeLocationsIfNeeded(forceReload))
  dispatch(fetchHostLocationsIfNeeded(forceReload))
  dispatch(fetchPoliciesIfNeeded(forceReload))
  dispatch(fetchSubEntitiesIfNeeded(forceReload))
}
