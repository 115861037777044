import {combineReducers} from 'redux'
import {
  FETCH_CURRENCIES_FAILURE,
  FETCH_CURRENCIES_REQUEST,
  FETCH_CURRENCIES_SUCCESS,
} from '../../../constants/actionTypes'

const currenciesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CURRENCIES_SUCCESS:
      return action.payload.entities.currencies || state
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_CURRENCIES_REQUEST:
      return true
    case FETCH_CURRENCIES_SUCCESS:
    case FETCH_CURRENCIES_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_CURRENCIES_FAILURE:
      return action.payload.message
    case FETCH_CURRENCIES_REQUEST:
    case FETCH_CURRENCIES_SUCCESS:
      return null
    default:
      return state
  }
}

const currencies = combineReducers({
  byId: currenciesById,
  isFetching,
  errorMessage,
})

export default currencies
