export const ADD_APPROVER_FORM = 'ADD_APPROVER_FORM'
export const CREATE_ASSIGNMENT_FORM = 'CREATE_ASSIGNMENT_FORM'
export const DELETE_ASSIGNMENT_CONFIRMATION_FORM =
  'DELETE_ASSIGNMENT_CONFIRMATION_FORM'
export const REJECT_ASSIGNMENT_CONFIRMATION_FORM =
  'REJECT_ASSIGNMENT_CONFIRMATION_FORM'
export const EDIT_ASSIGNMENT_FORM = 'EDIT_ASSIGNMENT_FORM'
export const REVIEW_ASSIGNMENT_FORM = 'REVIEW_ASSIGNMENT_FORM'
export const UPDATE_ASSIGNMENT_OPTIONS_FORM = 'UPDATE_ASSIGNMENT_OPTIONS_FORM'
export const CURRENCY_FIELD = 'Money'
export const SWITCH_COMPANY_FORM = 'SWITCH_COMPANY_FORM'
export const UPLOAD_FORM = 'UPLOAD_FORM'
