export default type => {
  const all = type === 'ALL'
  const ayo = type === 'AYO'
  const fyo = type === 'FYO'
  const lyo = type === 'LYO'
  const ayona = type === 'AYONA'

  return {
    first: all || fyo || ayo || ayona,
    last: all || ayo || lyo || ayona,
    remaining: all || ayo || ayona,
  }
}
