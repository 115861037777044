const prepareFields = section => {
  const {children} = section

  if (!children || !children.length) {
    return {}
  }

  const result = {}

  for (let i = 0; i < children.length; i += 1) {
    const {code, name} = children[i]
    result[code] = name
  }

  return result
}

export default sections => {
  let result = {}

  for (let i = 0; i < sections.length; i += 1) {
    const sectionFields = prepareFields(sections[i])
    result = {...result, ...sectionFields}
  }

  return result
}
