import {headerDropdownDefaultProps, headerDropdownPropTypes} from './propTypes'
import React from 'react'
import styled from 'styled-components'
import {Field, Form} from '../../arena-plugin-form'
import {FOR_DESKTOP, SMALLER, WHITE, LIGHTEST_GRAY} from 'arena-config'
import {Flex} from 'arena-components'
import {SWITCH_COMPANY_FORM} from '../../../../constants/form'

const Fields = styled(Flex)`
  ${FOR_DESKTOP} {
  }
`

const FieldWrap = styled.div`
  width: 100%;
  ${FOR_DESKTOP} {
    &:not(:last-of-type) {
      margin-right: ${SMALLER}px;
    }
  }
`

const makeField = props => (
  <FieldWrap>
    <Field {...props} type="select" />
  </FieldWrap>
)

const HeaderDropdown = ({dropdownOptions, dropdownDefaultValue}) => {
  return (
    <Form name={SWITCH_COMPANY_FORM} initialValues={dropdownDefaultValue}>
      <Fields>
        {makeField({
          isClearable: false,
          name: 'company',
          options: dropdownOptions,
          placeholder: 'Select Company',
          styleProps: {
            backgroundColor: 'transparent',
            backgroundColorHover: 'rgba(0,0,0,.1)',
            backgroundColorActive: 'rgba(0,0,0,.1)',

            borderWidth: '0px',

            menuItemBackgroundColor: WHITE,
            menuItemBackgroundColorHover: LIGHTEST_GRAY,

            placeholderTextColor: 'rgba(255, 255, 255, 0.8)',
            placeholderTextColorHover: WHITE,
            placeholderTextColorActive: WHITE,

            textColor: 'rgba(255, 255, 255, 0.8)',
            textColorHover: WHITE,
            textColorActive: WHITE,
          },
        })}
      </Fields>
    </Form>
  )
}

HeaderDropdown.defaultProps = headerDropdownDefaultProps
HeaderDropdown.propTypes = headerDropdownPropTypes

export default HeaderDropdown
