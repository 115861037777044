import React from 'react'
import pure from 'recompose/pure'
import styled from 'styled-components'
import {Flex, Relative} from 'arena-components'
import * as c from 'arena-config'
import HeaderLinks from '../HeaderLinks'
import HeaderDropdown from '../HeaderDropdown'
import HeaderTypeahead from '../HeaderTypeahead'
import Logo from '../Logo'
import WebsiteName from '../WebsiteName'
import User from '../User'
import {headerTopDefaultProps, headerTopPropTypes} from './propTypes'
import MenuIcon from '../MenuIcon'

const Wrap = styled(Flex)`
  width: 100%;
`
const HeaderTopLinksWrapper = styled.div`
  position: fixed;
  background: ${props => props.backgroundColor};
  bottom: 0;
  left: 0;
  width: 100%;
  ${c.FOR_DESKTOP} {
    position: relative;
    padding: 0px 32px;
    gap: 64px;
    left: 235px;
  }
`

const TypeaheadWrap = styled.div`
  width: calc(100% - 81px * 2);
  position: absolute;
  top: 0;
  left: 190px;
  transition: transform;
  background: ${props => props.backgroundColor};
  z-index: 4;
  transform: translateX(-100px) !important;

  ${c.FOR_DESKTOP} {
    width: auto;
    position: relative;
    top: auto;
    left: auto;
    transform: translateX(0) translateY(0) !important;
    transition: none;
  }
`

const DropDownWrap = styled.div`
  width: 70%;
  position: absolute;
  top: 98px;
  left: 30%;
  background: ${props => props.backgroundColor};
  display: ${props => (props.isMobileMenuOpen ? 'block' : 'none')};
  z-index: 3;
  ${c.FOR_DESKTOP} {
    width: 150px;
    position: relative;
    top: auto;
    left: auto;
    transform: translateY(0);
    display: block;
    transition: none;
    font-size: 16px;
    z-index: 3;
  }
`

const UserWrap = styled.div`
  position: absolute;

  top: 49px;
  z-index: 3;
  width: 70%;
  left: 30%;
  background: ${props => props.backgroundColor};
  display: ${props => (props.isMobileMenuOpen ? 'block' : 'none')};
  ${c.FOR_DESKTOP} {
    position: relative;
    left: auto;
    top: 0;
    width: 120px;
    display: block;
  }
`

const HeaderTop = props => {
  return (
    <Relative style={{height: '100%'}}>
      {props.logoSrc && (
        <Logo
          defaultAction={props.defaultAction}
          logoSrc={props.logoSrc}
          onLogoClick={props.onLogoClick}
        />
      )}
      <WebsiteName />
      <Wrap>
        <div style={{flexGrow: 1}}>
          {props.isAuthenticated && (
            <HeaderTopLinksWrapper backgroundColor={props.backgroundColor}>
              <HeaderLinks
                activeTab={props.activeTab}
                align={props.align}
                currentRoute={props.currentRoute}
                defaultAction={props.defaultAction}
                height={props.height}
                links={props.links}
              />
            </HeaderTopLinksWrapper>
          )}
        </div>
        {props.isAuthenticated && (
          <TypeaheadWrap backgroundColor={props.backgroundColor}>
            <HeaderTypeahead
              search={props.search}
              typeahead={props.typeahead}
              searchResults={props.searchResults}
              searchResultsFetching={props.searchResultsFetching}
              nextUrl={props.nextUrl}
              loadMoreResults={props.loadMoreResults}
              loadMoreResultsLoading={props.loadMoreResultsLoading}
            ></HeaderTypeahead>
          </TypeaheadWrap>
        )}
        {props.dropdownOptions.length > 1 && props.isAuthenticated && (
          <DropDownWrap
            backgroundColor={props.backgroundColor}
            isMobileMenuOpen={props.isMobileMenuOpen}
          >
            <HeaderDropdown
              dropdownOptions={props.dropdownOptions}
              dropdownDefaultValue={props.dropdownDefaultValue}
            />
          </DropDownWrap>
        )}
        <UserWrap
          isMenuIconVisible={props.isAuthenticated && props.shouldShowMenu}
          backgroundColor={props.backgroundColor}
          isMobileMenuOpen={props.isMobileMenuOpen}
        >
          <User
            defaultAction={props.defaultAction}
            disableAuthentication={props.disableAuthentication}
            height={props.height}
            login={props.login}
            logout={props.logout}
            sendReport={props.sendReport}
            user={props.user}
          />
        </UserWrap>
        {props.isAuthenticated &&
          (props.menuSrc || props.menuCloseSrc) &&
          props.shouldShowMenu && (
            <MenuIcon
              menuSrc={props.menuSrc}
              menuCloseSrc={props.menuCloseSrc}
              isMobileMenuOpen={props.isMobileMenuOpen}
              onMobileMenuIconClick={props.onMobileMenuIconClick}
            />
          )}
      </Wrap>
    </Relative>
  )
}

HeaderTop.defaultProps = headerTopDefaultProps
HeaderTop.propTypes = headerTopPropTypes

export default pure(HeaderTop)
