import PT from 'prop-types'

export const cellWidthProps = props => ({
  amountFieldWidth: props.amountFieldWidth,
  resourceFieldWidth: props.resourceFieldWidth,
  titleWidth: props.titleWidth,
})

export const rowPropType = PT.shape({
  code: PT.string.isRequired,
  name: PT.string.isRequired,
})

export const rowsPropType = PT.arrayOf(rowPropType)

export const sectionPropType = PT.shape({
  children: rowsPropType,
  code: PT.string.isRequired,
  name: PT.string.isRequired,
})

export const sectionsPropType = PT.arrayOf(sectionPropType)
