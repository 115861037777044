import {isRequired, stringPT} from 'arena-prop-types'
import PT from 'prop-types'
import {formFieldsPT} from './formFields'

const {arrayOf, shape} = PT

export const taxSectionProps = {
  taxFields: formFieldsPT(true),
  code: stringPT(true),
  name: stringPT(true),
}

export const taxSectionPT = is => isRequired(shape(taxSectionProps), is)

export const taxSectionsPT = is => isRequired(arrayOf(taxSectionPT), is)
