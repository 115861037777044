import {ON_ERROR, CLEAR_ERRORS} from '../constants/actionTypes'

const errors = (error, errorInfo, hasError) => ({
  type: ON_ERROR,
  error,
  errorInfo,
  hasError,
})
const clearErrors = () => ({
  type: CLEAR_ERRORS,
  error: '',
  errorInfo: {},
  hasError: false,
})
export const setErrors = (error, errorInfo, hasError) => dispatch => {
  dispatch(errors(error, errorInfo, hasError))
}

export const clearErrorsOnNavigate = () => dispatch => {
  dispatch(clearErrors())
}
