import PT from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {ANIMATION_SPEED_MEDIUM, LIGHTEST_GRAY, WHITE} from 'arena-config'

const defaultProps = {
  disabled: false,
  onClick: () => console.warn('Switch component is missing an onClick handler'),
}

const propTypes = {
  disabled: PT.bool,
  on: PT.bool.isRequired,
  onClick: PT.func,
}

const SwitchWrapper = styled.div`
  background: ${props => (props.on ? 'teal' : '#8F8F8F')};
  border-radius: 12px;
  border: border: 1px solid ${LIGHTEST_GRAY};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-block;
  height: 26px;
  width: 50px;
  /* FIXME: The next two styling elements need to move out as they're specific to usage. */
  float: right;
  margin-top: 3px;
`

const SwitchToggle = styled.div`
  background: ${WHITE};
  border-radius: 12px;
  border: 1px solid ${LIGHTEST_GRAY};
  margin: 1.01px 1px;
  height: 24px;
  left: ${props => (props.on ? 23 : 0)}px;
  position: relative;
  transition: left ${ANIMATION_SPEED_MEDIUM} ease-in-out;
  width: 25px;
`

const Switch = ({on, onClick, disabled}) => (
  <SwitchWrapper
    disabled={disabled}
    on={on}
    onClick={event => (disabled ? {} : onClick(event))}
  >
    <SwitchToggle on={on} />
  </SwitchWrapper>
)

Switch.propTypes = propTypes
Switch.defaultProps = defaultProps

export default Switch
