import {combineReducers} from 'redux'
import {
  FETCH_ASSIGNMENT_AUDIT_LOGS_FAILURE,
  FETCH_ASSIGNMENT_AUDIT_LOGS_REQUEST,
  FETCH_ASSIGNMENT_AUDIT_LOGS_SUCCESS,
  FETCH_ASSIGNMENT_SUCCESS,
} from '../../constants/actionTypes'

const assignmentAuditLogsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ASSIGNMENT_AUDIT_LOGS_SUCCESS:
      return action.payload.entities.assignmentAuditLogs || {}
    case FETCH_ASSIGNMENT_SUCCESS:
      return {}
    default:
      return state
  }
}

const allAuditLogs = (state = [], action) => {
  switch (action.type) {
    case FETCH_ASSIGNMENT_AUDIT_LOGS_SUCCESS:
      return action.payload.result || []
    case FETCH_ASSIGNMENT_SUCCESS:
      return []
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_ASSIGNMENT_AUDIT_LOGS_REQUEST:
      return true
    case FETCH_ASSIGNMENT_AUDIT_LOGS_SUCCESS:
    case FETCH_ASSIGNMENT_AUDIT_LOGS_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_ASSIGNMENT_AUDIT_LOGS_FAILURE:
      return action.payload.message
    case FETCH_ASSIGNMENT_AUDIT_LOGS_REQUEST:
    case FETCH_ASSIGNMENT_AUDIT_LOGS_SUCCESS:
      return null
    default:
      return state
  }
}

const assignmentAuditLogs = combineReducers({
  byId: assignmentAuditLogsById,
  ids: allAuditLogs,
  errorMessage,
  isFetching,
})

export default assignmentAuditLogs
