import React from 'react'
import pure from 'recompose/pure'
import styled from 'styled-components'
import DataCell from '../DataCell'
import DataTable from '../DataTable/index'
import {resourcesLabelDefaultProps, resourcesLabelPropTypes} from './propTypes'

const Name = styled.div`
  font-size: 0.875em;
`

const ResourcesLabel = props => (
  <DataTable>
    {Object.keys(props.resources).map(key => (
      <DataCell key={key} width={props.width}>
        <Name>{props.resources[key].name}</Name>
      </DataCell>
    ))}
  </DataTable>
)

ResourcesLabel.defaultProps = resourcesLabelDefaultProps
ResourcesLabel.propTypes = resourcesLabelPropTypes

export default pure(ResourcesLabel)
