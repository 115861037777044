import {Absolute, Icon} from 'arena-components'
import * as c from 'arena-config'
import {Button} from '../../../arena-plugin-form'
import React from 'react'
import pure from 'recompose/pure'
import styled from 'styled-components'
import {expandButtonDefaultProps, expandButtonPropTypes} from './propTypes'

const makeProps = ({opacity, shouldRotate, ...rest}) => <div {...rest} />

const ButtonWrap = styled(makeProps)`
  display: inline-block;
  height: 1em;
  opacity: ${props => props.opacity};
  transform: ${props => (props.shouldRotate ? undefined : 'rotate(-180deg)')};
  transition: transform 0.2s;
  transition-timing-function: linear;
  width: 1em;
`

const ExpandButton = props => (
  <Absolute
    style={{
      fontSize: '2.5em',
      height: '1em',
      right: 0,
      width: '1em',
    }}
    vertical
  >
    <ButtonWrap
      opacity={props.isActive || props.isHovering ? 1 : 0}
      shouldRotate={props.isActive}
    >
      <Button
        onClick={props.onClick}
        preventDefault
        round
        style={{
          height: '1em',
          position: 'relative',
          verticalAlign: 'top',
          width: '1em',
        }}
        styleProps={{
          // backgroundColorActive: 'transparent',
          color: props.isBulkEditingRow ? c.WHITE : c.DARK_GRAY,
          colorActive: props.isBulkEditingRow ? c.WHITE : c.DARK_GRAY,
          showBorders: props.isActive,
          textColorActive: props.isBulkEditingRow ? c.BLUE : c.WHITE,
        }}
      >
        <Absolute
          horizontal
          style={{
            fontSize: '.5em',
            lineHeight: 1,
          }}
          vertical
        >
          <Icon thick type="angle" />
        </Absolute>
      </Button>
    </ButtonWrap>
  </Absolute>
)

ExpandButton.defaultProps = expandButtonDefaultProps
ExpandButton.propTypes = expandButtonPropTypes

export default pure(ExpandButton)
