import {combineReducers} from 'redux'
import {
  FETCH_ACCOUNT_SECTIONS_FAILURE,
  FETCH_ACCOUNT_SECTIONS_REQUEST,
  FETCH_ACCOUNT_SECTIONS_SUCCESS,
} from '../../../constants/actionTypes'

const accountSectionsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SECTIONS_SUCCESS:
      return action.payload.entities.accountSections || state
    default:
      return state
  }
}

const allAccountSections = (state = [], action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SECTIONS_SUCCESS:
      return action.payload.result || state
    default:
      return state
  }
}

// NOTE: This is the plan type code used last to fetch the filtered account sections.
const lastFilter = (state = null, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SECTIONS_SUCCESS:
      return action.meta.planTypeCode || null
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SECTIONS_REQUEST:
      return true
    case FETCH_ACCOUNT_SECTIONS_SUCCESS:
    case FETCH_ACCOUNT_SECTIONS_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SECTIONS_FAILURE:
      return action.payload.message
    case FETCH_ACCOUNT_SECTIONS_REQUEST:
    case FETCH_ACCOUNT_SECTIONS_SUCCESS:
      return null
    default:
      return state
  }
}

const accountSections = combineReducers({
  byId: accountSectionsById,
  ids: allAccountSections,
  lastFilter,
  isFetching,
  errorMessage,
})

export default accountSections
