import {numberOrStringPT} from 'arena-prop-types'
import PT from 'prop-types'
import {commonPropTypes} from '../../propTypes'

export const dataCellDefaultProps = {
  width: undefined,
}

export const dataCellPropTypes = {
  ...commonPropTypes,
  children: PT.node.isRequired,
  width: numberOrStringPT(),
}
