import PT from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {FONT_WEIGHT_3, LARGE, SMALL} from 'arena-config'
import {Icon, Tooltip, Button} from '../../lib/components/core'

const StyledSectionName = styled.div`
  font-weight: ${FONT_WEIGHT_3};
  letter-spacing: 1px;
  padding: ${SMALL}px ${LARGE}px;
  text-transform: uppercase;
`
const iconProps = {
  className: 'white',
  inlineSVG: true,
}
const buttonProps = {
  className: 'round solid',
  style: {
    width: '20px',
    height: '20px',
    fontSize: '14px',
    marginTop: '-4px',
    marginLeft: '4px',
  },
}

const SectionName = ({children, helpText}) => (
  <StyledSectionName>
    <span>
      {children}
      {helpText && (
        <Tooltip title={helpText} placement="right">
          <span>
            <Button {...buttonProps} preventDefault>
              <Icon {...iconProps} type="information" />
            </Button>
          </span>
        </Tooltip>
      )}
    </span>
  </StyledSectionName>
)

SectionName.propTypes = {
  children: PT.oneOfType([PT.node, PT.string]).isRequired,
}

export default SectionName
