import PT from 'prop-types'
import React from 'react'

const Router = ({router, routes}) => {
  const {path} = router.route
  if (path in routes) {
    const Component = routes[path]
    return <Component />
  }

  return null
}

Router.propTypes = {
  router: PT.shape({
    route: PT.shape({
      path: PT.string,
    }),
  }).isRequired,
  routes: PT.shape({}).isRequired,
}

export default Router
