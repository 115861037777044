import React from 'react'
import {alertsDefaultProps, alertsPropTypes} from './propTypes'

const Alerts = props => (
  <div>
    {props.alerts.map(alert => (
      <div key={alert.id}>
        <strong>{alert.message}</strong>
      </div>
    ))}
  </div>
)

Alerts.defaultProps = alertsDefaultProps
Alerts.propTypes = alertsPropTypes

export default Alerts
