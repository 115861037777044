import {combineReducers} from 'redux'
import {
  FETCH_FAMILY_FORM_FIELDS_SUCCESS,
  FETCH_FORM_SECTIONS_SUCCESS,
} from '../../../constants/actionTypes'

const formFieldsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FORM_SECTIONS_SUCCESS:
      return {...state, ...action.payload.entities.formFields}
    case FETCH_FAMILY_FORM_FIELDS_SUCCESS:
      return {...state, ...action.payload.entities.formFields}
    default:
      return state
  }
}

const familyCustomFormFieldsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FAMILY_FORM_FIELDS_SUCCESS:
      return action.payload.entities.formFields || state
    default:
      return state
  }
}

const formFields = combineReducers({
  byId: formFieldsById,
  familyCustomFormFieldsById,
})

export default formFields
