import {combineReducers} from 'redux'
import {
  FETCH_FAMILY_FORM_FIELDS_FAILURE,
  FETCH_FAMILY_FORM_FIELDS_REQUEST,
  FETCH_FAMILY_FORM_FIELDS_SUCCESS,
  FETCH_FORM_SECTIONS_FAILURE,
  FETCH_FORM_SECTIONS_REQUEST,
  FETCH_FORM_SECTIONS_SUCCESS,
  UPDATE_PREVIOUS_FORM_TAB_ERRORS,
} from '../../../constants/actionTypes'

const formSectionsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FORM_SECTIONS_SUCCESS:
      return action.payload.entities.formSections
    default:
      return state
  }
}

const allFormSections = (state = [], action) => {
  switch (action.type) {
    case FETCH_FORM_SECTIONS_SUCCESS:
      return action.payload.result || state
    default:
      return state
  }
}

const allCustomFamilySectionFormFields = (state = [], action) => {
  switch (action.type) {
    case FETCH_FAMILY_FORM_FIELDS_SUCCESS:
      return action.payload.result || state
    default:
      return state
  }
}

const formTabs = (state = {}, action) => {
  switch (action.type) {
    case FETCH_FORM_SECTIONS_SUCCESS: {
      const tabs = {}
      action.payload.result.map(section => {
        tabs[section] = {errorCount: 0, errors: {}, hasErrors: false}
        return tabs
      })

      return tabs
    }
    case UPDATE_PREVIOUS_FORM_TAB_ERRORS: {
      const {errors, formSectionId, previousFormSectionId} = action
      const errorCount = errors ? Object.keys(errors).length : 0
      const hasErrors = errorCount > 0

      return {
        ...state,
        [formSectionId]: {errorCount: 0, errors: {}, hasErrors: false},
        [previousFormSectionId]: {errorCount, errors, hasErrors},
      }
    }
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_FORM_SECTIONS_REQUEST:
      return true
    case FETCH_FORM_SECTIONS_SUCCESS:
    case FETCH_FORM_SECTIONS_FAILURE:
      return false
    default:
      return state
  }
}

const familyFormFieldsIsFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_FAMILY_FORM_FIELDS_REQUEST:
      return true
    case FETCH_FAMILY_FORM_FIELDS_SUCCESS:
    case FETCH_FAMILY_FORM_FIELDS_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_FORM_SECTIONS_FAILURE:
    case FETCH_FAMILY_FORM_FIELDS_FAILURE:
      return action.payload.message
    case FETCH_FORM_SECTIONS_REQUEST:
    case FETCH_FORM_SECTIONS_SUCCESS:
    case FETCH_FAMILY_FORM_FIELDS_REQUEST:
    case FETCH_FAMILY_FORM_FIELDS_SUCCESS:
      return null
    default:
      return state
  }
}

const formSections = combineReducers({
  byId: formSectionsById,
  ids: allFormSections,
  customFamilySectionFormFieldIds: allCustomFamilySectionFormFields,
  isFetching,
  formTabs,
  familyFormFieldsIsFetching,
  errorMessage,
})

export default formSections
