import React, {useEffect, useState} from 'react'
import {Button, Icon, Tooltip} from '../../lib/components/core'
import {setUserFavorite, removeUserFavorite} from '../../actions/users'
import {useDispatch} from 'react-redux'

const FavoriteToggle = ({id, favorites, buttonProps, ...props}) => {
  const [isFavorite, setFavorite] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    var index = favorites.findIndex(favorite => favorite.positionId === id)
    setFavorite(index > -1)
  })

  const iconFavorited = 'AssignmentCard-shortcut-icon rose'
  const iconNoneFavorite = 'AssignmentCard-shortcut-icon white'

  const handleSetFavorite = event => {
    isFavorite
      ? dispatch(removeUserFavorite(id))
      : dispatch(setUserFavorite(id))
    setFavorite(!isFavorite)
  }

  return (
    <Tooltip title="Favorite">
      <span>
        <Button
          {...buttonProps}
          onClick={event => handleSetFavorite(event)}
          style={{padding: '1px 0 0 0'}}
        >
          <Icon
            className={isFavorite ? iconFavorited : iconNoneFavorite}
            inlineSVG={true}
            type="fas_heart"
          ></Icon>
        </Button>
      </span>
    </Tooltip>
  )
}

export default FavoriteToggle
