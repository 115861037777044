import * as c from 'arena-config'
import pure from 'recompose/pure'
import styled from 'styled-components'
import {dataLabelDefaultProps, dataLabelPropTypes} from './propTypes'

const DataLabel = styled.div`
  ${c.fontSmoothing()};
  color: ${c.LIGHT_GRAY};
  font-size: ${props => (props.isHeader ? '.875em' : undefined)};
  letter-spacing: ${props => (props.isHeader ? '.05em' : undefined)};
  text-transform: uppercase;
`

DataLabel.defaultProps = dataLabelDefaultProps
DataLabel.propTypes = dataLabelPropTypes

export default pure(DataLabel)
