import React from 'react'
import DefaultSelect from 'react-select'
import Async from 'react-select/async'
import Creatable from 'react-select/creatable'
import AsyncCreatable from 'react-select/async-creatable'
import styled from 'styled-components'

const getStyleFromProps = (styleProps, key) => {
  return styleProps[key] ? styleProps[key] : null
}

const makeSelectProps = props => {
  const styleProps = {
    container: (styles, {isDisabled}) => {
      return {
        pointerEvents: 'auto',
        cursor: isDisabled
          ? 'not-allowed'
          : getStyleFromProps(props.styleProps, 'cursor'),
      }
    },
    control: (styles, {isDisabled}) => {
      return {
        ...styles,
        backgroundColor: getStyleFromProps(props.styleProps, 'backgroundColor'),
        borderColor: props.hasValue
          ? getStyleFromProps(props.styleProps, 'borderColorActive')
          : getStyleFromProps(props.styleProps, 'borderColor'),
        borderRadius: getStyleFromProps(props.styleProps, 'borderRadius'),
        borderStyle: getStyleFromProps(props.styleProps, 'borderStyle'),
        borderWidth: getStyleFromProps(props.styleProps, 'borderWidth'),
        cursor: isDisabled
          ? 'not-allowed'
          : getStyleFromProps(props.styleProps, 'cursor'),
        boxShadow: getStyleFromProps(props.styleProps, 'boxShadow'),
        fontSize: getStyleFromProps(props.styleProps, 'fontSize'),
        fontWeight: getStyleFromProps(props.styleProps, 'fontWeight'),
        ':hover': {
          borderColor: getStyleFromProps(props.styleProps, 'borderColorHover'),
          backgroundColor: getStyleFromProps(
            props.styleProps,
            'backgroundColorHover'
          ),
        },
        ':focus': {
          backgroundColor: getStyleFromProps(
            props.styleProps,
            'backgroundColorActive'
          ),
          borderColor: getStyleFromProps(props.styleProps, 'borderColorActive'),
        },
      }
    },
    input: styles => {
      return {
        ...styles,
        color: getStyleFromProps(props.styleProps, 'textColor'),
      }
    },
    indicatorsContainer: styles => {
      return {
        ...styles,
        display: 'none',
      }
    },
    menu: styles => {
      return {
        ...styles,
        maxHeight: getStyleFromProps(props.styleProps, 'menuMaxHeight'),
        zIndex: getStyleFromProps(props.styleProps, 'menuZIndex'),
        borderRadius: getStyleFromProps(props.styleProps, 'borderRadius'),
        marginTop: 0,
        marginBottom: 0,
        fontSize: getStyleFromProps(props.styleProps, 'fontSize'),
        cursor: 'pointer',
      }
    },
    menuList: styles => {
      return {
        ...styles,
        maxHeight: getStyleFromProps(props.styleProps, 'menuMaxHeight'),
        paddingTop: 0,
        paddingBottom: 0,
      }
    },
    option: (styles, {isDisabled, isFocused, isSelected}) => {
      return {
        ...styles,
        backgroundColor: isSelected
          ? getStyleFromProps(props.styleProps, 'menuItemBackgroundColorActive')
          : isFocused
          ? getStyleFromProps(props.styleProps, 'menuItemBackgroundColorHover')
          : getStyleFromProps(props.styleProps, 'menuItemBackgroundColor'),
        color: isSelected ? '#fff' : '#4c4e51',
        cursor: 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: isSelected
            ? getStyleFromProps(
                props.styleProps,
                'menuItemBackgroundColorActive'
              )
            : getStyleFromProps(
                props.styleProps,
                'menuItemBackgroundColorHover'
              ),
        },
      }
    },
    placeholder: styles => {
      return {
        ...styles,
        color: getStyleFromProps(props.styleProps, 'placeholderTextColor'),
        fontStyle: 'italic',
        fontWeight: getStyleFromProps(
          props.styleProps,
          'placeholderFontWeight'
        ),
        opacity: 0.75,
        width: '100%',
        height: '100%',
        lineHeight: 2,
        ':hover': {
          opacity: 1,
        },
        ':focus': {
          opacity: 1,
        },
      }
    },
    singleValue: styles => {
      return {
        ...styles,
        fontSize: getStyleFromProps(props.styleProps, 'fontSize'),
        color: getStyleFromProps(props.styleProps, 'textColor'),
        fontWeight: 'normal',
        ':hover': {
          color: getStyleFromProps(props.styleProps, 'textColorHover'),
        },
        ':active': {
          color: getStyleFromProps(props.styleProps, 'textColorActive'),
        },
      }
    },
    valueContainer: styles => {
      return {
        ...styles,
        border: 0,
      }
    },
  }

  if (!props.creatable && !props.loadOptions) {
    return (
      <DefaultSelect
        {...props}
        styles={styleProps}
        className="react-select"
        classNamePrefix="react-select"
      />
    )
  }

  if (props.creatable && props.loadOptions) {
    return (
      <AsyncCreatable
        {...props}
        styles={styleProps}
        className="react-select"
        classNamePrefix="react-select"
      />
    )
  }

  if (props.loadOptions) {
    return (
      <Async
        {...props}
        styles={styleProps}
        className="react-select"
        classNamePrefix="react-select"
      />
    )
  }

  return (
    <Creatable
      {...props}
      styles={styleProps}
      className="react-select"
      classNamePrefix="react-select"
    />
  )
}

const StyledSelect = styled(makeSelectProps)``

export default StyledSelect
