import {normalize} from 'normalizr'
import {navigateTo} from './navigation'
import {RSAA, getJSON} from 'redux-api-middleware'
import {APPROVAL_STATUS_URL, APPROVERS_URL} from '../constants/api'
import {ASSIGNMENTS_PATH} from '../constants/router'
import {
  UPDATE_APPROVAL_STATUS_FAILURE,
  UPDATE_APPROVAL_STATUS_REQUEST,
  UPDATE_APPROVAL_STATUS_SUCCESS,
  UPDATE_APPROVER_STATUS_FAILURE,
  UPDATE_APPROVER_STATUS_REQUEST,
  UPDATE_APPROVER_STATUS_SUCCESS,
} from '../constants/actionTypes'
import {approverSchema} from '../constants/schemas'
import {fetchTimeline} from './timeline'
import {REJECTED_CHANNEL_ID} from '../constants/channels'
import {disableRejectAssignment} from './assignments'
import {fetchChannelSummary} from './channels'

const updateApproverStatus = (
  employeeId,
  positionId,
  newStatus,
  approverComments
) => ({
  [RSAA]: {
    endpoint: APPROVAL_STATUS_URL(
      employeeId,
      positionId,
      newStatus,
      approverComments
    ),
    method: 'PUT',
    types: [
      UPDATE_APPROVER_STATUS_REQUEST,
      UPDATE_APPROVER_STATUS_SUCCESS,
      UPDATE_APPROVER_STATUS_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
  },
})

export const updateApprovalStatus = (
  positionId,
  employeeId,
  newStatus,
  approverComments
) => (dispatch, getState) =>
  Promise.all([
    dispatch({
      [RSAA]: {
        endpoint: APPROVERS_URL(employeeId, positionId),
        method: 'GET',
        types: [
          UPDATE_APPROVAL_STATUS_REQUEST,
          {
            type: UPDATE_APPROVAL_STATUS_SUCCESS,
            payload: (action, currentState, res) =>
              getJSON(res).then(json => {
                const normalizedData = normalize(json.approvals, [
                  approverSchema,
                ])

                dispatch(
                  updateApproverStatus(
                    employeeId,
                    positionId,
                    newStatus,
                    approverComments
                  )
                )

                return normalizedData
              }),
          },
          UPDATE_APPROVAL_STATUS_FAILURE,
        ],
      },
    }),
    // TODO NavigateTo to the confirmation page or anywhere else we've decided.
  ]).then(() => {
    dispatch(disableRejectAssignment())
    const channel = REJECTED_CHANNEL_ID
    dispatch(fetchTimeline(positionId))
    dispatch(
      navigateTo({
        keys: {},
        options: {channel: channel},
        path: ASSIGNMENTS_PATH,
      })
    )
    dispatch(fetchChannelSummary())
  })
