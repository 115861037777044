import * as c from 'arena-config'
import {Button} from '../../../arena-plugin-form'
import React from 'react'
import pure from 'recompose/pure'
import styled from 'styled-components'
import {rowTitleDefaultProps, rowTitlePropTypes} from './propTypes'

const Inner = styled.div`
  ${c.fontSmoothing()};
  font-weight: ${c.FONT_WEIGHT_3};
  line-height: 1.1;

  ${c.FOR_DESKTOP} {
    button {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const Outer = styled.div`
  position: relative;
  padding-right: ${c.SMALL}px;
`

const RowTitle = props => (
  <Outer>
    <Inner>
      <Button
        onClick={() => props.toggleRowsWithBulkEdit(props.code)}
        preventDefault
        reset
        style={{
          borderColor: 'transparent',
          padding: 0,
        }}
      >
        {props.name}
      </Button>
    </Inner>
    {props.children}
  </Outer>
)

RowTitle.defaultProps = rowTitleDefaultProps
RowTitle.propTypes = rowTitlePropTypes

export default pure(RowTitle)
