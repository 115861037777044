import {
  DARK_GRAY,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_BORDER_STYLE,
  DEFAULT_BORDER_WIDTH,
  DEFAULT_TEXT_COLOR,
  FONT_WEIGHT_1,
  FONT_WEIGHT_2,
  FONT_WEIGHT_3,
  FONT_WEIGHT_4,
  FONT_WEIGHT_5,
  FONT_2_WEIGHT_1,
  FONT_2_WEIGHT_2,
  WHITE,
} from 'arena-config'
import {isRequired, numberOrStringPT, stringPT} from 'arena-prop-types'
import PT from 'prop-types'

const {arrayOf, oneOf, oneOfType, shape} = PT

const BORDER_STYLES = [
  'none',
  'hidden',
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset',
  'initial',
  'inherit',
]

const FONT_WEIGHTS = [
  FONT_WEIGHT_1,
  FONT_WEIGHT_2,
  FONT_WEIGHT_3,
  FONT_WEIGHT_4,
  FONT_WEIGHT_5,
  FONT_2_WEIGHT_1,
  FONT_2_WEIGHT_2,
]

const MENU_ALIGNS = ['bottom', 'left', 'right', 'top']

export const styleDefaultProps = {
  backgroundColor: 'transparent',
  backgroundColorActive: WHITE,
  backgroundColorHover: 'transparent',
  borderColor: `transparent transparent ${DARK_GRAY} transparent`,
  borderColorActive: DEFAULT_TEXT_COLOR,
  borderColorHover: DEFAULT_TEXT_COLOR,
  borderRadius: DEFAULT_BORDER_RADIUS,
  borderStyle: DEFAULT_BORDER_STYLE,
  borderWidth: DEFAULT_BORDER_WIDTH,
  cursor: 'text',
  fontSize: '1em',
  fontWeight: FONT_WEIGHT_3,
  height: 'auto',
  lineHeight: 1.5, // height = 42px (same as Button at lineHeight: 1)
  menuAlign: ['top', 'left'],
  menuItemBackgroundColor: 'transparent',
  menuItemBackgroundColorActive: DEFAULT_TEXT_COLOR,
  menuItemBackgroundColorHover: 'rgba(0, 0, 0, .05)',
  menuItemTextColor: DEFAULT_TEXT_COLOR,
  menuItemTextColorActive: WHITE,
  menuItemTextColorHover: DEFAULT_TEXT_COLOR,
  menuMaxHeight: '15em',
  menuWidth: '',
  menuZIndex: 2,
  padding: '.5em .75em',
  placeholderFontWeight: FONT_WEIGHT_2,
  placeholderTextColor: DEFAULT_TEXT_COLOR,
  placeholderTextColorHover: DEFAULT_TEXT_COLOR,
  textColor: DEFAULT_TEXT_COLOR,
  textColorActive: DEFAULT_TEXT_COLOR,
  textColorHover: DEFAULT_TEXT_COLOR,
  width: '100%',
}

export const stylePropTypes = {
  backgroundColor: stringPT(),
  backgroundColorActive: stringPT(),
  backgroundColorHover: stringPT(),
  borderColor: stringPT(),
  borderColorActive: stringPT(),
  borderColorHover: stringPT(),
  borderRadius: numberOrStringPT(),
  borderStyle: oneOf(BORDER_STYLES),
  cursor: stringPT(),
  fontSize: numberOrStringPT(),
  fontWeight: oneOf(FONT_WEIGHTS),
  height: numberOrStringPT(),
  lineHeight: numberOrStringPT(),
  menuAlign: oneOfType([oneOf(MENU_ALIGNS), arrayOf(oneOf(MENU_ALIGNS))]),
  menuItemBackgroundColor: stringPT(),
  menuItemBackgroundColorActive: stringPT(),
  menuItemBackgroundColorHover: stringPT(),
  menuItemTextColor: stringPT(),
  menuItemTextColorActive: stringPT(),
  menuItemTextColorHover: stringPT(),
  menuMaxHeight: numberOrStringPT(),
  menuWidth: numberOrStringPT(),
  padding: numberOrStringPT(),
  placeholderFontWeight: oneOf(FONT_WEIGHTS),
  placeholderTextColor: stringPT(),
  placeholderTextColorHover: stringPT(),
  textColor: stringPT(),
  textColorActive: stringPT(),
  textColorHover: stringPT(),
  width: numberOrStringPT(),
}

export const stylePropsPT = is =>
  isRequired(
    shape({
      ...stylePropTypes,
    }),
    is
  )
