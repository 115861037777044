import React from 'react'
import message from 'antd/lib/message'
import 'antd/lib/message/style/index.css'

message.config({
  maxCount: 1,
})

export const showMessage = (messageContent, type) => {
  const content = <span>{messageContent}</span>

  return message[type](content)
}
