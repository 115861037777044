import {numberOrStringPT} from 'arena-prop-types'
import PT from 'prop-types'
import {alignProp, linksProp, routeProp} from '../propTypes'

export const headerTopDefaultProps = {
  activeTab: null,
  login: null,
  logoSrc: null,
  logout: null,
  onLogoClick: null,
  renderAdvancedSearch: null,
  renderSearchResults: null,
  search: null,
  searchString: '',
  searchOnChange: null,
  searchWidth: null,
  sendReport: null,
  toggleAdvancedSearchIsOpen: null,
  toggleSearchResultsIsOpen: null,
}

export const headerTopPropTypes = {
  activeTab: PT.number,
  advancedSearchIsOpen: PT.bool.isRequired,
  align: alignProp.isRequired,
  currentRoute: routeProp.isRequired,
  defaultAction: PT.func.isRequired,
  disableAuthentication: PT.bool.isRequired,
  dropdownOptions: PT.array,
  dropdownDefaultValue: PT.shape({}),
  height: PT.oneOfType([PT.number, PT.string]).isRequired,
  isAuthenticated: PT.bool.isRequired,
  links: linksProp.isRequired,
  login: PT.func,
  logoSrc: PT.string,
  logout: PT.func,
  onLogoClick: PT.oneOfType([PT.func, PT.shape({}), PT.string]),
  renderAdvancedSearch: PT.node,
  renderSearchResults: PT.node,
  search: PT.func,
  typeahead: PT.func,
  searchString: PT.string,
  searchResults: PT.arrayOf(PT.shape({})),
  searchResultsFetching: PT.bool,
  searchOnChange: PT.func,
  searchResultsIsOpen: PT.bool.isRequired,
  searchValue: PT.string,
  searchWidth: numberOrStringPT(),
  sendReport: PT.func,
  toggleAdvancedSearchIsOpen: PT.func,
  toggleSearchResultsIsOpen: PT.func,
  user: PT.string.isRequired,
}
