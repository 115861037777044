import PT from 'prop-types'
import React, {Component} from 'react'
import {
  boolPT,
  emptyObjectPT,
  funcPT,
  numberPT,
  stringPT,
} from 'arena-prop-types'
import Assignments from '../Assignments'
import ChannelStatus from '../ChannelStatus'
import InfiniteScroll from '../../lib/components/InfiniteScroll'
import {assignmentsPT} from '../../constants/propTypes/assignments'
import {MY_PAST_DUE_CHANNEL_ID} from '../../constants/channels'
import {formFieldPT} from '../../constants/propTypes/formFields'
import {resourcePT} from '../../constants/propTypes/common'
import {timelinesPT} from '../../constants/propTypes/timelines'
import {isNullOrUndefined} from 'util'
import {favoritesPT} from '../../constants/propTypes/favorite'
import Loader from '../Form/Loader'
import styled from 'styled-components'

const ChannelStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 22px;
  align-items: center;
  height: 75%;
`

class Overview extends Component {
  componentDidMount() {
    const {
      channel,
      fetchAssignmentsIfNeeded,
      fetchChannelSummary,
      fetchCountriesIfNeeded,
      fetchCurrenciesIfNeeded,
      fetchCompaniesIfNeeded,
      fetchPoliciesIfNeeded,
      search,
      searchAssignments,
      fetchUserFavoritesIfNeeded,
    } = this.props

    fetchCountriesIfNeeded()
    fetchCurrenciesIfNeeded()
    fetchCompaniesIfNeeded()
    fetchPoliciesIfNeeded()
    fetchUserFavoritesIfNeeded()

    if (!search) fetchAssignmentsIfNeeded(channel)
    else searchAssignments(search)

    fetchChannelSummary(MY_PAST_DUE_CHANNEL_ID)
  }

  componentDidUpdate(prevProps) {
    const prevChannel = prevProps.channel
    const prevCompany =
      prevProps.lastSelectedCompany && prevProps.lastSelectedCompany.company
    const {
      channel,
      fetchAssignmentsIfNeeded,
      fetchResourcesIfNeeded,
      lastSelectedCompany,
      fetchUserFavoritesIfNeeded,
    } = this.props
    const couldFetchAssignment = channel !== prevChannel && !prevProps.search
    const shouldRefreshAssignments =
      !isNullOrUndefined(prevCompany) &&
      lastSelectedCompany &&
      lastSelectedCompany.company !== prevCompany

    if (couldFetchAssignment) fetchAssignmentsIfNeeded(channel)
    if (shouldRefreshAssignments) {
      fetchAssignmentsIfNeeded(channel, true)
      fetchResourcesIfNeeded(true)
      fetchUserFavoritesIfNeeded(true)
    }
  }

  render() {
    const {props} = this
    const channelInfo = props.channelsInfo[props.channel]
    const channelEffect = (channelInfo && channelInfo.effect) || 'assignments'

    const flyerMessage = 'Refreshing assignment data...'

    const renderChannelAssignments =
      Object.keys(props.assignments).length > 0 ? (
        <Assignments
          addApprover={props.addApprover}
          assignmentAddingApprover={props.assignmentAddingApprover}
          assignments={props.assignments}
          canDeleteAssignmentId={props.canDeleteAssignmentId}
          canRejectAssignmentId={props.canRejectAssignmentId}
          countries={props.countries}
          deleteAssignment={props.deleteAssignment}
          disableDeleteAssignment={props.disableDeleteAssignment}
          disableRejectAssignment={props.disableRejectAssignment}
          enableDeleteAssignment={props.enableDeleteAssignment}
          enableRejectAssignment={props.enableRejectAssignment}
          setCopyAssignmentId={props.setCopyAssignmentId}
          errors={props.errors}
          favorites={props.favorites}
          fetchFormSectionsIfNeeded={props.fetchFormSectionsIfNeeded}
          formFields={props.formFields}
          isCopyAssignmentDisabled={props.isCopyAssignmentDisabled}
          isMobile={props.isMobile}
          navigateTo={props.navigateTo}
          policies={props.policies}
          updateApprovalStatus={props.updateApprovalStatus}
          taskBeingExecuted={props.taskBeingExecuted}
          timelines={props.timelines}
          timelinesAreFetching={props.timelinesAreFetching}
          toggleAddApprover={props.toggleAddApprover}
          toggleUpdateAssignmentOptions={props.toggleUpdateAssignmentOptions}
          updateAssignmentOptionsAndExecuteTask={
            props.updateAssignmentOptionsAndExecuteTask
          }
          updateTaskIfNeeded={props.updateTaskIfNeeded}
          updatingAssignmentOptions={props.updatingAssignmentOptions}
          assignmentOptionIsUpdating={props.assignmentOptionIsUpdating}
        />
      ) : props.channelIsFetching ? (
        <Loader message={flyerMessage} />
      ) : (
        <ChannelStatusWrapper>
          <ChannelStatus effect={channelEffect} navigateTo={props.navigateTo} />
        </ChannelStatusWrapper>
      )

    const renderChannelContent = (
      <InfiniteScroll
        args={[props.channel, props.nextUrl]}
        onScroll={props.fetchAssignmentsNext}
        className="Infinite-scroll-height"
        isLoadingMore={
          Object.keys(props.assignments).length > 0 && props.channelIsFetching
        }
      >
        {renderChannelAssignments}
      </InfiniteScroll>
    )

    return <div className="Overview">{renderChannelContent}</div>
  }
}

Overview.defaultProps = {
  assignmentAddingApprover: null,
  assignments: [],
  canDeleteAssignmentId: null,
  canRejectAssignmentId: null,
  errors: {},
  favorites: [],
  formFields: {},
  nextUrl: null,
  taskBeingExecuted: null,
  timelines: [],
  updatingAssignmentOptions: null,
}

Overview.propTypes = {
  addApprover: funcPT(true),
  assignmentAddingApprover: numberPT(),
  assignments: assignmentsPT(),
  canDeleteAssignmentId: numberPT(),
  canRejectAssignmentId: numberPT(),
  channel: stringPT(true),
  channelIsFetching: boolPT(true),
  channelsInfo: emptyObjectPT(true),
  countries: resourcePT(true),
  deleteAssignment: funcPT(true),
  updateApprovalStatus: funcPT(true),
  disableDeleteAssignment: funcPT(true),
  disableRejectAssignment: funcPT(true),
  enableDeleteAssignment: funcPT(true),
  enableRejectAssignment: funcPT(true),
  setCopyAssignmentId: funcPT(true),
  errors: emptyObjectPT(),
  favorites: favoritesPT(),
  fetchAssignmentsIfNeeded: funcPT(true),
  fetchAssignmentsNext: funcPT(true),
  fetchCountriesIfNeeded: funcPT(true),
  fetchCurrenciesIfNeeded: funcPT(true),
  fetchCompaniesIfNeeded: funcPT(true),
  fetchFormSectionsIfNeeded: funcPT(true),
  fetchPoliciesIfNeeded: funcPT(true),
  formFields: PT.objectOf(formFieldPT(true)),
  isMobile: boolPT(true),
  navigateTo: funcPT(true),
  nextUrl: stringPT(),
  search: stringPT(true),
  searchAssignments: funcPT(true),
  taskBeingExecuted: numberPT(),
  timelines: timelinesPT(),
  timelinesAreFetching: boolPT(true),
  toggleAddApprover: funcPT(true),
  toggleUpdateAssignmentOptions: funcPT(true),
  updateAssignmentOptionsAndExecuteTask: funcPT(true),
  updateTaskIfNeeded: funcPT(true),
  updatingAssignmentOptions: stringPT(),
}

export default Overview
