import {createSelector} from 'reselect'

const getOidc = state => state.oidc

const getSession = state => state.session

export const getOauthToken = state => getSession(state).oauthToken

export const getSessionUser = state => getOidc(state).user

export const getLoggingInOutMessage = createSelector(
  getSession,
  session => session.message
)

export const getIsAuthenticated = createSelector(
  getSessionUser,
  sessionUser => Boolean(sessionUser && !sessionUser.expired)
)
