import getFormValues from 'redux-form/es/getFormValues'
import {createSelector} from 'reselect'
import {CREATE_ASSIGNMENT_FORM} from '../constants/form'

export const getReduxForm = state => state.form

export const getCreateAssignmentFormValues = state =>
  getFormValues(CREATE_ASSIGNMENT_FORM)(state) || {}

export const getCreateAssignmentStartDate = createSelector(
  getCreateAssignmentFormValues,
  values => values.startDate
)
