import AssignmentAuditLog from './AssignmentAuditLog/'
import Compensation from './Compensation/'
import Family from './Family/'
import TaxOptions from './TaxOptions/'
import CalculationOptions from './CalculationOptions'

export default {
  AssignmentAuditLog,
  Compensation,
  Family,
  TaxOptions,
  CalculationOptions,
}
