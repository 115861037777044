import {combineReducers} from 'redux'
import {
  EXECUTE_TASK_ACTION_FAILURE,
  EXECUTE_TASK_ACTION_REQUEST,
  EXECUTE_TASK_ACTION_SUCCESS,
  FETCH_TIMELINES_SUCCESS,
  FETCH_TIMELINE_SUCCESS,
} from '../../constants/actionTypes'
import {IS_TASK_PROCESSING} from '../../constants/taskStatus'

const taskBeingExecuted = (state = null, action) => {
  switch (action.type) {
    case EXECUTE_TASK_ACTION_REQUEST:
      return action.meta.taskId
    case EXECUTE_TASK_ACTION_SUCCESS:
    case EXECUTE_TASK_ACTION_FAILURE:
      return null
    default:
      return state
  }
}

const tasksById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TIMELINE_SUCCESS:
    case FETCH_TIMELINES_SUCCESS:
      return {...state, ...action.payload.entities.tasks}
    default:
      return state
  }
}

const isTaskProcessing = (state = false, action) => {
  switch (action.type) {
    case FETCH_TIMELINE_SUCCESS:
      let isTaskProcessingCount = 0
      for (let i of Object.keys(action.payload.entities.tasks)) {
        const {label} = action.payload.entities.tasks[i]
        if (IS_TASK_PROCESSING.indexOf(label) !== -1) {
          isTaskProcessingCount++
        }
      }
      return isTaskProcessingCount > 0
    default:
      return state
  }
}

const tasks = combineReducers({
  byId: tasksById,
  taskBeingExecuted,
  isTaskProcessing,
})

export default tasks
