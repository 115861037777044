import {createSelector} from 'reselect'
import {denormalize} from 'normalizr'
import {assignmentAuditLogSchema} from '../constants/schemas'
import {getId} from './router'

const getAssignments = state => state.initiations.assignments
const getAssignmentAuditLogs = state => state.initiations.assignmentAuditLogs
const getAssignmentFamilyMembers = state =>
  state.initiations.assignmentFamilyMembers
const getAssignmentOptions = state => state.initiations.assignmentOptions

const getAssignmentAuditLogById = state => getAssignmentAuditLogs(state).byId
export const getAssignmentsById = state => getAssignments(state).byId
export const getAutoCompleteAssignmentsById = state =>
  getAssignments(state).autoCompleteAssignmentsById

export const getAutoCompleteResults = createSelector(
  [getAutoCompleteAssignmentsById],
  autoCompleteAssignmentsById => {
    const result = Object.values(autoCompleteAssignmentsById).map(
      autocompleteAssignment => {
        return {
          [autocompleteAssignment.id]: {
            id: autocompleteAssignment.id,
            title: autocompleteAssignment.title,
            description: autocompleteAssignment.nameAndId,
            value: autocompleteAssignment.employeeId,
          },
        }
      }
    )
    return Object.assign({}, ...result)
  }
)

export const getAssignmentById = (state, {positionId}) =>
  getAssignments(state).byId[positionId]
export const getAssignmentFamilyMembersByPositionId = (state, {positionId}) =>
  getAssignmentFamilyMembers(state).byId[positionId]
export const getAssignmentOptionsByPositionId = (state, {positionId}) =>
  getAssignmentOptions(state).byId[positionId]

const getAssignmentAuditLogIds = state => getAssignmentAuditLogs(state).ids

export const getAddingApprover = state => getAssignments(state).addingApprover

export const getUpdatingAssignmentOptions = state =>
  getAssignments(state).updatingAssignmentOptions

export const getAssignmentsIsCreating = state =>
  getAssignments(state).isCreating
export const getAssignmentsIsDeleting = state =>
  getAssignments(state).isDeleting
export const getAssignmentsIsFetching = state =>
  getAssignments(state).isFetching
export const getAutoCompleteAssignmentsIsFetching = state =>
  getAssignments(state).isAutoCompleteFetching
export const getAutoCompleteAssignmentsNextIsFetching = state =>
  getAssignments(state).isAutoCompleteNextFetching
export const getAssignmentDetailsIsFetching = state =>
  getAssignments(state).isFetchingAssignmentDetails
export const getAssignmentsIsUpdating = state =>
  getAssignments(state).isUpdating

export const getAssignmentFamilyMembersIsFetching = state =>
  getAssignmentFamilyMembers(state).isFetching
export const getAssignmentOptionsIsFetching = state =>
  getAssignmentOptions(state).isFetching
export const getAssignmentOptionsIsUpdating = state =>
  getAssignmentOptions(state).isUpdating

export const getCanDeleteAssignmentId = state =>
  getAssignments(state).canDeleteAssignmentId

export const getCanRejectAssignmentId = state =>
  getAssignments(state).canRejectAssignmentId

export const getCloneAssignmentId = state =>
  getAssignments(state).cloneAssignmentId

export const getAssignmentsErrorMessage = state =>
  getAssignments(state).errorMessage

export const getAssignment = createSelector(
  [getId, getAssignmentsById],
  (id, entities) => (id in entities ? entities[id] : null)
)

export const getCurrentAssignmentAuditLogs = createSelector(
  [getAssignmentAuditLogById, getAssignmentAuditLogIds],
  (assignmentAuditLogs, ids) =>
    denormalize(ids, [assignmentAuditLogSchema], {assignmentAuditLogs})
)

export const getAssignmentAuditLogsSummary = (state, props) => {
  const assignment = getAssignmentById(state, props)
  if (!assignment) return {}

  const {createdBy, createdOn, updatedBy, updatedOn} = assignment

  return {createdBy, createdOn, updatedBy, updatedOn}
}

export const getAssignmentsAuditLogsIsFetching = state =>
  getAssignmentAuditLogs(state).isFetching

export const getAssignmentIsLocked = (state, props) => {
  const assignment = getAssignmentById(state, props)

  return assignment && assignment.isLocked
}
