import React from 'react'
import {change} from 'redux-form'
import {connect} from 'react-redux'
import Create from '../../components/CreateAssignment/index'
import {View} from '../../lib/components/core'
import AuthContainer from '../Auth/index'
import {getHeight} from '../../selectors/environment'
import {
  createAssignment,
  fetchAssignment,
  fetchAssignmentFamilyMembersIfNeeded,
  fetchAssignmentOptionsIfNeeded,
} from '../../actions/assignments'
import {
  executeBusinessRulesIfNeeded,
  executeEmployeeAutofillIfNeeded,
  fetchFormSectionsIfNeeded,
  fetchCalculationSectionsIfNeeded,
  fetchTaxSectionsIfNeeded,
  updatePreviousFormTabErrors,
  fetchAccountSectionsIfNeeded,
} from '../../actions/form'
import {
  fetchResourcesIfNeeded,
  fetchFilingStatusOnLocationChange,
  updateEmployeesIfNeeded,
} from '../../actions/resource'
import {CREATE_ASSIGNMENT_FORM} from '../../constants/form'
import {
  getAssignmentsIsCreating,
  getAssignmentsErrorMessage,
  getCloneAssignmentId,
} from '../../selectors/assignments'
import {
  getAccountSections,
  getCreateAssignmentFormTabs,
  getAssignmentFormValues,
  getCreateAssignmentFormInitialValues,
  getCreateErrors,
  getCreateSubmitFailed,
  getCreateSyncErrors,
  getCreateFormIsFetching,
  getFormSections,
  getTaxSections,
  getCalculationSections,
  getCreateAllErrors,
  getCreateValidationErrorMessage,
  getCopyAssignmentFormInitialValues,
} from '../../selectors/form'
import {getCreateAssignmentStartDate} from '../../selectors/reduxForm'
import {getResources} from '../../selectors/resources'
import {fetchCompaniesIfNeeded} from '../../actions/companies'
import {
  getCompanies,
  getLastSelectedCompany,
  getLastSelectedCompanyFormId,
  getLastSelectedCompanyFormRecordTitle,
} from '../../selectors/company'
import ErrorBoundaryContainer from '../../containers/ErrorBoundary'

const CreateAssignmentContainer = props => (
  <ErrorBoundaryContainer>
    <View authContainer={AuthContainer} name="Initiations">
      <Create {...props} />
    </View>
  </ErrorBoundaryContainer>
)

const mapStateToProps = state => ({
  accountSections: getAccountSections(state),
  companies: getCompanies(state),
  currentValues: getAssignmentFormValues(state, {
    formName: CREATE_ASSIGNMENT_FORM,
    fieldsToGet: [
      'employeeId',
      'firstName',
      'homeLocation',
      'hostLocation',
      'lastName',
      'startDate',
      'planTypeCode',
      'options.General__HomeCompensationLocation__List.value',
      'options.General__HostCompensationLocation__List.value',
      'options.General__HypoCompensationLocation__List.value',
      'options.General__HostHypoCompensationLocation__List.value',
      'options.General__HomeIncomeTaxLocation__List.value',
      'options.General__HostIncomeTaxLocation__List.value',
      'options.General__HypoIncomeTaxLocation__List.value',
      'options.General__HostHypoIncomeTaxLocation__List.value',
      'options.General__HomeSocialTaxLocation__List.value',
      'options.General__HostSocialTaxLocation__List.value',
      'options.General__HypoSocialTaxLocation__List.value',
      'options.General__HostHypoSocialTaxLocation__List.value',
    ],
  }),
  environmentHeight: getHeight(state),
  errors: getCreateErrors(state),
  errorsFromAllTabs: getCreateAllErrors(state),
  formIsFetching: getCreateFormIsFetching(state),
  formIsSubmitting: getAssignmentsIsCreating(state),
  formName: CREATE_ASSIGNMENT_FORM,
  formSections: getFormSections(state),
  formTabs: getCreateAssignmentFormTabs(state),
  formValidationError: getCreateValidationErrorMessage(state),
  formSubmissionError: getAssignmentsErrorMessage(state),
  initialValues: getCloneAssignmentId(state)
    ? getCopyAssignmentFormInitialValues(state, {
        positionId: getCloneAssignmentId(state),
      })
    : getCreateAssignmentFormInitialValues(state),
  lastSelectedCompany: getLastSelectedCompany(state),
  lastSelectedCompanyFormId: getLastSelectedCompanyFormId(state),
  resources: getResources(state),
  startDate: getCreateAssignmentStartDate(state),
  submitFailed: getCreateSubmitFailed(state),
  syncErrors: getCreateSyncErrors(state),
  taxSections: getTaxSections(state),
  calculationSections: getCalculationSections(state),
  formTitle: getLastSelectedCompanyFormRecordTitle(state),
  cloneAssignmentId: getCloneAssignmentId(state),
})

export default connect(mapStateToProps, {
  change,
  createAssignment,
  executeBusinessRulesIfNeeded,
  executeEmployeeAutofillIfNeeded,
  fetchFormSectionsIfNeeded,
  fetchAccountSectionsIfNeeded,
  fetchResourcesIfNeeded,
  fetchCalculationSectionsIfNeeded,
  fetchTaxSectionsIfNeeded,
  fetchCompaniesIfNeeded,
  fetchAssignment,
  fetchAssignmentFamilyMembersIfNeeded,
  fetchAssignmentOptionsIfNeeded,
  fetchFilingStatusOnLocationChange,
  updateEmployeesIfNeeded,
  updatePreviousFormTabErrors,
})(CreateAssignmentContainer)
