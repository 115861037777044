import * as c from 'arena-config'
import React from 'react'
import pure from 'recompose/pure'
import styled from 'styled-components'
import {dataCellDefaultProps, dataCellPropTypes} from './propTypes'

const Inner = styled(({width, ...rest}) => <div {...rest} />)`
  width: ${props => (props.width ? c.px(props.width) : undefined)};

  ${c.FOR_DESKTOP} {
    &:not(:last-of-type) {
      margin-right: ${c.SMALLEST}px;
    }
  }
`

const Outer = styled.td`
  padding-left: ${c.SMALLEST}px;

  ${c.FOR_DESKTOP} {
    &:not(:last-of-type) {
      margin-right: ${c.SMALLEST}px;
    }
  }
`

const DataCell = props => (
  <Outer>
    <Inner width={props.width}>{props.children}</Inner>
  </Outer>
)

DataCell.defaultProps = dataCellDefaultProps
DataCell.propTypes = dataCellPropTypes

export default pure(DataCell)
