import {schema} from 'normalizr'

const accountField = new schema.Entity(
  'accountFields',
  {},
  {idAttribute: s => s.code}
)
const accountSection = new schema.Entity(
  'accountSections',
  {},
  {idAttribute: s => s.code}
)
const approver = new schema.Entity(
  'approvers',
  {},
  {idAttribute: a => a.positionApproverId}
)
const assignment = new schema.Entity('assignments')
const assignmentAuditLog = new schema.Entity('assignmentAuditLogs')
const assignmentFamilyMember = new schema.Entity(
  'assignmentFamilyMembers',
  {},
  {idAttribute: m => m.familyMemberId}
)
const assignmentOption = new schema.Entity(
  'assignmentOptions',
  {},
  {idAttribute: o => o.name}
)
const channel = new schema.Entity('channels')
const currentValues = new schema.Entity(
  'currentValues',
  {},
  {idAttribute: o => o.name}
)
const employee = new schema.Entity(
  'employees',
  {},
  {idAttribute: e => e.employeeId}
)
const fieldOption = new schema.Entity('fieldOptions')
const formField = new schema.Entity(
  'formFields',
  {},
  {idAttribute: s => s.code}
)
const formSection = new schema.Entity(
  'formSections',
  {},
  {idAttribute: s => s.code}
)
const position = new schema.Entity('positions')
const positionToken = new schema.Entity(
  'positionTokens',
  {},
  {idAttribute: e => e.positionId}
)
const task = new schema.Entity('tasks')
const taxField = new schema.Entity('taxFields', {}, {idAttribute: s => s.code})
const taxSection = new schema.Entity(
  'taxSections',
  {},
  {idAttribute: s => s.name}
)
const calculationField = new schema.Entity(
  'calculationFields',
  {},
  {idAttribute: s => s.code}
)
const calculationSection = new schema.Entity(
  'calculationSections',
  {},
  {idAttribute: s => s.name}
)
const timeline = new schema.Entity('timelines')

const company = new schema.Entity(
  'companies',
  {},
  {
    idAttribute: c => c.value,
  }
)

// resources

const country = new schema.Entity('countries', {}, {idAttribute: c => c.value})
const currency = new schema.Entity(
  'currencies',
  {},
  {idAttribute: c => c.value}
)
const entity = new schema.Entity('entities', {}, {idAttribute: c => c.value})
const jobTitle = new schema.Entity('jobTitles', {}, {idAttribute: c => c.value})
const location = new schema.Entity('locations', {}, {idAttribute: c => c.value})
const policy = new schema.Entity('policies', {}, {idAttribute: c => c.id})
const subEntity = new schema.Entity(
  'subEntities',
  {},
  {idAttribute: c => c.value}
)
const filingStatus = new schema.Entity(
  'filingStatuses',
  {},
  {idAttribute: c => c.id}
)

accountSection.define({
  accountFields: [accountField],
})

approver.define({
  approver: [employee],
})

assignment.define({
  employee,
  position,
})

channel.define({
  items: [assignment],
})

employee.define({
  homeCountry: country,
  level: jobTitle,
})

formField.define({
  children: [fieldOption],
})

formSection.define({
  formFields: [formField],
})

taxField.define({
  children: [fieldOption],
})

taxSection.define({
  taxFields: [taxField],
})
calculationField.define({
  children: [fieldOption],
})

calculationSection.define({
  calculationFields: [calculationField],
})

timeline.define({
  items: [task],
})

export const accountSectionSchema = accountSection
export const approverSchema = approver
export const assignmentAuditLogSchema = assignmentAuditLog
export const assignmentFamilyMemberSchema = assignmentFamilyMember
export const assignmentOptionSchema = assignmentOption
export const assignmentSchema = assignment
export const channelSchema = channel
export const currentValuesSchema = currentValues
export const formFieldSchema = formField
export const formSectionSchema = formSection
export const positionTokenSchema = positionToken
export const taskSchema = task
export const taxSectionSchema = taxSection
export const calculationSectionSchema = calculationSection
export const timelineSchema = timeline
export const companySchema = company
// resources

export const countrySchema = country
export const currencySchema = currency
export const employeeSchema = employee
export const entitySchema = entity
export const jobTitleSchema = jobTitle
export const locationSchema = location
export const policySchema = policy
export const subEntitySchema = subEntity
export const filingStatusSchema = filingStatus
