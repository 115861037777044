import {Button} from 'arena-components'
import React from 'react'
import styled from 'styled-components'
import * as c from 'arena-config'
import {headerButtonDefaultProps, headerButtonPropTypes} from './propTypes'

const ButtonWrapper = styled(Button)`
  width: auto;
  min-width: 33.33%;
  line-height: 1;
  height: 48px;
  padding: 0 10px;
  border: 0;
  display: block;
  ${c.FOR_DESKTOP} {
    width: auto;
    height: inherit;
    padding: 0;
    font-size: inherit;
    min-width: auto;
  }
`
const HeaderButton = props => (
  <ButtonWrapper onClick={props.onClick} reset>
    {props.children}
  </ButtonWrapper>
)

HeaderButton.defaultProps = headerButtonDefaultProps
HeaderButton.propTypes = headerButtonPropTypes

export default HeaderButton
