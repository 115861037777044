import React from 'react'
import pure from 'recompose/pure'
import AmountsLabel from '../AmountsLabel'
import DataCell from '../DataCell'
import DataTable from '../DataTable/index'
import DataLabel from '../DataLabel'
import ResourcesLabel from '../ResourcesLabel'
import {rowTemplateDefaultProps, rowTemplatePropTypes} from './propTypes'

const RowTemplate = props => (
  <DataTable>
    <DataCell width={props.titleWidth}>
      {!props.isHeader ? (
        props.title
      ) : (
        <DataLabel isHeader>{props.title}</DataLabel>
      )}
    </DataCell>
    <DataCell>
      {props.children || (
        <DataLabel>
          <ResourcesLabel
            resources={props.resources}
            width={props.resourceFieldWidth}
          />
        </DataLabel>
      )}
    </DataCell>
    <DataCell>
      {props.amounts || (
        <DataLabel>
          <AmountsLabel
            duration={props.duration}
            startYear={props.startYear}
            width={props.amountFieldWidth}
          />
        </DataLabel>
      )}
    </DataCell>
  </DataTable>
)

RowTemplate.defaultProps = rowTemplateDefaultProps
RowTemplate.propTypes = rowTemplatePropTypes

export default pure(RowTemplate)
