import * as c from 'arena-config'
import {Field} from '../../../arena-plugin-form'
import React from 'react'
import pure from 'recompose/pure'
import makeFieldName from '../../utils/makeFieldName'
import DataCell from '../DataCell'
import DataTable from '../DataTable'
import {resourcesDefaultProps, resourcesPropTypes} from './propTypes'

const Resources = props => {
  const {delimiter, isLocked, prefix, resources} = props
  const showLabels =
    props.isHovering ||
    (props.isManuallyShowingResouces && !props.showAllResources)

  const children = Object.keys(resources).map(entity => {
    const name = makeFieldName(prefix, delimiter, entity)
    const resource = resources[entity]

    const handleBulkChangeForField = (event, newValue) => {
      try {
        return props.handleBulkChange(name, prefix)(event, newValue)
      } catch (error) {
        console.error(error)
      }
    }

    const handleChangeIfNeeded = props.isBulkEditing
      ? handleBulkChangeForField
      : undefined

    return (
      <DataCell key={name} width={props.resourceFieldWidth}>
        <Field
          disabled={isLocked}
          label={showLabels ? resource.name : ''}
          name={name}
          onChange={handleChangeIfNeeded}
          options={resource.options}
          styleProps={{
            borderColor: props.isBulkEditing ? c.WHITE : c.DARK_GRAY,
            borderColorActive: props.isBulkEditing ? c.WHITE : c.DARK_GRAY,
            paddingBottom: '.375em',
            paddingTop: '.375em',
            showBorders: props.isActive,
          }}
          type="select"
        />
      </DataCell>
    )
  })

  return <DataTable>{children}</DataTable>
}

Resources.defaultProps = resourcesDefaultProps
Resources.propTypes = resourcesPropTypes

export default pure(Resources)
