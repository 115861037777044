import {INDEX_PATH, ASSIGNMENTS_PATH} from '../constants/router'
import {DEFAULT_CHANNEL_ID} from '../constants/channels'
import * as routerUtil from '../lib/utils/router'

export const compileHash = route => routerUtil.compileHash(route)

export const makeRoute = ({path, keys, options}) =>
  routerUtil.makeRoute({
    path,
    keys,
    options,
  })

export const formatIndexRoute = route => {
  if (route.path === INDEX_PATH) {
    return makeRoute({
      options: {channel: DEFAULT_CHANNEL_ID},
      path: ASSIGNMENTS_PATH,
    })
  }

  return route
}
