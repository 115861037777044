import React, {useMemo, Fragment} from 'react'
import AntAlert from 'antd/lib/alert'
import 'antd/lib/alert/style/index.css'

const Alert = props => {
  const {message, splitStrings} = props

  const formattedMessage = useMemo(
    () =>
      message && splitStrings && message.includes('\\n')
        ? message.split('\\n').map((e, index) => (
            <Fragment key={index}>
              {index > 0 && <br />}
              {e}
            </Fragment>
          ))
        : message,
    [message, splitStrings]
  )

  const alertProps = {
    ...props,
    message: formattedMessage,
  }

  return <AntAlert {...alertProps}></AntAlert>
}

export default Alert
