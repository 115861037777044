import {boolPT, funcPT, isRequired, stringPT} from 'arena-prop-types'
import PT from 'prop-types'

const {shape} = PT

export const metaPropTypes = {
  active: boolPT(true),
  asyncValidating: boolPT(true),
  autofilled: boolPT(true),
  dirty: boolPT(true),
  dispatch: funcPT(true),
  error: stringPT(),
  invalid: boolPT(true),
  pristine: boolPT(true),
  submitFailed: boolPT(true),
  submitting: boolPT(true),
  touched: boolPT(true),
  valid: boolPT(true),
  visited: boolPT(true),
  warning: stringPT(),
}

export const metaPT = is =>
  isRequired(
    shape({
      ...metaPropTypes,
    }),
    is
  )
