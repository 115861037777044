import React from 'react'
import pure from 'recompose/pure'
import {dataTableDefaultProps, dataTablePropTypes} from './propTypes'

const DataTable = props => (
  <table>
    <tbody>
      <tr>{props.children}</tr>
    </tbody>
  </table>
)

DataTable.defaultProps = dataTableDefaultProps
DataTable.propTypes = dataTablePropTypes

export default pure(DataTable)
