import {combineReducers} from 'redux'
import {
  FETCH_CALCULATION_OPTIONS_REQUEST,
  FETCH_CALCULATION_OPTIONS_SUCCESS,
} from '../../../constants/actionTypes'

const calculationSectionsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CALCULATION_OPTIONS_REQUEST:
      return {isFetching: true}
    case FETCH_CALCULATION_OPTIONS_SUCCESS:
      return {...action.payload.entities.calculationSections, isFetching: false}
    default:
      return state
  }
}

const allCalculationSections = (state = [], action) => {
  switch (action.type) {
    case FETCH_CALCULATION_OPTIONS_REQUEST:
      return []
    case FETCH_CALCULATION_OPTIONS_SUCCESS:
      return action.payload.result || state
    default:
      return state
  }
}

const calculationSections = combineReducers({
  byId: calculationSectionsById,
  ids: allCalculationSections,
})

export default calculationSections
