import PT from 'prop-types'
import {commonPropTypes, hasValidResources} from '../../propTypes'

export const resourcesDefaultProps = {
  invertedMenu: false,
  isActive: false,
  isBulkEditField: false,
  isHovering: false,
  isShowingResources: false,
  showAllResources: false,
}

export const resourcesPropTypes = {
  ...commonPropTypes,
  change: PT.func.isRequired,
  formName: PT.string.isRequired,
  handleBulkChange: PT.func,
  invertedMenu: PT.bool,
  isLocked: PT.bool,
  isBulkEditField: PT.bool.isRequired,
  isHovering: PT.bool,
  isShowingResources: PT.bool,
  prefix: PT.string.isRequired,
  resourceFieldWidth: PT.number.isRequired,
  resources: hasValidResources,
  showAllResources: PT.bool,
}
