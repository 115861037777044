import React from 'react'
import ReactScrollTop from 'react-scroll-top'

const ScrollTop = () => (
  <div data-component="ScrollTop">
    <ReactScrollTop
      className="test"
      hideAt={160}
      position="bottom"
      style={{
        animationDuration: '360ms',
        animationFillMode: 'forwards',
        animationName: 'slideInVertical',
        animationTimingFunction: 'ease',
        backgroundColor: 'transparent',
        position: 'fixed',
        transform: 'translateY(100px)',
        zIndex: 999,
      }}
    >
      test
    </ReactScrollTop>
  </div>
)

export default ScrollTop
