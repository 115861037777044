import {boolPT, funcPT, numberOrStringPT, stringPT} from 'arena-prop-types'
import PT from 'prop-types'
import React from 'react'
import {components} from 'react-select'
import {inputPT} from '../../../constants/inputProps'
import fieldPropTypes, {makeFieldProps} from '../propTypes'
import StyledSelect from './Styled'
import Decorator from '../Decorator'
import {formatOptionsByValue} from '../../../utils/formatOptions'
import {isUndefined} from 'util'

const Select = props => {
  const formattedOptions = {}
  formatOptionsByValue(props.options, formattedOptions)

  const handleBlur = event => {
    try {
      return props.onBlurSelect ? props.onBlurSelect(event) : null
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = selectedOption => {
    try {
      selectedOption
        ? props.input.onChange(selectedOption.value)
        : props.input.onChange(null)
    } catch (error) {
      console.error(error)
    }
  }
  const handleShouldKeyDownEventCreateNewOption = event => {
    try {
      switch (event.keyCode) {
        case 9:
        case 13:
          return true
        default:
          return false
      }
    } catch (error) {
      console.error(error)
    }
  }
  const Placeholder = props => {
    return <components.Placeholder {...props} />
  }

  return (
    <Decorator
      customValidationMessage={props.customValidationMessage}
      fixedLabel={props.styleProps.fixedLabel}
      hasValue={props.hasValue}
      label={props.label}
      validationColor={props.validationColor}
      validationMessage={props.validationMessage}
      width={props.styleProps.width}
    >
      <StyledSelect
        {...props.input}
        {...makeFieldProps(props)}
        autoload={props.autoload}
        isClearable={props.isClearable}
        isDisabled={props.disabled}
        creatable={props.creatable}
        components={{Placeholder}}
        loadOptions={props.loadOptions}
        loadingPlaceholder={props.loadingPlaceholder}
        onBlur={handleBlur}
        onChange={handleChange}
        options={props.options}
        isOptionDisabled={option =>
          option.description === 'No_Options_Available'
        }
        placeholder={!props.hasValue ? props.placeholder : ''}
        promptTextCreator={props.promptTextCreator}
        shouldKeyDownEventCreateNewOption={
          handleShouldKeyDownEventCreateNewOption
        }
        value={
          !isUndefined(formattedOptions[props.input.value])
            ? formattedOptions[props.input.value]
            : null
        }
      />
    </Decorator>
  )
}

Select.defaultProps = {
  loadOptions: null,
  onBlurSelect: null,
  optionRenderer: null,
  isClearable: true,
}

Select.propTypes = {
  ...fieldPropTypes,
  isClearable: boolPT(),
  creatable: boolPT(true),
  hasValue: boolPT(true),
  input: inputPT(true),
  loadOptions: funcPT(),
  loadingPlaceholder: PT.oneOfType([PT.func, PT.string]).isRequired,
  onBlurSelect: funcPT(),
  optionLabelKey: stringPT(true),
  optionValueKey: stringPT(true),
  options: PT.arrayOf(
    PT.shape({
      label: PT.string.isRequired,
      value: numberOrStringPT(true),
    })
  ).isRequired,
  placeholder: numberOrStringPT(true),
  promptTextCreator: funcPT(),
}

export default Select
