import {combineReducers} from 'redux'
import {
  FETCH_COUNTRIES_FAILURE,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
} from '../../../constants/actionTypes'

const countriesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_SUCCESS:
      return action.payload.entities.countries || state
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_REQUEST:
      return true
    case FETCH_COUNTRIES_SUCCESS:
    case FETCH_COUNTRIES_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_FAILURE:
      return action.payload.message
    case FETCH_COUNTRIES_REQUEST:
    case FETCH_COUNTRIES_SUCCESS:
      return null
    default:
      return state
  }
}

const countries = combineReducers({
  byId: countriesById,
  isFetching,
  errorMessage,
})

export default countries
