import costEstimate from '../../data/tokenSections/costEstimate.json'

const initialState = {
  costEstimate,
}

const tokenSections = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default tokenSections
