import {createSelector} from 'reselect'

export const getCompaniesById = state => state.companies.byId
export const getCompaniesIsFetching = state => state.companies.isFetching
export const getCompanies = createSelector([getCompaniesById], companies => {
  return Object.values(companies)
})
export const getLastSelectedCompany = state =>
  state.companies.lastSelectedCompany

export const getLastSelectedCompanyId = createSelector(
  [getCompaniesById, getLastSelectedCompany],
  (companies, selectedCompany) =>
    selectedCompany &&
    selectedCompany.company &&
    companies[selectedCompany.company] &&
    companies[selectedCompany.company].id
)

export const getLastSelectedUserDetails = createSelector(
  [getCompaniesById, getLastSelectedCompany],
  (companies, selectedCompany) =>
    selectedCompany &&
    selectedCompany.company &&
    companies[selectedCompany.company] &&
    companies[selectedCompany.company].userId &&
    companies[selectedCompany.company].userEmail &&
    `${companies[selectedCompany.company].userId}-${
      companies[selectedCompany.company].userEmail.split('@')[1]
    }`
)

export const getLastSelectedCompanyFormId = createSelector(
  [getLastSelectedCompany],
  lastSelectedCompany => lastSelectedCompany && lastSelectedCompany.formId
)
export const getLastSelectedCompanyFormRecordTitle = createSelector(
  [getLastSelectedCompany],
  lastSelectedCompany =>
    lastSelectedCompany && lastSelectedCompany.formRecordTitle
)
export const getLastSelectedCompanyMessages = createSelector(
  [getLastSelectedCompany],
  lastSelectedCompany => lastSelectedCompany && lastSelectedCompany.messages
)
export const getLastSelectedCompanyIsCopyAssignmentDisabled = createSelector(
  [getLastSelectedCompany],
  lastSelectedCompany =>
    lastSelectedCompany && lastSelectedCompany.isCopyAssignmentDisabled
)
