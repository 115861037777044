import React from 'react'
import {connect} from 'react-redux'
import AuthContainer from '../Auth/index'
import Overview from '../../components/Overview/index'
import {View} from '../../lib/components/core'
import {navigateTo} from '../../actions/navigation'
import {
  addApprover,
  toggleAddApprover,
  toggleUpdateAssignmentOptions,
  updateTaskIfNeeded,
} from '../../actions/timeline'
import {
  deleteAssignment,
  disableDeleteAssignment,
  disableRejectAssignment,
  enableDeleteAssignment,
  enableRejectAssignment,
  setCopyAssignmentId,
  updateAssignmentOptionsAndExecuteTask,
} from '../../actions/assignments'
import {updateApprovalStatus} from '../../actions/approval'
import {
  fetchAssignmentsIfNeeded,
  fetchAssignmentsNext,
  searchAssignments,
  fetchChannelSummary,
} from '../../actions/channels'
import {
  fetchCountriesIfNeeded,
  fetchCurrenciesIfNeeded,
  fetchPoliciesIfNeeded,
  fetchResourcesIfNeeded,
} from '../../actions/resource'
import {fetchFormSectionsIfNeeded} from '../../actions/form'
import {fetchUserFavoritesIfNeeded} from '../../actions/users'
import {
  getAddingApprover,
  getCanDeleteAssignmentId,
  getUpdatingAssignmentOptions,
  getCanRejectAssignmentId,
  getAssignmentOptionsIsUpdating,
} from '../../selectors/assignments'
import {
  getUpdateAssignmentOptionsSubmitFailed,
  getUpdateAssignmentOptionsErrors,
} from '../../selectors/form'
import {getIsMobile} from '../../selectors/environment'
import {getSearch} from '../../selectors/router'
import {
  getChannel,
  getChannelAssignments,
  getChannelFetching,
  getChannelNextUrl,
  getChannelsInfo,
} from '../../selectors/channels'
import {getLastSelectedCompanyIsCopyAssignmentDisabled} from '../../selectors/company'
import {getCountriesById, getPoliciesById} from '../../selectors/resources'
import {
  getIsExecutingTaskId,
  getTimelines,
  getTimelinesAreFetching,
} from '../../selectors/timelines'
import {getFormFieldsById} from '../../selectors/form'
import {fetchCompaniesIfNeeded} from '../../actions/companies'
import {getCompanies, getLastSelectedCompany} from '../../selectors/company'
import ErrorBoundaryContainer from '../../containers/ErrorBoundary'
import {getFavorites} from '../../selectors/users'

const OverviewContainer = props => (
  <ErrorBoundaryContainer>
    <View authContainer={AuthContainer} name="Initiations">
      <Overview {...props} />
    </View>
  </ErrorBoundaryContainer>
)

const mapStateToProps = state => ({
  assignmentAddingApprover: getAddingApprover(state),
  assignments: getChannelAssignments(state),
  canDeleteAssignmentId: getCanDeleteAssignmentId(state),
  canRejectAssignmentId: getCanRejectAssignmentId(state),
  channel: getChannel(state),
  channelIsFetching: getChannelFetching(state),
  channelsInfo: getChannelsInfo(state),
  countries: getCountriesById(state),
  companies: getCompanies(state),
  errors: getUpdateAssignmentOptionsErrors(state),
  favorites: getFavorites(state),
  formFields: getFormFieldsById(state),
  isCopyAssignmentDisabled: getLastSelectedCompanyIsCopyAssignmentDisabled(
    state
  ),
  isMobile: getIsMobile(state),
  lastSelectedCompany: getLastSelectedCompany(state),
  nextUrl: getChannelNextUrl(state),
  policies: getPoliciesById(state),
  search: getSearch(state),
  submitFailed: getUpdateAssignmentOptionsSubmitFailed(state),
  taskBeingExecuted: getIsExecutingTaskId(state),
  assignmentOptionIsUpdating: getAssignmentOptionsIsUpdating(state),
  timelines: getTimelines(state),
  timelinesAreFetching: getTimelinesAreFetching(state),
  updatingAssignmentOptions: getUpdatingAssignmentOptions(state),
})

export default connect(mapStateToProps, {
  addApprover,
  deleteAssignment,
  updateApprovalStatus,
  disableDeleteAssignment,
  disableRejectAssignment,
  enableDeleteAssignment,
  enableRejectAssignment,
  setCopyAssignmentId,
  fetchAssignmentsIfNeeded,
  fetchAssignmentsNext,
  fetchChannelSummary,
  fetchCountriesIfNeeded,
  fetchCurrenciesIfNeeded,
  fetchFormSectionsIfNeeded,
  fetchPoliciesIfNeeded,
  fetchResourcesIfNeeded,
  fetchCompaniesIfNeeded,
  fetchUserFavoritesIfNeeded,
  navigateTo,
  searchAssignments,
  toggleAddApprover,
  toggleUpdateAssignmentOptions,
  updateAssignmentOptionsAndExecuteTask,
  updateTaskIfNeeded,
})(OverviewContainer)
