export const APPROVED = 'approved'
export const COMPLETED = 'completed'
export const IN_PROGRESS = 'inprogress'
export const NOT_STARTED = 'notstarted'
export const REJECTED = 'rejected'

export const TASKS = [APPROVED, COMPLETED, IN_PROGRESS, NOT_STARTED, REJECTED]

export const TASK_APPROVED = status => status === APPROVED
export const TASK_COMPLETED = status => status === COMPLETED
export const TASK_IN_PROGRESS = status => status === IN_PROGRESS
export const TASK_NOT_STARTED = status => status === NOT_STARTED
export const TASK_REJECTED = status => status === REJECTED

export const TASK_ACTION_DELETE = 'Delete'
export const TASK_ACTION_EXTERNAL_LINK = 'ExternalLink'
export const TASK_ACTION_REDIRECT = 'Redirect'
export const TASK_ACTION_FILE = 'File'
export const TASK_ACTION_POST = 'Post'
export const TASK_ACTION_PUT = 'Put'
