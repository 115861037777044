const initialState = [
  {label: '6 months', value: '6'},
  {label: '12 months', value: '12'},
  {label: '2 years', value: '24'},
  {label: '3 years', value: '36'},
  {label: 'Custom', value: 'custom'},
]

const durations = (state = initialState) => state

export default durations
