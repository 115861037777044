import {fontSmoothing, px} from 'arena-config'
import {
  boolPT,
  funcPT,
  nodePT,
  numberOrStringPT,
  stringPT,
} from 'arena-prop-types'
import React from 'react'
import pure from 'recompose/pure'
import styled from 'styled-components'

const Label = styled.div`
  ${fontSmoothing()};
  bottom: 100%;
  font-size: 0.8em;
  left: 0;
  line-height: 1.125;
  padding-bottom: 0.15em;
  position: absolute;
`

const ValidationMessage = styled.div`
  color: ${props => props.validationColor};
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
`

const Wrap = styled.div`
  position: relative;
  width: ${props => px(props.width)};
`

const Decorator = props => (
  <Wrap width={props.width}>
    {(props.fixedLabel || (!!props.label && props.hasValue)) && (
      <Label>{props.label}</Label>
    )}
    <div>{props.children}</div>
    {props.validationMessage && (
      <ValidationMessage validationColor={props.validationColor}>
        {props.customValidationMessage(props.validationMessage)}
      </ValidationMessage>
    )}
  </Wrap>
)

Decorator.defaultProps = {
  fixedLabel: false,
}

Decorator.propTypes = {
  children: nodePT(true),
  customValidationMessage: funcPT(true),
  fixedLabel: boolPT(),
  hasValue: boolPT(true),
  label: numberOrStringPT(true),
  validationColor: stringPT(true),
  validationMessage: stringPT(true),
  width: numberOrStringPT(true),
}

export default pure(Decorator)
