import React from 'react'
import cn from 'classnames'
import {boolPT, funcPT} from 'arena-prop-types'
import {Button} from '../../lib/components/core'

const AssignmentApproval = ({isMobile, approve, reject}) => {
  const buttonClassName = cn('AssignmentApproval-button', 'large', {
    solid: isMobile,
  })

  return (
    <div className="AssignmentApproval">
      <div>
        <Button
          className={`${buttonClassName} green`}
          onClick={() => approve()}
          preventDefault
        >
          Approve
        </Button>
      </div>
      <div>
        <Button
          className={`${buttonClassName} red`}
          onClick={() => reject()}
          preventDefault
        >
          Reject
        </Button>
      </div>
    </div>
  )
}

AssignmentApproval.propTypes = {
  approve: funcPT(true),
  isMobile: boolPT(true),
  reject: funcPT(true),
}

export default AssignmentApproval
