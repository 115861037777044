import PT from 'prop-types'
import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import styled from 'styled-components'
import {FONT_WEIGHT_1, WHITE} from 'arena-config'
import {Field, Form, Submit} from '../../lib/components/arena-plugin-form'
import {funcPT, stringPT} from 'arena-prop-types'
import {
  UPDATE_ASSIGNMENT_OPTIONS_FORM,
  CURRENCY_FIELD,
} from '../../constants/form'
import {formFieldPT} from '../../constants/propTypes/formFields'
import {getValidationFunctions} from '../../utils/validation'
import {getCurrenciesById} from '../../selectors/resources'
import {getStandardFormSectionsIsFetching} from '../../selectors/form'
import {Spin} from 'antd'
const Heading = styled.div`
  padding-bottom: 20px;
  font-weight: ${FONT_WEIGHT_1};
  font-size: 2em;
`

const Message = styled.div`
  font-style: italic;
  padding-bottom: 20px;
`

const FieldWrapper = styled.div`
  margin: 20px 0;
`

const SubmitWrapper = styled.div`
  margin: 40px 0 10px 0;
  text-align: center;

  > button {
    border-color: ${WHITE};
    color: #000000;
  }
`
const FlyerWrapper = styled.h1`
  display: flex;
  justify-content: center;
`
const Spinner = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #0a838a;
  }
`

const UpdateAssignmentDetails = props => {
  useEffect(() => {
    const {fetchFormSectionsIfNeeded} = props
    fetchFormSectionsIfNeeded()
  }, [])

  const {
    errors,
    formFields,
    label,
    missingFields,
    onSubmit,
    onSubmitSuccess,
    isSubmitting,
  } = props

  const currencies = useSelector(state => getCurrenciesById(state))
  const isFetchingFormDesign = useSelector(state =>
    getStandardFormSectionsIsFetching(state)
  )

  const fields =
    missingFields && Object.keys(formFields).length > 0
      ? missingFields
          .map(missingFieldCode => {
            const fieldCode = Object.keys(formFields).find(f =>
              f.startsWith(`options.${missingFieldCode.replace('_', '__')}`)
            )

            const field = formFields[fieldCode]
            if (field) {
              return {
                code: field.code.replace('options.', ''),
                label: field.label,
                maxLength: field.maxLength || 255,
                options: field.options,
                suffix: field.suffix && field.suffix.replace('options.', ''),
                type: field.type,
                required: true, // missing data is always required to proceed
                validate: field.validate,
              }
            } else {
              return null
            }
          })
          .filter(item => item != null)
      : null

  return isFetchingFormDesign ? (
    <FlyerWrapper>
      <Spinner size="large" />
    </FlyerWrapper>
  ) : (
    <>
      <Form
        name={UPDATE_ASSIGNMENT_OPTIONS_FORM}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
      >
        <Heading>{label}</Heading>
        <Message>
          Additional information is required to complete this action, please
          fill in the required fields below and then submit.
        </Message>
        {fields &&
          fields.map(field => {
            const validate = field.validate
              ? getValidationFunctions(field.validate)
              : []
            const code = field.code.split('.')[0]

            const validationMessage =
              errors[code] &&
              (errors[code].value ||
                (field.type &&
                  field.type === CURRENCY_FIELD &&
                  errors[code].currency))

            const fieldOptions =
              field.type && field.type === CURRENCY_FIELD
                ? Object.values(currencies)
                : field.options

            return (
              <FieldWrapper key={field.code}>
                <Field
                  label={field.label}
                  name={field.code}
                  maxLength={field.maxLength}
                  options={fieldOptions || []}
                  suffix={field.suffix}
                  required={field.required}
                  type={field.type}
                  validate={validate}
                  validationMessage={validationMessage || ''}
                  white
                />
              </FieldWrapper>
            )
          })}
      </Form>
      <SubmitWrapper>
        <Submit
          name={UPDATE_ASSIGNMENT_OPTIONS_FORM}
          isSubmitting={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : label}
        </Submit>
      </SubmitWrapper>
    </>
  )
}

UpdateAssignmentDetails.defaultProps = {
  fields: null,
}

UpdateAssignmentDetails.propTypes = {
  fetchFormSectionsIfNeeded: funcPT(true),
  fields: PT.arrayOf(PT.shape({})),
  formFields: PT.objectOf(formFieldPT(true)),
  label: stringPT(true),
  missingFields: PT.arrayOf(PT.string),
  onSubmit: funcPT(true),
  onSubmitSuccess: funcPT(true),
}

export default UpdateAssignmentDetails
