import PT from 'prop-types'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import cn from 'classnames'
import moment from 'moment'
import {
  boolPT,
  funcPT,
  numberPT,
  stringPT,
  emptyObjectPT,
} from 'arena-prop-types'
import TaskActions from './Actions'
import {Icon, Tooltip} from '../../lib/components/core'
import {PAST_DUE_CHANNEL_ID} from '../../constants/channels'
import {formFieldPT} from '../../constants/propTypes/formFields'
import {taskActionsPT} from '../../constants/propTypes/taskActions'
import {taskStatusProp} from '../../constants/propTypes/taskStatus'
import {getChannel} from '../../selectors/channels'

const renderDueDate = dueDate => {
  const days = moment(dueDate).diff(moment(), 'days')
  const isOverdue = days < 0 && 'red'
  const css = cn('Task-due-date', {isOverdue})
  const dayString =
    (days === 0 && 'today') || (days < 0 && `${-days}d ago`) || `in ${days}d`

  return <span className={css}>({dayString})</span>
}

const Task = ({
  actions,
  assignmentId,
  dueDate,
  errors,
  fetchFormSectionsIfNeeded,
  formFields,
  icon,
  id,
  label,
  refreshCardOnComplete,
  status,
  taskBeingExecuted,
  title,
  toggleUpdateAssignmentOptions,
  tooltip,
  updateAssignmentOptionsAndExecuteTask,
  updateTaskIfNeeded,
  updatingAssignmentOptions,
  assignmentOptionIsUpdating,
}) => {
  const lastActiveChannel = useSelector(state => getChannel(state))
  const [
    shouldHighlightRenderedDueDate,
    setShouldHighlightRenderedDueDate,
  ] = useState(false)
  useEffect(() => {
    setShouldHighlightRenderedDueDate(lastActiveChannel === PAST_DUE_CHANNEL_ID)
  }, [lastActiveChannel])
  const isCompleted = status === 'completed'
  const isInProgress = status === 'inprogress'
  const hasActions = actions.length || false
  const hasIcon = icon || false

  const css = cn(
    'Task',
    {hasActions},
    {hasIcon},
    {taskBeingExecuted},
    {[`status-${status}`]: status}
  )

  const renderedDueDate = !isCompleted ? renderDueDate(dueDate) : null

  const renderedIconBase = icon && (
    <div className="Task-icon-wrap">
      <Icon className="Task-icon" inlineSVG={isInProgress} type={icon} />
    </div>
  )

  const renderedIcon = tooltip ? (
    <Tooltip title={tooltip}>{renderedIconBase}</Tooltip>
  ) : (
    renderedIconBase
  )

  return (
    <div className={css}>
      <div className="Task-inner">
        {renderedIcon}
        <div className="Task-text">
          <div className="Task-title">
            <span className="Task-title-label">{title}</span>
            <span
              className={
                shouldHighlightRenderedDueDate ? 'Task-renderedDueDate' : null
              }
            >
              {renderedDueDate}
            </span>
          </div>
          <div className="Task-label">{label}</div>
        </div>
        {actions.length ? (
          <TaskActions
            actions={actions}
            assignmentId={assignmentId}
            errors={errors}
            fetchFormSectionsIfNeeded={fetchFormSectionsIfNeeded}
            formFields={formFields}
            refreshCardOnComplete={refreshCardOnComplete}
            status={status}
            taskBeingExecuted={taskBeingExecuted}
            taskId={id}
            toggleUpdateAssignmentOptions={toggleUpdateAssignmentOptions}
            updateAssignmentOptionsAndExecuteTask={
              updateAssignmentOptionsAndExecuteTask
            }
            updateTaskIfNeeded={updateTaskIfNeeded}
            updatingAssignmentOptions={updatingAssignmentOptions}
            assignmentOptionIsUpdating={assignmentOptionIsUpdating}
            dueDate={dueDate}
          />
        ) : null}
      </div>
    </div>
  )
}

Task.defaultProps = {
  className: '',
  formFields: {},
  icon: '',
  iconType: '',
  status: null,
  taskBeingExecuted: '',
  tooltip: '',
  updatingAssignmentOptions: null,
}

Task.propTypes = {
  actions: taskActionsPT(true),
  assignmentId: numberPT(true),
  className: stringPT(),
  completedDate: stringPT(true),
  dueDate: stringPT(true),
  errors: emptyObjectPT(),
  fetchFormSectionsIfNeeded: funcPT(true),
  formFields: PT.objectOf(formFieldPT(true)),
  icon: stringPT(),
  iconType: stringPT(),
  id: numberPT(true),
  label: stringPT(true),
  refreshCardOnComplete: boolPT(true),
  status: taskStatusProp,
  taskBeingExecuted: numberPT(),
  title: stringPT(true),
  toggleUpdateAssignmentOptions: funcPT(true),
  tooltip: stringPT(),
  updateAssignmentOptionsAndExecuteTask: funcPT(true),
  updateTaskIfNeeded: funcPT(true),
  updatingAssignmentOptions: stringPT(),
}

export default Task
