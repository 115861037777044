import {combineReducers} from 'redux'
import {CURRENCY_OPTIONS_US_DOLLAR} from '../constants/locale'

const currency = (state = CURRENCY_OPTIONS_US_DOLLAR, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default combineReducers({
  currency,
})
