import PT from 'prop-types'
import {numberOrStringPT} from 'arena-prop-types'

export const headerLinkDefaultProps = {
  children: null,
  customRenderer: null,
  handleClick: null,
  isActive: false,
  white: false,
}

export const headerLinkPropTypes = {
  children: PT.node,
  customRenderer: PT.func,
  handleClick: PT.func,
  height: numberOrStringPT(true),
  id: PT.string.isRequired,
  isActive: PT.bool,
  to: PT.oneOfType([
    PT.string,
    PT.shape({
      pathname: PT.string,
      search: PT.string,
      hash: PT.string,
      state: PT.shape({}),
    }),
  ]),
  white: PT.bool,
}
