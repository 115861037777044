import moment from 'moment'
import {DATE_FORMAT} from 'arena-config'
import {isNullOrUndefined} from 'util'

export const VALIDATION_IS_DATE = value => {
  if (moment(value, DATE_FORMAT, true).isValid()) {
    return undefined
  }

  return `Date is not valid (${DATE_FORMAT})`
}

export const VALIDATION_IS_START_DATE_GREATER_THAN_END_DATE = (
  value,
  formValues
) => {
  const startDate = moment(formValues.startDate, DATE_FORMAT)
  const endDate = moment(formValues.endDate, DATE_FORMAT)
  const dateDifference = endDate.diff(startDate, 'days')
  if (dateDifference >= 0) {
    return undefined
  }
  return 'End date should be greater than start date'
}

export const VALIDATION_IS_DATE_IS_NOT_FUTURE_DATE = value => {
  const currentDate = moment(new Date(), DATE_FORMAT)
  const enteredDate = moment(value, DATE_FORMAT)
  const isFutureDate = enteredDate.diff(currentDate, 'days') > 0 ? true : false
  if (!isFutureDate) return undefined
  return 'Future date is not allowed'
}

export const VALIDATION_IS_REQUIRED = value =>
  !isNullOrUndefined(value) ? undefined : 'Required'

export const VALIDATION_IS_STRING = value =>
  typeof value === 'string' ? undefined : 'Must be a string'

export const VALIDATION_IS_LONGER_THAN_ONE_CHARACTER = value => {
  if (!VALIDATION_IS_STRING(value) && value.length > 1) {
    return undefined
  }

  return 'String must be longer than 1 character'
}

export const VALIDATION_IS_VALID_EMAIL_FORMAT = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined
