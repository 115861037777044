import {isRequired, stringPT} from 'arena-prop-types'
import PT from 'prop-types'

const assignmentAuditLogProps = {
  action: stringPT(true),
  actionBy: stringPT(true),
  actionOn: stringPT(true),
  id: stringPT(true),
}

const assignmentAuditLogPT = is =>
  isRequired(PT.shape(assignmentAuditLogProps), is)

export const assignmentAuditLogsPT = is =>
  isRequired(PT.arrayOf(assignmentAuditLogPT), is)
