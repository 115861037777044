import {numberOrStringPT} from 'arena-prop-types'
import PT from 'prop-types'
import {alignProp, routeProp} from '../propTypes'

export const headerLinksDefaultProps = {
  activeTab: null,
  handleClick: null,
  white: true,
}

export const headerLinksPropTypes = {
  activeTab: PT.number,
  align: alignProp,
  currentRoute: routeProp.isRequired,
  defaultAction: PT.func.isRequired,
  handleClick: PT.func,
  height: numberOrStringPT(true),
  white: PT.bool,
}
