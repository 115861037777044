import {funcPT, numberPT} from 'arena-prop-types'
import React from 'react'
import styled from 'styled-components'
import {selectedDatePT} from '../propTypes'

const Wrap = styled(({selectedDate, year, ...rest}) => <div {...rest} />)``

const DateTimeYear = props => {
  return (
    <td>
      <Wrap {...props}>{props.year}</Wrap>
    </td>
  )
}

DateTimeYear.defaultProps = {
  onClick: null,
  selectedDate: {},
}

DateTimeYear.propTypes = {
  onClick: funcPT(),
  selectedDate: selectedDatePT(),
  year: numberPT(true),
}

export default DateTimeYear
