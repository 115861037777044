import {isRequired, stringPT} from 'arena-prop-types'
import PT from 'prop-types'
import {valuePT} from './common'

const {arrayOf, shape} = PT

export const fieldOptionProps = {
  label: stringPT(true),
  value: valuePT(true),
}

export const fieldOptionPT = is => isRequired(shape(fieldOptionProps), is)

export const fieldOptionsPT = is => isRequired(arrayOf(fieldOptionPT()), is)
