import {combineReducers} from 'redux'
import {
  FETCH_TIMELINE_FAILURE,
  FETCH_TIMELINE_REQUEST,
  FETCH_TIMELINE_SUCCESS,
  FETCH_TIMELINES_FAILURE,
  FETCH_TIMELINES_REQUEST,
  FETCH_TIMELINES_SUCCESS,
} from '../../constants/actionTypes'

const timelinesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TIMELINE_SUCCESS:
    case FETCH_TIMELINES_SUCCESS:
      return {...state, ...action.payload.entities.timelines}
    default:
      return state
  }
}

const allTimelines = (state = [], action) => {
  switch (action.type) {
    case FETCH_TIMELINE_SUCCESS: {
      const timelines = [...state]
      timelines.push(action.payload.result)

      return timelines
    }
    case FETCH_TIMELINES_SUCCESS:
      return [...new Set([...state, ...action.payload.result])]
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_TIMELINES_REQUEST:
      return true
    case FETCH_TIMELINES_SUCCESS:
    case FETCH_TIMELINES_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_TIMELINE_FAILURE:
    case FETCH_TIMELINES_FAILURE:
      return action.payload.message
    case FETCH_TIMELINE_REQUEST:
    case FETCH_TIMELINE_SUCCESS:
    case FETCH_TIMELINES_REQUEST:
    case FETCH_TIMELINES_SUCCESS:
      return null
    default:
      return state
  }
}

const timelines = combineReducers({
  byId: timelinesById,
  ids: allTimelines,
  isFetching,
  errorMessage,
})

export default timelines
