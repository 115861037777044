import {boolPT, funcPT, stringPT} from 'arena-prop-types'
import moment from 'moment'
import React from 'react'
import ReactDateTime from 'react-datetime'
import {inputPT} from '../../../constants/inputProps'
import dateTimeChange from '../../../utils/handleDateTimeChange'
import fieldPropTypes, {
  dateFormatPT,
  makeFieldProps,
  timeFormatPT,
} from '../propTypes'
import Input from '../Input'
import StyledDateTime from './Styled'

class DateTime extends React.PureComponent {
  constructor(props) {
    super(props)
    this.dateTimeInputRef = React.createRef()
  }

  render() {
    const {props} = this
    const {dateFormat, timeFormat} = props
    const cleanDateFormat = dateFormat || ''
    const cleanTimeFormat = timeFormat || ''
    const formatSpace = cleanDateFormat && cleanTimeFormat ? ' ' : ''
    const dateTimeFormat = cleanDateFormat + formatSpace + cleanTimeFormat

    const handleDateTimeChange = event => {
      try {
        dateTimeChange(event, {
          afterChange: props.onChange,
          format: dateTimeFormat,
          onChange: props.input.onChange,
        })
      } catch (error) {
        console.error(error)
      }
    }

    const handleInputChange = event => {
      try {
        const isValid = moment(
          event.target.value,
          dateTimeFormat,
          true
        ).isValid()

        if (isValid) {
          props.input.onChange(event)
        }
      } catch (error) {
        console.error(error)
      }
    }

    const handleFocus = event => {
      try {
        if (props.onFocusDateTime) {
          props.onFocusDateTime(event)
        }
        return props.input.onFocus
      } catch (error) {
        console.error(error)
      }
    }

    const handleKeyDown = event => {
      try {
        if (
          event.key === 'Backspace' &&
          props.clearOnBackspace &&
          props.hasValue
        ) {
          props.input.onChange('')
          event.preventDefault()
          this.dateTimeInputRef.current.blur()
        }
      } catch (error) {
        console.error(error)
      }
    }

    const renderInput = inputProps => (
      <Input
        {...inputProps}
        {...makeFieldProps(props)}
        disabled={props.disabled}
        name={props.input.name}
        onChange={handleInputChange}
        refId={this.dateTimeInputRef}
        type="text"
      />
    )

    return (
      <StyledDateTime
        hasValue={props.hasValue}
        onBlur={props.onBlurDateTime}
        onKeyDown={handleKeyDown}
        open={props.dateTimeOpen}
        styleProps={props.styleProps}
        validationColor={props.validationColor}
        validationMessage={props.validationMessage}
      >
        <ReactDateTime
          {...props.input}
          {...makeFieldProps(props)}
          clearOnBackspace={props.clearOnBackspace}
          closeOnSelect={props.closeOnSelect}
          dateFormat={props.dateFormat}
          disableOnClickOutside={props.disableOnClickOutside}
          isValidDate={props.isValidDate}
          onChange={handleDateTimeChange}
          onFocus={handleFocus}
          open={props.dateTimeOpen}
          renderDay={props.renderDay}
          renderInput={renderInput}
          renderMonth={props.renderMonth}
          renderYear={props.renderYear}
          timeFormat={props.timeFormat}
        />
      </StyledDateTime>
    )
  }
}

DateTime.defaultProps = {
  dateTimeOpen: null,
  onBlurDateTime: null,
  renderDay: null,
  renderMonth: null,
  renderYear: null,
}

DateTime.propTypes = {
  ...fieldPropTypes,
  clearOnBackspace: boolPT(true),
  closeOnSelect: boolPT(true),
  dateFormat: dateFormatPT(true),
  dateTimeOpen: boolPT(),
  disableOnClickOutside: boolPT(true),
  input: inputPT(true),
  isValidDate: funcPT(),
  loadingPlaceholder: stringPT(true),
  onBlurDateTime: funcPT(),
  renderDay: funcPT(),
  renderMonth: funcPT(),
  renderYear: funcPT(),
  timeFormat: timeFormatPT(true),
}

export default DateTime
