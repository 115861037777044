import {combineReducers} from 'redux'
import {
  FETCH_ASSIGNMENT_FAMILY_MEMBERS_FAILURE,
  FETCH_ASSIGNMENT_FAMILY_MEMBERS_REQUEST,
  FETCH_ASSIGNMENT_FAMILY_MEMBERS_SUCCESS,
} from '../../constants/actionTypes'

const assignmentFamilyMembersById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ASSIGNMENT_FAMILY_MEMBERS_SUCCESS:
      return {
        ...state,
        [action.meta.positionId]: {
          ...action.payload.entities.assignmentFamilyMembers,
        },
      }
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_ASSIGNMENT_FAMILY_MEMBERS_REQUEST:
      return true
    case FETCH_ASSIGNMENT_FAMILY_MEMBERS_SUCCESS:
    case FETCH_ASSIGNMENT_FAMILY_MEMBERS_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  // TODO Add option to reset error message, e.g.:
  // if (action.type === RESET_ERROR_MESSAGE) return null

  switch (action.type) {
    case FETCH_ASSIGNMENT_FAMILY_MEMBERS_FAILURE:
      return action.payload.message
    case FETCH_ASSIGNMENT_FAMILY_MEMBERS_REQUEST:
    case FETCH_ASSIGNMENT_FAMILY_MEMBERS_SUCCESS:
      return null
    default:
      return state
  }
}

const assignmentFamilyMembers = combineReducers({
  byId: assignmentFamilyMembersById,
  errorMessage,
  isFetching,
})

export default assignmentFamilyMembers
