import {API_URL} from '../constants/api'
import {
  TASK_ACTION_REDIRECT,
  TASK_ACTION_EXTERNAL_LINK,
} from '../constants/tasks'

export const determineTaskActionType = (link, type) => {
  switch (type) {
    case TASK_ACTION_REDIRECT:
      return {href: link}
    case TASK_ACTION_EXTERNAL_LINK:
      return {href: link, target: '_blank'}
    default:
      return {link: `${API_URL}/${link}`}
  }
}
