import {RSAA, getJSON} from 'redux-api-middleware'
import {
  FETCH_DOCUMENTS_FAILURE,
  FETCH_DOCUMENTS_REQUEST,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_BY_ID_FAILURE,
  FETCH_DOCUMENTS_BY_ID_REQUEST,
  FETCH_DOCUMENTS_BY_ID_SUCCESS,
  DELETE_DOCUMENT_FAILURE,
  DELETE_DOCUMENT_REQUEST,
  DELETE_DOCUMENT_SUCCESS,
  FETCH_DOCUMENT_CATEGORY_FAILURE,
  FETCH_DOCUMENT_CATEGORY_SUCCESS,
  FETCH_DOCUMENT_CATEGORY_REQUEST,
} from '../constants/actionTypes'
import {
  GET_SINGLE_DOCUMENT_BY_ID,
  DELETE_DOCUMENT,
  GET_DOCUMENTS_FOR_ASSIGNMENT,
  GET_DOCUMENT_CATEGORY_AND_SUBCATEGORY,
} from '../constants/api'
import {isIE} from '../utils/browser'
import {sendFileToBrowser} from 'arena-redux'

export const fetchDocuments = id => dispatch => {
  const url = GET_DOCUMENTS_FOR_ASSIGNMENT(id)
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }
  dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      headers,
      types: [
        {
          type: FETCH_DOCUMENTS_REQUEST,
        },
        {
          type: FETCH_DOCUMENTS_SUCCESS,
          payload: (action, currentState, res) =>
            getJSON(res).then(json => {
              return {...json}
            }),
        },
        FETCH_DOCUMENTS_FAILURE,
      ],
    },
  })
}

export const fetchDocumentsById = (id, documentId) => dispatch => {
  const url = GET_SINGLE_DOCUMENT_BY_ID(id, documentId)
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }
  dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      headers,
      types: [
        {
          type: FETCH_DOCUMENTS_BY_ID_REQUEST,
        },
        {
          type: FETCH_DOCUMENTS_BY_ID_SUCCESS,
          payload: (action, currentState, res) =>
            getJSON(res).then(json => {
              sendFileToBrowser(res)
            }),
        },
        FETCH_DOCUMENTS_BY_ID_FAILURE,
      ],
    },
  })
}

export const deleteDocument = id => dispatch => {
  const url = DELETE_DOCUMENT(id)
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }
  dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'DELETE',
      headers,
      types: [
        {
          type: DELETE_DOCUMENT_REQUEST,
        },
        {
          type: DELETE_DOCUMENT_SUCCESS,
          meta: {id},
          payload: (action, currentState, res) =>
            getJSON(res).then(json => {
              return currentState.initiations.documents.documentReducer
            }),
        },
        DELETE_DOCUMENT_FAILURE,
      ],
    },
  })
}

export const fetchDocumentCategoryAndSubcategory = dispatch => {
  const url = GET_DOCUMENT_CATEGORY_AND_SUBCATEGORY
  let headers = {}
  if (isIE) {
    headers = {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }
  }
  dispatch({
    [RSAA]: {
      endpoint: url,
      method: 'GET',
      headers,
      types: [
        {
          type: FETCH_DOCUMENT_CATEGORY_REQUEST,
        },
        {
          type: FETCH_DOCUMENT_CATEGORY_SUCCESS,
          payload: (action, currentState, res) => getJSON(res),
        },
        FETCH_DOCUMENT_CATEGORY_FAILURE,
      ],
    },
  })
}
