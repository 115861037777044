import moment from 'moment/moment'

export default (event, {afterChange, format, onChange}) => {
  const formatted = format ? moment(event).format(format) : event

  if (onChange) {
    onChange(formatted)
  }

  if (afterChange) {
    afterChange(formatted)
  }
}
