import {isRequired, stringPT} from 'arena-prop-types'
import PT from 'prop-types'
import {formFieldsPT} from './formFields'

const {arrayOf, node, shape} = PT

export const formSectionProps = {
  code: stringPT(true),
  component: node,
  formFields: formFieldsPT(),
  name: stringPT(true),
}

export const formSectionPT = is => isRequired(shape(formSectionProps), is)

export const formSectionsPT = is => isRequired(arrayOf(formSectionPT), is)
