import React from 'react'
import styled from 'styled-components'
import fieldPropTypes from '../propTypes'
import Field from '../../Field'
const FieldsWrap = styled.div`
   {
    margin: 0px;
    padding: 0px;
  }
`
const AmountFieldWrap = styled.div`
   {
    display: inline-block;
    width: 45%;
    margin-right: 5%;
    position: relative;
  }
`

const CurrencyFieldWrap = styled.div`
   {
    display: inline-block;
    width: 50%;
    position: relative;
  }
`

const Currency = props => {
  const makeField = props => {
    return <Field {...props} />
  }

  return (
    <FieldsWrap>
      <AmountFieldWrap>
        {makeField({
          ...props,
          name: props.input.name,
          label: props.required ? props.label.replace('*', '') : props.label,
          placeholder: props.placholder,
          type: 'number',
        })}
      </AmountFieldWrap>
      <CurrencyFieldWrap>
        {makeField({
          ...props,
          name: props.suffix,
          options: props.options,
          label: 'Currency',
          placeholder: 'Currency',
          type: 'select',
        })}
      </CurrencyFieldWrap>
    </FieldsWrap>
  )
}

Currency.propTypes = {
  ...fieldPropTypes,
}

export default Currency
