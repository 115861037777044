import {combineReducers} from 'redux'
import {
  UPDATE_ASSIGNMENT_SUCCESS,
  UPDATE_ASSIGNMENT_REQUEST,
  UPDATE_ASSIGNMENT_FAILURE,
  TOGGLE_UPDATE_ASSIGNMENT_OPTIONS,
  TOGGLE_ADD_APPROVER,
  FETCH_ASSIGNMENT_SUCCESS,
  FETCH_ASSIGNMENT_REQUEST,
  FETCH_ASSIGNMENT_FAILURE,
  FETCH_ASSIGNMENTS_SUCCESS,
  FETCH_ASSIGNMENTS_REQUEST,
  FETCH_ASSIGNMENTS_FAILURE,
  FETCH_FORM_DESIGN_REQUEST,
  DELETE_ASSIGNMENT_SUCCESS,
  DELETE_ASSIGNMENT_REQUEST,
  DELETE_ASSIGNMENT_FAILURE,
  DELETE_ASSIGNMENT_ENABLE,
  DELETE_ASSIGNMENT_DISABLE,
  CREATE_ASSIGNMENT_SUCCESS,
  CREATE_ASSIGNMENT_REQUEST,
  CREATE_ASSIGNMENT_FAILURE,
  FETCH_AUTOCOMPLETE_ASSIGNMENTS_FAILURE,
  FETCH_AUTOCOMPLETE_ASSIGNMENTS_REQUEST,
  FETCH_AUTOCOMPLETE_ASSIGNMENTS_SUCCESS,
  REJECT_ASSIGNMENT_ENABLE,
  REJECT_ASSIGNMENT_DISABLE,
  CLONE_ASSIGNMENT_ENABLE,
  FETCH_CHANNEL_SUMMARY_SUCCESS,
} from '../../constants/actionTypes'

const autoCompleteAssignmentsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_SUCCESS:
      return {...state, ...action.payload.entities.assignments}
    default:
      return state
  }
}

const assignmentsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ASSIGNMENT_SUCCESS:
    case FETCH_ASSIGNMENTS_SUCCESS:
    case CREATE_ASSIGNMENT_SUCCESS:
    case UPDATE_ASSIGNMENT_SUCCESS:
      return {...state, ...action.payload.entities.assignments}
    case DELETE_ASSIGNMENT_SUCCESS: {
      const newState = {...state}
      delete newState[action.payload.positionId]

      return newState
    }
    default:
      return state
  }
}

const addingApprover = (state = null, action) => {
  switch (action.type) {
    case TOGGLE_ADD_APPROVER:
      return action.positionId || null
    default:
      return state
  }
}

const updatingAssignmentOptions = (state = null, action) => {
  switch (action.type) {
    case TOGGLE_UPDATE_ASSIGNMENT_OPTIONS:
      return `${action.positionId}_${action.taskId}_${action.actionId}` || null
    default:
      return state
  }
}

const canDeleteAssignmentId = (state = null, action) => {
  switch (action.type) {
    case DELETE_ASSIGNMENT_ENABLE:
      return action.id
    case DELETE_ASSIGNMENT_DISABLE:
      return null
    default:
      return state
  }
}

const canRejectAssignmentId = (state = null, action) => {
  switch (action.type) {
    case REJECT_ASSIGNMENT_ENABLE:
      return action.id
    case REJECT_ASSIGNMENT_DISABLE:
      return null
    default:
      return state
  }
}

const cloneAssignmentId = (state = null, action) => {
  switch (action.type) {
    case CLONE_ASSIGNMENT_ENABLE:
      return action.id
    case FETCH_CHANNEL_SUMMARY_SUCCESS:
    case CREATE_ASSIGNMENT_SUCCESS:
    case FETCH_ASSIGNMENTS_SUCCESS:
      return null
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_ASSIGNMENT_FAILURE:
    case FETCH_ASSIGNMENTS_FAILURE:
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_FAILURE:
    case CREATE_ASSIGNMENT_FAILURE:
    case UPDATE_ASSIGNMENT_FAILURE:
      return action.payload.response && action.payload.response.message
        ? action.payload.response.message
        : action.payload.message
    case FETCH_ASSIGNMENT_REQUEST:
    case FETCH_ASSIGNMENT_SUCCESS:
    case FETCH_ASSIGNMENTS_REQUEST:
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_REQUEST:
    case FETCH_ASSIGNMENTS_SUCCESS:
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_SUCCESS:
    case FETCH_FORM_DESIGN_REQUEST:
    case CREATE_ASSIGNMENT_REQUEST:
    case CREATE_ASSIGNMENT_SUCCESS:
    case UPDATE_ASSIGNMENT_REQUEST:
    case UPDATE_ASSIGNMENT_SUCCESS:
      return null
    default:
      return state
  }
}

const isCreating = (state = false, action) => {
  switch (action.type) {
    case CREATE_ASSIGNMENT_REQUEST:
      return true
    case CREATE_ASSIGNMENT_SUCCESS:
    case CREATE_ASSIGNMENT_FAILURE:
      return false
    default:
      return state
  }
}

const isDeleting = (state = false, action) => {
  switch (action.type) {
    case DELETE_ASSIGNMENT_REQUEST:
      return true
    case DELETE_ASSIGNMENT_SUCCESS:
    case DELETE_ASSIGNMENT_FAILURE:
      return false
    default:
      return state
  }
}
const isAutoCompleteFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_REQUEST:
      return action.meta && action.meta.loadMore ? false : true
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_SUCCESS:
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_FAILURE:
      return false
    default:
      return state
  }
}
const isAutoCompleteNextFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_REQUEST:
      return action.meta && action.meta.loadMore ? true : false
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_SUCCESS:
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_FAILURE:
      return false
    default:
      return state
  }
}
const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_ASSIGNMENT_REQUEST:
    case FETCH_ASSIGNMENTS_REQUEST:
      return true
    case FETCH_ASSIGNMENT_SUCCESS:
    case FETCH_ASSIGNMENT_FAILURE:
    case FETCH_ASSIGNMENTS_SUCCESS:
    case FETCH_ASSIGNMENTS_FAILURE:
      return false
    default:
      return state
  }
}

const isFetchingAssignmentDetails = (state = false, action) => {
  switch (action.type) {
    case FETCH_ASSIGNMENT_REQUEST:
      return true
    case FETCH_ASSIGNMENT_SUCCESS:
    case FETCH_ASSIGNMENT_FAILURE:
      return false
    default:
      return state
  }
}

const isUpdating = (state = false, action) => {
  switch (action.type) {
    case UPDATE_ASSIGNMENT_REQUEST:
      return true
    case UPDATE_ASSIGNMENT_SUCCESS:
    case UPDATE_ASSIGNMENT_FAILURE:
      return false
    default:
      return state
  }
}

const assignments = combineReducers({
  byId: assignmentsById,
  autoCompleteAssignmentsById,
  addingApprover,
  canDeleteAssignmentId,
  canRejectAssignmentId,
  errorMessage,
  isCreating,
  isDeleting,
  isFetching,
  isAutoCompleteFetching,
  isAutoCompleteNextFetching,
  isFetchingAssignmentDetails,
  isUpdating,
  updatingAssignmentOptions,
  cloneAssignmentId,
})

export default assignments
