import {combineReducers} from 'redux'
import {
  GET_USER_FAVORITES_REQUEST,
  GET_USER_FAVORITES_SUCCESS,
  GET_USER_FAVORITES_FAILURE,
  SET_USER_FAVORITE_REQUEST,
  SET_USER_FAVORITE_SUCCESS,
  SET_USER_FAVORITE_FAILURE,
  REMOVE_USER_FAVORITE_REQUEST,
  REMOVE_USER_FAVORITE_SUCCESS,
  REMOVE_USER_FAVORITE_FAILURE,
} from '../../constants/actionTypes'

const favorites = (state = [], action) => {
  switch (action.type) {
    case GET_USER_FAVORITES_SUCCESS:
      return action.payload
    case SET_USER_FAVORITE_SUCCESS:
      return [...state, action.payload]
    case REMOVE_USER_FAVORITE_SUCCESS:
      const index = state.findIndex(
        favorite => favorite.positionId === action.meta.positionId
      )
      return [...state.slice(0, index), ...state.slice(index + 1)]
    case REMOVE_USER_FAVORITE_REQUEST:
    case REMOVE_USER_FAVORITE_FAILURE:
    case SET_USER_FAVORITE_REQUEST:
    case SET_USER_FAVORITE_FAILURE:
    case GET_USER_FAVORITES_REQUEST:
    case GET_USER_FAVORITES_FAILURE:
    default:
      return state
  }
}

const userReducers = combineReducers({
  favorites,
})

export default userReducers
