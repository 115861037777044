import {createSelector} from 'reselect'

export const fetchDocuments = state => state.initiations.documents

export const getDocuments = createSelector([fetchDocuments], documents => {
  return documents.documentReducer
})

export const getDocumentCategoriesAndSubcategories = createSelector(
  [fetchDocuments],
  documents => {
    return documents.documentCategory.entries
  }
)

export const isDocumentFetching = createSelector(
  [fetchDocuments],
  documents => documents.isDocumentFetching
)

export const getUploadFrom = state =>
  state.form.UPLOAD_FORM ? state.form.UPLOAD_FORM.values : {}
