import {getStyleProp} from 'arena-button'
import {
  absoluteCenter,
  ANIMATION_SPEED_MEDIUM,
  DATE_TIME_FIELD,
  FONT_WEIGHT_1,
  FONT_WEIGHT_2,
  FONT_WEIGHT_4,
  FOR_DESKTOP,
  pseudoBlock,
  WHITE,
} from 'arena-config'
import {boolPT, stringPT} from 'arena-prop-types'
import styled from 'styled-components'
import {stylePropsPT} from '../styleProps'
import {menuStyles} from '../../../mixins/menu'
import {
  calendarPadding,
  preHoverOpacity,
  timeFontSize,
  timeToggleFontSize,
} from './constants'

const StyledDateTime = styled.div`
  .rdtDay,
  .rdtMonth,
  .rdtNext,
  .rdtPrev,
  .rdtSwitch,
  .rdtYear {
    cursor: pointer;
  }

  .rdtDay,
  .rdtNext,
  .rdtPrev,
  .rdtSwitch {
    height: 3em;
    line-height: 3em;
  }

  .rdtDay,
  .rdtMonth,
  .rdtNext,
  .rdtPrev,
  .rdtYear {
    border: 1px solid transparent;
    border-radius: 50%;
    text-align: center;

    &:hover {
      border-color: ${getStyleProp('borderColorActive')};
    }
  }

  .rdtDay,
  .rdtNext,
  .rdtPrev {
    width: 3em;
  }

  .rdtMonth,
  .rdtYear {
    height: 4em;
    line-height: 4em;
    width: 4em;
  }

  .rdtNew,
  .rdtOld {
    opacity: 0.25;
  }

  .rdtNext,
  .rdtPrev,
  .rdtSwitch {
    position: absolute;
    top: 0;
  }

  .rdtNext,
  .rdtPrev {
    width: 3em;

    span {
      font-size: 2em;
      font-weight: ${FONT_WEIGHT_1};
      line-height: 0.5em;
    }
  }

  .rdt {
    color: ${getStyleProp('textColor')};
    position: relative;
  }

  .rdtActive {
    background-color: ${getStyleProp('borderColorActive')};
    color: ${WHITE};
  }

  .rdtBtn {
    cursor: pointer;
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    text-indent: -9999px;

    &::before {
      ${pseudoBlock()};
      content: '‹';
      font-size: 0.75em;
      height: 1em;
      line-height: 0;
      opacity: 0;
      position: absolute;
      text-align: center;
      text-indent: 0;
      width: 1em;
    }

    &:first-of-type {
      bottom: 50%;
      top: 0;

      &::before {
        top: 0.5em;
        transform: rotate(90deg) translate(-60%, -20%);
      }
    }

    &:last-of-type {
      bottom: 0;
      top: 50%;

      &::before {
        bottom: 0.5em;
        transform: rotate(-90deg) translate(-60%, 100%);
      }
    }

    ${FOR_DESKTOP} {
      background-color: rgba(255, 255, 255, 0.625);
      border: 1px dashed transparent;

      &:hover {
        background-color: transparent;
        border-color: ${getStyleProp('borderColorHover')};

        &::before {
          opacity: 1;
        }
      }
    }
  }

  .rdtCount {
    font-weight: ${FONT_WEIGHT_4};
  }

  .rdtCounter {
    height: ${timeFontSize / 1.5}em;
    line-height: ${timeFontSize / 1.5}em;
    position: relative;
    text-align: center;
    width: ${timeFontSize / 1.75}em;

    &:first-of-type {
      &::before {
        ${absoluteCenter(false, true)};
        content: ':';
        display: block;
        left: 100%;
        margin-left: -0.125em;
        margin-top: -0.075em;
        opacity: 1;
        transition: opacity ${ANIMATION_SPEED_MEDIUM};
      }
    }

    &:last-of-type {
      width: ${timeFontSize / 1.5}em;

      .rdtBtn {
        &::before {
          display: none;
        }

        &:first-of-type {
          bottom: 0;
        }

        &:last-of-type {
          display: none;
        }
      }
    }
  }

  .rdtCounterSeparator {
    display: none;
  }

  .rdtCounters {
    align-items: center;
    display: flex;
    font-size: ${timeToggleFontSize}em;
    font-weight: ${FONT_WEIGHT_1};
    justify-content: center;
    padding: ${calendarPadding}em 0 0;
    position: relative;

    &::before {
      ${pseudoBlock()};
      border-top: 1px solid ${getStyleProp('borderColorActive')};
      left: -${calendarPadding / 2}em;
      position: absolute;
      right: -${calendarPadding / 2}em;
      top: ${calendarPadding / 2}em;
    }

    ${FOR_DESKTOP} {
      &:hover {
        .rdtCounter:first-of-type {
          &::before {
            opacity: 0;
          }
        }
      }
    }
  }

  .rdtDays {
    th {
      &.dow {
        font-size: 0.9em;
        font-weight: ${FONT_WEIGHT_2};
        letter-spacing: 1px;
      }
    }
  }

  .rdtNext {
    left: 3em;
  }

  .rdtPicker {
    ${props => menuStyles(props, DATE_TIME_FIELD)};
    display: none;
    padding: ${calendarPadding}em;

    .rdtDays,
    .rdtMonths,
    .rdtYears {
      position: relative;

      > table {
        thead {
          tr:first-of-type {
            height: 4em;
            position: relative;
          }
        }
      }
    }
  }

  .rdtPrev {
    left: 0;
  }

  .rdtSwitch {
    left: 6em;
    letter-spacing: 1px;
    font-weight: ${FONT_WEIGHT_2};
    padding-right: 1em;
    right: 0;
    text-align: right;
    text-transform: uppercase;

    ${FOR_DESKTOP} {
      opacity: ${preHoverOpacity};

      &:hover {
        opacity: 1;
      }
    }
  }

  .rdtTime {
    .rdtSwitch {
      padding-left: 1em;
      position: static;
    }
  }

  .rdtTimeToggle {
    cursor: pointer;
    font-size: ${timeToggleFontSize}em;
    font-weight: ${FONT_WEIGHT_1};
    padding-top: ${calendarPadding}em;
    position: relative;
    text-align: center;

    &::before {
      ${pseudoBlock()};
      border-top: 1px solid ${getStyleProp('borderColorActive')};
      left: -${calendarPadding / 2}em;
      position: absolute;
      right: -${calendarPadding / 2}em;
      top: ${calendarPadding / 2}em;
    }

    ${FOR_DESKTOP} {
      &:hover {
        color: ${getStyleProp('borderColorHover')};
      }
    }
  }

  .rdtToday {
    font-weight: ${FONT_WEIGHT_4};
  }

  .rdt.rdtOpen {
    .rdtPicker {
      display: inline-block;
    }
  }
`

StyledDateTime.defaultProps = {
  open: null,
}

StyledDateTime.propTypes = {
  hasValue: boolPT(true),
  open: boolPT(),
  styleProps: stylePropsPT(true),
  validationColor: stringPT(true),
  validationMessage: stringPT(true),
}

export default StyledDateTime
