// TODO: `@babel/polyfill` is huge therefore we should aim to import only the
// features that we use. The `core-js` library is an option.
import '@babel/polyfill'
import 'react-app-polyfill/ie9'
import 'sanitize.css'
import 'arena-icons/font/icons.css'
import React from 'react'
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import ReactDOM from 'react-dom'
import './styles/main.scss'
import * as serviceWorker from './serviceWorker'
import {AUTHENTICATION_CALLBACK_PATH} from './constants/router'
import {APP_BASE_NAME} from './constants/api'
import App from './App'

ReactDOM.render(
  <BrowserRouter basename={`${APP_BASE_NAME}`}>
    <Routes>
      <Route
        path={`/${AUTHENTICATION_CALLBACK_PATH}`}
        element={
          <Navigate
            to={`/#/${AUTHENTICATION_CALLBACK_PATH}${window.location.hash.replace(
              '#',
              '?'
            )}`}
            replace
          />
        }
      />
      <Route path="" element={<App />} />
    </Routes>
  </BrowserRouter>,
  //<App />,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
