import PT from 'prop-types'
import {commonPropTypes} from '../../propTypes'

export const BulkEditPillDefaultProps = {}

export const BulkEditPillPropTypes = {
  ...commonPropTypes,
  code: PT.string.isRequired,
  name: PT.string.isRequired,
  onClick: PT.func.isRequired,
}
