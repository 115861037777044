import React from 'react'
import styled from 'styled-components'
import {FONT_WEIGHT_1, FOR_DESKTOP, SMALL, SMALLER, WHITE} from 'arena-config'
import {Field, Form, Submit} from '../../lib/components/arena-plugin-form'
import {Flex} from 'arena-components'
import {funcPT, stringPT} from 'arena-prop-types'

const Fields = styled(Flex)`
  ${FOR_DESKTOP} {
    margin-bottom: ${SMALL}px;
  }
`

const FieldWrap = styled.div`
  ${FOR_DESKTOP} {
    &:not(:last-of-type) {
      margin-right: ${SMALLER}px;
    }
  }
`

const Heading = styled.div`
  font-size: 2em;
  font-weight: ${FONT_WEIGHT_1};
  margin-bottom: 1.5em;
  margin-top: -1.5em;
`

const makeField = props => (
  <FieldWrap>
    <Field {...props} type="text" white />
  </FieldWrap>
)

const SubmitWrapper = styled.div`
  text-align: center;

  > button {
    border-color: ${WHITE};
    color: ${WHITE};
  }
`

const AddApprover = ({formName, onSubmit, onSubmitSuccess}) => (
  <>
    <Form name={formName} onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
      <Heading>Add an approver</Heading>
      <Fields>
        {makeField({label: 'First name', name: 'firstName'})}
        {makeField({label: 'Last name', name: 'lastName'})}
        {makeField({label: 'Email', name: 'email'})}
      </Fields>
    </Form>
    <SubmitWrapper>
      <Submit name={formName}>Add approver</Submit>
    </SubmitWrapper>
  </>
)

AddApprover.propTypes = {
  formName: stringPT(true),
  onSubmit: funcPT(true),
  onSubmitSuccess: funcPT(true),
}

export default AddApprover
