import {
  borderRadiusStyleProp,
  getStyleProp,
  paddingStyleProp,
  squareStyleProp,
} from 'arena-button'
import {px} from 'arena-config'
import {boolPT, funcPT, stringPT} from 'arena-prop-types'
import React from 'react'
import styled from 'styled-components'
import {inputPropTypes} from '../../../constants/inputProps'
import {stylePropsPT} from '../styleProps'
import fieldPropTypes from '../propTypes'

const makeInputProps = ({
  customValidationMessage,
  hasValue,
  label,
  meta,
  refId,
  styleProps,
  validationColor,
  validationMessage,
  valueKey,
  ...rest
}) => <input {...rest} ref={refId} />

const StyledInput = styled(makeInputProps)`
  background-color: ${getStyleProp('backgroundColor')};
  border-color: ${getStyleProp('borderColor')};
  border-radius: ${px(borderRadiusStyleProp())};
  border-style: ${getStyleProp('borderStyle')};
  border-width: ${px(getStyleProp('borderWidth'))};
  color: ${getStyleProp('textColor')};
  cursor: ${getStyleProp('cursor')};
  font-size: ${px(getStyleProp('fontSize'))};
  font-weight: ${getStyleProp('fontWeight')};
  height: ${px(squareStyleProp())};
  line-height: ${getStyleProp('lineHeight')};
  padding: ${px(paddingStyleProp())};
  width: ${px(squareStyleProp(true))};

  ::placeholder {
    color: ${getStyleProp('placeholderTextColor')};
    font-style: italic;
    font-weight: ${getStyleProp('placeholderFontWeight')};
    opacity: 0.75;
  }

  &:hover {
    background-color: ${getStyleProp('backgroundColorHover')};
    border-color: ${getStyleProp('borderColorHover')};
    color: ${getStyleProp('textColorHover')};

    ::placeholder {
      color: ${getStyleProp('placeholderTextColorHover')};
      opacity: 1;
    }
  }

  &:focus {
    background-color: ${getStyleProp('backgroundColorActive')};
    border-color: ${getStyleProp('borderColorActive')};
    color: ${getStyleProp('textColorActive')};
    outline: none;

    ::placeholder {
      color: ${getStyleProp('placeholderTextColorActive')};
      opacity: 1;
    }
  }
  &:disabled {
    cursor: not-allowed;
  }
  &.hasValue {
    border-color: ${getStyleProp('borderColorActive')};
    color: ${getStyleProp('textColorActive')};
  }
`

StyledInput.defaultProps = {
  onKeyPress: null,
}

StyledInput.propTypes = {
  ...fieldPropTypes,
  ...inputPropTypes,
  hasValue: boolPT(true),
  onChange: funcPT(true),
  onKeyPress: funcPT(),
  styleProps: stylePropsPT(true),
  type: stringPT(true),
  validationColor: stringPT(true),
  validationMessage: stringPT(true),
}

export default StyledInput
