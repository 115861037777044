import {createSelector} from 'reselect'

const getErrors = state => state.errors

export const getError = createSelector(
  [getErrors],
  errors => errors.error
)

export const getErrorInfo = createSelector(
  [getErrors],
  errors => errors.errorInfo
)

export const getHasError = createSelector(
  [getErrors],
  errors => errors.hasError
)
