import React from 'react'
import {connect} from 'react-redux'
import {login} from '../../actions/session'
import {
  getIsAuthenticated,
  getSessionUser,
  getLoggingInOutMessage,
} from '../../selectors/session'
import Auth from '../../lib/components/Auth'

const AuthContainer = props => <Auth {...props} />

const mapStateToProps = state => ({
  isAuthenticated: getIsAuthenticated(state),
  user: getSessionUser(state),
  currentURL: window.location.href,
  isLoading: state.oidc.isLoadingUser,
  message: getLoggingInOutMessage(state),
})

export default connect(mapStateToProps, {
  login,
})(AuthContainer)
