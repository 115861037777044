import {combineReducers} from 'redux'
import {FETCH_TAX_OPTIONS_SUCCESS} from '../../../constants/actionTypes'

const taxFieldsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TAX_OPTIONS_SUCCESS:
      return {...state, ...action.payload.entities.taxFields}
    default:
      return state
  }
}

const taxFields = combineReducers({
  byId: taxFieldsById,
})

export default taxFields
