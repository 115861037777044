import React from 'react'
import {inputPT} from '../../../constants/inputProps'
import handleInputChange from '../../../utils/handleInputChange'
import fieldPropTypes, {makeFieldProps} from '../propTypes'
import Input from '../Input'

const Text = props => (
  <Input
    {...props.input}
    {...makeFieldProps(props)}
    disabled={props.disabled}
    maxLength={props.maxLength}
    onChange={event =>
      handleInputChange(event.target, props.valueKey, props.input.onChange)
    }
  />
)

Text.propTypes = {
  ...fieldPropTypes,
  input: inputPT(true),
}

export default Text
