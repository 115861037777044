import Navigation from '../../lib/components/arena-plugin-navigation'
import PT from 'prop-types'
import React, {Component} from 'react'
import styled from 'styled-components'
import {LIGHT_GRAY, FOR_DESKTOP} from 'arena-config'
import Router from '../Router/index'
import {
  ASSIGNMENTS_PATH,
  CREATE_ASSIGNMENT_PATH,
  INDEX_PATH,
} from '../../constants/router'
import {EXPORT_ASSIGNMENTS_URL} from '../../constants/api'
import {APP_LOGO, MENU_ICON, X} from '../../lib/constants/icons'
import {Switch, Button, Icon} from '../../lib/components/core'
import {makeRoute} from '../../utils/router'
import {emptyObjectPT} from 'arena-prop-types/build/propTypes/common'
import {assignmentsPT} from '../../constants/propTypes/assignments'
import Alert from '../../lib/components/Alert'

const AlertBanner = styled(props => <Alert {...props} />)`
    z-index: 1 !important;
  }
`

const makeChannelsLinks = ({
  activeChannel,
  channels,
  updateLastActiveChannel,
  isMobile,
}) =>
  Object.keys(channels).map(channelId => ({
    active: channelId === activeChannel,
    id: channelId,
    title: channels[channelId].title,
    to: makeRoute({
      options: {channel: channelId},
      path: 'initiations',
    }),
    onClick: () => updateLastActiveChannel(channelId),
    shouldCloseMobileMenu: isMobile,
  }))

const ChannelFilters = styled.div`
  color: ${LIGHT_GRAY};
  margin-right: 16px;
  height: ${props => (props.isMobile ? '48px' : 'inherit')};
  ${FOR_DESKTOP} {
    margin-left: auto;
  }
`
const ChannelRefresh = styled.div`
  color: ${LIGHT_GRAY};
  margin-left: 0px;
  margin-right: 16px;
  height: ${props => (props.isMobile ? '48px' : 'inherit')};
`

const ChannelFilter = styled.div`
  display: flex;
`

const ChannelFilterLabel = styled.div`
  padding: 0 10px 0 16px;
  line-height: 32px;
  color: black;
`

// FIXME: These styles are not applied for some reason. For that reason, I added them to the
// Switch component for now.
const StyledSwitch = styled(Switch)``

const buttonProps = {
  className: 'borderless round white transparentOnHover',
  style: {
    height: '32px',
    width: '32px',
  },
}
const linkIconProps = {
  inlineSVG: true,
  style: {
    color: 'inherit',
  },
}
const iconProps = {
  className: 'gray',
  inlineSVG: true,
  style: {
    width: '70%',
    height: '70%',
  },
}

class Root extends Component {
  componentDidMount() {
    const {props} = this
    props.initEnvironment()
    props.initRouter(props.paths)
  }

  componentWillReceiveProps(nextProps) {
    const {currentValues, updateLastSelectedCompany} = this.props
    const nextValues = nextProps.currentValues
    const shouldRefreshAssignmentsView =
      nextValues &&
      nextValues.company &&
      nextValues.company.value &&
      nextValues.company.value !==
        (currentValues && currentValues.company && currentValues.company.value)

    const previousSelectedCompany =
      currentValues && currentValues.company && currentValues.company.value

    if (shouldRefreshAssignmentsView) {
      updateLastSelectedCompany(
        previousSelectedCompany,
        nextValues.company.value
      )
    }
  }

  toggleAssignmentsView = () => {
    const {
      channel,
      channelFilters,
      fetchAssignmentsIfNeeded,
      search,
      searchAssignments,
      updateChannelFilters,
      fetchChannelSummary,
    } = this.props

    updateChannelFilters(channel, {
      showUserAssignmentsOnly: !channelFilters.showUserAssignmentsOnly,
    })
    fetchChannelSummary()

    if (!search) fetchAssignmentsIfNeeded(channel, true)
    else searchAssignments(search)
  }

  RefreshAssignmentsView = () => {
    const {
      channel,
      channelFilters,
      fetchAssignmentsIfNeeded,
      search,
      searchAssignments,
      updateChannelFilters,
      fetchChannelSummary,
    } = this.props

    updateChannelFilters(channel, {
      showUserAssignmentsOnly: channelFilters.showUserAssignmentsOnly,
    })

    fetchChannelSummary()

    if (!search) fetchAssignmentsIfNeeded(channel, true)
    else searchAssignments(search)
  }

  render() {
    const {props} = this
    const {path} = props.router.route
    const hasChannelsBar = path === ASSIGNMENTS_PATH || path === INDEX_PATH
    const user = props.user ? props.user.profile.name : ''

    const channelsLinks = !hasChannelsBar
      ? []
      : makeChannelsLinks({
          channels: props.channelsInfo,
          activeChannel: props.channel,
          navigateTo: props.navigateTo,
          updateLastActiveChannel: props.updateLastActiveChannel,
          isMobile: props.isMobile,
        })

    if (hasChannelsBar) {
      channelsLinks.push({
        customRenderer: () => (
          <ChannelFilters isMobile={props.isMobile}>
            <ChannelFilter>
              {props.isMobile ? (
                <ChannelFilterLabel>My assignments</ChannelFilterLabel>
              ) : (
                <ChannelFilterLabel>
                  Show my assignments only
                </ChannelFilterLabel>
              )}
              <StyledSwitch
                on={props.channelFilters.showUserAssignmentsOnly}
                onClick={
                  !props.isMobile ? this.toggleAssignmentsView : undefined
                }
              />
            </ChannelFilter>
          </ChannelFilters>
        ),
        id: 'myAssignments',
        onClick: () => {
          props.isMobile && this.toggleAssignmentsView()
        },
        shouldCloseMobileMenu: props.isMobile,
      })
      channelsLinks.push({
        customRenderer: () => (
          <ChannelRefresh isMobile={props.isMobile}>
            <ChannelFilter>
              {props.isMobile && (
                <ChannelFilterLabel>Refesh assignments</ChannelFilterLabel>
              )}
              <Button
                {...buttonProps}
                onClick={
                  !props.isMobile ? this.RefreshAssignmentsView : undefined
                }
              >
                <Icon {...iconProps} type="refresh" />
              </Button>
            </ChannelFilter>
          </ChannelRefresh>
        ),
        id: 'refresh',
        onClick: () => {
          props.isMobile && this.RefreshAssignmentsView()
        },
        shouldCloseMobileMenu: props.isMobile,
      })
    }

    const lastActiveChannelRoute = makeRoute({
      options: {channel: props.lastActiveChannel},
      path: ASSIGNMENTS_PATH,
    })

    const links = [
      {
        id: 'assignments',
        title: 'Home',
        to: lastActiveChannelRoute,
        icon: props.isMobile && <Icon {...linkIconProps} type="home" />,
      },
      {
        id: `create${props.formTitle}`,
        title: props.isMobile
          ? 'Create'
          : props.formTitle
          ? `Create ${props.formTitle}`
          : 'Create',
        to: makeRoute({path: CREATE_ASSIGNMENT_PATH}),
        icon: props.isMobile && <Icon {...linkIconProps} type="plus-thick" />,
      },
      {
        id: 'exportAssignments',
        title: props.isMobile ? 'Export' : 'Export data',
        onClick: () => props.downloadFile(EXPORT_ASSIGNMENTS_URL),
        icon: props.isMobile && <Icon {...linkIconProps} type="export" />,
        hasProgress: true,
        progress: props.exportProgressPercentage,
        isStarting: props.exportProgressIsStarting,
      },
    ]

    return (
      <Navigation
        currentRoute={props.router.route}
        defaultAction={props.navigateTo}
        dropdownOptions={props.companies}
        dropdownDefaultValue={props.lastSelectedCompany}
        isMobile={props.isMobile}
        links={links}
        login={props.login}
        logoSrc={APP_LOGO}
        logout={props.logout}
        menuSrc={MENU_ICON()}
        menuCloseSrc={X()}
        onLogoClick={makeRoute({path: INDEX_PATH})}
        search={props.searchAssignments}
        typeahead={props.fetchAutoCompleteAssignments}
        searchResults={props.autocompleteAssignmentsList}
        searchResultsFetching={props.autocompleteAssignmentsListIsFetching}
        nextUrl={props.autocompleteAssignmentsNext}
        loadMoreResults={props.fetchAutocompleteAssignmentsNext}
        loadMoreResultsLoading={props.autoCompleteAssignmentsNextIsFetching}
        subLinks={channelsLinks}
        user={user}
        sendReport={props.sendMail}
      >
        {props.alertMessage && (
          <AlertBanner
            message={props.alertMessage}
            type="warning"
            closable
            showIcon
            splitStrings
          />
        )}
        <Router router={props.router} routes={props.routes} />
      </Navigation>
    )
  }
}

Root.defaultProps = {
  currentValues: {},
  user: {},
}

Root.propTypes = {
  channel: PT.string.isRequired,
  channelFilters: PT.shape({}).isRequired,
  channelsInfo: PT.shape({}).isRequired,
  channelFetching: PT.bool.isRequired,
  companies: PT.array,
  lastSelectedCompany: PT.shape({}),
  currentValues: emptyObjectPT(),
  downloadFile: PT.func.isRequired,
  fetchAssignmentsIfNeeded: PT.func.isRequired,
  initEnvironment: PT.func.isRequired,
  initRouter: PT.func.isRequired,
  isMobile: PT.bool.isRequired,
  lastActiveChannel: PT.string.isRequired,
  login: PT.func.isRequired,
  logout: PT.func.isRequired,
  navigateTo: PT.func.isRequired,
  paths: PT.arrayOf(PT.string).isRequired,
  router: PT.shape({
    route: PT.shape({
      keys: PT.shape({}).isRequired,
      options: PT.shape({}).isRequired,
      path: PT.string.isRequired,
    }).isRequired,
  }).isRequired,
  routes: PT.shape({}).isRequired,
  search: PT.string.isRequired,
  searchAssignments: PT.func.isRequired,
  fetchAutoCompleteAssignments: PT.func.isRequired,
  autocompleteAssignmentsList: assignmentsPT(),
  autocompleteAssignmentsListIsFetching: PT.bool,
  fetchAutocompleteAssignmentsNext: PT.func.isRequired,
  autoCompleteAssignmentsNextIsFetching: PT.bool,
  sendMail: PT.func,
  updateChannelFilters: PT.func.isRequired,
  updateLastActiveChannel: PT.func.isRequired,
  updateLastSelectedCompany: PT.func.isRequired,
  user: PT.shape({}),
  formTitle: PT.string,
}

export default Root
