import * as c from 'arena-config'
import React, {useState} from 'react'
import styled from 'styled-components'
import Alerts from '../Alerts'
import HeaderBottom from '../HeaderBottom'
import HeaderTop from '../HeaderTop'
import {headerDefaultProps, headerPropTypes} from './propTypes'

const Wrap = styled.div`
  background-color: ${props => props.backgroundColor};
  font-size: ${props => c.px(Math.ceil(props.height / 2.875))};
  font-weight: ${c.FONT_WEIGHT_1};
  height: 48px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${props => props.zIndex};
  transition: height 0.6s ease, opacity 0.6s ease;
  ${c.FOR_DESKTOP} {
    height: 48px;
  }
`

const Header = props => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const handleMobileMenuIconClick = () => setIsMobileMenuOpen(!isMobileMenuOpen)
  const shouldShowMenu = props.subLinks.length > 0
  return (
    <Wrap
      backgroundColor={props.headerBackgroundColor}
      height={props.headerHeight}
      zIndex={props.zIndex}
      shouldHideDropdwon={props.shouldHideDropdwon}
    >
      <HeaderTop
        activeTab={props.activeTab}
        advancedSearchIsOpen={props.advancedSearchIsOpen}
        align={props.headerAlign}
        backgroundColor={props.headerBackgroundColor}
        currentRoute={props.currentRoute}
        defaultAction={props.defaultAction}
        disableAuthentication={props.disableAuthentication}
        dropdownOptions={props.dropdownOptions}
        dropdownDefaultValue={props.dropdownDefaultValue}
        height={props.headerHeight}
        isAuthenticated={props.isAuthenticated}
        isMobileMenuOpen={isMobileMenuOpen}
        onMobileMenuIconClick={handleMobileMenuIconClick}
        links={props.links}
        login={props.login}
        logoSrc={props.logoSrc}
        logout={props.logout}
        menuSrc={props.menuSrc}
        menuCloseSrc={props.menuCloseSrc}
        shouldShowMenu={shouldShowMenu}
        onLogoClick={props.onLogoClick}
        onSelect={props.onSelect}
        selectedItem={props.selectedItem}
        shouldHideDropdwon={props.shouldHideDropdwon}
        renderAdvancedSearch={props.renderAdvancedSearch}
        renderSearchResults={props.renderSearchResults}
        search={props.search}
        typeahead={props.typeahead}
        searchString={props.searchString}
        searchResults={props.searchResults}
        searchResultsFetching={props.searchResultsFetching}
        nextUrl={props.nextUrl}
        loadMoreResults={props.loadMoreResults}
        loadMoreResultsLoading={props.loadMoreResultsLoading}
        searchOnChange={props.searchOnChange}
        searchResultsIsOpen={props.searchResultsIsOpen}
        searchValue={props.searchValue}
        searchWidth={props.searchWidth}
        sendReport={props.sendReport}
        toggleAdvancedSearchIsOpen={props.toggleAdvancedSearchIsOpen}
        toggleSearchResultsIsOpen={props.toggleSearchResultsIsOpen}
        user={props.user}
      />
      {props.isAuthenticated && (
        <HeaderBottom
          align={props.subHeaderAlign}
          currentRoute={props.currentRoute}
          defaultAction={props.defaultAction}
          height={props.subHeaderHeight}
          isMobile={props.isMobile}
          isMobileMenuOpen={isMobileMenuOpen}
          paddingLeft={props.headerHeight}
          subLinks={props.subLinks}
          onMobileMenuIconClick={
            props.isMobile ? handleMobileMenuIconClick : null
          }
        />
      )}
      {props.alerts.length ? <Alerts alerts={props.alerts} /> : undefined}
    </Wrap>
  )
}

Header.defaultProps = headerDefaultProps
Header.propTypes = headerPropTypes

export default Header
