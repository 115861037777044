import * as c from 'arena-config'
import React from 'react'
import pure from 'recompose/pure'
import styled from 'styled-components'
import RowTemplate from '../RowTemplate'
import SectionRow from '../SectionRow'
import prepareDefaultResources from '../../utils/prepareDefaultResources'
import {cellWidthProps} from '../../constants/propTypes'
import {sectionDefaultProps, sectionPropTypes} from './propTypes'

const Inner = styled.div`
  border-bottom: ${c.DASHED_BORDER};
  padding-bottom: 0.5em;
`

const Outer = styled.div`
  margin-top: ${c.SMALLER}px;
`

const Section = props => {
  const headerResources = props.showAllResources
    ? props.resources
    : prepareDefaultResources(props.resources)

  return (
    <Outer>
      <Inner>
        <RowTemplate
          {...cellWidthProps(props)}
          duration={props.duration}
          isHeader
          resources={headerResources}
          startYear={props.startYear}
          title={props.name}
        />
      </Inner>
      {props.rows.map(row => (
        <SectionRow
          {...cellWidthProps(props)}
          change={props.change}
          code={row.code}
          delimiter={props.delimiter}
          duration={props.duration}
          enableAutofill={props.enableAutofill}
          enableFormatting={props.enableFormatting}
          formName={props.formName}
          formValues={props.formValues}
          handleBulkChange={props.handleBulkChange}
          isLocked={props.isLocked}
          key={row.code}
          name={row.name}
          resources={props.resources}
          rowsWithBulkEdit={props.rowsWithBulkEdit}
          rowsWithOpenResources={props.rowsWithOpenResources}
          showAllResources={props.showAllResources}
          startYear={props.startYear}
          toggleRowsWithBulkEdit={props.toggleRowsWithBulkEdit}
          toggleRowsWithOpenResources={props.toggleRowsWithOpenResources}
          type={row.type}
          useSingleAmountInsteadOfYears={props.useSingleAmountInsteadOfYears}
        />
      ))}
      <br />
    </Outer>
  )
}

Section.defaultProps = sectionDefaultProps
Section.propTypes = sectionPropTypes

// export default sizeMe()(pure(Section))
export default pure(Section)
