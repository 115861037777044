import {
  FETCH_CHANNEL_SUMMARY_REQUEST,
  FETCH_CHANNEL_SUMMARY_SUCCESS,
  FETCH_CHANNEL_SUMMARY_FAILURE,
} from '../../constants/actionTypes'

const channelSummary = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CHANNEL_SUMMARY_REQUEST:
      return {}
    case FETCH_CHANNEL_SUMMARY_SUCCESS:
      return action.payload
    case FETCH_CHANNEL_SUMMARY_FAILURE:
      return {}
    default:
      return state
  }
}

export default channelSummary
