import {createSelector} from 'reselect'
import {getId} from './router'

export const getPositionTokensById = state =>
  state.initiations.positionTokens.byId

export const getPositionTokensIsFetching = state =>
  state.initiations.positionTokens.isFetching

export const getTokenSections = state => state.initiations.tokenSections

export const getPositionToken = createSelector(
  getId,
  getPositionTokensById,
  (id, entities) => (id in entities ? entities[id] : {})
)

export const getSectionItems = createSelector(
  getTokenSections,
  getPositionToken,
  (sections, entity) =>
    Object.keys(sections).map(key => {
      const section = sections[key]
      const {name, fields} = section
      const result = {fields: [], id: section.id, name}

      for (let i = 0; i < fields.length; i += 1) {
        const field = fields[i]
        const {label, value, valueSuffix} = field
        const hasLabel = label in entity
        const hasValue = value in entity
        const hasValueSuffix = valueSuffix in entity

        if (hasLabel && hasValue) {
          result.fields.push({
            id: field.id,
            label: entity[label],
            value: entity[value],
            valueSuffix: hasValueSuffix ? entity[valueSuffix] : '',
          })
        }
      }

      return result
    })
)
