import React from 'react'
import {notification} from 'antd'
import {
  googleApiUrl,
  googleDiscoveryUrl,
  googleDriveScope,
  googleApiJs,
  googleOpenDocUrl,
  googleOpenFileUrl,
  googleOpenSpreadSheetUrl,
} from '../../constants/api'
import {
  GOOGLE_DRIVE_AUTH_FAILED_MESSAGE,
  GOOGLE_DRIVE_AUTH_UNEXPECTED_ERROR_MESSAGE,
  GOOGLE_DRIVE_POST_FAILED_MESSAGE,
  GOOGLE_DRIVE_POST_UNEXPECTED_ERROR_MESSAGE,
} from '../constants/messages'

let auth2 = null

const handleClientLoad = callback => {
  const gapi = window.gapi

  gapi.load('client:auth2', () => {
    gapi.client
      .init({
        apiKey: process.env.REACT_APP_GOOGLE_OAUTH2_API_KEY,
        clientId: process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID,
        scope: googleDriveScope,
        discoveryDocs: [googleDiscoveryUrl],
      })
      .then(() => {
        gapi.auth2
          .getAuthInstance()
          .signIn()
          .then(() => {
            auth2 = gapi.auth2
            callback(auth2)
          })
          .catch(error => {
            switch (error.error) {
              case 'popup_blocked_by_browser':
                notification['error']({
                  message: GOOGLE_DRIVE_AUTH_FAILED_MESSAGE,
                  description:
                    'The authentication process can not proceed because your browser is blocking pop-ups from this site, please allow pop-ups from this site and try again',
                  duration: 10,
                })
                break
              case 'popup_closed_by_user':
                notification['error']({
                  message: GOOGLE_DRIVE_AUTH_FAILED_MESSAGE,
                  description:
                    'The authentication process was cancelled by the user, please try again to sign-in to your Google account',
                  duration: 10,
                })
                break
              case 'access_denied':
                notification['error']({
                  message: GOOGLE_DRIVE_AUTH_FAILED_MESSAGE,
                  description:
                    'Access to your Google Drive was denied, please grant the necessary access to post files to your Google Drive',
                  duration: 10,
                })
                break
              case 'immediate_failed':
                notification['error']({
                  message: GOOGLE_DRIVE_AUTH_FAILED_MESSAGE,
                  description:
                    'Automatic sign-in was unsuccessful, you may need to logout from your Google account and then try again',
                  duration: 10,
                })
                break

              default:
                break
            }
          })
      })
      .catch(error => {
        if (error.error === `idpiframe_initialization_failed`) {
          notification['error']({
            message: GOOGLE_DRIVE_AUTH_FAILED_MESSAGE,
            description: `This often occurs when your browser is configured to block third party cookies or iframes, please check your browser settings and try again.  Error: ${error.details}`,
            duration: 10,
          })
        } else {
          notification['error']({
            message: GOOGLE_DRIVE_AUTH_FAILED_MESSAGE,
            description: `${GOOGLE_DRIVE_AUTH_UNEXPECTED_ERROR_MESSAGE}  Error: ${error.details}`,
            duration: 10,
          })
        }
      })
  })
}

export const GDriveLogout = () => {
  if (auth2) {
    auth2
      .getAuthInstance()
      .signOut()
      .then(() => {
        auth2.getAuthInstance().disconnect() //revoke scope
      })
      .catch(() => {
        notification['error']({
          message: 'Google account logout failed',
          duration: 5,
        })
      })
  }
}

export const getGDrive = callback => {
  if (
    auth2 &&
    auth2
      .getAuthInstance()
      .currentUser.get()
      .hasGrantedScopes(googleDriveScope)
  ) {
    callback(auth2)
  } else {
    const script = document.createElement('script')
    script.onload = () => handleClientLoad(callback)
    script.src = googleApiJs
    document.body.appendChild(script)
  }
}

export const uploadToGDrive = async res => {
  const gapiToken = window.gapi.client.getToken()
  if (!gapiToken) {
    notification['error']({
      message: GOOGLE_DRIVE_AUTH_FAILED_MESSAGE,
      description: 'An invalid token was returned, please try again',
      duration: 10,
    })
    return false
  } else if (
    !auth2
      .getAuthInstance()
      .currentUser.get()
      .hasGrantedScopes(googleDriveScope)
  ) {
    notification['error']({
      message: GOOGLE_DRIVE_AUTH_FAILED_MESSAGE,
      description:
        'You have successfully signed in to your Google account, however, permission to post files to your Google Drive was not granted, please try again and follow the prompts to grant the appropriate access',
      duration: 10,
    })
    return false
  }

  const authUser = getAuthUser()

  const gapiAccessToken = gapiToken.access_token
  var contentDisposition = res.headers.get('Content-Disposition')
  var result =
    contentDisposition &&
    contentDisposition
      .split(';')[1]
      .trim()
      .split('=')[1]
  var filename = result ? result.replace(/"/g, '') : ''
  var fileExtension = filename.split('.')[1]
  const mimeType = ['doc', 'docx'].includes(fileExtension)
    ? 'application/vnd.google-apps.document'
    : ['xlsx', 'xls'].includes(fileExtension)
    ? 'application/vnd.google-apps.spreadsheet'
    : fileExtension

  if (filename && res.ok) {
    return res.blob().then(async file => {
      const metadata = {
        name: filename.split('.')[0], //Filename at Google Drive
        mimeType, //Mimetype at Google Drive
      }
      const form = new FormData()
      form.append(
        'metadata',
        new Blob([JSON.stringify(metadata)], {type: 'application/json'})
      )
      form.append('file', file)

      try {
        const response = await fetch(googleApiUrl, {
          headers: {
            Authorization: `Bearer ${gapiAccessToken}`,
          },
          body: form,
          method: 'POST',
        })

        const responseData = await response.json()
        const documentId = responseData.id
        const url = ['doc', 'docx'].includes(fileExtension)
          ? googleOpenDocUrl(documentId, authUser)
          : ['xlsx', 'xls'].includes(fileExtension)
          ? googleOpenSpreadSheetUrl(documentId, authUser)
          : googleOpenFileUrl(documentId, authUser)

        notification['success']({
          message: 'Document saved to Google Drive',
          description: (
            <>
              <span key="1">
                The file is opening in a new window, please allow up to 10
                seconds for it to open
              </span>
              <br />
              <br />

              <span key="2">
                Didn't open? Check your browser's pop-up blocker settings and
                try again, or click this
              </span>
              <a href={url} target={'_blank'} rel="noreferrer">
                &nbsp;Direct link
              </a>
            </>
          ),
          duration: 20,
        })

        const openWindow = window.open(url, '_blank')
        if (openWindow) {
          openWindow.focus()
        }
        return true
      } catch (error) {
        notification['error']({
          message: GOOGLE_DRIVE_POST_FAILED_MESSAGE,
          description: `${GOOGLE_DRIVE_POST_UNEXPECTED_ERROR_MESSAGE}  Error: ${error.message}`,
          duration: 10,
        })
        return false
      }
    })
  }
}

const getAuthUser = () => {
  // Attempt to retrieve the Google authUser index - this can be used to generate url links to Google resources
  // The default is 0, if the user has multiple Google accounts each account will have an index, e.g. 0, 1, 2
  let result = '0'

  try {
    // get the current user from the Google auth2 api
    const currentUser = window.gapi.auth2.getAuthInstance().currentUser.get()

    // if there is no current user, assume authUser is 0
    if (currentUser) {
      // first, check known property names that could contain the session_state (vc, wc, etc.)
      // these are obscured by google and can change over time - hence the backup mechanisms for retrieving the authUser below
      if (currentUser.vc || currentUser.wc) {
        result =
          (currentUser.vc &&
            currentUser.vc.session_state.extraQueryParams.authuser) ||
          (currentUser.wc &&
            currentUser.wc.session_state.extraQueryParams.authuser) ||
          '0'
      } else {
        // next, check the auth response - this will usually have the session_state and authUser property
        const authResponse = currentUser.getAuthResponse()

        if (authResponse && authResponse.session_state) {
          result = authResponse.session_state.extraQueryParams.authuser
        } else {
          // if all else fails, dynamically search the currentUser object for a child element named session_state, and use that to retrieve the authUser
          const childWithSessionState = Object.values(currentUser).filter(
            x => x.session_state
          )[0]
          if (childWithSessionState) {
            result =
              childWithSessionState.session_state.extraQueryParams.authuser
          }
        }
      }
    }
  } finally {
    return result
  }
}
