import {Comment, Avatar, List} from 'antd'
import {Icon, Tooltip, ConfirmationModal} from '../../../lib/components/core'
import styled from 'styled-components'
import {useDispatch} from 'react-redux'
import {MentionsInput, Mention} from 'react-mentions'
import {useCallback, useState, useEffect} from 'react'
import moment from 'moment'
import React from 'react'
import {useSelector} from 'react-redux'
import _ from 'lodash'
import classNames from '../../../styles/app/example.module.scss'
import CommentParagraph from './CommentParagraph'
import {getDocuments} from '../../../selectors/document'
import AttachDocument from '../Documents/AttachDocument'
import {
  createComment,
  deleteComment,
  fetchUsers,
} from '../../../actions/comment'
import {
  DELETE_COMMENT_CONFIRMATION,
  DELETE_CONFIRMATION_OF_COMMENT,
} from '../../../constants/comment'
import {Spin} from 'antd'

const AttachmentIcon = styled(props => <Icon {...props} />)`
  display: inline-flex;
  color: white;
  height: 1rem;
  position: relative;
  vertical-align: top;
  width: 100%;
  margin: 0 0 0 0;
  padding: 2 2 2 2;
`
const DeleteIcon = styled(props => <Icon {...props} />)`
  border-style: none;
  margin-top: 3.4px;
  padding-left: 5px;
  cursor: pointer;
  width: fit-content !important;
`

const FlyerWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const AddCommentButton = styled.button`
  padding: 4px;
  margin: auto;
  position: absolute;
  right: 0px;
  color: #000000;
  background: #1cdbbc;
  border: 1px solid #1cdbbc;
  border-radius: 24px;
  cursor: pointer;
  &:hover {
    background: #8bf5e3;
    border: 1px solid #8bf5e3;
  }
`
const Spinner = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #0a838a;
  }
  .ant-spin-text {
    color: teal;
  }
`
const AttachmentClipButton = styled.button`
  padding: 4px;
  left: 0px;
  background: #1cdbbc;
  border: 1px solid #1cdbbc;
  border-radius: 24px;
  cursor: pointer;
  &:hover {
    background: #8bf5e3;
    border: 1px solid #8bf5e3;
  }
`

const getColor = length => {
  const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#5ba072']
  return colorList[length % 5]
}

const user = user =>
  user
    .split('@')[0]
    .split('.')
    .map(word => word[0].toUpperCase())

const Editor = ({
  onChange,
  onSubmit,
  submitting,
  value,
  assignmentId,
  setinfo,
}) => {
  const [shouldShowUploadModal, setShouldShowUploadModal] = useState(false)
  const dispatch = useDispatch()
  const [title, setTitle] = useState(null)
  const [details, setDetails] = useState('')
  const [effectiveDate, setEffectiveDate] = useState()
  const documentList = Object.values(useSelector(state => getDocuments(state)))
  const myDocument = documentList.map(document => ({
    id: document.id,
    display: document.fileName,
  }))
  const fetchUser = useCallback(
    _.debounce((query, callback) => {
      if (query.length < 2) {
        return []
      } else {
        try {
          dispatch(
            fetchUsers(query, json => {
              callback(
                json.items.map(user => ({
                  id: user.email,
                  display: `${user.firstName} ${user.lastName} (${user.email})`,
                }))
              )
            })
          )
        } catch (error) {
          return error
        }
      }
    }, 400),
    []
  )
  return (
    <>
      <MentionsInput
        className="mentions"
        classNames={classNames}
        value={value}
        onChange={onChange}
        allowSuggestionsAboveCursor={true}
        allowSpaceInQuery={true}
      >
        <Mention
          trigger="@"
          className={classNames.mentions__mention}
          data={fetchUser}
        />
        <Mention
          trigger="#"
          className={classNames.mentions__mention}
          data={myDocument}
        />
      </MentionsInput>
      <div
        style={{
          display: 'flex',
        }}
      >
        <Tooltip title="Attach Documents">
          <AttachmentClipButton
            type="primary"
            onClick={() => setShouldShowUploadModal(true)}
          >
            <AttachmentIcon type="fas_paperclip" />
          </AttachmentClipButton>
        </Tooltip>
        <AttachDocument
          shouldShowUploadModal={shouldShowUploadModal}
          setShouldShowUploadModal={setShouldShowUploadModal}
          title={title}
          setTitle={setTitle}
          assignmentId={assignmentId}
          details={details}
          setDetails={setDetails}
          effectiveDate={effectiveDate}
          setEffectiveDate={setEffectiveDate}
          setinfo={setinfo}
        />
        <AddCommentButton onClick={onSubmit}>Add Comment</AddCommentButton>
      </div>
    </>
  )
}

const CommentList = props => {
  const dispatch = useDispatch()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [selectedComment, setselectedComment] = useState({})
  const [showCommentById, setshowCommentById] = useState('')
  const handleDelete = id => {
    dispatch(deleteComment(id))
    props.setComments(props.comments.filter(comment => comment.id !== id))
  }
  return (
    <>
      <List
        dataSource={props.comments}
        itemLayout="horizontal"
        renderItem={item => {
          let initials = (
            <Avatar
              style={{
                color: '#ffffff',
                backgroundColor: getColor(user(item.createdBy).length),
              }}
            >
              {user(item.createdBy)}
            </Avatar>
          )

          const actions = [
            showCommentById === item.id ? (
              <span
                key={item.id}
                onClick={() => {
                  setshowCommentById('')
                }}
              >
                see less
              </span>
            ) : (
              <span
                key={item.id}
                onClick={() => {
                  setshowCommentById(item.id)
                }}
              >
                see more
              </span>
            ),
          ]
          const commentDeleteAction = [
            <span
              key={item.id}
              onClick={() => {
                setShowDeleteConfirmation(true)
                setselectedComment(item)
              }}
            >
              <DeleteIcon type="trash-can" />
            </span>,
          ]

          if (item.text.length > 500) {
            if (showCommentById !== item.id) {
              return (
                <li key={item.id}>
                  <Comment
                    actions={actions}
                    author={item.createdBy}
                    avatar={initials}
                    content={
                      <CommentParagraph
                        positionId={item.positionId}
                        comment={`${item.text.substring(0, 500)} ...`}
                      />
                    }
                    datetime={[
                      moment(item.createdOn).fromNow(),
                      commentDeleteAction,
                    ]}
                  />
                </li>
              )
            } else {
              return (
                <li key={item.id}>
                  <Comment
                    actions={actions}
                    author={item.createdBy}
                    avatar={initials}
                    content={
                      <CommentParagraph
                        positionId={item.positionId}
                        comment={item.text}
                      />
                    }
                    datetime={[
                      moment(item.createdOn).fromNow(),
                      commentDeleteAction,
                    ]}
                  />
                </li>
              )
            }
          } else {
            return (
              <li key={item.id}>
                <Comment
                  author={item.createdBy}
                  avatar={initials}
                  content={
                    <CommentParagraph
                      positionId={item.positionId}
                      comment={item.text}
                    />
                  }
                  datetime={[
                    moment(item.createdOn).fromNow(),
                    commentDeleteAction,
                  ]}
                />
              </li>
            )
          }
        }}
      />
      {showDeleteConfirmation && (
        <ConfirmationModal
          confirmationMessage={DELETE_CONFIRMATION_OF_COMMENT(
            `${selectedComment.text.substring(0, 150)}  ...`
          )}
          confirmationTitle={DELETE_COMMENT_CONFIRMATION}
          formName={DELETE_COMMENT_CONFIRMATION}
          onClose={() => setShowDeleteConfirmation(false)}
          onDelete={() => {
            handleDelete(selectedComment.id)
            setShowDeleteConfirmation(false)
          }}
        />
      )}
    </>
  )
}

const Comments = ({id, cardcomments, isCommentFetching}) => {
  const [comments, setComments] = useState(cardcomments)
  const [info, setinfo] = useState('')
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [value, setValue] = useState('')
  const getAuthor = useSelector(
    state => state.oidc && state.oidc.user && state.oidc.user.profile.name
  )
  let avatar = (
    <Avatar
      style={{
        color: '#ffffff',
        backgroundColor: getColor(user(getAuthor).length),
      }}
    >
      {user(getAuthor)}
    </Avatar>
  )

  const handleSubmit = () => {
    if (!value) {
      return
    }
    dispatch(
      createComment(id, value, resp => {
        setComments([
          ...comments,
          {
            createdBy: resp.createdBy,
            text: resp.text,
            createdOn: resp.createdOn,
            positionId: resp.positionId,
            id: resp.id,
          },
        ])
        setValue('')
        setSubmitting(false)
      })
    )
  }
  useEffect(() => {
    setComments(cardcomments)
  }, [cardcomments])

  useEffect(() => {
    if (info && info.file)
      setValue(`${value} @[${info.file.name}](${info.file.response.id})`)
  }, [info])

  const handleChange = event => {
    setValue(event.target.value)
  }

  return (
    <>
      {isCommentFetching ? (
        <FlyerWrapper>
          <Spinner size="large" />
        </FlyerWrapper>
      ) : (
        <>
          {comments.length > 0 && (
            <CommentList comments={comments} setComments={setComments} />
          )}
          <Comment
            avatar={avatar}
            content={
              <Editor
                assignmentId={id}
                onChange={handleChange}
                onSubmit={handleSubmit}
                value={value}
                submitting={submitting}
                setinfo={setinfo}
              />
            }
          />
        </>
      )}
    </>
  )
}

export default Comments
