import {
  environmentReducer as environment,
  routerReducer as router,
} from 'arena-redux'
import form from 'redux-form/es/reducer'
import {combineReducers} from 'redux'
import {reducer as oidc} from 'redux-oidc'
import initiations from './initiations'
import locale from './locale'
import session from './session'
import idle from './idle'
import companies from './companies'
import errors from './errors'
import exportProgress from './exportProgress'

const rootReducer = combineReducers({
  environment,
  form,
  idle,
  initiations,
  locale,
  oidc,
  router,
  session,
  companies,
  errors,
  exportProgress,
})

export default rootReducer
