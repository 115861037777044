import {isRequired, numberPT, momentObjectPT} from 'arena-prop-types'
import PT from 'prop-types'

const {arrayOf, shape} = PT

export const favoriteProps = {
  id: numberPT(true),
  positionId: numberPT(true),
  companyId: numberPT(true),
  createdOn: momentObjectPT(true),
}

export const favoritePT = is => isRequired(shape(favoriteProps), is)
export const favoritesPT = is => isRequired(arrayOf(favoritePT), is)
