import {Button} from 'arena-components'
import * as c from 'arena-config'
import React from 'react'
import styled from 'styled-components'
import {logoDefaultProps, logoPropTypes} from './propTypes'

const Wrap = styled.div`
  height: 48px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  padding-left: 5px;

  img {
    cursor: pointer;
    height: 100%;
  }

  ${c.FOR_DESKTOP} {
    left: 0;
    transition: opacity 0.25s;
  }
`

const Logo = props => {
  const noPropWarning = () =>
    console.warn(`${'<'}Navigation onLogoClick${'/>'} [func|string]`)

  const handleClick =
    typeof props.onLogoClick !== 'function'
      ? () => props.defaultAction(props.onLogoClick)
      : props.onLogoClick || noPropWarning

  return (
    <Wrap>
      <Button
        onClick={handleClick}
        reset
        style={{
          border: 0,
          height: '100%',
          padding: 0,
        }}
      >
        <img src={props.logoSrc} viewBox="0 0 46 46" width="80" alt="Logo" />
      </Button>
    </Wrap>
  )
}

Logo.defaultProps = logoDefaultProps
Logo.propTypes = logoPropTypes

export default Logo
