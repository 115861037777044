import {createUserManager} from 'redux-oidc'
import {getBaseUrl} from 'arena-redux'
import {AUTHENTICATION_CALLBACK_PATH} from '../constants/router'
import {
  IS_LOCAL,
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE,
  AUTH0_SCOPE,
} from '../constants/api'

const stripTrailingSlash = url => url.replace(/\/+$/, '')
const baseUrl = stripTrailingSlash(getBaseUrl())
const redirectUri = baseUrl.endsWith(AUTHENTICATION_CALLBACK_PATH)
  ? `${baseUrl}/`
  : `${baseUrl}/${AUTHENTICATION_CALLBACK_PATH}/`
const userManagerConfig = {
  authority: `${AUTH0_DOMAIN}`,
  client_id: `${AUTH0_CLIENT_ID}`,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: !IS_LOCAL,
  redirect_uri: `${redirectUri}`,
  response_type: 'id_token token',
  revokeAccessTokenOnSignout: true,
  scope: `${AUTH0_SCOPE}`,
  extraQueryParams: {audience: `${AUTH0_AUDIENCE}`},
}

const userManager = createUserManager(userManagerConfig)

export default userManager
