import cn from 'classnames'
import moment from 'moment'
import Isvg from 'react-inlinesvg'
import PT from 'prop-types'
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import {ICON} from '../constants/icons'

const PT_ICON_SUB_POSITION = props => {
  const positionTypes = [
    'bottom',
    'bottomLeft',
    'bottomRight',
    'center',
    'left',
    'right',
    'top',
    'topLeft',
    'topRight',
  ]
  const {modifiers} = props
  const subPosition = modifiers ? modifiers.subPosition : null

  if (subPosition && !positionTypes.includes(subPosition)) {
    return new Error(`${subPosition} is not a valid Task sub position`)
  }
}

const defaultProps = {
  alt: '',
  cacheRequests: true,
  children: null,
  className: '',
  inlineSVG: false,
  style: {},
  sub: '',
  subPosition: 'center',
  thickness: '',
  uniquifyIDs: false,
}

const propTypes = {
  alt: PT.string,
  cacheRequests: PT.bool,
  children: PT.node,
  className: PT.string,
  inlineSVG: PT.bool,
  style: PT.shape({}),
  sub: PT.string,
  subPosition: props => PT_ICON_SUB_POSITION(props),
  thickness: PT.string,
  type: PT.string.isRequired,
  uniquifyIDs: PT.bool,
}

const FaWrapper = styled.div`
  font-size: 1.25em;
`
const Icon = ({
  alt,
  cacheRequests,
  children,
  className,
  inlineSVG,
  style,
  sub,
  subPosition,
  thickness,
  type,
  uniquifyIDs,
}) => {
  const extraChildren = []
  let realSub = sub
  const realSubPosition = subPosition
  let realType = type

  const makeIcon = iconSrc => {
    const iconProps = {
      src: iconSrc,
    }

    return inlineSVG ? (
      <Isvg {...iconProps} uniquifyIDs={uniquifyIDs} />
    ) : (
      <img {...iconProps} alt={alt} />
    )
  }

  if (type === 'calendar') {
    extraChildren.push(
      <div className="Icon-Calendar-children">{moment().format('D')}</div>
    )
  } else if (type === 'file-document') {
    realSub = 'document'
    realType = 'file'
  } else if (type === 'file-pdf') {
    realSub = 'pdf'
    realType = 'file'
  } else if (type === 'file-spreadsheet' || type === 'file-sheet') {
    realSub = 'spreadsheet'
    realType = 'file'
  } else if (type === 'person-add') {
    realType = 'person' // temporary
  } else if (type === 'file-reject') {
    realType = 'reject'
  } else if (type === 'up-arrow') {
    realType = 'arrow'
  } else if (type.includes('fas_')) {
    realType = type.split('_')
  } else if (type.includes('far_')) {
    realType = type.split('_')
  } else if (type.includes('fab_')) {
    realType = type.split('_')
  }

  const src = ICON(realType, thickness)

  if (typeof realType !== 'string') {
    return (
      <FaWrapper data-component="Icon" className={className}>
        <FontAwesomeIcon icon={realType} size="lg"></FontAwesomeIcon>
      </FaWrapper>
    )
  }

  if (!src) {
    console.warn(`${realType || 'Blank'} is not a valid icon type`)
    return null
  }

  if (realSub) {
    const subThickness =
      thickness !== 'thin' && realSubPosition !== 'center' ? 'thick' : ''
    extraChildren.push(
      <div
        className={cn('Icon-sub', {
          [`sub-${realSubPosition}`]: realSubPosition,
        })}
      >
        {makeIcon(ICON(realSub, subThickness))}
      </div>
    )
  }

  return (
    <div
      data-component="Icon"
      className={cn(
        `Icon-${realType}`,
        {[`Icon-${thickness}`]: thickness},
        className
      )}
      style={style}
    >
      {children}
      {extraChildren.map((child, key) => (
        <div className="Icon-extra-child" key={key}>
          {child}
        </div>
      ))}
      {makeIcon(src)}
    </div>
  )
}

Icon.defaultProps = defaultProps
Icon.propTypes = propTypes

export default Icon
