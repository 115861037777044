import channelsInfo from '../../data/channelsInfo.json'
import {DEFAULT_CHANNEL_ID} from '../../constants/channels'
import {
  CREATE_ASSIGNMENT_SUCCESS,
  DELETE_ASSIGNMENT_SUCCESS,
  FETCH_ASSIGNMENTS_REQUEST,
  FETCH_ASSIGNMENTS_SUCCESS,
  UPDATE_APPROVAL_STATUS_SUCCESS,
  UPDATE_ASSIGNMENT_SUCCESS,
  UPDATE_CHANNEL_FILTERS,
  UPDATE_LAST_ACTIVE_CHANNEL,
  FETCH_AUTOCOMPLETE_ASSIGNMENTS_REQUEST,
  FETCH_AUTOCOMPLETE_ASSIGNMENTS_SUCCESS,
  UPDATE_SELECTED_COMPANY_REQUEST,
  REMOVE_USER_FAVORITE_CHANNEL_ITEM_SUCCESS,
  ADD_USER_FAVORITE_CHANNEL_ITEM_SUCCESS,
} from '../../constants/actionTypes'

const channelInitialState = {
  isFetching: false,
  items: [],
  nextUrl: '',
}

const channelsInitialState = {
  info: channelsInfo,
  lastActiveChannel: DEFAULT_CHANNEL_ID,
  filters: {
    showUserAssignmentsOnly: true,
  },
}

const channel = (state = channelInitialState, action) => {
  switch (action.type) {
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_REQUEST:
      return {...state, isFetching: true}
    case FETCH_ASSIGNMENTS_REQUEST:
      return {...state, isFetching: true, nextUrl: ''}
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items:
          action.meta && action.meta.loadMore
            ? [...new Set([...state.items, ...action.payload.result])]
            : [...new Set([...action.payload.result])],
        nextUrl: action.payload.nextUrl,
      }
    case FETCH_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items:
          action.meta && action.meta.isSearch
            ? [...new Set([...action.payload.result])]
            : [...new Set([...state.items, ...action.payload.result])],
        nextUrl: action.payload.nextUrl,
      }
    case CREATE_ASSIGNMENT_SUCCESS: {
      const newChannelItems = [...state.items]
      newChannelItems.unshift(action.payload.result)

      return {...state, items: newChannelItems}
    }
    case UPDATE_ASSIGNMENT_SUCCESS: {
      const updatedChannelItems = state.items.filter(
        a => a !== action.payload.result
      )
      updatedChannelItems.unshift(action.payload.result)

      return {...state, items: updatedChannelItems}
    }
    case DELETE_ASSIGNMENT_SUCCESS: {
      const newChannelItems = [...state.items]
      const index = newChannelItems.indexOf(action.meta.positionId)
      if (index !== -1) newChannelItems.splice(index, 1)

      return {...state, items: newChannelItems}
    }
    case REMOVE_USER_FAVORITE_CHANNEL_ITEM_SUCCESS:
      if (state.items.length > 0) {
        if (
          action.meta.channel === 'MyFavorites' ||
          action.meta.channel === 'Favorites'
        ) {
          const index = state.items.findIndex(
            item => item === action.meta.positionId
          )
          return {
            ...state,
            items: [
              ...state.items.slice(0, index),
              ...state.items.slice(index + 1),
            ],
          }
        }
      }
      return state
    case ADD_USER_FAVORITE_CHANNEL_ITEM_SUCCESS:
      if (
        action.meta.channel === 'MyFavorites' ||
        action.meta.channel === 'Favorites'
      ) {
        if (state.items.length > 0) {
          return {
            ...state,
            items: [...state.items, action.meta.positionId],
          }
        }
      }
      return state
    case UPDATE_APPROVAL_STATUS_SUCCESS:
    case UPDATE_CHANNEL_FILTERS:
      return {...state, items: [], nextUrl: ''}
    default:
      return state
  }
}

const channels = (state = channelsInitialState, action) => {
  switch (action.type) {
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_REQUEST:
    case FETCH_ASSIGNMENTS_REQUEST:
    case FETCH_AUTOCOMPLETE_ASSIGNMENTS_SUCCESS:
    case FETCH_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        [action.meta.channel]: channel(state[action.meta.channel], action),
      }
    case CREATE_ASSIGNMENT_SUCCESS:
      return {...state, RecentlyUpdated: channel(state.RecentlyUpdated, action)}
    case UPDATE_ASSIGNMENT_SUCCESS:
      return {...state, RecentlyUpdated: channel(state.RecentlyUpdated, action)}
    case ADD_USER_FAVORITE_CHANNEL_ITEM_SUCCESS:
    case REMOVE_USER_FAVORITE_CHANNEL_ITEM_SUCCESS:
    case DELETE_ASSIGNMENT_SUCCESS:
      return {
        ...state,
        [action.meta.channel]: channel(state[action.meta.channel], action),
      }
    case UPDATE_APPROVAL_STATUS_SUCCESS:
      return {
        ...state,
        InProgress: channel(state.InProgress, action),
        PastDue: channel(state.PastDue, action),
        Rejected: channel(state.Rejected, action),
      }
    case UPDATE_LAST_ACTIVE_CHANNEL:
      return {...state, lastActiveChannel: action.channel}
    case UPDATE_CHANNEL_FILTERS:
      return {
        ...channelsInitialState,
        filters: action.filters,
        [action.meta.channel]: channel(state[action.meta.channel], action),
      }
    case UPDATE_SELECTED_COMPANY_REQUEST:
      return action.meta.hasCompanyChanged ? channelsInitialState : state
    default:
      return state
  }
}

export default channels
