import {denormalize} from 'normalizr'
import {createSelector} from 'reselect'
import {timelineSchema} from '../constants/schemas'

const TASKS = state => state.initiations.tasks
const TIMELINES = state => state.initiations.timelines

export const getTasksById = state => TASKS(state).byId

export const getIsTaskProcessing = state => TASKS(state).isTaskProcessing

export const getIsExecutingTaskId = state => TASKS(state).taskBeingExecuted

export const getTimelinesById = state => TIMELINES(state).byId

export const getTimelineIds = state => TIMELINES(state).ids

export const getTimelinesAreFetching = state => TIMELINES(state).isFetching

export const getTimelines = createSelector(
  [getTasksById, getTimelineIds, getTimelinesById],
  (tasksById, ids, timelinesById) =>
    denormalize(ids, [timelineSchema], {
      tasks: tasksById,
      timelines: timelinesById,
    })
)
