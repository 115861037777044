import PT from 'prop-types'
import React, {Component} from 'react'
import {Flyer} from 'arena-components'
import {LOGIN_MESSAGE} from '../../lib/constants/messages'

const defaultProps = {
  message: LOGIN_MESSAGE,
}

const propTypes = {
  children: PT.node.isRequired,
  isAuthenticated: PT.bool.isRequired,
  isLoading: PT.bool.isRequired,
  login: PT.func.isRequired,
  message: PT.string.isRequired,
}

class Auth extends Component {
  componentDidUpdate(prevProps) {
    const {isAuthenticated, isLoading, login} = this.props
    if (!isAuthenticated && !isLoading) login()
  }

  render() {
    const {children, isAuthenticated, message} = this.props

    if (isAuthenticated) return children

    return (
      <div data-component="Auth">
        <Flyer icon="lock" message={message} orange />
      </div>
    )
  }
}

Auth.defaultProps = defaultProps
Auth.propTypes = propTypes

export default Auth
