import PT from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import {FONT_WEIGHT_3, LIGHTER_GRAY, WHITE} from 'arena-config'
import FormSection from '../Form/Section'
import StyledFlex from '../Form/StyledFlex'
import {assignmentAuditLogsPT} from '../../constants/propTypes/assignmentAuditLogs'
import {convertDateTimeToLocalTimezone} from '../../utils/moment'

const Table = styled.table`
  border-collapse: collapse;
`
const StyledButton = styled.button`
  background: #1cdbbc;
  border: 1px solid #1cdbbc;
  color: #000000;
  width: fit-content;
  cursor: pointer;
  padding: 5px 5px;
  border-radius: 24px;
  &:hover {
    background: #8bf5e3;
    border: 1px solid #8bf5e3;
  }
`

const TableBody = styled.tbody``

const TableData = styled.td`
  border: 1px solid ${LIGHTER_GRAY};
  padding: 8px;
  text-align: left;
`

const TableHeading = styled.th`
  border: 1px solid ${LIGHTER_GRAY};
  font-weight: ${FONT_WEIGHT_3};
  padding: 8px;
  text-align: left;
`

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: ${WHITE};
  }
`
const AssignmentAuditLog = ({code, customFormSectionData, name}) => {
  const {
    assignmentAuditLogs,
    assignmentAuditLogsIsFetching,
    assignmentAuditLogsSummary,
    assignmentId,
    fetchAssignmentAuditLog,
  } = customFormSectionData
  const {
    createdBy,
    createdOn,
    updatedBy,
    updatedOn,
  } = assignmentAuditLogsSummary

  const isExecuting = assignmentAuditLogsIsFetching

  return (
    <>
      <FormSection
        code={code}
        columnCount={1}
        formFields={[]}
        name={
          <>
            <span>{name}</span>{' '}
            <StyledButton
              type="button"
              className={cn('auditLogsButton', {isExecuting})}
              onClick={() => fetchAssignmentAuditLog(assignmentId)}
            >
              Show detailed history
            </StyledButton>
          </>
        }
      >
        {Object.keys(customFormSectionData.assignmentAuditLogsSummary).length >
          0 && (
          <StyledFlex columnCount={1}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableHeading>Created by</TableHeading>
                  <TableData>{createdBy}</TableData>
                </TableRow>
                <TableRow>
                  <TableHeading>Created on</TableHeading>
                  <TableData>
                    {convertDateTimeToLocalTimezone(createdOn)}
                  </TableData>
                </TableRow>
                <TableRow>
                  <TableHeading>Last updated by</TableHeading>
                  <TableData>{updatedBy}</TableData>
                </TableRow>
                <TableRow>
                  <TableHeading>Last updated on</TableHeading>
                  <TableData>
                    {convertDateTimeToLocalTimezone(updatedOn)}
                  </TableData>
                </TableRow>
              </TableBody>
            </Table>
          </StyledFlex>
        )}
      </FormSection>
      {assignmentAuditLogs && assignmentAuditLogs.length > 0 && (
        <FormSection
          code={code}
          columnCount={1}
          formFields={[]}
          name="Detailed history"
        >
          <StyledFlex columnCount={1}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableHeading>When</TableHeading>
                  <TableHeading>Who</TableHeading>
                  <TableHeading>What</TableHeading>
                </TableRow>
                {assignmentAuditLogs.map(auditLogItem => (
                  <TableRow key={auditLogItem.id}>
                    <TableData>{auditLogItem.actionOn}</TableData>
                    <TableData>{auditLogItem.actionBy}</TableData>
                    <TableData>{auditLogItem.action}</TableData>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledFlex>
        </FormSection>
      )}
    </>
  )
}

AssignmentAuditLog.defaultTypes = {
  customFormSectionData: PT.shape({
    assignmentAuditLogs: [],
    assignmentAuditLogsIsFetching: false,
    assignmentAuditLogsSummary: PT.shape({
      createdBy: '',
      createdOn: '',
      updatedBy: '',
      updatedOn: '',
    }),
  }),
}

AssignmentAuditLog.propTypes = {
  customFormSectionData: PT.shape({
    assignmentAuditLogs: assignmentAuditLogsPT(),
    assignmentAuditLogsIsFetching: PT.bool,
    assignmentAuditLogsSummary: PT.shape({
      createdBy: PT.string,
      createdOn: PT.string,
      updatedBy: PT.string,
      updatedOn: PT.string,
    }).isRequired,
    assignmentId: PT.number.isRequired,
  }).isRequired,
  code: PT.string.isRequired,
  name: PT.string.isRequired,
}

export default AssignmentAuditLog
