import React from 'react'
import styled from 'styled-components'
import Highlight from 'react-highlighter'
import {showMessage} from '../../../../Message'

const ListItem = styled.li`
  background-color: transparent;
  padding: 8px 12px;
  cursor: pointer;
  &: hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 1);
  }
`

const highlightStyles = {
  background: 'transparent',
  fontWeight: 'bold',
  padding: 0,
}

const SearchResultItem = props => {
  const handleClick = event => {
    try {
      props.handleChange(props.item.value)
      props.toggleSearchResult()
      props.handleSearchOnSelect(props.item.value)
    } catch (error) {
      showMessage(
        'Sorry, an unexpected error occurred while processing your request.',
        'error'
      )
      console.error(error)
    }
  }

  return (
    <ListItem onClick={event => handleClick(event)} value={props.item.value}>
      <span>
        <Highlight search={props.highlight} matchStyle={highlightStyles}>
          {props.item.title}
        </Highlight>
        {props.item.description && (
          <span>
            {' '}
            -{' '}
            <Highlight search={props.highlight} matchStyle={highlightStyles}>
              {props.item.description}
            </Highlight>
          </span>
        )}
      </span>
    </ListItem>
  )
}

export default SearchResultItem
