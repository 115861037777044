import {combineReducers} from 'redux'
import {FETCH_ACCOUNT_SECTIONS_SUCCESS} from '../../../constants/actionTypes'

const accountFieldsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACCOUNT_SECTIONS_SUCCESS:
      return action.payload.entities.accountFields || state
    default:
      return state
  }
}

const accountFields = combineReducers({
  byId: accountFieldsById,
})

export default accountFields
