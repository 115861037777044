import {
  boolPT,
  funcPT,
  nodePT,
  numberPT,
  stringPT,
  momentObjectOrStringPT,
} from 'arena-prop-types'
import AntDModal from 'antd/lib/modal'
import 'antd/lib/modal/style/index.css'
import React, {useState} from 'react'
import AddApprover from '../AddApprover'
import {
  ADD_APPROVER_FORM,
  DELETE_ASSIGNMENT_CONFIRMATION_FORM,
  REJECT_ASSIGNMENT_CONFIRMATION_FORM,
} from '../../constants/form'
import {
  Banner,
  Button,
  Icon,
  Modal,
  ConfirmationModal,
  Tooltip,
} from '../../lib/components/core'
import {COUNTRY_BANNER_SRC} from '../../constants/images'
import {
  DELETE_ASSIGNMENT_CONFIRMATION_MESSAGE,
  REJECT_OR_DELETE_ASSIGNMENT_CONFIRMATION_TITLE,
  REJECT_ASSIGNMENT_CONFIRMATION_MESSAGE,
} from '../../constants/validationMessages'
import {
  EDIT_ASSIGNMENT_PATH,
  CREATE_ASSIGNMENT_PATH,
} from '../../constants/router'
import {resourcePT} from '../../constants/propTypes/common'
import {
  durationInMonths,
  getStartDateOrYearForCard,
  getMonthForDate,
} from '../../utils/moment'
import {useDispatch, useSelector} from 'react-redux'
import {compileHash, makeRoute} from '../../utils/router'
import FavoriteToggle from './FavoriteToggle'
import {favoritesPT} from '../../constants/propTypes/favorite'
import {
  fetchDocuments,
  fetchDocumentCategoryAndSubcategory,
} from '../../actions/document'
import Document from './Documents/Document'
import Comments from './Comments/Comments'
import {fetchComments} from '../../actions/comment'

const AssignmentCard = ({
  addApprover,
  assignmentAddingApprover,
  canDeleteAssignmentId,
  canRejectAssignmentId,
  children,
  className,
  deleteAssignment,
  disableDeleteAssignment,
  disableRejectAssignment,
  employeeId,
  enableDeleteAssignment,
  enableRejectAssignment,
  setCopyAssignmentId,
  endDate,
  favorites,
  firstName,
  hasComments,
  hasDocuments,
  hostCountry,
  id,
  isCopyAssignmentDisabled,
  isLocked,
  lastName,
  navigateTo,
  updateApprovalStatus,
  startDate,
  timelinesAreFetching,
  timelineLoaded,
  title,
  toggleAddApprover,
}) => {
  const bannerImage = code => COUNTRY_BANNER_SRC.replace(':id', code)
  const duration = durationInMonths(startDate, endDate)
  const shouldShowAddApprover = id === assignmentAddingApprover
  const shouldShowDeleteConfirmation = id === canDeleteAssignmentId
  const shouldShowRejectConfirmation = id === canRejectAssignmentId
  const startDateOrYear = getStartDateOrYearForCard(startDate)
  const startMonth = getMonthForDate(startDate)
  const dispatch = useDispatch()
  const areDocumentsStale = useSelector(
    state => state.initiations.documents.isStale
  )

  const [shouldShowDocumentModal, setShouldShowDocumentModal] = useState(false)
  const [shouldShowCommentModal, setShouldShowCommentModal] = useState(false)
  const [cardcomments, setcardcomments] = useState([])
  const [isCommentFetching, setisCommentFetching] = useState(false)
  const buttonProps = {
    className:
      'AssignmentCard-shortcut borderedOnHover borderless round transparentOnHover white',
  }

  const iconProps = {
    className: 'AssignmentCard-shortcut-icon white',
    inlineSVG: true,
  }

  const shortcuts =
    timelinesAreFetching && !timelineLoaded ? (
      <div className="AssignmentCard-shortcuts" />
    ) : (
      <div className="AssignmentCard-shortcuts">
        <Tooltip title="Comments">
          <span>
            <Button
              {...buttonProps}
              onClick={() => {
                setShouldShowCommentModal(true)
                dispatch(fetchDocuments(id))
                dispatch(fetchDocumentCategoryAndSubcategory)
                setisCommentFetching(true)
                dispatch(
                  fetchComments(id, json => {
                    setcardcomments(json)
                    setisCommentFetching(false)
                  })
                )
              }}
              style={{paddingLeft: '1px'}}
            >
              <Icon
                {...iconProps}
                type={hasComments ? 'fas_comments' : 'far_comment'}
              />
            </Button>
          </span>
        </Tooltip>
        {!isCopyAssignmentDisabled && (
          <Tooltip title="Copy assignment card">
            <span>
              <Button
                {...buttonProps}
                onClick={() => {
                  setCopyAssignmentId(id)
                  navigateTo(makeRoute({path: CREATE_ASSIGNMENT_PATH}))
                }}
                style={{paddingLeft: '1px'}}
              >
                <Icon {...iconProps} type="fas_clone" />
              </Button>
            </span>
          </Tooltip>
        )}
        <Tooltip title="Documents">
          <span>
            <Button
              {...buttonProps}
              onClick={() => {
                setShouldShowDocumentModal(true)
                dispatch(fetchDocuments(id))
                dispatch(fetchDocumentCategoryAndSubcategory)
              }}
              style={{paddingLeft: '3px'}}
            >
              <Icon
                {...iconProps}
                type={hasDocuments ? 'doc-paperclip' : 'fas_paperclip'}
              />
            </Button>
          </span>
        </Tooltip>
        <FavoriteToggle
          id={id}
          favorites={favorites}
          buttonProps={buttonProps}
        />
        <Tooltip title="Edit">
          <span>
            <Button
              {...buttonProps}
              href={`${compileHash({
                path: EDIT_ASSIGNMENT_PATH,
                keys: {id},
                options: {},
              })}`}
              onClick={() =>
                navigateTo({
                  keys: {id},
                  options: {},
                  path: EDIT_ASSIGNMENT_PATH,
                })
              }
            >
              <Icon {...iconProps} type="pencil" />
            </Button>
          </span>
        </Tooltip>
        {!isLocked && (
          <Tooltip title="Reject assignment">
            <span>
              <Button
                {...buttonProps}
                onClick={() => enableRejectAssignment(id)}
              >
                <Icon {...iconProps} type="file-reject" />
              </Button>
            </span>
          </Tooltip>
        )}
        {!isLocked && (
          <Tooltip title="Delete assignment">
            <span>
              <Button
                {...buttonProps}
                onClick={() => enableDeleteAssignment(id)}
              >
                <Icon {...iconProps} type="trash-can" />
              </Button>
            </span>
          </Tooltip>
        )}
      </div>
    )

  const top = hostCountry && (
    <Banner
      className="AssignmentCard-top"
      mainSrc={bannerImage(hostCountry.value)}
      thumbSrc={bannerImage(`${hostCountry.value}_thumb`)}
    >
      <div className="AssignmentCard-top-content">
        {shortcuts}
        <div className="AssignmentCard-title">{title}</div>
        <div className="AssignmentCard-duration">
          {hostCountry.label}, {duration} months
        </div>
      </div>
    </Banner>
  )
  const fullName = `${firstName} ${lastName}`

  const middle = startDate && (
    <div className="AssignmentCard-middle">
      <div className="AssignmentCard-initials">
        <span className="AssignmnetCard-Month">{startMonth}</span>
        <span className="AssignmnetCard-Year">{startDateOrYear}</span>
      </div>
      <div className="AssignmentCard-employee">
        <div className="AssignmentCard-employee-name">
          <Tooltip title={fullName}>
            <span className="Assignment-employee-name-span">{fullName}</span>
          </Tooltip>
        </div>
        <div className="AssignmentCard-employee-id">
          <span>{employeeId}</span>
        </div>
      </div>
    </div>
  )

  const bottom = children && (
    <div className="AssignmentCard-bottom">{children}</div>
  )
  return (
    <div>
      {
        <AntDModal
          visible={shouldShowDocumentModal}
          onCancel={() => setShouldShowDocumentModal(false)}
          footer={null}
          width={'60%'}
        >
          <Document
            assignmentId={id}
            firstName={firstName}
            lastName={lastName}
            employeeId={employeeId}
            hostCountry={hostCountry}
            isStale={areDocumentsStale}
          ></Document>
        </AntDModal>
      }
      {
        <AntDModal
          visible={shouldShowCommentModal}
          onCancel={() => setShouldShowCommentModal(false)}
          footer={null}
          width={'60%'}
        >
          <Comments
            id={id}
            cardcomments={cardcomments}
            isCommentFetching={isCommentFetching}
          />
        </AntDModal>
      }
      {shouldShowRejectConfirmation && (
        <ConfirmationModal
          confirmationMessage={REJECT_ASSIGNMENT_CONFIRMATION_MESSAGE}
          confirmationTitle={REJECT_OR_DELETE_ASSIGNMENT_CONFIRMATION_TITLE(
            employeeId,
            firstName,
            hostCountry.label,
            lastName,
            'Reject'
          )}
          formName={REJECT_ASSIGNMENT_CONFIRMATION_FORM}
          onClose={() => disableRejectAssignment()}
          onReject={() => updateApprovalStatus(id, employeeId, 'Rejected', '')}
        />
      )}
      {shouldShowDeleteConfirmation && (
        <ConfirmationModal
          confirmationMessage={DELETE_ASSIGNMENT_CONFIRMATION_MESSAGE}
          confirmationTitle={REJECT_OR_DELETE_ASSIGNMENT_CONFIRMATION_TITLE(
            employeeId,
            firstName,
            hostCountry.label,
            lastName,
            'Delete'
          )}
          formName={DELETE_ASSIGNMENT_CONFIRMATION_FORM}
          onClose={() => disableDeleteAssignment()}
          onDelete={() => deleteAssignment(canDeleteAssignmentId)}
        />
      )}
      {shouldShowAddApprover && (
        <Modal onClose={() => toggleAddApprover()}>
          <AddApprover
            formName={ADD_APPROVER_FORM}
            onSubmit={approver => addApprover(approver, employeeId, id)}
            onSubmitSuccess={() => toggleAddApprover()}
          />
        </Modal>
      )}
      <div className={`AssignmentCard ${className}`}>
        {top}
        {middle}
        {bottom}
      </div>
    </div>
  )
}

AssignmentCard.defaultProps = {
  assignmentAddingApprover: null,
  canDeleteAssignmentId: null,
  canRejectAssignmentId: null,
  children: null,
  className: '',
  employeeId: '',
  firstName: '',
  lastName: '',
  isCopyAssignmentDisabled: false,
}

AssignmentCard.propTypes = {
  addApprover: funcPT(true),
  assignmentAddingApprover: numberPT(),
  canDeleteAssignmentId: numberPT(),
  canRejectAssignmentId: numberPT(),
  children: nodePT(),
  className: stringPT(),
  deleteAssignment: funcPT(true),
  updateApprovalStatus: funcPT(true),
  disableDeleteAssignment: funcPT(true),
  disableRejectAssignment: funcPT(true),
  employeeId: stringPT(),
  enableDeleteAssignment: funcPT(true),
  enableRejectAssignment: funcPT(true),
  endDate: stringPT(true),
  favorites: favoritesPT(),
  firstName: stringPT(),
  hostCountry: resourcePT(),
  id: numberPT(true),
  isCopyAssignmentDisabled: boolPT(),
  isLocked: boolPT(true),
  lastName: stringPT(),
  navigateTo: funcPT(true),
  startDate: momentObjectOrStringPT(true),
  timelinesAreFetching: boolPT(true),
  timelineLoaded: boolPT(true),
  title: stringPT(true),
  toggleAddApprover: funcPT(true),
}

export default AssignmentCard
