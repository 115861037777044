import {TABLET_WIDTH} from 'arena-config'
import {createSelector} from 'reselect'

const getEnvironment = state => state.environment

export const getHeight = state => getEnvironment(state).height
export const getWidth = state => getEnvironment(state).width

export const getIsMobile = createSelector(
  getWidth,
  width => width < TABLET_WIDTH
)
