import React from 'react'
import styled from 'styled-components'

const makeProps = ({isSearchResultOpen, ...rest}) => <input {...rest} />

const Input = styled(makeProps)`
  border: none;
  color: inherit;
  display: inline-block;
  font-weight: notmal;
  height: 48px;
  line-height: normal;
  padding: 14px 10px 14px 41px;
  margin: 0px 16px;
  width: ${props =>
    props.isSearchResultOpen || props.value.length > 0 ? 'auto' : '108px'};
  &::placeholder {
    color: inherit;
    font-weight: normal;
  }

  &:focus {
    font-weight: normal;
    width: auto;
    ::placeholder {
      opacity: 0.3;
    }
  }
`

const SearchInput = props => {
  return (
    <Input
      isSearchResultOpen={props.isSearchResultOpen}
      onKeyUp={event => props.onKeyUp(event)}
      onChange={event => props.onChange(event.currentTarget.value)}
      placeholder="Search"
      type="text"
      value={props.value}
    />
  )
}

export default SearchInput
