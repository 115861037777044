import React from 'react'
import TaskAction from './TaskAction'

const TaskActions = props => {
  const {
    actions,
    assignmentId,
    errors,
    fetchFormSectionsIfNeeded,
    formFields,
    refreshCardOnComplete,
    status,
    taskBeingExecuted,
    taskId,
    toggleUpdateAssignmentOptions,
    updateAssignmentOptionsAndExecuteTask,
    updateTaskIfNeeded,
    updatingAssignmentOptions,
    assignmentOptionIsUpdating,
    dueDate,
  } = props

  return (
    <div className="Task-actions">
      {actions.map((action, index) => (
        <TaskAction
          key={index}
          action={action}
          assignmentId={assignmentId}
          errors={errors}
          fetchFormSectionsIfNeeded={fetchFormSectionsIfNeeded}
          formFields={formFields}
          refreshCardOnComplete={refreshCardOnComplete}
          status={status}
          taskBeingExecuted={taskBeingExecuted}
          taskId={taskId}
          toggleUpdateAssignmentOptions={toggleUpdateAssignmentOptions}
          updateAssignmentOptionsAndExecuteTask={
            updateAssignmentOptionsAndExecuteTask
          }
          updateTaskIfNeeded={updateTaskIfNeeded}
          updatingAssignmentOptions={updatingAssignmentOptions}
          assignmentOptionIsUpdating={assignmentOptionIsUpdating}
          dueDate={dueDate}
        />
      ))}
    </div>
  )
}

export default TaskActions
