import React from 'react'
import styled from 'styled-components'
import {FOR_DESKTOP} from 'arena-config'
import {funcPT, stringPT} from 'arena-prop-types'
import Link from '../../lib/components/Link'

const StyledLink = styled(Link)`
  ${FOR_DESKTOP} {
    color: #0a838a;
    font-weight: 400;
    font-size: larger;
    font-style: italic;
    &:hover {
      text-decoration: underline;
      color: #8bf5e3;
      font-weight: 400;
    }
  }
`

const ChannelStatus = props => (
  <div>
    <span>There are no {props.effect}, please select another channel or </span>
    <StyledLink navigateTo={props.navigateTo} path="initiations/create">
      create an assignment
    </StyledLink>
  </div>
)

ChannelStatus.propTypes = {
  effect: stringPT(true),
  navigateTo: funcPT(true),
}

export default ChannelStatus
