import PT from 'prop-types'

export const WebsiteNameDefaultProps = {
  onClick: null,
}

export const WebsiteNamePropTypes = {
  defaultAction: PT.func,
  onClick: PT.func,
}
