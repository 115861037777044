import {createSelector} from 'reselect'

export const getChannelSummary = state => state.initiations.channelSummary

export const getChannelSummaryCount = createSelector(
  [getChannelSummary],
  channelSummary => {
    return channelSummary.count
  }
)
