import PT from 'prop-types'
import {commonPropTypes, hasValidResources} from '../../propTypes'

export const rowTemplateDefaultProps = {
  amounts: null,
  children: null,
  isHeader: false,
  resources: {},
  width: undefined,
}

export const rowTemplatePropTypes = {
  ...commonPropTypes,
  amounts: PT.node,
  children: PT.node,
  isHeader: PT.bool,
  resources: hasValidResources,
  startYear: PT.number.isRequired,
  title: PT.node.isRequired,
  width: PT.number,
}
