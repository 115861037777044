import {RSAA, getJSON} from 'redux-api-middleware'
import {
  GET_USER_FAVORITES_URL,
  SET_REMOVE_USER_FAVORITE_URL,
} from '../constants/api'
import {
  GET_USER_FAVORITES_REQUEST,
  GET_USER_FAVORITES_SUCCESS,
  GET_USER_FAVORITES_FAILURE,
  SET_USER_FAVORITE_REQUEST,
  SET_USER_FAVORITE_SUCCESS,
  SET_USER_FAVORITE_FAILURE,
  REMOVE_USER_FAVORITE_REQUEST,
  REMOVE_USER_FAVORITE_SUCCESS,
  REMOVE_USER_FAVORITE_FAILURE,
  REMOVE_USER_FAVORITE_CHANNEL_ITEM_SUCCESS,
  ADD_USER_FAVORITE_CHANNEL_ITEM_SUCCESS,
} from '../constants/actionTypes'
import {getFavorites} from '../selectors/users'

export const getUserFavoritesRequest = () => {
  return {type: GET_USER_FAVORITES_REQUEST}
}

export const setUserFavoriteRequest = positionId => {
  return {type: SET_USER_FAVORITE_REQUEST, positionId}
}

export const removeUserFavoriteRequest = positionId => {
  return {type: REMOVE_USER_FAVORITE_REQUEST, positionId}
}

export const getUserFavorites = () => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: GET_USER_FAVORITES_URL(),
      method: 'GET',
      types: [
        GET_USER_FAVORITES_REQUEST,
        {
          type: GET_USER_FAVORITES_SUCCESS,
          payload: (_action, _currentState, res) => getJSON(res),
        },
        {
          type: GET_USER_FAVORITES_FAILURE,
          meta: (_action, _currentState, res) => {
            if (res) {
              return {
                status: res.status,
                statusText: res.statusTest,
              }
            } else {
              return {
                status: 'Network request failed',
              }
            }
          },
        },
      ],
    },
  })

export const setUserFavorite = positionId => dispatch => {
  dispatch({
    [RSAA]: {
      endpoint: SET_REMOVE_USER_FAVORITE_URL(positionId),
      method: 'POST',
      types: [
        SET_USER_FAVORITE_REQUEST,
        {
          type: SET_USER_FAVORITE_SUCCESS,
          payload: (_action, _currentState, res) =>
            getJSON(res).then(json => {
              dispatch({
                type: ADD_USER_FAVORITE_CHANNEL_ITEM_SUCCESS,
                meta: {
                  channel: 'Favorites',
                  positionId: json.positionId,
                },
              })
              return json
            }),
        },
        {
          type: SET_USER_FAVORITE_FAILURE,
          meta: (_action, _currentState, res) => {
            if (res) {
              return {
                status: res.status,
                statusText: res.statusTest,
              }
            } else {
              return {
                status: 'Network request failed',
              }
            }
          },
        },
      ],
    },
  })
}

export const removeUserFavorite = positionId => (dispatch, getState) => {
  dispatch({
    [RSAA]: {
      endpoint: SET_REMOVE_USER_FAVORITE_URL(positionId),
      method: 'DELETE',
      types: [
        REMOVE_USER_FAVORITE_REQUEST,
        {
          type: REMOVE_USER_FAVORITE_SUCCESS,
          payload: (_action, _currentState, res) => {
            dispatch({
              type: REMOVE_USER_FAVORITE_CHANNEL_ITEM_SUCCESS,
              meta: {channel: 'Favorites', positionId},
            })
          },
          meta: {positionId},
        },
        {
          type: REMOVE_USER_FAVORITE_FAILURE,
          meta: (_action, _currentState, res) => {
            if (res) {
              return {
                status: res.status,
                statusText: res.statusTest,
              }
            } else {
              return {
                status: 'Network request failed',
              }
            }
          },
        },
      ],
    },
  })
}

export const fetchUserFavoritesIfNeeded = (forceReload = false) => (
  dispatch,
  getState
) => {
  if (forceReload) {
    dispatch(getUserFavorites())
  } else {
    const state = getState()
    if (getFavorites(state).length === 0) {
      dispatch(getUserFavorites())
    }
  }
}
