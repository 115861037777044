import {compileHash} from '../../utils/router'
import _ from 'lodash'

export const logPageView = (path, customDimensions) => {
  try {
    // set the date of the page view
    window.gtag('js', new Date())

    // log the page view using the path params, and include any custom dimensions provided
    // Design Note: page_path is transformed to encode the hash #, based on a change to google analytics on
    // Aug-14 that is stripping out the query params (e.g. channel=All) when the path starts with a #
    // Encoding the hash as %23 resolves the issue and the page view is logged with the query path included
    window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
      ...customDimensions,
      page_title: document.title,
      page_location: window.location.href,
      page_path: `/%23${compileHash(path).slice(1)}`,
    })
  } catch (e) {
    console.log('Error sending analytics data - ' + e.toString())
  }
}

export const logEventTrigger = (category, action, label, customDimensions) => {
  try {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      // set the date of the event
      window.gtag('js', new Date())

      // log the event using the category, action and label params, converting the label to json if it is not a string
      window.gtag('event', process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
        ...customDimensions,
        event_category: category,
        event_action: action,
        event_label: _.isString(label) ? label : JSON.stringify(label),
      })
    }
  } catch (e) {
    console.log('Error sending analytics data - ' + e.toString())
  }
}
