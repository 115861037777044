import * as c from 'arena-config'
import React from 'react'
import pure from 'recompose/pure'
import styled from 'styled-components'
import Amounts from '../Amounts'
import ExpandButton from '../ExpandButton'
import Resources from '../Resources'
import RowTemplate from '../RowTemplate'
import RowTitle from '../RowTitle'
import checkIfAnyFieldInThisRowHasValue from '../../utils/checkIfAnyFieldInThisRowHasValue'
import prepareActiveYears from '../../utils/prepareActiveYears'
import prepareDefaultResources from '../../utils/prepareDefaultResources'
import {cellWidthProps} from '../../constants/propTypes'
import {sectionRowDefaultProps, sectionRowPropTypes} from './propTypes'

const Inner = styled.div`
  background-color: ${props => (props.isBulkEditingRow ? c.BLUE : undefined)};
  box-shadow: ${props =>
    props.isBulkEditingRow
      ? 'inset 0 -1px 0 rgba(255, 255, 255, .25)'
      : undefined};
  color: ${props => (props.isBulkEditingRow ? c.WHITE : undefined)};
  padding: ${props => (props.hasPadding ? '1.5em' : '1em')} 0;
  transition: all ${c.ANIMATION_SPEED_SLOW};

  ${c.FOR_DESKTOP} {
    padding-right: ${c.SMALL}px;
  }
`

const Outer = styled.div`
  &:nth-child(odd) {
    background-color: rgba(80, 100, 120, 0.0375);
    border-radius: ${c.BORDER_RADIUS};
  }
`

class SectionRow extends React.PureComponent {
  state = {active: false}

  render() {
    const {props} = this
    const toggleActive = active => this.setState({active})

    const {code, resources} = props

    const activeYears = prepareActiveYears(props.type)
    const isBulkEditingRow = props.rowsWithBulkEdit.includes(code)
    const isOpen = props.rowsWithOpenResources.includes(code)

    const isManuallyShowingResouces = isBulkEditingRow || isOpen
    const toggleRowsWithOpenResources = () =>
      isBulkEditingRow
        ? props.toggleRowsWithBulkEdit(code)
        : props.toggleRowsWithOpenResources(code)

    const isShowingResources =
      isManuallyShowingResouces || props.showAllResources

    const activeResources = isShowingResources
      ? resources
      : prepareDefaultResources(resources)

    const aFieldInThisRowHasValue = checkIfAnyFieldInThisRowHasValue(
      code,
      props.formValues
    )

    const renderedAmounts = (
      <Amounts
        activeYears={activeYears}
        amountFieldWidth={props.amountFieldWidth}
        change={props.change}
        code={code}
        delimiter={props.delimiter}
        duration={props.duration}
        enableAutofill={props.enableAutofill}
        enableFormatting={props.enableFormatting}
        formName={props.formName}
        formValues={props.formValues}
        isLocked={props.isLocked}
        isBulkEditingRow={isBulkEditingRow}
        isHovering={this.state.active}
        isManuallyShowingResouces={isManuallyShowingResouces}
        showAllResources={props.showAllResources}
        startYear={props.startYear}
        type={props.type}
        useSingleAmountInsteadOfYears={props.useSingleAmountInsteadOfYears}
      />
    )

    const renderedTitle = (
      <RowTitle
        code={code}
        isActive={isManuallyShowingResouces}
        name={props.name}
        toggleRowsWithBulkEdit={props.toggleRowsWithBulkEdit}
      >
        {!props.showAllResources && (
          <ExpandButton
            isActive={isManuallyShowingResouces}
            isBulkEditingRow={isBulkEditingRow}
            isHovering={this.state.active}
            onClick={toggleRowsWithOpenResources}
          />
        )}
      </RowTitle>
    )

    return (
      <Outer
        onMouseEnter={() => toggleActive(true)}
        onMouseLeave={() => toggleActive()}
      >
        <Inner
          hasPadding={aFieldInThisRowHasValue && isManuallyShowingResouces}
          isBulkEditingRow={isBulkEditingRow}
          isManuallyShowingResouces={isManuallyShowingResouces}
          showAllResources={props.showAllResources}
        >
          <RowTemplate
            {...cellWidthProps(props)}
            amounts={renderedAmounts}
            duration={props.duration}
            startYear={props.startYear}
            title={renderedTitle}
          >
            <Resources
              change={props.change}
              delimiter={props.delimiter}
              formName={props.formName}
              handleBulkChange={props.handleBulkChange}
              isLocked={props.isLocked}
              isBulkEditing={isBulkEditingRow}
              isHovering={this.state.active}
              isManuallyShowingResouces={isManuallyShowingResouces}
              prefix={code}
              resourceFieldWidth={props.resourceFieldWidth}
              resources={activeResources}
              rowsWithBulkEdit={props.rowsWithBulkEdit}
              showAllResources={props.showAllResources}
            />
          </RowTemplate>
        </Inner>
      </Outer>
    )
  }
}

SectionRow.defaultProps = sectionRowDefaultProps
SectionRow.propTypes = sectionRowPropTypes

export default pure(SectionRow)
