import PT from 'prop-types'
import React from 'react'
import {emptyObjectPT} from 'arena-prop-types'
import FormSection from '../Form/Section'
import {
  NO_TAX_FIELDS_MESSAGE,
  NO_TAX_SECTIONS_MESSAGE,
} from '../../constants/validationMessages'
import {resourcesPT} from '../../constants/propTypes/resources'
import {taxSectionsPT} from '../../constants/propTypes/taxSections'

const loadingMessage = 'Loading relevant tax options, please wait...'

const hasLocationsSelected = currentValues => {
  const currentValueByKey = key =>
    currentValues[key] && currentValues[key].value

  return (
    currentValues &&
    (currentValueByKey([
      'options.General__HomeIncomeTaxLocation__List.value',
    ]) ||
      currentValueByKey([
        'options.General__HostIncomeTaxLocation__List.value',
      ]) ||
      currentValueByKey([
        'options.General__HomeSocialTaxLocation__List.value',
      ]) ||
      currentValueByKey(['options.General__HostSocialTaxLocation__List.value']))
  )
}

const shouldDisplaySection = (section, currentValues) =>
  section && hasLocationsSelected(currentValues)

const shouldDisplayLoading = customFormSectionData =>
  customFormSectionData &&
  customFormSectionData.taxSections &&
  customFormSectionData.taxSections.length > 0
    ? null
    : loadingMessage

const noDataPlaceholderMessage = (customFormSectionData, currentValues) =>
  !hasLocationsSelected(currentValues)
    ? NO_TAX_SECTIONS_MESSAGE
    : shouldDisplayLoading(customFormSectionData)

const TaxOptions = ({
  customFormSectionData,
  resources,
  currentValues,
  helpText,
}) => (
  <div className="TaxOptions" key="TaxOptionsSection">
    {hasLocationsSelected(currentValues) &&
    customFormSectionData.taxSections &&
    customFormSectionData.taxSections.length > 0 ? (
      customFormSectionData.taxSections.map(
        section =>
          shouldDisplaySection(section, currentValues) && (
            <FormSection
              code={section.code}
              columnCount={2}
              formFields={section.taxFields}
              isLocked={customFormSectionData.isLocked}
              key={section.code}
              name={`${section.name}`}
              noDataPlaceholderMessage={NO_TAX_FIELDS_MESSAGE}
              resources={resources}
            />
          )
      )
    ) : (
      <FormSection
        code="TaxOptionsSection"
        columnCount={1}
        formFields={[]}
        helpText={helpText}
        isLocked={customFormSectionData.isLocked}
        key="TaxOptionsSection"
        name="Tax Options"
        noDataPlaceholderMessage={noDataPlaceholderMessage(
          customFormSectionData,
          currentValues
        )}
        resources={resources}
      />
    )}
  </div>
)

TaxOptions.propTypes = {
  currentValues: emptyObjectPT(true),
  customFormSectionData: PT.shape({
    taxSections: taxSectionsPT(true),
  }).isRequired,
  resources: resourcesPT(true),
}

export default TaxOptions
