import PT from 'prop-types'
import React from 'react'
import {
  boolPT,
  numberPT,
  funcPT,
  stringPT,
  emptyObjectPT,
} from 'arena-prop-types'
import Task from '../Task'
import {Blokk, ToggleHeight} from '../../lib/components/core'
import {formFieldPT} from '../../constants/propTypes/formFields'
import {randomRange} from '../../utils/number'
import {taskActionsPT} from '../../constants/propTypes/taskActions'

const timelineWhileFetching = () => {
  const random = randomRange(8, 10)
  const items = []

  for (let i = 0; i < random; i += 1) {
    items.push(
      <div className="Task-wrap" key={i}>
        <div className="Task hasIcon isEmpty">
          <div className="Task-inner">
            <div className="Task-icon-wrap">
              <div className="Task-icon" />
            </div>
            <div className="Task-text">
              <Blokk className="Timeline-blokk" minLength={16} maxLength={24} />
              <Blokk
                className="Timeline-blokk large"
                minLength={16}
                maxLength={24}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return items
}

const Timeline = ({
  assignmentId,
  errors,
  fetchFormSectionsIfNeeded,
  formFields,
  taskBeingExecuted,
  tasks,
  timelinesAreFetching,
  timelineLoaded,
  toggleUpdateAssignmentOptions,
  updateAssignmentOptionsAndExecuteTask,
  updateTaskIfNeeded,
  updatingAssignmentOptions,
  assignmentOptionIsUpdating,
}) => {
  const timelineFetched = () => (
    <ToggleHeight>
      {tasks.map(task => {
        const {
          actions,
          completedDate,
          dueDate,
          icon,
          id,
          isLastTask,
          label,
          status,
          title,
          tooltip,
        } = task

        return (
          <div className="Task-wrap" key={id}>
            <Task
              actions={actions}
              assignmentId={assignmentId}
              completedDate={completedDate}
              dueDate={dueDate}
              errors={errors}
              fetchFormSectionsIfNeeded={fetchFormSectionsIfNeeded}
              formFields={formFields}
              icon={icon}
              id={id}
              label={label}
              refreshCardOnComplete={isLastTask}
              status={status}
              taskBeingExecuted={taskBeingExecuted}
              title={title}
              toggleUpdateAssignmentOptions={toggleUpdateAssignmentOptions}
              tooltip={tooltip}
              updateAssignmentOptionsAndExecuteTask={
                updateAssignmentOptionsAndExecuteTask
              }
              updateTaskIfNeeded={updateTaskIfNeeded}
              updatingAssignmentOptions={updatingAssignmentOptions}
              assignmentOptionIsUpdating={assignmentOptionIsUpdating}
            />
          </div>
        )
      })}
    </ToggleHeight>
  )

  return (
    <div className="Timeline">
      {timelinesAreFetching && !timelineLoaded
        ? timelineWhileFetching()
        : timelineFetched()}
    </div>
  )
}

Timeline.defaultProps = {
  formFields: {},
  taskBeingExecuted: null,
  tasks: [],
  updatingAssignmentOptions: null,
}

Timeline.propTypes = {
  assignmentId: numberPT(true),
  errors: emptyObjectPT(),
  fetchFormSectionsIfNeeded: funcPT(true),
  formFields: PT.objectOf(formFieldPT(true)),
  taskBeingExecuted: numberPT(),
  tasks: PT.arrayOf(
    PT.shape({
      actions: taskActionsPT(),
      completedDate: stringPT(true),
      dueDate: stringPT(true),
      id: PT.number.isRequired,
      isLastTask: boolPT(true),
      label: stringPT(true),
      status: stringPT(true),
      title: stringPT(true),
    })
  ),
  timelinesAreFetching: boolPT(true),
  timelineLoaded: boolPT(true),
  toggleUpdateAssignmentOptions: funcPT(true),
  updateAssignmentOptionsAndExecuteTask: funcPT(true),
  updateTaskIfNeeded: funcPT(true),
  updatingAssignmentOptions: stringPT(),
}

export default Timeline
