import {boolPT} from 'arena-prop-types'
import React from 'react'
import {Gallery} from '../../lib/components/core'
import {PT_CURRENCY_DEFAULTS} from '../../lib/constants/propTypes'
import {sectionItemsPT} from '../../constants/propTypes/sectionItems'
import TokenField from './Field'

const PositionToken = ({currencySettings, isMobile, items}) => {
  const renderedItems = items.map(item => (
    <div className="Token-card-wrap" key={item.id}>
      <div className="Token-card">
        <div className="Token-card-name">{item.name}</div>
        {item.fields.length ? (
          <div className="Token-card-fields">
            {item.fields.map(field => (
              <div className="Token-field" key={field.id}>
                <TokenField
                  currencySettings={currencySettings}
                  label={field.label}
                  value={field.value}
                  valueSuffix={field.valueSuffix}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  ))

  return (
    <div className="Token">
      {items.length > 4 ? (
        <Gallery isMobile={isMobile}>{renderedItems}</Gallery>
      ) : (
        renderedItems
      )}
    </div>
  )
}

PositionToken.propTypes = {
  currencySettings: PT_CURRENCY_DEFAULTS.isRequired,
  isMobile: boolPT(true),
  items: sectionItemsPT(true),
}

export default PositionToken
