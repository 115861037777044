import PropTypes from 'prop-types'
import React from 'react'

class Callback extends React.Component {
  static propTypes = {
    query: PropTypes.string.isRequired,
    // the content to render
    children: PropTypes.element.isRequired,

    // the userManager
    userManager: PropTypes.object.isRequired,

    // a function invoked when the callback succeeds
    successCallback: PropTypes.func.isRequired,

    // a function invoked when the callback fails
    errorCallback: PropTypes.func,
  }

  componentDidMount() {
    this.props.userManager
      .signinRedirectCallback(this.props.query)
      .then(user => this.onRedirectSuccess(user))
      .catch(error => this.onRedirectError(error))
  }

  onRedirectSuccess = user => {
    this.props.successCallback(user)
  }

  onRedirectError = error => {
    if (this.props.errorCallback) {
      this.props.errorCallback(error)
    } else {
      throw new Error(`Error handling redirect callback: ${error.message}`)
    }
  }

  render() {
    return React.Children.only(this.props.children)
  }
}

export default Callback
