import {createSelector} from 'reselect'
import {
  LOCATION_TYPE_HOME_HOST_DEMOGRAPHY,
  LOCATION_TYPE_HOME_HOST_COMPENSATION,
  LOCATION_TYPE_HOME_HOST_INCOMETAX,
  LOCATION_TYPE_HOME_HOST_SOCIALTAX,
  LOCATION_TYPE_HYPO_COMPENSATION,
  LOCATION_TYPE_HYPO_INCOMETAX,
  LOCATION_TYPE_HYPO_SOCIALTAX,
} from '../constants/locationTypes'
import {sortListsByProperty} from '../utils/sortBy'

const RESOURCES = state => state.initiations.resources

export const getCountriesById = state => RESOURCES(state).countries.byId
export const getEmployeesById = state => RESOURCES(state).employees.byId
export const getHomeLocationsById = state => RESOURCES(state).homeLocations.byId
export const getHostLocationsById = state => RESOURCES(state).hostLocations.byId
export const getHomeLocationsByType = state =>
  RESOURCES(state).homeLocations.byType
export const getHostLocationsByType = state =>
  RESOURCES(state).hostLocations.byType
export const getPoliciesById = state => RESOURCES(state).policies.byId
export const getCurrenciesById = state => RESOURCES(state).currencies.byId
const getDurations = state => RESOURCES(state).durations
const getEntitiesById = state => RESOURCES(state).entities.byId
export const getHomeFilingStatusesById = state =>
  RESOURCES(state).homeFilingStatuses.byId
export const getHostFilingStatusesById = state =>
  RESOURCES(state).hostFilingStatuses.byId
export const getHypoFilingStatusesById = state =>
  RESOURCES(state).hypoFilingStatuses.byId
export const getHostHypoFilingStatusesById = state =>
  RESOURCES(state).hostHypoFilingStatuses.byId
const getJobTitlesById = state => RESOURCES(state).jobTitles.byId
const getSubEntitiesById = state => RESOURCES(state).subEntities.byId

export const getCountriesIsFetching = state =>
  RESOURCES(state).countries.isFetching
export const getCurrenciesIsFetching = state =>
  RESOURCES(state).currencies.isFetching
export const getEmployeesIsFetching = state =>
  RESOURCES(state).employees.isFetching
export const getEntitiesIsFetching = state =>
  RESOURCES(state).entities.isFetching
export const getJobTitlesIsFetching = state =>
  RESOURCES(state).jobTitles.isFetching
export const getHomeLocationsIsFetching = state =>
  RESOURCES(state).homeLocations.isFetching
export const getHostLocationsIsFetching = state =>
  RESOURCES(state).hostLocations.isFetching
export const getPoliciesIsFetching = state =>
  RESOURCES(state).policies.isFetching
export const getSubEntitiesIsFetching = state =>
  RESOURCES(state).subEntities.isFetching
export const getHomeFilingStatusesIsFetching = state =>
  RESOURCES(state).homeFilingStatuses.isFetching
export const getHostFilingStatusesIsFetching = state =>
  RESOURCES(state).hostFilingStatuses.isFetching
export const getFilingStatusesIsFetching = state =>
  RESOURCES(state).homeFilingStatuses.isFetching ||
  RESOURCES(state).hostFilingStatuses.isFetching ||
  RESOURCES(state).hypoFilingStatuses.isFetching ||
  RESOURCES(state).hostHypoFilingStatuses.isFetching

export const getResources = createSelector(
  [
    getCountriesById,
    getCurrenciesById,
    getDurations,
    getEmployeesById,
    getEntitiesById,
    getJobTitlesById,
    getHomeLocationsById,
    getHomeLocationsByType,
    getHostLocationsById,
    getHostLocationsByType,
    getPoliciesById,
    getSubEntitiesById,
    getHomeFilingStatusesById,
    getHostFilingStatusesById,
    getHypoFilingStatusesById,
    getHostHypoFilingStatusesById,
  ],
  (
    countries,
    currencies,
    durations,
    employees,
    entities,
    jobTitles,
    homeLocations,
    allHomeLocations,
    hostLocations,
    allHostLocations,
    policies,
    subEntities,
    homeFilingStatuses,
    hostFilingStatuses,
    hypoFilingStatuses,
    hostHypoFilingStatuses
  ) => {
    const employeesOptions = Object.keys(employees).map(employee => {
      const {employeeId, nameAndId} = employees[employee]

      return {id: employeeId, label: nameAndId, value: employeeId}
    })

    const demographyHomeLocations =
      allHomeLocations[LOCATION_TYPE_HOME_HOST_DEMOGRAPHY] || {}
    const demographyHostLocations =
      allHostLocations[LOCATION_TYPE_HOME_HOST_DEMOGRAPHY] || {}
    const compensationHomeLocations =
      allHomeLocations[LOCATION_TYPE_HOME_HOST_COMPENSATION] || {}
    const incomeTaxHomeLocations =
      allHomeLocations[LOCATION_TYPE_HOME_HOST_INCOMETAX] || {}
    const socialTaxHomeLocations =
      allHomeLocations[LOCATION_TYPE_HOME_HOST_SOCIALTAX] || {}
    const compensationHostLocations =
      allHostLocations[LOCATION_TYPE_HOME_HOST_COMPENSATION] || {}
    const incomeTaxHostLocations =
      allHostLocations[LOCATION_TYPE_HOME_HOST_INCOMETAX] || {}
    const socialTaxHostLocations =
      allHostLocations[LOCATION_TYPE_HOME_HOST_SOCIALTAX] || {}
    const compensationHomeHypoLocations =
      allHomeLocations[LOCATION_TYPE_HYPO_COMPENSATION] || {}
    const incomeTaxHomeHypoLocations =
      allHomeLocations[LOCATION_TYPE_HYPO_INCOMETAX] || {}
    const socialTaxHomeHypoLocations =
      allHomeLocations[LOCATION_TYPE_HYPO_SOCIALTAX] || {}
    const compensationHostHypoLocations =
      allHostLocations[LOCATION_TYPE_HYPO_COMPENSATION] || {}
    const incomeTaxHostHypoLocations =
      allHostLocations[LOCATION_TYPE_HYPO_INCOMETAX] || {}
    const socialTaxHostHypoLocations =
      allHostLocations[LOCATION_TYPE_HYPO_SOCIALTAX] || {}

    return {
      countries: Object.values(countries),
      currencies: Object.values(currencies),
      durations,
      employees: employeesOptions,
      entities: Object.values(entities),
      jobTitles: Object.values(jobTitles),
      homeLocations: Object.values(homeLocations),
      hostLocations: Object.values(hostLocations),

      demographyHomeLocations: Object.values(demographyHomeLocations),
      demographyHostLocations: Object.values(demographyHostLocations),

      compensationHomeLocations: Object.values(compensationHomeLocations),
      compensationHostLocations: Object.values(compensationHostLocations),
      compensationHomeHypoLocations: Object.values(
        compensationHomeHypoLocations
      ),
      compensationHostHypoLocations: Object.values(
        compensationHostHypoLocations
      ),

      incomeTaxHomeLocations: Object.values(incomeTaxHomeLocations),
      incomeTaxHostLocations: Object.values(incomeTaxHostLocations),
      incomeTaxHomeHypoLocations: Object.values(incomeTaxHomeHypoLocations),
      incomeTaxHostHypoLocations: Object.values(incomeTaxHostHypoLocations),

      socialTaxHomeLocations: Object.values(socialTaxHomeLocations),
      socialTaxHostLocations: Object.values(socialTaxHostLocations),
      socialTaxHomeHypoLocations: Object.values(socialTaxHomeHypoLocations),
      socialTaxHostHypoLocations: Object.values(socialTaxHostHypoLocations),

      policies: sortListsByProperty(Object.values(policies), 'label'),
      subEntities: Object.values(subEntities),
      homeFilingStatuses: Object.values(homeFilingStatuses),
      hostFilingStatuses: Object.values(hostFilingStatuses),
      hypoFilingStatuses: Object.values(hypoFilingStatuses),
      hostHypoFilingStatuses: Object.values(hostHypoFilingStatuses),
    }
  }
)
export const getCompensationHomeLocations = createSelector(
  [getResources],
  resources => resources.compensationHomeLocations
)
export const getCompensationHostLocations = createSelector(
  [getResources],
  resources => resources.compensationHostLocations
)
export const getCompensationHomeHypoLocations = createSelector(
  [getResources],
  resources => resources.compensationHomeHypoLocations
)
export const getCompensationHostHypoLocations = createSelector(
  [getResources],
  resources => resources.compensationHostHypoLocations
)
export const getIncomeTaxHomeLocations = createSelector(
  [getResources],
  resources => resources.incomeTaxHomeLocations
)
export const getIncomeTaxHostLocations = createSelector(
  [getResources],
  resources => resources.incomeTaxHostLocations
)
export const getIncomeTaxHomeHypoLocations = createSelector(
  [getResources],
  resources => resources.incomeTaxHomeHypoLocations
)
export const getIncomeTaxHostHypoLocations = createSelector(
  [getResources],
  resources => resources.incomeTaxHostHypoLocations
)
export const getSocialTaxHomeLocations = createSelector(
  [getResources],
  resources => resources.socialTaxHomeLocations
)
export const getSocialTaxHostLocations = createSelector(
  [getResources],
  resources => resources.socialTaxHostLocations
)
export const getSocialTaxHomeHypoLocations = createSelector(
  [getResources],
  resources => resources.socialTaxHomeHypoLocations
)
export const getSocialTaxHostHypoLocations = createSelector(
  [getResources],
  resources => resources.socialTaxHostHypoLocations
)

export const getResourcesIsFetching = createSelector(
  [
    getCountriesIsFetching,
    getCurrenciesIsFetching,
    getEmployeesIsFetching,
    getEntitiesIsFetching,
    getJobTitlesIsFetching,
    getHomeLocationsIsFetching,
    getHostLocationsIsFetching,
    getPoliciesIsFetching,
    getSubEntitiesIsFetching,
  ],
  (
    countriesIsFetching,
    currenciesIsFetching,
    employeesIsFetching,
    entitiesIsFetching,
    jobTitlesIsFetching,
    homeLocationsIsFetching,
    hostLocationsIsFetching,
    policiesIsFetching,
    subEntitiesIsFetching
  ) =>
    countriesIsFetching ||
    currenciesIsFetching ||
    employeesIsFetching ||
    entitiesIsFetching ||
    jobTitlesIsFetching ||
    homeLocationsIsFetching ||
    hostLocationsIsFetching ||
    policiesIsFetching ||
    subEntitiesIsFetching
)
