import PT from 'prop-types'
import React, {Component} from 'react'
import AnimateHeight from 'react-animate-height'

const defaultProps = {
  className: '',
  duration: 0,
  height: 0,
}

const propTypes = {
  children: PT.node.isRequired,
  className: PT.string,
  duration: PT.number.isRequired,
  height: PT.oneOfType([PT.number, PT.string]),
}

class ToggleHeight extends Component {
  render() {
    const {children, className, duration, height} = this.props
    return (
      <div
        ref={wrap => {
          this.wrap = wrap
        }}
      >
        <AnimateHeight
          className={className}
          duration={duration}
          height={height > 0 ? height : 'auto'}
        >
          {children}
        </AnimateHeight>
      </div>
    )
  }
}

ToggleHeight.defaultProps = defaultProps
ToggleHeight.propTypes = propTypes

export default ToggleHeight
