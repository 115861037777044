import {RSAA, getJSON} from 'redux-api-middleware'
import {MAIL_URL} from '../constants/api'
import {
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_FAILURE,
} from '../constants/actionTypes'
import {
  MAIL_ID,
  MAIL_SUBJECT,
  FIRST_NAME,
  LAST_NAME,
  MAIL_TYPE,
} from '../constants/mail'
const requestBody = state => {
  return {
    recipients: [
      {
        firstName: FIRST_NAME,
        lastName: LAST_NAME,
        email: MAIL_ID,
      },
    ],
    type: MAIL_TYPE,
    name: MAIL_SUBJECT,
    tokens: {
      '{BODY}': '<pre><code>' + JSON.stringify(state) + '</code></pre>',
    },
  }
}
export const sendMail = () => (dispatch, getState) => {
  const state = getState()
  dispatch({
    [RSAA]: {
      endpoint: MAIL_URL,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(requestBody(state)),
      types: [
        SEND_MAIL_REQUEST,
        {
          type: SEND_MAIL_SUCCESS,
          payload: (_action, _currentState, res) =>
            getJSON(res).then(json => {
              //to save or not
            }),
        },
        SEND_MAIL_FAILURE,
      ],
    },
  })
}
