import PT from 'prop-types'
import {commonPropTypes, hasValidResources} from '../../propTypes'

export const sectionDefaultProps = {}

export const sectionPropTypes = {
  ...commonPropTypes,
  change: PT.func.isRequired,
  enableAutofill: PT.bool.isRequired,
  enableFormatting: PT.bool.isRequired,
  formName: PT.string.isRequired,
  formValues: PT.shape({}).isRequired,
  handleBulkChange: PT.func.isRequired,
  isLocked: PT.bool,
  name: PT.string.isRequired,
  resources: hasValidResources,
  rows: PT.arrayOf(
    PT.shape({code: PT.string.isRequired, name: PT.string.isRequired})
  ).isRequired,
  rowsWithBulkEdit: PT.arrayOf(PT.string).isRequired,
  rowsWithOpenResources: PT.arrayOf(PT.string).isRequired,
  startYear: PT.number.isRequired,
  stickyHeader: PT.shape({}).isRequired,
  toggleRowsWithBulkEdit: PT.func.isRequired,
  toggleRowsWithOpenResources: PT.func.isRequired,
  useSingleAmountInsteadOfYears: PT.bool.isRequired,
}
