import PT from 'prop-types'
import React, {Fragment} from 'react'

const Conditional = ({condition, children}) =>
  condition ? <Fragment>{children}</Fragment> : null

Conditional.propTypes = {
  children: PT.node.isRequired,
  condition: PT.bool.isRequired,
}

export default Conditional
