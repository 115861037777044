import {combineReducers} from 'redux'
import {
  FETCH_TAX_OPTIONS_FAILURE,
  FETCH_TAX_OPTIONS_REQUEST,
  FETCH_TAX_OPTIONS_SUCCESS,
} from '../../../constants/actionTypes'

const taxSectionsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TAX_OPTIONS_REQUEST:
      return {isFetching: true}
    case FETCH_TAX_OPTIONS_SUCCESS:
      return {...action.payload.entities.taxSections, isFetching: false}
    case FETCH_TAX_OPTIONS_FAILURE:
      return {...state, isFetching: false}
    default:
      return state
  }
}

const allTaxSections = (state = [], action) => {
  switch (action.type) {
    case FETCH_TAX_OPTIONS_REQUEST:
      return []
    case FETCH_TAX_OPTIONS_SUCCESS:
      return action.payload.result || state
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_TAX_OPTIONS_FAILURE:
      return action.payload.message
    case FETCH_TAX_OPTIONS_REQUEST:
    case FETCH_TAX_OPTIONS_SUCCESS:
      return null
    default:
      return state
  }
}

const taxSections = combineReducers({
  byId: taxSectionsById,
  ids: allTaxSections,
  errorMessage,
})

export default taxSections
