import PT from 'prop-types'
import React from 'react'

const ErrorMessage = ({validationErrorMessage, submissionErrorMessage}) => {
  let errorMessage = ''
  if (validationErrorMessage) {
    const validationErrorList = validationErrorMessage
      .split('\n')
      .map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        )
      })

    errorMessage = (
      <div className="color-red text-center">
        <span>
          Please fix the following validation errors before submitting:
          <br />
        </span>
        {validationErrorList}
      </div>
    )
  }

  if (submissionErrorMessage) {
    const submissionErrorList = submissionErrorMessage
      .split('\\n')
      .map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        )
      })

    errorMessage = (
      <div className="color-red text-center">{submissionErrorList}</div>
    )
  }
  return <>{errorMessage}</>
}

ErrorMessage.defaultProps = {
  validationErrorMessage: '',
  submissionErrorMessage: '',
}

ErrorMessage.propTypes = {
  validationErrorMessage: PT.string,
  submissionErrorMessage: PT.string,
}

export default ErrorMessage
