import 'moment-timezone'
import moment from 'moment'
import {DATE_FORMAT} from 'arena-config'

const DAY_FORMAT = 'DD'
const MONTH_FORMAT = 'MMM'
const YEAR_FORMAT = 'YYYY'

export const formatDate = (
  datetime,
  inputFormat = DATE_FORMAT,
  preferredFormat = DATE_FORMAT
) => datetime && moment(datetime, inputFormat).format(preferredFormat)

export const todaysDate = () => moment(new Date()).format(DATE_FORMAT)

export const nextYear = () =>
  moment(new Date())
    .add(1, 'years')
    .format(DATE_FORMAT)

export const durationInMonths = (startDate, endDate) => {
  const difference = moment(endDate).diff(moment(startDate))
  const differenceInMonths = moment.duration(difference).asMonths()

  return Math.round(differenceInMonths)
}

export const getYearForDate = date => {
  if (!date) {
    return moment().get('year')
  }
  return moment(date).get('year')
}
const getLocalTimezoneLocation = () => moment.tz.guess()

const getLocalTimezoneAbbreviation = () => {
  const timezoneLocation = getLocalTimezoneLocation()

  return moment.tz(timezoneLocation).zoneAbbr()
}

const convertDatetimeToLocalDatetime = (
  datetime,
  inputFormat = DATE_FORMAT,
  preferredFormat = DATE_FORMAT
) => {
  const localTimezoneLocation = getLocalTimezoneLocation()

  return moment
    .tz(datetime, inputFormat, localTimezoneLocation)
    .format(preferredFormat)
}

export const convertDateTimeToLocalTimezone = (
  date,
  inputFormat = 'YYYY-MM-DDTHH:mm:ssZZ',
  preferredFormat = 'MMMM DD, YYYY HH:mm:ss'
) => {
  const localTimezone = getLocalTimezoneAbbreviation()

  return `${convertDatetimeToLocalDatetime(
    date,
    inputFormat,
    preferredFormat
  )} ${localTimezone}`
}

export const getStartDateOrYearForCard = date => {
  const isSameYear = getYearForDate(date) === getYearForDate()
  if (isSameYear) {
    return moment(date)
      .format(DAY_FORMAT)
      .toString()
  }
  return moment(date)
    .format(YEAR_FORMAT)
    .toString()
}
export const getMonthForDate = date => {
  return moment(date)
    .format(MONTH_FORMAT)
    .toString()
}
