import PT from 'prop-types'
import {commonPropTypes} from '../../propTypes'

export const expandButtonDefaultProps = {
  isHovering: false,
}

export const expandButtonPropTypes = {
  ...commonPropTypes,
  isActive: PT.bool.isRequired,
  isBulkEditingRow: PT.bool.isRequired,
  isHovering: PT.bool,
  onClick: PT.func.isRequired,
}
