import React from 'react'
import {inputPT} from '../../../constants/inputProps'
import handleInputChange from '../../../utils/handleInputChange'
import fieldPropTypes, {makeFieldProps} from '../propTypes'
import Input from '../Input'

const TextArea = props => (
  <Input
    {...props.input}
    {...makeFieldProps(props)}
    onChange={event =>
      handleInputChange(event.target, props.valueKey, props.input.onChange)
    }
    type="textarea"
  />
)

TextArea.propTypes = {
  ...fieldPropTypes,
  input: inputPT(true),
}

export default TextArea
