import {combineReducers} from 'redux'
import {
  FETCH_HOST_LOCATIONS_FAILURE,
  FETCH_HOST_LOCATIONS_REQUEST,
  FETCH_HOST_LOCATIONS_SUCCESS,
} from '../../../constants/actionTypes'
import {
  LOCATION_TYPE_HOME_HOST_COMPENSATION,
  LOCATION_TYPE_HOME_HOST_DEMOGRAPHY,
  LOCATION_TYPE_HOME_HOST_INCOMETAX,
  LOCATION_TYPE_HOME_HOST_SOCIALTAX,
  LOCATION_TYPE_HYPO_COMPENSATION,
  LOCATION_TYPE_HYPO_INCOMETAX,
  LOCATION_TYPE_HYPO_SOCIALTAX,
} from '../../../constants/locationTypes'
import {filterLocaionsByEnabledFor} from '../../../utils/filterLocations'

const locationsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_HOST_LOCATIONS_SUCCESS:
      return action.payload.entities.locations || {}
    default:
      return state
  }
}

const locationsByType = (state = {}, action) => {
  switch (action.type) {
    case FETCH_HOST_LOCATIONS_SUCCESS:
      return {
        [LOCATION_TYPE_HOME_HOST_DEMOGRAPHY]: filterLocaionsByEnabledFor(
          action.payload.entities.locations || state,
          LOCATION_TYPE_HOME_HOST_DEMOGRAPHY
        ),
        [LOCATION_TYPE_HOME_HOST_COMPENSATION]: filterLocaionsByEnabledFor(
          action.payload.entities.locations || state,
          LOCATION_TYPE_HOME_HOST_COMPENSATION
        ),
        [LOCATION_TYPE_HYPO_COMPENSATION]: filterLocaionsByEnabledFor(
          action.payload.entities.locations || state,
          LOCATION_TYPE_HYPO_COMPENSATION
        ),
        [LOCATION_TYPE_HOME_HOST_INCOMETAX]: filterLocaionsByEnabledFor(
          action.payload.entities.locations || state,
          LOCATION_TYPE_HOME_HOST_INCOMETAX
        ),
        [LOCATION_TYPE_HYPO_INCOMETAX]: filterLocaionsByEnabledFor(
          action.payload.entities.locations || state,
          LOCATION_TYPE_HYPO_INCOMETAX
        ),
        [LOCATION_TYPE_HOME_HOST_SOCIALTAX]: filterLocaionsByEnabledFor(
          action.payload.entities.locations || state,
          LOCATION_TYPE_HOME_HOST_SOCIALTAX
        ),
        [LOCATION_TYPE_HYPO_SOCIALTAX]: filterLocaionsByEnabledFor(
          action.payload.entities.locations || state,
          LOCATION_TYPE_HYPO_SOCIALTAX
        ),
      }
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_HOST_LOCATIONS_REQUEST:
      return true
    case FETCH_HOST_LOCATIONS_SUCCESS:
    case FETCH_HOST_LOCATIONS_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_HOST_LOCATIONS_FAILURE:
      return action.payload.message
    case FETCH_HOST_LOCATIONS_REQUEST:
    case FETCH_HOST_LOCATIONS_SUCCESS:
      return null
    default:
      return state
  }
}

const hostLocations = combineReducers({
  byId: locationsById,
  byType: locationsByType,
  isFetching,
  errorMessage,
})

export default hostLocations
