import {combineReducers} from 'redux'
import {
  FETCH_ASSIGNMENT_OPTIONS_FAILURE,
  FETCH_ASSIGNMENT_OPTIONS_REQUEST,
  FETCH_ASSIGNMENT_OPTIONS_SUCCESS,
  UPDATE_ASSIGNMENT_OPTIONS_REQUEST,
  UPDATE_ASSIGNMENT_OPTIONS_SUCCESS,
  UPDATE_ASSIGNMENT_OPTIONS_FAILURE,
} from '../../constants/actionTypes'

const assignmentOptionsById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ASSIGNMENT_OPTIONS_SUCCESS:
      return {
        ...state,
        [action.meta.positionId]: {
          ...action.payload.entities.assignmentOptions,
        },
      }
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_ASSIGNMENT_OPTIONS_REQUEST:
      return true
    case FETCH_ASSIGNMENT_OPTIONS_SUCCESS:
    case FETCH_ASSIGNMENT_OPTIONS_FAILURE:
      return false
    default:
      return state
  }
}

const isUpdating = (state = false, action) => {
  switch (action.type) {
    case UPDATE_ASSIGNMENT_OPTIONS_REQUEST:
      return true
    case UPDATE_ASSIGNMENT_OPTIONS_SUCCESS:
    case UPDATE_ASSIGNMENT_OPTIONS_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  // TODO Add option to reset error message, e.g.:
  // if (action.type === RESET_ERROR_MESSAGE) return null

  switch (action.type) {
    case FETCH_ASSIGNMENT_OPTIONS_FAILURE:
      return action.payload.message
    case FETCH_ASSIGNMENT_OPTIONS_REQUEST:
    case FETCH_ASSIGNMENT_OPTIONS_SUCCESS:
      return null
    default:
      return state
  }
}

const assignmentOptions = combineReducers({
  byId: assignmentOptionsById,
  errorMessage,
  isFetching,
  isUpdating,
})

export default assignmentOptions
