import {combineReducers} from 'redux'
import {
  FETCH_HOST_FILING_STATUS_FAILURE,
  FETCH_HOST_FILING_STATUS_REQUEST,
  FETCH_HOST_FILING_STATUS_SUCCESS,
} from '../../../constants/actionTypes'

const initialState = {
  0: {
    description: 'No_Options_Available',
    id: 0,
    value: '',
    label: 'No options available',
  },
}

const hostFilingStatusesById = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HOST_FILING_STATUS_REQUEST:
      return state
    case FETCH_HOST_FILING_STATUS_SUCCESS:
      return action.payload.entities.filingStatuses || state
    case FETCH_HOST_FILING_STATUS_FAILURE:
      return initialState
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_HOST_FILING_STATUS_REQUEST:
      return true
    case FETCH_HOST_FILING_STATUS_SUCCESS:
      return false
    case FETCH_HOST_FILING_STATUS_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_HOST_FILING_STATUS_FAILURE:
      return action.payload.message
    case FETCH_HOST_FILING_STATUS_REQUEST:
      return null
    case FETCH_HOST_FILING_STATUS_SUCCESS:
      return null
    default:
      return state
  }
}

const hostFilingStatuses = combineReducers({
  byId: hostFilingStatusesById,
  isFetching,
  errorMessage,
})

export default hostFilingStatuses
