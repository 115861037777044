import {SubmissionError} from 'redux-form'
import {
  VALIDATION_IS_DATE,
  VALIDATION_IS_REQUIRED,
  VALIDATION_IS_STRING,
  VALIDATION_IS_LONGER_THAN_ONE_CHARACTER,
  VALIDATION_IS_START_DATE_GREATER_THAN_END_DATE,
  VALIDATION_IS_DATE_IS_NOT_FUTURE_DATE,
  VALIDATION_IS_VALID_EMAIL_FORMAT,
} from '../constants/validation'
import {isNullOrUndefined} from 'util'

export const getValidationFunctions = arrayOfFunctionNames => {
  const results = []

  for (let i = 0; i < arrayOfFunctionNames.length; i += 1) {
    const func = arrayOfFunctionNames[i]

    if (func === 'isDate') {
      results.push(VALIDATION_IS_DATE)
    } else if (func === 'isRequired') {
      results.push(VALIDATION_IS_REQUIRED)
    } else if (func === 'isString') {
      results.push(VALIDATION_IS_STRING)
    } else if (func === 'isLongerThanOneCharacter') {
      results.push(VALIDATION_IS_LONGER_THAN_ONE_CHARACTER)
    } else if (func === 'isStartDateGreaterThanEndDate') {
      results.push(VALIDATION_IS_START_DATE_GREATER_THAN_END_DATE)
    } else if (func === 'isFutureDate') {
      results.push(VALIDATION_IS_DATE_IS_NOT_FUTURE_DATE)
    } else if (func === 'isEmail') {
      results.push(VALIDATION_IS_VALID_EMAIL_FORMAT)
    }
  }

  return results
}

export const validateAndSubmit = (values, errors, onSubmit, id = null) => {
  if (Object.keys(errors).length > 0) {
    throw new SubmissionError(errors)
  } else {
    if (isNullOrUndefined(id)) onSubmit(values)
    else onSubmit(id, values)
  }
}
