import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {FONT_WEIGHT_1} from 'arena-config'
import {Icon, ConfirmationModal} from '../../../lib/components/core'
import {deleteDocument, fetchDocumentsById} from '../../../actions/document'
import {getDocuments, isDocumentFetching} from '../../../selectors/document'
import {Tooltip} from 'antd'
import {
  DOCUMENT_MODAL_HEADER,
  DELETE_CONFIRMATION,
  DELETE_DOCUMENT_CONFIRMATION,
} from '../../../constants/documents'
import {formatDate} from '../../../utils/moment'
import {Spin} from 'antd'
import AttachDocument from './AttachDocument'

const Heading = styled.div`
  padding-bottom: 20px;
  font-weight: ${FONT_WEIGHT_1};
  font-size: 1.5em;
`
const ListItem = styled.div`
  display: flex;
`
const FlyerWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const Spinner = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #0a838a;
  }
`
const AddDocumentButton = styled.button`
  margin: 10px 0px;
  padding: 4px;
  background: #1cdbbc;
  border: 1px solid #1cdbbc;
  color: #000000;
  cursor: pointer;
  border-radius: 24px;
  &:hover {
    background: #8bf5e3;
    border: 1px solid #8bf5e3;
  }
`

const Document = ({
  assignmentId,
  firstName,
  lastName,
  employeeId,
  hostCountry,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [info, setinfo] = useState('')
  const [selectedDocument, setSelectedDocument] = useState(null)
  const documentList = Object.values(useSelector(state => getDocuments(state)))
  const isFetchingDocument = useSelector(state => isDocumentFetching(state))
  const [details, setDetails] = useState('')
  const [title, setTitle] = useState(null)
  const [effectiveDate, setEffectiveDate] = useState()
  const dispatch = useDispatch()
  const country = hostCountry ? hostCountry.label : null
  const handleDelete = () => {
    dispatch(deleteDocument(selectedDocument.id))
    return info
  }
  const handleDownload = documentId => {
    dispatch(fetchDocumentsById(assignmentId, documentId))
  }
  const [shouldShowUploadModal, setShouldShowUploadModal] = useState(false)

  return (
    <div>
      <Heading>
        {DOCUMENT_MODAL_HEADER(firstName, lastName, employeeId, country)}
      </Heading>
      {isFetchingDocument ? (
        <FlyerWrapper>
          <Spinner size="large" />
        </FlyerWrapper>
      ) : (
        ''
      )}
      Documents:
      <div>
        {documentList.map(item => (
          <ListItem key={item.id}>
            <button
              style={{
                border: 'hidden',
                padding: '3.4px 0 0 0',
                cursor: 'pointer',
              }}
              type="button"
              onClick={() => {
                setShowDeleteConfirmation(true)
                setSelectedDocument(item)
              }}
            >
              <Icon style={{padding: '0 0 0 0'}} type="trash-can" />
            </button>
            <span
              style={{
                padding: '0 15px 0 10px',
                color: 'blue',
                cursor: 'pointer',
              }}
              onClick={() => handleDownload(item.id)}
            >
              {item.fileName}
            </span>
            <Tooltip
              title={
                <div>
                  <div>Title: {item.title}</div>
                  <div>Category: {item.category && item.category.name}</div>
                  <div>
                    Subcategory: {item.subcategory && item.subcategory.name}
                  </div>
                  <div>Effective Date: {formatDate(item.effectiveDate)}</div>
                  <div>Details: {item.details}</div>
                </div>
              }
            >
              <span>{item.category && item.category.name}</span>
            </Tooltip>
          </ListItem>
        ))}
      </div>
      <AddDocumentButton
        type="button"
        onClick={() => setShouldShowUploadModal(true)}
      >
        Add a document
      </AddDocumentButton>
      <AttachDocument
        shouldShowUploadModal={shouldShowUploadModal}
        setShouldShowUploadModal={setShouldShowUploadModal}
        title={title}
        setTitle={setTitle}
        assignmentId={assignmentId}
        details={details}
        setDetails={setDetails}
        effectiveDate={effectiveDate}
        setEffectiveDate={setEffectiveDate}
        setinfo={setinfo}
      />
      {showDeleteConfirmation && (
        <ConfirmationModal
          confirmationMessage={DELETE_CONFIRMATION(selectedDocument.fileName)}
          confirmationTitle={DELETE_DOCUMENT_CONFIRMATION}
          formName={DELETE_DOCUMENT_CONFIRMATION}
          onClose={() => setShowDeleteConfirmation(false)}
          onDelete={() => {
            handleDelete()
            setShowDeleteConfirmation(false)
          }}
        />
      )}
    </div>
  )
}

export default Document
