import * as c from 'arena-config'
import React from 'react'
import styled, {keyframes} from 'styled-components'
import HeaderLinks from '../HeaderLinks'
import {headerBottomDefaultProps, headerBottomPropTypes} from './propTypes'

const backGroundKeyFrame = keyframes`{
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }`

const Wrap = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  color: ${c.GRAY};
  font-size: ${props => c.px(Math.ceil(props.height / 2.2))};
  font-weight: ${c.FONT_WEIGHT_2};
  position: absolute;
  width: 100%;
  height: ${props => (props.shouldShowLinks ? 'calc(100vh - 48px)' : '0px')};
  animation: ${props => props.isOpen && backGroundKeyFrame + ' 0.6s forwards'};
  display: ${props => (props.isOpen ? 'block' : 'none')};
  top: 48px;
  z-index: 2;
  ${c.FOR_DESKTOP} {
    height: ${props => (props.shouldShowLinks ? props.height : 0)}px;
    display: block;
  }
`
const LinkWrap = styled.div`
  height: 100%;
  background-color: #ffffff;
  width: 70%;
  margin-left: auto;
  padding-top: 98px;
  transform: ${props => (props.isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.6s ease;
  ${c.FOR_DESKTOP} {
    width: 100%;
    transform: translateX(0);
    padding-top: 0;
  }
`

const HeaderBottom = props => {
  const shouldShowLinks = props.subLinks.length > 0
  const styles = {
    flexDirection: props.isMobile ? 'column' : 'row',
  }
  return (
    <Wrap
      height={props.height}
      isOpen={props.isMobileMenuOpen}
      shouldShowLinks={shouldShowLinks}
    >
      <LinkWrap isOpen={props.isMobileMenuOpen}>
        <HeaderLinks
          align={props.align}
          currentRoute={props.currentRoute}
          defaultAction={props.defaultAction}
          height={props.height}
          links={props.subLinks}
          paddingLeft={props.paddingLeft}
          white={false}
          styles={styles}
          onMobileMenuIconClick={props.onMobileMenuIconClick}
        />
      </LinkWrap>
    </Wrap>
  )
}

HeaderBottom.defaultProps = headerBottomDefaultProps
HeaderBottom.propTypes = headerBottomPropTypes

export default HeaderBottom
