import {combineReducers} from 'redux'
import {
  CREATE_ASSIGNMENT_SUCCESS,
  FETCH_EMPLOYEES_FAILURE,
  FETCH_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEES_SUCCESS,
  UPDATE_ASSIGNMENT_SUCCESS,
  SET_NEW_EMPLOYEE,
} from '../../../constants/actionTypes'

const employeesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEES_SUCCESS:
      return action.payload.entities.employees || state
    case CREATE_ASSIGNMENT_SUCCESS:
    case UPDATE_ASSIGNMENT_SUCCESS:
      return {...state, ...action.payload.entities.employees}
    case SET_NEW_EMPLOYEE:
      return {...action.meta.employee, ...state}
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEES_REQUEST:
      return true
    case FETCH_EMPLOYEES_SUCCESS:
    case FETCH_EMPLOYEES_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEES_FAILURE:
      return action.payload.message
    case FETCH_EMPLOYEES_REQUEST:
    case FETCH_EMPLOYEES_SUCCESS:
      return null
    default:
      return state
  }
}

const employees = combineReducers({
  byId: employeesById,
  isFetching,
  errorMessage,
})

export default employees
