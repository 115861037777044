import PT from 'prop-types'

export const logoDefaultProps = {
  onClick: null,
}

export const logoPropTypes = {
  defaultAction: PT.func.isRequired,
  logoSrc: PT.string.isRequired,
  onClick: PT.func,
}
