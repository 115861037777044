import {isNullOrUndefined} from 'util'
import {RSAA, getJSON} from 'redux-api-middleware'
import _ from 'lodash'
import {change, formValueSelector} from 'redux-form'
import {normalize} from 'normalizr'
import createBusinessRules from '../data/create/businessRules.json'
// import standardFormSections from '../data/standardFormSections.json'
// import standardFormSectionsForEdit from '../data/standardFormSectionsForEdit.json'
import {
  ACCOUNT_SECTIONS_BY_POLICY_TYPE_URL,
  ACCOUNT_SECTIONS_URL,
  FAMILY_FORM_FIELDS_URL,
  FORM_DESIGN_URL,
  FORM_SECTIONS_URL,
  TAX_QUESTIONS_URL,
} from '../constants/api'
import {CREATE_ASSIGNMENT_FORM, EDIT_ASSIGNMENT_FORM} from '../constants/form'
import {
  FETCH_FORM_SECTIONS_REQUEST,
  FETCH_FORM_SECTIONS_SUCCESS,
  FETCH_FORM_SECTIONS_FAILURE,
  FETCH_ACCOUNT_SECTIONS_REQUEST,
  FETCH_ACCOUNT_SECTIONS_SUCCESS,
  FETCH_ACCOUNT_SECTIONS_FAILURE,
  FETCH_FAMILY_FORM_FIELDS_REQUEST,
  FETCH_FAMILY_FORM_FIELDS_SUCCESS,
  FETCH_FAMILY_FORM_FIELDS_FAILURE,
  FETCH_TAX_OPTIONS_REQUEST,
  FETCH_TAX_OPTIONS_SUCCESS,
  FETCH_TAX_OPTIONS_FAILURE,
  UPDATE_PREVIOUS_FORM_TAB_ERRORS,
  FETCH_CALCULATION_OPTIONS_REQUEST,
  FETCH_CALCULATION_OPTIONS_SUCCESS,
  FETCH_FORM_DESIGN_REQUEST,
  FETCH_FORM_DESIGN_SUCCESS,
  FETCH_FORM_DESIGN_FAILURE,
} from '../constants/actionTypes'
import {
  accountSectionSchema,
  formFieldSchema,
  formSectionSchema,
  taxSectionSchema,
  calculationSectionSchema,
} from '../constants/schemas'
import {
  getAccountSectionsById,
  getAccountSectionsIsFetching,
  getAccountSectionsLastFilter,
  getFamilyCustomFormFieldsById,
  getFamilyFormFieldsIsFetching,
  getFormSectionsIsFetching,
  getTaxSectionsIsFetching,
  getCalculationSectionsIsFetching,
  getStandardFormSections,
  getStandardFormSectionsIsFetching,
  getBusinessRules,
} from '../selectors/form'
import {
  getEmployeesById,
  getIncomeTaxHomeLocations,
  getIncomeTaxHostHypoLocations,
  getIncomeTaxHomeHypoLocations,
  getIncomeTaxHostLocations,
  getSocialTaxHomeLocations,
  getSocialTaxHostLocations,
  getSocialTaxHomeHypoLocations,
  getSocialTaxHostHypoLocations,
  getHomeFilingStatusesById,
  getHostFilingStatusesById,
  getHypoFilingStatusesById,
  getHostHypoFilingStatusesById,
  getPoliciesById,
} from '../selectors/resources'
import {
  mapAccountSections,
  mapFormFields,
  mapFormSections,
  mapTaxSections,
  mapCalculationSections,
  prepareNewTaxSectionsParams,
} from '../utils/api'
import {filteredCalculationOptionsFieldsWithColumnCount} from '../utils/calculationSection'
import {
  getLocationId,
  getPlanTypeAdditionalPropertiesByKeys,
} from '../utils/resources'
import {
  LOCATION_TYPE_HOME,
  LOCATION_TYPE_HOST,
  LOCATION_TYPE_HYPO,
  LOCATION_TYPE_HOST_HYPO,
} from '../constants/locationTypes.js'
import {getLastSelectedCompanyFormId} from '../selectors/company'

// Fetch custom form sections and combine them with the standard form sections in the JSON file.
export const fetchCustomFormSectionsWithStandardFormSectionsIfNeeded = formName => (
  dispatch,
  getState
) => {
  const state = getState()
  const formSectionsIsFetching = getFormSectionsIsFetching(state)
  const standardFormSections = getStandardFormSections(state)
  const standardFormSectionsIsFetching = getStandardFormSectionsIsFetching(
    state
  )
  const shouldFetchStandardFormSections =
    standardFormSections && !standardFormSectionsIsFetching
  if (!formSectionsIsFetching && shouldFetchStandardFormSections) {
    dispatch({
      [RSAA]: {
        endpoint: FORM_SECTIONS_URL,
        method: 'GET',
        types: [
          FETCH_FORM_SECTIONS_REQUEST,
          {
            type: FETCH_FORM_SECTIONS_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json => {
                let allFormSections = []
                allFormSections = [
                  ...mapFormSections(standardFormSections.entries),
                  ...mapFormSections(json.entries, 'options'),
                ]
                return normalize(allFormSections, [formSectionSchema])
              }),
          },
          FETCH_FORM_SECTIONS_FAILURE,
        ],
      },
    })
  }
}

export const fetchAccountSectionsIfNeeded = currentValues => (
  dispatch,
  getState
) => {
  const state = getState()
  const accountSections = getAccountSectionsById(state)
  const planTypeCode =
    (currentValues &&
      currentValues.planTypeCode &&
      currentValues.planTypeCode.value) ||
    null
  if (
    !getAccountSectionsIsFetching(state) ||
    !(
      Object.keys(accountSections).length > 0 &&
      getAccountSectionsLastFilter(state) === planTypeCode
    )
  ) {
    dispatch({
      [RSAA]: {
        endpoint: planTypeCode
          ? ACCOUNT_SECTIONS_BY_POLICY_TYPE_URL(planTypeCode)
          : ACCOUNT_SECTIONS_URL,
        method: 'GET',
        types: [
          FETCH_ACCOUNT_SECTIONS_REQUEST,
          {
            type: FETCH_ACCOUNT_SECTIONS_SUCCESS,
            meta: {planTypeCode},
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                planTypeCode
                  ? normalize(mapAccountSections(json.children), [
                      accountSectionSchema,
                    ])
                  : normalize(mapAccountSections(json.entries), [
                      accountSectionSchema,
                    ])
              ),
          },
          FETCH_ACCOUNT_SECTIONS_FAILURE,
        ],
      },
    })
  }
}

const fetchFamilyFormFieldsIfNeeded = () => (dispatch, getState) => {
  const state = getState()
  const familyFormFields = getFamilyCustomFormFieldsById(state)
  if (
    !(
      getFamilyFormFieldsIsFetching(state) ||
      Object.keys(familyFormFields).length > 0
    )
  ) {
    dispatch({
      [RSAA]: {
        endpoint: FAMILY_FORM_FIELDS_URL,
        method: 'GET',
        types: [
          FETCH_FAMILY_FORM_FIELDS_REQUEST,
          {
            type: FETCH_FAMILY_FORM_FIELDS_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json =>
                normalize(
                  mapFormFields(
                    json.entries,
                    'familyMemberRequests[0].options',
                    'Family'
                  ),
                  [formFieldSchema]
                )
              ),
          },
          FETCH_FAMILY_FORM_FIELDS_FAILURE,
        ],
      },
    })
  }
}
const fetchCalculationOptionsRequest = (homeLocation, hostLocation) => ({
  type: FETCH_CALCULATION_OPTIONS_REQUEST,
  meta: {homeLocation},
})

const fetchCalculationOptionsSuccess = (
  homeLocation,
  hostLocation,
  calculationSections
) => ({
  type: FETCH_CALCULATION_OPTIONS_SUCCESS,
  meta: {homeLocation, hostLocation},
  payload: normalize(
    [...mapCalculationSections(calculationSections)],
    [calculationSectionSchema]
  ),
})

const filterCalculationOptionsByPlanTypeCode = (
  homeLocation,
  hostLocation,
  planTypeCode
) => (dispatch, getState) => {
  const state = getState()
  const policies = getPoliciesById(state)
  const standardFormSections = getStandardFormSections(state)

  const calculationSection =
    standardFormSections &&
    standardFormSections.entries.filter(
      item => item.code === 'CalculationOptions'
    )[0]

  const calculationSections =
    calculationSection && calculationSection.children
      ? JSON.parse(JSON.stringify(calculationSection.children))
      : []

  const additionalPropsKeys = [
    'homeCompEnabled',
    'homeTaxEnabled',
    'hostCompEnabled',
    'hostTaxEnabled',
    'homeHypoCompEnabled',
    'homeHypoTaxEnabled',
    'hostHypoCompEnabled',
    'hostHypoTaxEnabled',
  ]

  const planTypeAdditionalProperties = Object.assign(
    {},
    ...getPlanTypeAdditionalPropertiesByKeys(
      policies,
      additionalPropsKeys,
      planTypeCode
    )
  )

  const isHomeCompEnabled = planTypeAdditionalProperties['homeCompEnabled']
  const isHomeTaxEnabled = planTypeAdditionalProperties['homeTaxEnabled']
  const isHostCompEnabled = planTypeAdditionalProperties['hostCompEnabled']
  const isHostTaxEnabled = planTypeAdditionalProperties['hostTaxEnabled']
  const isHomeHypoCompEnabled =
    planTypeAdditionalProperties['homeHypoCompEnabled']
  const isHomeHypoTaxEnabled =
    planTypeAdditionalProperties['homeHypoTaxEnabled']
  const isHostHypoCompEnabled =
    planTypeAdditionalProperties['hostHypoCompEnabled']
  const isHostHypoTaxEnabled =
    planTypeAdditionalProperties['hostHypoTaxEnabled']

  const calculationSectionsData = calculationSections.map(section => {
    const {columnCount, formFields} = {
      ...filteredCalculationOptionsFieldsWithColumnCount(
        section.children,
        isHomeCompEnabled,
        isHomeTaxEnabled,
        isHostCompEnabled,
        isHostTaxEnabled,
        isHomeHypoCompEnabled,
        isHomeHypoTaxEnabled,
        isHostHypoCompEnabled,
        isHostHypoTaxEnabled
      ),
    }
    section.children = formFields
    section.columnCount = columnCount
    return section
  })
  dispatch(
    fetchCalculationOptionsSuccess(
      homeLocation,
      hostLocation,
      calculationSectionsData
    )
  )
}

export const fetchCalculationOptionsIfNeeded = (
  homeLocation,
  hostLocation,
  planTypeCode,
  form
) => (dispatch, getState) => {
  const state = getState()
  const standardFormSection = getStandardFormSections(state)
  const standardFormSectionsIsFetching = getStandardFormSectionsIsFetching(
    state
  )
  if (
    !getCalculationSectionsIsFetching(state) &&
    standardFormSection &&
    !standardFormSectionsIsFetching
  ) {
    dispatch(fetchCalculationOptionsRequest(homeLocation, hostLocation))
    dispatch(
      filterCalculationOptionsByPlanTypeCode(
        homeLocation,
        hostLocation,
        planTypeCode
      )
    )
  }
}

export const fetchTaxOptionsIfNeeded = (form, taxOptionsParams) => (
  dispatch,
  getState
) => {
  const state = getState()
  const shouldFetchTaxOptions =
    taxOptionsParams &&
    ((taxOptionsParams.incomeTaxHomeLocationId &&
      taxOptionsParams.incomeTaxHomeLocationId !== -1) ||
      (taxOptionsParams.incomeTaxHostLocationId &&
        taxOptionsParams.incomeTaxHostLocationId !== -1) ||
      (taxOptionsParams.socialTaxHomeLocationId &&
        taxOptionsParams.socialTaxHomeLocationId !== -1) ||
      (taxOptionsParams.socialTaxHostLocationId &&
        taxOptionsParams.socialTaxHostLocationId !== -1))

  if (!getTaxSectionsIsFetching(state) && shouldFetchTaxOptions) {
    dispatch({
      [RSAA]: {
        endpoint: TAX_QUESTIONS_URL,
        method: 'POST',
        body: JSON.stringify(prepareNewTaxSectionsParams(taxOptionsParams)),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        types: [
          {
            type: FETCH_TAX_OPTIONS_REQUEST,
            meta: {
              incomeTaxHomeLocation: taxOptionsParams.incomeTaxHomeLocation,
            },
          },
          {
            type: FETCH_TAX_OPTIONS_SUCCESS,
            meta: {
              incomeTaxHomeLocation: taxOptionsParams.incomeTaxHomeLocation,
              incomeTaxHostLocation: taxOptionsParams.incomeTaxHostLocation,
            },
            payload: (_action, _currentState, res) =>
              getJSON(res).then(json => {
                const section = mapTaxSections(json.entries)
                dispatch(setTaxFieldsDefaultValues(state, section, form))

                return normalize([...section], [taxSectionSchema])
              }),
          },
          {
            type: FETCH_TAX_OPTIONS_FAILURE,
            meta: {
              incomeTaxHomeLocation: taxOptionsParams.incomeTaxHomeLocation,
              incomeTaxHostLocation: taxOptionsParams.incomeTaxHostLocation,
            },
          },
        ],
      },
    })
  }
}

export const setTaxFieldsDefaultValues = (
  state,
  sections,
  form = CREATE_ASSIGNMENT_FORM
) => dispatch => {
  const selector = formValueSelector(form)
  if (Object.keys(sections).length > 0) {
    sections.forEach(
      section =>
        section.taxFields &&
        section.taxFields.forEach(field => {
          const newValue =
            field.additionalProperties &&
            field.additionalProperties.defaultValue
          if (newValue) {
            const currentValue = getFieldValue(state, selector, field.code)

            !currentValue &&
              dispatch(updateFieldValue(form, field.code, newValue))
          }
        })
    )
  }
}

export const fetchCalculationSectionsIfNeeded = (
  currentValues,
  form = CREATE_ASSIGNMENT_FORM
) => dispatch =>
  currentValues &&
  currentValues.homeLocation &&
  currentValues.homeLocation.value &&
  currentValues.hostLocation &&
  currentValues.hostLocation.value &&
  currentValues.planTypeCode &&
  currentValues.planTypeCode.value &&
  dispatch(
    fetchCalculationOptionsIfNeeded(
      currentValues.homeLocation.value,
      currentValues.hostLocation.value,
      currentValues.planTypeCode.value,
      form
    )
  )

export const fetchTaxSectionsIfNeeded = (
  currentValues,
  form = CREATE_ASSIGNMENT_FORM
) => (dispatch, getState) => {
  const state = getState()

  const currentValueByKey = key =>
    currentValues && currentValues[key] && currentValues[key].value

  const incomeTaxHomeLocations = getIncomeTaxHomeLocations(state)
  const incomeTaxHostLocations = getIncomeTaxHostLocations(state)
  const incomeTaxHomeHypoLocations = getIncomeTaxHomeHypoLocations(state)
  const incomeTaxHostHypoLocations = getIncomeTaxHostHypoLocations(state)
  const socialTaxHomeLocations = getSocialTaxHomeLocations(state)
  const socialTaxHostLocations = getSocialTaxHostLocations(state)
  const socialTaxHomeHypoLocations = getSocialTaxHomeHypoLocations(state)
  const socialTaxHostHypoLocations = getSocialTaxHostHypoLocations(state)

  const incomeTaxHomeLocationId = getLocationId(
    incomeTaxHomeLocations,
    currentValueByKey(['options.General__HomeIncomeTaxLocation__List.value'])
  )
  const incomeTaxHostLocationId = getLocationId(
    incomeTaxHostLocations,
    currentValueByKey(['options.General__HostIncomeTaxLocation__List.value'])
  )
  const incomeTaxHomeHypoLocationId = getLocationId(
    incomeTaxHomeHypoLocations,
    currentValueByKey(['options.General__HypoIncomeTaxLocation__List.value'])
  )
  const incomeTaxHostHypoLocationId = getLocationId(
    incomeTaxHostHypoLocations,
    currentValueByKey([
      'options.General__HostHypoIncomeTaxLocation__List.value',
    ])
  )
  const socialTaxHomeLocationId = getLocationId(
    socialTaxHomeLocations,
    currentValueByKey(['options.General__HomeSocialTaxLocation__List.value'])
  )
  const socialTaxHostLocationId = getLocationId(
    socialTaxHostLocations,
    currentValueByKey(['options.General__HostSocialTaxLocation__List.value'])
  )
  const socialTaxHomeHypoLocationId = getLocationId(
    socialTaxHomeHypoLocations,
    currentValueByKey(['options.General__HypoSocialTaxLocation__List.value'])
  )
  const socialTaxHostHypoLocationId = getLocationId(
    socialTaxHostHypoLocations,
    currentValueByKey([
      'options.General__HostHypoSocialTaxLocation__List.value',
    ])
  )
  const planTypeCode = currentValueByKey(['planTypeCode']) || ''

  const taxSectionParams = {
    incomeTaxHomeLocationId,
    incomeTaxHostLocationId,
    socialTaxHomeLocationId,
    socialTaxHostLocationId,
    incomeTaxHomeHypoLocationId,
    socialTaxHomeHypoLocationId,
    incomeTaxHostHypoLocationId,
    socialTaxHostHypoLocationId,
    planTypeCode,
  }

  dispatch(fetchTaxOptionsIfNeeded(form, taxSectionParams))
}

export const fetchFormSectionsIfNeeded = (
  currentValues,
  formName
) => async dispatch => {
  const isEditMode = formName === EDIT_ASSIGNMENT_FORM
  const hasFormDesignsFetched = await dispatch(
    fetchFormDesignsIfNeeded(isEditMode)
  )
  if (
    !isNullOrUndefined(hasFormDesignsFetched && hasFormDesignsFetched.payload)
  ) {
    // This ensures that the custom form fields are fetched before the family custom form fields.
    Promise.all([
      dispatch(
        fetchCustomFormSectionsWithStandardFormSectionsIfNeeded(formName)
      ),
      dispatch(fetchAccountSectionsIfNeeded(currentValues)),
    ]).then(() => {
      dispatch(fetchFamilyFormFieldsIfNeeded())
      dispatch(fetchTaxSectionsIfNeeded(currentValues, formName))
      dispatch(fetchCalculationSectionsIfNeeded(currentValues))
    })
  }
}

const executeBusinessRule = (
  state,
  ruleFormula,
  previousValues,
  currentValues,
  currentValue,
  selector,
  formName
) =>
  // eslint-disable-next-line
  Function(
    'state',
    'previousValues',
    'currentValues',
    'currentValue',
    'selector',
    'formName',
    `'use strict'; return (${ruleFormula})`
  )(state, previousValues, currentValues, currentValue, selector, formName)

const runBusinessRule = (
  dispatch,
  state,
  rule,
  previousValues,
  currentValues,
  selector,
  formName
) => {
  const currentValue = getFieldValue(state, selector, rule.target)
  const newValue = executeBusinessRule(
    state,
    rule.formula,
    previousValues,
    currentValues,
    currentValue,
    selector,
    formName
  )
  if ((!currentValue && newValue) || newValue !== currentValue) {
    const overwrite =
      rule.overwrite === true ||
      executeBusinessRule(
        state,
        rule.overwrite,
        previousValues,
        currentValues,
        currentValue,
        selector,
        formName
      )
    if (overwrite) {
      dispatch(updateFieldValue(formName, rule.target, newValue))
    }
  }
}

export const executeEmployeeAutofillIfNeeded = (
  previousValues,
  currentValues
) => (dispatch, getState) => {
  const state = getState()

  // First execute known business rules - autofill form based on existing employee
  const employeeAutoFillIsNeeded =
    currentValues.employeeId && currentValues.employeeId.value

  if (employeeAutoFillIsNeeded) {
    const employee = getEmployeesById(state)[currentValues.employeeId.value]
    if (employee) {
      dispatch(
        updateFieldValue(
          CREATE_ASSIGNMENT_FORM,
          'firstName',
          employee.firstName
        )
      )
      dispatch(
        updateFieldValue(CREATE_ASSIGNMENT_FORM, 'lastName', employee.lastName)
      )

      if (employee.homeLocation && employee.homeLocation.name) {
        dispatch(
          updateFieldValue(
            CREATE_ASSIGNMENT_FORM,
            'homeLocation',
            employee.homeLocation.name
          )
        )
      }
    }
  }
}

export const setDefaultFilingStatusIfNeeded = (locationType, form) => (
  dispatch,
  getState
) => {
  const state = getState()
  let filingStatuses = {}
  let fieldName = ''
  switch (locationType) {
    case LOCATION_TYPE_HOME:
      filingStatuses = getHomeFilingStatusesById(state)
      fieldName = 'options.General__HomeFilingStatus__List.value'
      break
    case LOCATION_TYPE_HOST:
      filingStatuses = getHostFilingStatusesById(state)
      fieldName = 'options.General__HostFilingStatus__List.value'
      break
    case LOCATION_TYPE_HYPO:
      filingStatuses = getHypoFilingStatusesById(state)
      fieldName = 'options.General__HypoFilingStatus__List.value'
      break
    case LOCATION_TYPE_HOST_HYPO:
      filingStatuses = getHostHypoFilingStatusesById(state)
      fieldName = 'options.General__HostHypoFilingStatus__List.value'
      break
    default:
      filingStatuses = {}
      fieldName = ''
  }
  if (Object.keys(filingStatuses).length === 1 && fieldName !== '') {
    dispatch(
      updateFieldValue(form, fieldName, Object.values(filingStatuses)[0].value)
    )
  }
}

export const updateFieldValue = (form, fieldName, newValue) => dispatch =>
  dispatch(change(form, fieldName, newValue))

export const getFieldValue = (state, selector, fieldName) =>
  selector(state, fieldName)

export const getFormFieldValue = (state, form, fieldName) =>
  getFieldValue(state, formValueSelector(form), fieldName)

export const executeBusinessRulesIfNeeded = (
  previousValues,
  currentValues,
  formName
) => (dispatch, getState) => {
  const state = getState()

  // Then execute dynamic business rules
  let businessRules = getBusinessRules(state)
  let initValues = _.isEmpty(businessRules)
    ? createBusinessRules
    : businessRules

  // Define the formValue selector to get a handle on current values
  const selector = formValueSelector(formName)

  if (initValues && Object.keys(initValues).length > 0) {
    Object.keys(initValues).forEach(key => {
      const rule = initValues[key]
      if (rule)
        runBusinessRule(
          dispatch,
          state,
          rule,
          previousValues,
          currentValues,
          selector,
          formName
        )
    })
  }
}

export const updatePreviousFormTabErrors = (
  errors,
  formSectionId,
  previousFormSectionId
) => ({
  type: UPDATE_PREVIOUS_FORM_TAB_ERRORS,
  errors,
  formSectionId,
  previousFormSectionId,
})

export const fetchFormDesignsIfNeeded = (isEditing = false) => (
  dispatch,
  getState
) => {
  const state = getState()
  const formId = getLastSelectedCompanyFormId(state)
  if (formId) {
    return dispatch({
      [RSAA]: {
        endpoint: FORM_DESIGN_URL(formId, isEditing),
        method: 'GET',
        types: [
          FETCH_FORM_DESIGN_REQUEST,
          {
            type: FETCH_FORM_DESIGN_SUCCESS,
            payload: (_action, _currentState, res) =>
              getJSON(res)
                .then(json => {
                  return {
                    form: JSON.parse(json.form),
                    businessrules: JSON.parse(json.businessrules),
                  }
                })
                .catch(error => console.log(error)),
          },
          FETCH_FORM_DESIGN_FAILURE,
        ],
      },
    })
  }
}
