import {
  boolPT,
  emptyObject,
  emptyObjectPT,
  funcPT,
  isRequired,
  numberOrStringPT,
  stringPT,
} from 'arena-prop-types'
import PT from 'prop-types'
import {metaPT} from '../../constants/metaProps'
import {momentObj} from 'react-moment-proptypes'

const {arrayOf, bool, func, oneOf, oneOfType, string} = PT

export const namePT = stringPT

export const fieldTypes = [
  'dateTime',
  'number',
  'select',
  'text',
  'textArea',
  'currency',
  'money',
  'Money',
]

export const dateFormatPT = is => isRequired(oneOfType([string, bool]), is)
export const fieldTypePT = is => isRequired(oneOf(fieldTypes), is)
export const selectedDatePT = is =>
  isRequired(oneOfType([emptyObject, momentObj]), is)
export const timeFormatPT = is => isRequired(oneOfType([string, bool]), is)
export const validatePT = is => isRequired(oneOfType([func, arrayOf(func)]), is)

export const makeFieldProps = props => ({
  customValidationMessage: props.customValidationMessage,
  hasValue: props.hasValue,
  label: props.label,
  meta: props.meta,
  placeholder: props.placeholder,
  styleProps: props.styleProps,
  validationColor: props.validationColor,
  validationMessage: props.validationMessage,
  valueKey: props.valueKey,
})

export default {
  customValidationMessage: funcPT(true),
  hasValue: boolPT(true),
  label: numberOrStringPT(true),
  meta: metaPT(true),
  placeholder: numberOrStringPT(true),
  styleProps: emptyObjectPT(true),
  validationColor: stringPT(true),
  validationMessage: stringPT(true),
  valueKey: stringPT(true),
}
