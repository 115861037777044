import React, {Component} from 'react'
import {
  boolPT,
  emptyObjectPT,
  funcPT,
  numberPT,
  stringPT,
} from 'arena-prop-types'
import debounce from 'lodash.debounce'
import {isNullOrUndefined} from 'util'
import AssignmentForm from '../Form'
import {accountSectionsPT} from '../../constants/propTypes/accountSections'
import {assignmentAuditLogsPT} from '../../constants/propTypes/assignmentAuditLogs'
import {formSectionsPT} from '../../constants/propTypes/formSections'
import {resourcesPT} from '../../constants/propTypes/resources'
import {taxSectionsPT} from '../../constants/propTypes/taxSections'
import {
  LOCATION_TYPE_HYPO,
  LOCATION_TYPE_HOST,
  LOCATION_TYPE_HOME,
  LOCATION_TYPE_HOST_HYPO,
} from '../../constants/locationTypes'
import {calculationSectionsPT} from '../../constants/propTypes/calculationSections'
import {validateAndSubmit} from '../../utils/validation'

class EditAssignment extends Component {
  debouncedfetchTaxSectionsIfNeeded = debounce(() => {
    const {currentValues, fetchTaxSectionsIfNeeded, formName} = this.props
    fetchTaxSectionsIfNeeded(currentValues, formName)
  }, 1000)

  componentDidMount() {
    const {
      fetchAssignment,
      fetchAssignmentFamilyMembersIfNeeded,
      fetchAssignmentOptionsIfNeeded,
      fetchFormSectionsIfNeeded,
      fetchResourcesIfNeeded,
      fetchCompaniesIfNeeded,
      formName,
      currentValues,
      id,
    } = this.props
    fetchFormSectionsIfNeeded(currentValues, formName)
    fetchResourcesIfNeeded()
    fetchCompaniesIfNeeded()
    // NOTE: We're fetching the assignment again, so that we can initialize planTypeCode which is
    // not included in the paged channel assignments fetch.
    fetchAssignment(id)
    fetchAssignmentOptionsIfNeeded(id)
    fetchAssignmentFamilyMembersIfNeeded(id)
  }

  componentDidUpdate(prevProps) {
    const {
      currentValues,
      executeBusinessRulesIfNeeded,
      fetchFormSectionsIfNeeded,
      fetchAccountSectionsIfNeeded,
      fetchCalculationSectionsIfNeeded,
      fetchFilingStatusOnLocationChange,
      formIsFetching,
      formName,
      lastSelectedCompany,
      lastSelectedCompanyFormId,
      fetchResourcesIfNeeded,
    } = this.props

    const prevValues = prevProps.currentValues

    const shouldFetchFormSections =
      (!isNullOrUndefined(prevProps.lastSelectedCompanyFormId) &&
        prevProps.lastSelectedCompanyFormId) !==
      (!isNullOrUndefined(lastSelectedCompanyFormId) &&
        lastSelectedCompanyFormId)

    const shouldRefreshResources =
      !isNullOrUndefined(prevProps.lastSelectedCompany) &&
      prevProps.lastSelectedCompany &&
      prevProps.lastSelectedCompany.company !==
        (lastSelectedCompany && lastSelectedCompany.company)

    if (shouldFetchFormSections) {
      fetchFormSectionsIfNeeded(currentValues, formName)
    }

    if (shouldRefreshResources) {
      fetchResourcesIfNeeded(true)
    }

    const currentValueHasChanged = key =>
      (currentValues && currentValues[key] && currentValues[key].value) !==
      (prevValues && prevValues[key] && prevValues[key].value)

    const currentValuesHaveChanged = keys => {
      let result = false
      keys.forEach(x => {
        if (currentValueHasChanged(x)) {
          result = true
          return
        }
      })

      return result
    }

    const hasPolicyChanged = currentValueHasChanged('planTypeCode')

    const hasCompensationHomeHypoLocationChanged = currentValueHasChanged(
      'options.General__HypoCompensationLocation__List.value'
    )

    const hasCompensationHostHypoLocationChanged = currentValueHasChanged(
      'options.General__HostHypoCompensationLocation__List.value'
    )

    const hasIncomeTaxHomeLocationChanged = currentValueHasChanged(
      'options.General__HomeIncomeTaxLocation__List.value'
    )

    const hasIncomeTaxHostLocationChanged = currentValueHasChanged(
      'options.General__HostIncomeTaxLocation__List.value'
    )

    const hasIncomeTaxHomeHypoLocationChanged = currentValueHasChanged(
      'options.General__HypoIncomeTaxLocation__List.value'
    )

    const hasIncomeTaxHostHypoLocationChanged = currentValueHasChanged(
      'options.General__HostHypoIncomeTaxLocation__List.value'
    )

    const hasSocialTaxHomeLocationChanged = currentValueHasChanged(
      'options.General__HomeSocialTaxLocation__List.value'
    )

    const hasSocialTaxHostLocationChanged = currentValueHasChanged(
      'options.General__HostSocialTaxLocation__List.value'
    )

    const hasSocialTaxHomeHypoLocationChanged = currentValueHasChanged(
      'options.General__HypoSocialTaxLocation__List.value'
    )

    const hasSocialTaxHostHypoLocationChanged = currentValueHasChanged(
      'options.General__HostHypoSocialTaxLocation__List.value'
    )

    const couldFetchTaxSections =
      hasIncomeTaxHomeLocationChanged ||
      hasIncomeTaxHostLocationChanged ||
      hasIncomeTaxHomeHypoLocationChanged ||
      hasIncomeTaxHostHypoLocationChanged ||
      hasSocialTaxHomeLocationChanged ||
      hasSocialTaxHostLocationChanged ||
      hasSocialTaxHomeHypoLocationChanged ||
      hasSocialTaxHostHypoLocationChanged ||
      hasPolicyChanged

    const couldFetchCalculationSections = currentValuesHaveChanged([
      'homeLocation',
      'hostLocation',
      'planTypeCode',
    ])

    const couldExecuteDynamicBusinessRules = currentValuesHaveChanged([
      'firstName',
      'lastName',
      'homeLocation',
      'hostLocation',
      'planTypeCode',
    ])

    const shouldFetchHomeFilingStatusOptions =
      hasIncomeTaxHomeLocationChanged &&
      currentValues['options.General__HomeIncomeTaxLocation__List.value']
        .value !== undefined

    const shouldFetchHostFilingStatusOptions =
      hasIncomeTaxHostLocationChanged &&
      currentValues['options.General__HostIncomeTaxLocation__List.value']
        .value !== undefined

    const shouldFetchHypoFilingStatusOptions =
      hasIncomeTaxHomeHypoLocationChanged &&
      currentValues['options.General__HypoIncomeTaxLocation__List.value']
        .value !== undefined

    const shouldFetchHostHypoFilingStatusOptions =
      hasIncomeTaxHostHypoLocationChanged &&
      currentValues['options.General__HostHypoIncomeTaxLocation__List.value']
        .value !== undefined

    if (shouldFetchHomeFilingStatusOptions) {
      fetchFilingStatusOnLocationChange(
        currentValues['options.General__HomeIncomeTaxLocation__List.value']
          .value,
        LOCATION_TYPE_HOME,
        formName
      )
    }
    if (shouldFetchHostFilingStatusOptions) {
      fetchFilingStatusOnLocationChange(
        currentValues['options.General__HostIncomeTaxLocation__List.value']
          .value,
        LOCATION_TYPE_HOST,
        formName
      )
    }
    if (shouldFetchHypoFilingStatusOptions) {
      fetchFilingStatusOnLocationChange(
        currentValues['options.General__HypoIncomeTaxLocation__List.value']
          .value,
        LOCATION_TYPE_HYPO,
        formName
      )
    }
    if (shouldFetchHostHypoFilingStatusOptions) {
      fetchFilingStatusOnLocationChange(
        currentValues['options.General__HostHypoIncomeTaxLocation__List.value']
          .value,
        LOCATION_TYPE_HOST_HYPO,
        formName
      )
    }
    if (
      (couldExecuteDynamicBusinessRules ||
        hasCompensationHomeHypoLocationChanged ||
        hasCompensationHostHypoLocationChanged) &&
      !formIsFetching
    )
      executeBusinessRulesIfNeeded(prevValues, currentValues, formName)
    if (hasPolicyChanged) {
      fetchAccountSectionsIfNeeded(currentValues)
    }

    if (couldFetchCalculationSections) {
      fetchCalculationSectionsIfNeeded(currentValues)
    }

    if (couldFetchTaxSections) {
      this.debouncedfetchTaxSectionsIfNeeded()
    }
  }

  render() {
    // Adds the Audit Log section to the edit assignment form.
    const updatedFormSections = [
      ...this.props.formSections,
      {
        code: 'AssignmentAuditLog',
        component: 'AssignmentAuditLog',
        formFields: [],
        name: 'Audit Log',
      },
    ]

    return (
      <AssignmentForm
        assignmentId={this.props.id}
        accountSections={this.props.accountSections}
        assignmentAuditLogs={this.props.assignmentAuditLogs}
        assignmentAuditLogsIsFetching={this.props.assignmentAuditLogsIsFetching}
        assignmentAuditLogsSummary={this.props.assignmentAuditLogsSummary}
        change={this.props.change}
        currentValues={this.props.currentValues}
        environmentHeight={this.props.environmentHeight}
        errors={this.props.errors}
        fetchAssignmentAuditLog={this.props.fetchAssignmentAuditLog}
        formIsFetching={this.props.formIsFetching}
        formIsSubmitting={this.props.formIsSubmitting}
        formName={this.props.formName}
        formSections={updatedFormSections}
        formTabs={this.props.formTabs}
        formValidationError={this.props.formValidationError}
        formSubmissionError={this.props.formSubmissionError}
        initialValues={this.props.initialValues}
        isLocked={this.props.isLocked}
        linksHeaderTitle={
          this.props.formTitle ? `Edit ${this.props.formTitle}` : 'Edit'
        }
        loadingMessage=""
        onSubmit={values =>
          validateAndSubmit(
            values,
            this.props.errorsFromAllTabs,
            this.props.updateAssignment,
            this.props.id
          )
        }
        resources={this.props.resources}
        submitFailed={this.props.submitFailed}
        submitMessage={
          this.props.formTitle ? `Update` : 'Update this record now'
        }
        submittingMessage="Updating..."
        syncErrors={this.props.syncErrors}
        taxSections={this.props.taxSections}
        calculationSections={this.props.calculationSections}
        updatePreviousFormTabErrors={this.props.updatePreviousFormTabErrors}
      />
    )
  }
}

EditAssignment.defaultProps = {
  assignmentAuditLogs: [],
  assignmentAuditLogsIsFetching: false,
  assignmentAuditLogsSummary: {},
  currentValues: {},
  formTabs: {},
  formValidationError: null,
  initialValues: null,
  isLocked: false,
  syncErrors: {},
}

EditAssignment.propTypes = {
  accountSections: accountSectionsPT(true),
  assignmentAuditLogs: assignmentAuditLogsPT(),
  assignmentAuditLogsIsFetching: boolPT(true),
  assignmentAuditLogsSummary: emptyObjectPT(),
  change: funcPT(true),
  currentValues: emptyObjectPT(),
  environmentHeight: numberPT(true),
  errors: emptyObjectPT(true),
  executeBusinessRulesIfNeeded: funcPT(true),
  fetchAccountSectionsIfNeeded: funcPT(true),
  fetchAssignment: funcPT(true),
  fetchAssignmentAuditLog: funcPT(true),
  fetchAssignmentFamilyMembersIfNeeded: funcPT(true),
  fetchAssignmentOptionsIfNeeded: funcPT(true),
  fetchFormSectionsIfNeeded: funcPT(true),
  fetchResourcesIfNeeded: funcPT(true),
  fetchTaxSectionsIfNeeded: funcPT(true),
  formIsFetching: boolPT(true),
  formIsSubmitting: boolPT(true),
  formName: stringPT(true),
  formSections: formSectionsPT(true),
  formTabs: emptyObjectPT(),
  formValidationError: stringPT(),
  formSubmissionError: stringPT(),
  id: numberPT(true),
  initialValues: emptyObjectPT(),
  isLocked: boolPT(),
  resources: resourcesPT(true),
  submitFailed: boolPT(),
  syncErrors: emptyObjectPT(),
  taxSections: taxSectionsPT(true),
  calculationSections: calculationSectionsPT(true),
  updateAssignment: funcPT(true),
  updatePreviousFormTabErrors: funcPT(true),
  formTitle: stringPT(),
}

export default EditAssignment
