import {formatIndexRoute} from '../utils/router'
import {createSelector} from 'reselect'

const getStateRouter = state => state.router

export const getRouter = createSelector(
  getStateRouter,
  router => {
    const route = formatIndexRoute(router.route)
    return {
      route: route,
    }
  }
)

export const getId = state =>
  getRouter(state).route.keys.id ? Number(getRouter(state).route.keys.id) : 0

export const getSearch = state => getRouter(state).route.options.q || ''
