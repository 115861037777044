import React, {useState} from 'react'
import debounce from 'lodash.debounce'
import Typeahead from '../../arena-plugin-typeahead'
const debouncedSearch = debounce(
  (value, keyCode, search, typeahead, toggle) => {
    if (keyCode === 13) {
      //navigate to search page without type ahead when enter is pressed
      search(value)
      toggle(false)
    } else {
      //show typeahead results if search value is more than 3 characters
      if (value.length >= 3) {
        typeahead(value)
        toggle(true)
      }
    }
  },
  1000
)

const HeaderTypeahead = props => {
  const [value, setValue] = useState('')
  const [isSearchResultOpen, toggleSearchResult] = useState(false)

  const getValue = event => event.currentTarget.value
  const trimValue = value => value.trim()

  const handleOnKeyUp = event => {
    try {
      const value = trimValue(getValue(event))
      debouncedSearch(
        value,
        event.keyCode,
        props.search,
        props.typeahead,
        toggleSearchResult
      )
    } catch (error) {
      console.error(error)
    }
  }

  const handleOnChange = value => {
    try {
      setValue(value)
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <Typeahead
      handleSearch={handleOnKeyUp}
      handleChange={handleOnChange}
      handleSearchOnSelect={props.search}
      isSearchResultOpen={isSearchResultOpen}
      value={value}
      searchResults={props.searchResults}
      searchResultsFetching={props.searchResultsFetching}
      toggleSearchResult={toggleSearchResult}
      nextUrl={props.nextUrl}
      loadMoreResults={props.loadMoreResults}
      loadMoreResultsLoading={props.loadMoreResultsLoading}
    ></Typeahead>
  )
}

export default HeaderTypeahead
