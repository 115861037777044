import PT from 'prop-types'
import {commonPropTypes, hasValidResources} from '../../propTypes'

export const sectionRowDefaultProps = {
  type: 'ALL',
}

export const sectionRowPropTypes = {
  ...commonPropTypes,
  change: PT.func.isRequired,
  code: PT.string.isRequired,
  enableAutofill: PT.bool.isRequired,
  enableFormatting: PT.bool.isRequired,
  formName: PT.string.isRequired,
  formValues: PT.shape({}).isRequired,
  handleBulkChange: PT.func.isRequired,
  isLocked: PT.bool,
  name: PT.string.isRequired,
  resources: hasValidResources,
  rowsWithOpenResources: PT.arrayOf(PT.string).isRequired,
  startYear: PT.number.isRequired,
  toggleRowsWithBulkEdit: PT.func.isRequired,
  toggleRowsWithOpenResources: PT.func.isRequired,
  type: PT.string,
  useSingleAmountInsteadOfYears: PT.bool.isRequired,
}
