import React from 'react'
import AntTooltip from 'antd/lib/tooltip'
import 'antd/lib/tooltip/style/index.css'
import PT from 'prop-types'

const defaultProps = {
  mouseEnterDelay: 0.5,
  mouseLeaveDelay: 0.25,
  placement: 'top',
  title: '',
  transitionName: 'zoom-big',
}

const propTypes = {
  mouseEnterDelay: PT.number,
  placement: PT.string,
  title: PT.string,
  transitionName: PT.string,
}

const Tooltip = props => <AntTooltip {...props} />

Tooltip.defaultProps = defaultProps
Tooltip.propTypes = propTypes

export default Tooltip
