import {isRequired, numberPT, stringPT} from 'arena-prop-types'
import PT from 'prop-types'

const {arrayOf, shape} = PT

export const assignmentProps = {
  anticipatedEndDate: stringPT(true),
  anticipatedStartDate: stringPT(true),
  companyId: numberPT(true),
  employeeAssigned: stringPT(true),
  employeeId: stringPT(true),
  firstName: stringPT(true),
  homeCountryId: numberPT(true),
  hostCountryId: numberPT(true),
  id: numberPT(true),
  lastName: stringPT(true),
  nameAndId: stringPT(true),
  planTypeId: numberPT(true),
  title: stringPT(true),
}

export const assignmentPT = is => isRequired(shape(assignmentProps), is)

export const assignmentsPT = is => isRequired(arrayOf(assignmentPT), is)
