import {Relative} from 'arena-components'
import * as c from 'arena-config'
import {Button} from '../../components/arena-plugin-form'
import React from 'react'
import styled from 'styled-components'
import BulkEdit from './components/BulkEdit'
import Sections from './components/Sections'
import prepareRowMap from './utils/prepareRowMap'
import prepareStateFunctions from './utils/prepareStateFunctions'
import {cellWidthProps} from './constants/propTypes'
import {defaultProps, propTypes} from './propTypes'

const ButtonWrap = styled.div`
  padding: ${c.SMALLEST}px;
`

class Compensation extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      fieldMap: {},
      rowsWithBulkEdit: [],
      rowsWithOpenResources: [],
      showAllResources: props.showResourcesByDefault,
      stickyHeader: {},
    }
  }

  componentDidMount() {
    const {props} = this

    const fieldMap = prepareRowMap(props.sections)

    if (Object.keys(fieldMap).length) {
      this.setState({fieldMap})
    }
  }

  render() {
    const {props, state} = this

    const setState = stateObj => this.setState(stateObj)

    const stateFunctions = prepareStateFunctions(props, state, setState)

    return (
      <Relative>
        <BulkEdit
          change={props.change}
          delimiter={props.delimiter}
          emptyBulkEdit={stateFunctions.emptyBulkEdit}
          fieldMap={state.fieldMap}
          formName={props.formName}
          resourceFieldWidth={props.resourceFieldWidth}
          resources={props.resources}
          rowsWithBulkEdit={state.rowsWithBulkEdit}
          rowsWithOpenResources={state.rowsWithOpenResources}
          sections={props.sections}
          toggleRowsWithBulkEdit={stateFunctions.toggleRowsWithBulkEdit}
          fixedPosition={'bottom'}
        />
        <Sections
          {...cellWidthProps(props)}
          change={props.change}
          delimiter={props.delimiter}
          duration={props.duration}
          enableAutofill={props.enableAutofill}
          enableFormatting={props.enableFormatting}
          environmentHeight={props.environmentHeight}
          formName={props.formName}
          formValues={props.formValues}
          handleBulkChange={stateFunctions.handleBulkChange}
          isLocked={props.isLocked}
          resources={props.resources}
          rowsWithBulkEdit={state.rowsWithBulkEdit}
          rowsWithOpenResources={state.rowsWithOpenResources}
          sections={props.sections}
          showAllResources={state.showAllResources}
          showContentBeforeSections={props.showContentBeforeSections}
          startYear={props.startYear}
          stickyHeader={state.stickyHeader}
          toggleRowsWithBulkEdit={stateFunctions.toggleRowsWithBulkEdit}
          toggleRowsWithOpenResources={
            stateFunctions.toggleRowsWithOpenResources
          }
          toggleStickyHeader={stateFunctions.toggleStickyHeader}
          useSingleAmountInsteadOfYears={props.useSingleAmountInsteadOfYears}
        >
          <div>{props.children}</div>
          {props.showAllResourcesButton && (
            <ButtonWrap>
              <Button
                onClick={stateFunctions.toggleShowAllResources}
                preventDefault
              >
                {state.showAllResources ? 'Hide some' : 'Show all'} resources
              </Button>
            </ButtonWrap>
          )}
        </Sections>
      </Relative>
    )
  }
}

Compensation.defaultProps = defaultProps
Compensation.propTypes = propTypes

export default Compensation
