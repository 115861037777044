import {combineReducers} from 'redux'
import countries from './countries'
import currencies from './currencies'
import durations from './durations'
import employees from './employees'
import entities from './entities'
import jobTitles from './jobTitles'
import homeLocations from './homelocation'
import hostLocations from './hostlocation'
import policies from './policies'
import subEntities from './subEntities'
import homeFilingStatuses from './homeFilingStatuses'
import hostFilingStatuses from './hostFilingStatuses'
import hypoFilingStatuses from './hypoFilingStatuses'
import hostHypoFilingStatuses from './hostHypoFilingStatuses'

export default combineReducers({
  countries,
  currencies,
  durations,
  employees,
  entities,
  jobTitles,
  policies,
  subEntities,
  homeFilingStatuses,
  hostFilingStatuses,
  hypoFilingStatuses,
  hostHypoFilingStatuses,
  homeLocations,
  hostLocations,
})
