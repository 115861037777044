import {
  DATE_TIME_FIELD,
  NUMBER_FIELD,
  SELECT_FIELD,
  TEXT_FIELD,
} from 'arena-config'
import {CURRENCY_FIELD} from '../constants/form'
import {determineTaskActionType} from './timeline'
import {convertDateTimeToLocalTimezone, formatDate} from './moment'

export const mapAccountFields = accountFields =>
  accountFields.map(accountField => {
    const {id, name} = accountField

    return {
      code: `options.CompensationItem__${name}__Money`,
      id,
      label: name,
      type: NUMBER_FIELD,
    }
  })

export const mapAccountSection = accountSection => {
  const {children, code, id, name} = accountSection

  return {
    accountFields: children ? mapAccountFields(children) : [],
    code,
    id,
    name,
  }
}

export const mapAccountSections = accountSections =>
  accountSections.map(accountSection => mapAccountSection(accountSection))

export const mapAssignment = assignment => {
  const {
    employee: {
      companyId,
      email,
      employeeId,
      firstName,
      homeCountry,
      homeLocation,
      lastName,
      level,
      nameAndId,
    },
    position: {
      anticipatedEndDate,
      anticipatedStartDate,
      createdBy,
      createdOn,
      employeeAssigned,
      hostCountry,
      hostLocation,
      id,
      isLocked,
      planType,
      title,
      updatedBy,
      updatedOn,
      hasComments,
    },
    hasDocuments,
  } = assignment

  return {
    anticipatedEndDate,
    anticipatedStartDate,
    companyId,
    createdBy,
    createdOn,
    email,
    employeeAssigned,
    employeeId,
    firstName,
    hasComments,
    hasDocuments,
    homeCountryId: homeCountry.id,
    homeLocation,
    hostCountryId: hostCountry.id,
    hostLocation,
    id,
    isLocked,
    lastName,
    levelCode: level && level.code ? level.code : null,
    nameAndId,
    planTypeCode: planType.code,
    // FIXME: The API should send plan type code so that it doesn't expose the database IDs.
    planTypeId: planType.id,
    title,
    updatedBy,
    updatedOn,
  }
}

export const mapAssignmentOptions = assignmentOptions =>
  assignmentOptions.map(option => {
    const {optionType, name, valueType, currencyCode, value} = option
    return {
      currency: currencyCode,
      name: `${optionType}__${name}__${valueType}`,
      value,
    }
  })

export const mapAssignmentFamilyMemberOptions = assignmentFamilyMemberOptions => {
  const allOptions = {}
  assignmentFamilyMemberOptions.map(option => {
    const {optionType, name, valueType, value} = option

    allOptions[`${optionType}__${name}__${valueType}`] = {value}

    return option
  })

  return allOptions
}

export const mapAssignmentFamilyMembers = assignmentFamilyMembers =>
  assignmentFamilyMembers.map(familyMember => {
    if (familyMember.options && familyMember.options.length > 0) {
      return {
        ...familyMember,
        ...familyMember.summary,
        options: mapAssignmentFamilyMemberOptions(familyMember.options),
      }
    }

    return {...familyMember, ...familyMember.summary, options: null}
  })

export const mapAssignmentAuditLog = assignmentAuditLog =>
  assignmentAuditLog.map(auditLogItem => ({
    ...auditLogItem,
    actionOn: convertDateTimeToLocalTimezone(auditLogItem.actionOn),
  }))

export const mapAssignments = assignments =>
  assignments.items.map(assignment => mapAssignment(assignment))

const mapFieldOptions = fieldOptions =>
  fieldOptions.map(option => ({value: option.code, label: option.name}))

export const mapFieldType = type => {
  switch (type) {
    case 'Date':
      return DATE_TIME_FIELD
    case 'Money':
    case 'Numeric':
      return NUMBER_FIELD
    case 'List':
      return SELECT_FIELD
    case 'Textbox':
      return TEXT_FIELD
    case 'Currency':
      return CURRENCY_FIELD
    default:
      // Unmapped values are text field by default.
      return TEXT_FIELD
  }
}

export const mapFieldValueType = type => {
  switch (type) {
    case 'Currency':
      return CURRENCY_FIELD
    default:
      // Unmapped values should just use the type already set
      return type
  }
}

export const mapFormFields = (
  formFields,
  namePrefix,
  keyPrefix = 'CustomField'
) =>
  formFields.map(formField => {
    const {
      additionalProperties,
      children,
      code,
      creatable,
      description,
      disabled,
      maxLength,
      name,
      optionsType,
      required,
      validate,
    } = formField
    const type = mapFieldType(description)
    const valueType = mapFieldValueType(description)

    return {
      additionalProperties,
      code: namePrefix
        ? `${namePrefix}.${keyPrefix}__${code}__${valueType}.value`
        : code,
      suffix:
        type === CURRENCY_FIELD
          ? namePrefix
            ? `${namePrefix}.${keyPrefix}__${code}__${valueType}.currency`
            : code
          : null,
      creatable,
      disabled:
        disabled || (additionalProperties && additionalProperties.disabled),
      id: code,
      label: name,
      maxLength:
        maxLength || (additionalProperties && additionalProperties.maxLength),
      options: children ? mapFieldOptions(children) : [],
      optionsType:
        optionsType || (type === CURRENCY_FIELD ? 'currencies' : optionsType),
      required,
      type,
      validate,
    }
  })

export const mapFormSection = (formSection, namePrefix) => {
  const {
    children,
    code,
    component,
    componentType,
    name,
    helpText,
    additionalProperties,
  } = formSection

  return {
    code,
    component,
    componentType,
    formFields: children ? mapFormFields(children, namePrefix) : [],
    name,
    helpText: helpText
      ? helpText
      : additionalProperties && additionalProperties.helpText
      ? additionalProperties.helpText
      : '',
    additionalProperties,
  }
}

export const mapFormSections = (formSections, namePrefix = '') =>
  formSections.map(formSection => mapFormSection(formSection, namePrefix))

export const mapTaskAction = taskAction => {
  const {
    description,
    icon,
    id,
    isDisabled,
    label,
    link,
    missingFields,
    newStatus,
    type,
  } = taskAction

  return {
    description,
    icon,
    id,
    isDisabled,
    label,
    missingFields,
    newStatus,
    type,
    ...determineTaskActionType(link, type),
  }
}

export const mapTaskActions = taskActions =>
  taskActions
    .map(taskAction => mapTaskAction(taskAction))
    .sort((a, b) => b.newStatus.localeCompare(a.newStatus) || a.id - b.id)

export const mapTaskIcon = icon => {
  switch (icon) {
    case 'person-add':
      return 'AddPerson'
    case 'file-document':
      return 'AddPerson'
    default:
      return ''
  }
}

export const mapTaxFieldType = type => {
  switch (type) {
    case 'FilingStatus':
    case 'TaxUDFOptions':
    case '':
      return 'General'
    case 'TaxUDFFieldHypo':
      return 'TaxOptionHypo'
    case 'TaxUDFField':
    default:
      return 'TaxOption'
  }
}

export const mapTimeline = timeline => ({
  id: timeline.name,
  items: timeline.items.map((item, index) => {
    const {
      actions,
      completedDate,
      dueDate,
      icon,
      id,
      label,
      link,
      owner,
      status,
      title,
      tooltip,
    } = item

    return {
      actions: mapTaskActions(actions),
      completedDate,
      dueDate,
      employeeId: owner,
      icon,
      id,
      isLastTask: timeline.items.length - 1 === index,
      label,
      link,
      status: status.toLowerCase(),
      title,
      tooltip,
    }
  }),
})

export const mapTaxFields = taxFields =>
  taxFields.map(taxField => {
    const {
      children,
      code,
      creatable,
      description,
      disabled,
      maxLength,
      name,
      optionsType,
      listType,
      additionalProperties,
    } = taxField
    const type = mapFieldType(description)
    const taxFieldType = mapTaxFieldType(listType)
    const valueType = mapFieldValueType(description)
    const isTaxFieldCurrency =
      taxFieldType === 'TaxOption' && type === CURRENCY_FIELD
    if (isTaxFieldCurrency) {
      return {
        code: `options.${taxFieldType}__${code}__${valueType}.value`,
        suffix: `options.${taxFieldType}__${code}__${valueType}.currency`,
        creatable,
        disabled:
          disabled || (additionalProperties && additionalProperties.disabled),
        label: name,
        maxLength:
          maxLength || (additionalProperties && additionalProperties.maxLength),
        options: children ? mapFieldOptions(children) : [],
        optionsType: optionsType || 'currencies' || 'TaxOption',
        type,
        additionalProperties,
      }
    }
    return {
      code: `options.${taxFieldType}__${code}__${valueType}.value`,
      creatable,
      disabled:
        disabled || (additionalProperties && additionalProperties.disabled),
      label: name,
      maxLength:
        maxLength || (additionalProperties && additionalProperties.maxLength),
      options: children ? mapFieldOptions(children) : [],
      optionsType: optionsType || taxFieldType || 'TaxOption',
      type,
      additionalProperties,
    }
  })

export const mapTaxSection = taxSection => {
  const {children, code, component, componentType, name} = taxSection

  return {
    code,
    component: component || 'TaxOptions',
    componentType,
    taxFields: children ? mapTaxFields(children) : [],
    name,
  }
}

export const mapTaxSections = taxSections =>
  taxSections.map(taxSection => mapTaxSection(taxSection))

export const mapTimelines = timelines =>
  timelines.map(timeline => mapTimeline(timeline))

export const mapDataList = dataList =>
  dataList.map(item => ({id: item.id, label: item.name, value: item.code}))

export const mapUploadForm = data =>
  data.map(item => ({
    value: item.code,
    label: item.name,
  }))

export const mapPolicyList = policyList =>
  policyList.map(item => ({
    additionalProperties: item.additionalProperties,
    id: item.id,
    label: item.name,
    value: item.code,
  }))

export const mapFilingStatuses = items =>
  items.map(item => ({
    description: item.description,
    id: item.id,
    label: item.name,
    value: item.code,
  }))

export const mapCompanyList = companyList =>
  companyList.map(item => ({
    id: item.companyID,
    label: item.companyName,
    userId: item.userID,
    userEmail: item.email,
    value: item.companyCode,
    additionalProperties: item.additionalProperties,
  }))

export const mapLocations = items =>
  items.map(item => ({
    additionalProperties: item.additionalProperties,
    id: item.id,
    label: `${item.name}, ${item.code}`,
    value: item.name,
    code: item.code,
  }))
export const deepCopy = object => JSON.parse(JSON.stringify(object))

export const filterFields = (filter, fields) =>
  filter
    ? deepCopy(fields)
        .map(section => {
          section.formFields = section.formFields
            .map(item => {
              if (
                item.additionalProperties &&
                item.additionalProperties.includedIn
              ) {
                if (
                  item.additionalProperties.includedIn
                    .toString()
                    .toUpperCase()
                    .includes(`"${filter.toUpperCase()}"`)
                ) {
                  return item
                } else {
                  return null
                }
              } else {
                return item
              }
            })
            .filter(item => item != null)
          if (
            section.component !== undefined ||
            section.formFields.length > 0
          ) {
            return section
          } else {
            return null
          }
        })
        .filter(item => item != null)
    : fields

export const mapCalculationFields = calcFields =>
  calcFields.map(calcField => {
    const {
      children,
      code,
      creatable,
      description,
      disabled,
      maxLength,
      name,
      optionsType,
      required,
      additionalProperties,
    } = calcField
    const type = mapFieldType(description)
    return {
      code,
      creatable,
      disabled:
        disabled || (additionalProperties && additionalProperties.disabled),
      label: name,
      maxLength:
        maxLength || (additionalProperties && additionalProperties.maxLength),
      options: children ? mapFieldOptions(children) : [],
      optionsType: optionsType || 'General',
      required,
      type,
      additionalProperties,
    }
  })

export const mapCalculationSection = calcSection => {
  const {
    children,
    code,
    component,
    componentType,
    name,
    columnCount,
  } = calcSection

  return {
    code,
    component: component || 'CalculationOptions',
    componentType,
    columnCount,
    calculationFields: children ? mapCalculationFields(children) : [],
    name,
  }
}

export const mapCalculationSections = calcSections => {
  return calcSections.map(calcSection => mapCalculationSection(calcSection))
}

// Update transformAssignmentOption for multi year support //
export const transformAssignmentOptions = (
  options,
  optionType = null,
  startDate,
  endDate
) => {
  if (!options || Object.keys(options).length === 0) return undefined
  const results = Object.keys(options).map(key => {
    const option = options[key]
    const keySegments = key.split('__')
    const valueType = keySegments[2]
    return (
      option &&
      option.value && {
        currencyCode: option.currency || option.currencyCode || undefined,
        description: keySegments[1],
        name: keySegments[1],
        optionType: optionType || keySegments[0],
        value: valueType === 'Date' ? formatDate(option.value) : option.value,
        valueType,
      }
    )
  })
  return results.filter(e => e)
}

const transformAssignmentFamilyMemberRequests = familyMemberRequests => {
  if (!familyMemberRequests || Object.keys(familyMemberRequests).length === 0)
    return undefined

  const results = Object.keys(familyMemberRequests).map(key => {
    const {dateOfBirth, gender, options} = familyMemberRequests[key]
    return {
      ...familyMemberRequests[key],
      dateOfBirth: dateOfBirth ? formatDate(dateOfBirth) : undefined,
      gender: gender || '-1',
      options: transformAssignmentOptions(options, 'Family'),
    }
  })

  return results.filter(e => e)
}

export const prepareNewAssignment = assignment => {
  const {
    email,
    employeeId,
    endDate,
    firstName,
    homeCountryCode,
    homeLocation,
    hostCountryCode,
    hostLocation,
    lastName,
    planTypeCode,
    positionName,
    levelCode,
    startDate,
  } = assignment
  return {
    email: email || undefined,
    employeeId,
    endDate: formatDate(endDate),
    familyMemberRequests: transformAssignmentFamilyMemberRequests(
      assignment.familyMemberRequests
    ),
    firstName,
    homeCountryCode:
      (homeCountryCode && homeCountryCode.value) || homeCountryCode,
    hostCountryCode:
      (hostCountryCode && hostCountryCode.value) || hostCountryCode,
    homeLocation: (homeLocation && homeLocation.value) || homeLocation,
    hostLocation: (hostLocation && hostLocation.value) || hostLocation,
    lastName,
    options: transformAssignmentOptions(assignment.options),
    planTypeCode,
    positionName: positionName ? positionName : levelCode,
    levelCode,
    startDate: formatDate(startDate),
  }
}

export const prepareNewTaxSectionsParams = taxSectionsParams => {
  const {
    compensationHomeLocationId,
    compensationHostLocationId,
    compensationHomeHypoLocationId,
    compensationHostHypoLocationId,
    incomeTaxHomeLocationId,
    incomeTaxHostLocationId,
    incomeTaxHomeHypoLocationId,
    incomeTaxHostHypoLocationId,
    socialTaxHomeLocationId,
    socialTaxHostLocationId,
    socialTaxHomeHypoLocationId,
    socialTaxHostHypoLocationId,
    planTypeCode,
  } = taxSectionsParams

  return {
    policyType: planTypeCode,
    homeCompensationLocationId: compensationHomeLocationId,
    homeIncomeTaxLocationId: incomeTaxHomeLocationId,
    homeSocialTaxLocationId: socialTaxHomeLocationId,
    hostCompensationLocationId: compensationHostLocationId,
    hostIncomeTaxLocationId: incomeTaxHostLocationId,
    hostSocialTaxLocationId: socialTaxHostLocationId,
    homeHypoCompensationLocationId: compensationHomeHypoLocationId,
    homeHypoIncomeTaxLocationId: incomeTaxHomeHypoLocationId,
    homeHypoSocialTaxLocationId: socialTaxHomeHypoLocationId,
    hostHypoCompensationLocationId: compensationHostHypoLocationId,
    hostHypoIncomeTaxLocationId: incomeTaxHostHypoLocationId,
    hostHypoSocialTaxLocationId: socialTaxHostHypoLocationId,
  }
}
