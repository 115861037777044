import {isRequired, stringPT} from 'arena-prop-types'
import PT from 'prop-types'

const {arrayOf, bool, shape, string, array} = PT

export const taskActionProps = {
  description: stringPT(true),
  icon: string,
  isDisabled: bool.isRequired,
  label: stringPT(true),
  link: string,
  missingFields: array,
  newStatus: string,
  type: stringPT(true),
}

export const taskActionPT = is => isRequired(shape(taskActionProps), is)

export const taskActionsPT = is => isRequired(arrayOf(taskActionPT), is)
