import * as c from 'arena-config'

import React from 'react'
import styled, {keyframes} from 'styled-components'
import HeaderButton from '../HeaderButton'
import {headerLinkDefaultProps, headerLinkPropTypes} from './propTypes'

const CustomRendererWrapper = styled.div``

const Inner = styled.div`
  letter-spacing: 0.025em;
  line-height: 1;
  ${c.FOR_DESKTOP} {
    padding: 0px 4px;
    margin: 0px 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: ${props => c.px(props.height)};
  }
`

const Progress = styled.div`
  min-height: ${props => c.px(props.height)};
  width: 100%;
  position: relative;
`
const ProgressInner = styled.div`
  min-height: 48px;
  position: absolute;
  width: ${props => props.width}%;
  background: rgba(28, 219, 188, 0.5);
  transition: width 2s;
`

const ProgressStart = styled.div`
  min-height: 48px;
  position: relative;
  width: 100%;
`
const pulse = keyframes`
0% {
        transform: scale(0.75);
        box-shadow: 0 0 0 0 rgba(28, 219, 188, 0.1);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(28, 219, 188, 0);
    }

    100% {
        transform: scale(0.75);
        box-shadow: 0 0 0 0 rgba(28, 219, 188, 0);
    }
`

const ProgressStartInner = styled.div`
  min-height: 48px;
  position: absolute;
  background: rgba(28, 219, 188, 0.5);
  box-shadow: 0 0 0 0 rgba(28, 219, 188, 0.1);
  width: 48px;
  border-radius: 24px;
  left: calc((100% - 48px) / 2);
  animation: 2s ${pulse} ease-out infinite;
`

const Outer = styled(({color, isActive, white, ...rest}) => <div {...rest} />)`
  color: ${props => props.color};
  cursor: ${props => (props.isActive ? 'default' : 'pointer')};
  display: block;

  ${c.FOR_DESKTOP} {
    &:hover {
      background-color: ${props =>
        props.white && !props.isActive ? 'rgba(0, 0, 0, .1)' : undefined};
      color: ${props =>
        props.isActive ? undefined : props.white ? 'white' : c.DARK_GRAY};
      opacity: 1;
    }
  }
`

const HeaderLink = props => {
  if (props.customRenderer) {
    return (
      <CustomRendererWrapper onClick={props.handleClick}>
        {props.customRenderer()}
      </CustomRendererWrapper>
    )
  }

  const color = props.isActive
    ? props.white
      ? '#008080'
      : '#008080'
    : props.white
    ? 'rgba(255, 255, 255, .8)'
    : 'black'

  return (
    <HeaderButton onClick={props.handleClick} reset>
      <Outer color={color} isActive={props.isActive} white={props.white}>
        {props.hasProgress && props.progress ? (
          props.isStarting ? (
            <ProgressStart>
              <ProgressStartInner></ProgressStartInner>
              <Inner height={props.height}>{props.children}</Inner>
            </ProgressStart>
          ) : (
            <Progress>
              <ProgressInner width={props.progress}></ProgressInner>
              <Inner height={props.height}>{props.children}</Inner>
            </Progress>
          )
        ) : (
          <Inner height={props.height}>{props.children}</Inner>
        )}
      </Outer>
    </HeaderButton>
  )
}

HeaderLink.defaultProps = headerLinkDefaultProps
HeaderLink.propTypes = headerLinkPropTypes

export default HeaderLink
