import {combineReducers} from 'redux'
import {
  FETCH_JOB_TITLES_FAILURE,
  FETCH_JOB_TITLES_REQUEST,
  FETCH_JOB_TITLES_SUCCESS,
} from '../../../constants/actionTypes'

const jobTitlesById = (state = {}, action) => {
  switch (action.type) {
    case FETCH_JOB_TITLES_SUCCESS:
      return action.payload.entities.jobTitles || {}
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_JOB_TITLES_REQUEST:
      return true
    case FETCH_JOB_TITLES_SUCCESS:
    case FETCH_JOB_TITLES_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_JOB_TITLES_FAILURE:
      return action.payload.message
    case FETCH_JOB_TITLES_REQUEST:
    case FETCH_JOB_TITLES_SUCCESS:
      return null
    default:
      return state
  }
}

const jobTitles = combineReducers({
  byId: jobTitlesById,
  isFetching,
  errorMessage,
})

export default jobTitles
