import {overlay} from 'arena-config'
import React, {useState} from 'react'
import Notifications from 'react-notification-system-redux'
import styled from 'styled-components'
import Body from './Body'
import Header from './Header'
import {defaultProps, propTypes} from './propTypes'
import {TIMEOUT_DELAY} from '../../constants/scroll'
import {scrollThrottle} from '../../utils/scrollThrottle'

const Wrap = styled.div`
  ${overlay()};
`

const Navigation = props => {
  const [shouldHideDropdwon, setShouldHideDropdown] = useState(false)
  scrollThrottle(callbackData => {
    const {previousScrollTop, currentScrollTop} = callbackData
    const isScrolledDown = previousScrollTop < currentScrollTop
    setTimeout(() => {
      setShouldHideDropdown(isScrolledDown)
    }, TIMEOUT_DELAY)
  })
  return (
    <Wrap>
      <Notifications notifications={props.notifications} />
      {props.hasHeader && (
        <Header
          activeTab={props.activeTab}
          advancedSearchIsOpen={props.advancedSearchIsOpen}
          alerts={props.alerts}
          currentRoute={props.currentRoute}
          defaultAction={props.defaultAction}
          disableAuthentication={props.disableAuthentication}
          dropdownOptions={props.dropdownOptions}
          dropdownDefaultValue={props.dropdownDefaultValue}
          headerAlign={props.headerAlign}
          headerBackgroundColor={props.headerBackgroundColor}
          headerHeight={props.headerHeight}
          isAuthenticated={!!(props.user || props.disableAuthentication)}
          isMobile={props.isMobile}
          links={props.links}
          login={props.login}
          logoSrc={props.logoSrc}
          logout={props.logout}
          menuSrc={props.menuSrc}
          menuCloseSrc={props.menuCloseSrc}
          onLogoClick={props.onLogoClick}
          onSelect={props.onSelect}
          selectedItem={props.selectedItem}
          renderAdvancedSearch={props.renderAdvancedSearch}
          renderSearchResults={props.renderSearchResults}
          search={props.search}
          shouldHideDropdwon={shouldHideDropdwon}
          typeahead={props.typeahead}
          searchString={props.searchString}
          searchResults={props.searchResults}
          searchResultsFetching={props.searchResultsFetching}
          nextUrl={props.nextUrl}
          loadMoreResults={props.loadMoreResults}
          loadMoreResultsLoading={props.loadMoreResultsLoading}
          searchOnChange={props.searchOnChange}
          searchResultsIsOpen={props.searchResultsIsOpen}
          searchValue={props.searchValue}
          searchWidth={props.searchWidth}
          sendReport={props.sendReport}
          subHeaderAlign={props.subHeaderAlign}
          subHeaderHeight={props.subHeaderHeight}
          subLinks={props.subLinks}
          toggleAdvancedSearchIsOpen={props.toggleAdvancedSearchIsOpen}
          toggleSearchResultsIsOpen={props.toggleSearchResultsIsOpen}
          user={props.user}
          zIndex={props.zIndex}
        />
      )}
      <Body
        hasHeader={props.hasHeader}
        hasHeaderBottom={!!props.subLinks.length}
        headerHeight={props.headerHeight}
        subHeaderHeight={props.subHeaderHeight}
        shouldHideDropdwon={shouldHideDropdwon}
      >
        {props.children}
      </Body>
    </Wrap>
  )
}

Navigation.defaultProps = defaultProps
Navigation.propTypes = propTypes

export default Navigation
