import {RSAA} from 'redux-api-middleware'
import {
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_FAILURE,
  DOWNLOAD_PROGRESS,
  RESET_PROGRESS,
} from '../constants/actionTypes'
import {sendFileToBrowser} from 'arena-redux'

export const downloadFile = endpoint => dispatch =>
  dispatch({
    [RSAA]: {
      endpoint: endpoint,
      method: 'GET',
      types: [
        DOWNLOAD_REQUEST,
        {
          type: DOWNLOAD_SUCCESS,
          payload: (_action, _state, res) => {
            sendFileToBrowser(res)
            setTimeout(() => dispatch(resetProgress()), 4000)
          },
        },
        DOWNLOAD_FAILURE,
        DOWNLOAD_PROGRESS,
      ],
      headers: {'Content-Type': 'application/json'},
    },
  })

export const resetProgress = () => ({
  type: RESET_PROGRESS,
})
