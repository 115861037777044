export default (code, formValues) => {
  const valueKeys = Object.keys(formValues)

  for (let i = 0; i < valueKeys.length; i += 1) {
    const key = valueKeys[i]

    if (key.indexOf(code) > -1) {
      return true
    }
  }

  return false
}
