/* global document */
/* global window */
import PT from 'prop-types'
import React, {Component} from 'react'
import styled from 'styled-components'
import {Icon as AntIcon} from 'antd'

const defaultProps = {
  args: [],
  className: '',
}

const propTypes = {
  args: PT.arrayOf(PT.any),
  children: PT.node.isRequired,
  className: PT.string,
  onScroll: PT.func.isRequired,
}

const IconWrap = styled.div`
  line-height: 1;
  line-height: 1;
  font-size: 2.5em;
  color: #3498db;
`
const LoadingNext = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

class InfiniteScroll extends Component {
  constructor(props) {
    super(props)
    this.onScroll = this.onScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true)
  }

  componentDidUpdate(prevProps) {
    const {args} = this.props
    const currentChannel = args[0]
    const previousChannel = prevProps.args[0]
    const shouldScrollToTop = previousChannel !== currentChannel
    if (shouldScrollToTop) {
      window.scrollTo({top: 0, behaviour: 'smooth'})
    }
  }

  onScroll() {
    const mainContent = document.getElementsByClassName('Overview')[0]
      .parentElement.parentElement

    if (
      mainContent &&
      window.innerHeight + (window.scrollY || 0) >=
        mainContent.scrollHeight - mainContent.scrollTop - 300
    ) {
      const {args, onScroll} = this.props
      onScroll(...args)
    }
  }

  render() {
    const {children, className, isLoadingMore} = this.props

    return (
      <>
        <div className={className}>
          {children}
          {isLoadingMore && (
            <LoadingNext>
              <IconWrap>
                <AntIcon type="loading"></AntIcon>
              </IconWrap>
            </LoadingNext>
          )}
        </div>
      </>
    )
  }
}

InfiniteScroll.defaultProps = defaultProps
InfiniteScroll.propTypes = propTypes

export default InfiniteScroll
