import {createSelector} from 'reselect'

const getExportProgress = state => state.exportProgress

export const getExportProgressPercentage = createSelector(
  [getExportProgress],
  exportProgress => exportProgress.progress
)
export const getExportProgressIsStarting = createSelector(
  [getExportProgress],
  exportProgress => exportProgress.isStarting
)
