import React from 'react'
import AntDModal from 'antd/lib/modal'
import {colorPropTypes} from 'arena-components/build/constants/propTypes'
import {Spin} from 'antd'
import {boolPT, emptyObjectPT, stringPT} from 'arena-prop-types'
import styled from 'styled-components'

const FlyerWrapper = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`
const ModalFlyerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`
const Spinner = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #0a838a;
  }
  .ant-spin-text {
    color: #0a838a;
    font-size: 1.5rem;
    font-weight: 200;
  }
`

const Loader = props => {
  const loader = props.isModal ? (
    <AntDModal
      visible={props.visible}
      destroyOnClose={props.destroyOnClose}
      footer={false}
      closable={props.closable}
      maskClosable={props.maskClosable}
      keyboard={props.keyboard}
      centered
      bodyStyle={props.bodyStyle}
    >
      <ModalFlyerWrapper>
        <Spinner size="large" tip={props.message} />
      </ModalFlyerWrapper>
    </AntDModal>
  ) : (
    // <Flyer icon={props.icon} message={props.message} {...props} />
    <FlyerWrapper>
      <Spinner size="large" tip={props.message} />
    </FlyerWrapper>
  )
  return loader
}

Loader.defaultProps = {
  visible: false,
  destroyOnClose: true,
  closable: false,
  maskClosable: false,
  keyboard: false,
  bodyStyle: {height: '225px'},
  isModal: false,
  icon: 'dots',
  message: '',
}

Loader.prototype = {
  visible: boolPT(),
  destroyOnClose: boolPT(),
  closable: boolPT(),
  maskClosable: boolPT(),
  keyboard: boolPT(),
  bodyStyle: emptyObjectPT(),
  message: stringPT(),
  isModal: boolPT(),
  icon: stringPT(),
  ...colorPropTypes,
}

export default Loader
