import PT from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {Modal} from './core'

const defaultProps = {
  confirmationMessage: 'Are you sure you want to delete this?',
  confirmationTitle: 'Delete',
  iconSrc: null,
}

const propTypes = {
  confirmationMessage: PT.string,
  confirmationTitle: PT.string,
  iconSrc: PT.string,
  onClose: PT.func.isRequired,
  onSubmit: PT.func,
}

const CancelButton = styled.button`
  margin-right: 15px;
  padding: 5px 16px;
  color: #000000;
  cursor: pointer;
  font-size: large;
  font-weight: 500;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 24px;
  &:hover {
    background: #8bf5e3;
    border: 1px solid #8bf5e3;
  }
`
const Button = styled.button`
  padding: 5px 16px;
  color: white;
  cursor: pointer;
  font-size: large;
  font-weight: 500;
  background: #e5484d;
  border: 1px solid #e5484d;
  border-radius: 24px;
  opacity: 0.8;
  &:hover {
    background: #f3aeaf;
    border: 1px solid #f3aeaf;
  }
`

const ConfirmationModal = ({
  confirmationMessage,
  confirmationTitle,
  iconSrc,
  onClose,
  onDelete,
  onReject,
}) => (
  <Modal
    iconSrc={iconSrc}
    message={confirmationMessage}
    onClose={onClose}
    title={confirmationTitle}
  >
    <CancelButton className="white" onClick={onClose}>
      Cancel
    </CancelButton>
    {onDelete && (
      <Button className="white" onClick={onDelete}>
        Delete
      </Button>
    )}
    {onReject && (
      <Button className="white" onClick={onReject}>
        Reject
      </Button>
    )}
  </Modal>
)

ConfirmationModal.defaultProps = defaultProps
ConfirmationModal.propTypes = propTypes

export default ConfirmationModal
