import {RED} from 'arena-config'
import {
  boolPT,
  funcPT,
  nodePT,
  numberPT,
  numberOrStringPT,
  stringPT,
} from 'arena-prop-types'
import React from 'react'

import {Field as ReduxField, formValues} from 'redux-form' // ES6

import styled from 'styled-components'
import {currentValuePT} from '../../constants/inputProps'
import {styleDefaultProps, stylePropsPT} from './styleProps'
import determineFieldComponent from '../../utils/determineFieldComponent'
import prepareValidation from '../../utils/prepareValidation'
import {
  dateFormatPT,
  fieldTypePT,
  namePT,
  timeFormatPT,
  validatePT,
} from './propTypes'
import DateTimeDay from './DateTimeDay'
import DateTimeMonth from './DateTimeMonth'
import DateTimeYear from './DateTimeYear'
import PT from 'prop-types'

const Wrap = styled.div`
  position: relative;
`

const Field = props => {
  const hasValue =
    props.hasValue ||
    !!props.currentValue ||
    (props.currentValue === false && typeof props.currentValue === typeof false)
  const requiredIndicator =
    props.label && props.required ? props.requiredIndicator : ''

  const prepareIfRequired = text => (text ? text + requiredIndicator : '')

  const label = prepareIfRequired(props.label)
  const placeholder = prepareIfRequired(props.placeholder)

  const labelAsPlaceholder =
    placeholder || (!props.styleProps.fixedLabel ? label : '')
  return (
    <Wrap>
      <ReduxField
        allowDecimal={props.allowDecimal}
        allowNegative={props.allowNegative}
        clearOnBackspace={props.clearOnBackspace}
        clearable={false} // leave as is for now, needs styling
        closeOnSelect={props.closeOnSelect}
        component={determineFieldComponent(props.type)}
        creatable={props.creatable}
        customValidationMessage={props.customValidationMessage}
        dateFormat={props.dateFormat}
        dateTimeOpen={props.dateTimeOpen}
        decimalScale={props.allowDecimal ? props.decimalScale : 0}
        decimalSeparator={props.decimalSeparator}
        disabled={props.disabled}
        disableOnClickOutside={props.disableOnClickOutside}
        hasValue={hasValue}
        isClearable={props.isClearable}
        isValidDate={props.isValidDate}
        joinValues={props.joinValues}
        label={label}
        loadOptions={props.loadOptions}
        loadingPlaceholder={props.loadingPlaceholder}
        maxLength={props.maxLength}
        name={props.name}
        numberPrefix={props.numberPrefix}
        onBlur={props.onBlur}
        onBlurDateTime={props.onBlur}
        onBlurSelect={props.onBlur}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onFocusDateTime={props.onFocus} // react-datetime don't overload their focus handler properly
        optionLabelKey={props.optionLabelKey}
        optionValueKey={props.optionValueKey}
        options={props.options}
        placeholder={labelAsPlaceholder}
        promptTextCreator={props.promptTextCreator}
        renderDay={props.renderDay}
        renderMonth={props.renderMonth}
        renderYear={props.renderYear}
        required={props.required}
        styleProps={{
          ...styleDefaultProps,
          ...props.styleProps,
        }}
        suffix={props.suffix}
        thousandSeparator={props.thousandSeparator}
        timeFormat={props.timeFormat}
        validate={prepareValidation(props.validate, props.required)}
        validationColor={props.validationColor}
        validationMessage={props.validationMessage}
        valueKey={props.valueKey}
      />
    </Wrap>
  )
}

Field.defaultProps = {
  allowDecimal: true,
  allowNegative: true,
  clearOnBackspace: true,
  clearable: false,
  closeOnSelect: true,
  creatable: false,
  currentValue: null,
  customValidationMessage: message => message,
  dateFormat: 'YYYY/MM/DD',
  dateTimeOpen: null,
  decimalScale: undefined,
  decimalSeparator: '.',
  disabled: false,
  disableOnClickOutside: false,
  hasValue: false,
  isClearable: true,
  isValidDate: () => true,
  joinValues: true,
  label: '',
  loadOptions: null,
  loadingPlaceholder: 'Loading...',
  maxLength: 255,
  numberPrefix: '',
  onBlur: null,
  onBlurSelect: null,
  onChange: null,
  onFocus: null,
  onFocusDateTime: null,
  optionLabelKey: 'label',
  optionValueKey: 'value',
  options: [],
  placeholder: '',
  promptTextCreator: label => `Create: ${label}`,
  renderDay: (props, currentDate, selectedDate) => (
    <DateTimeDay
      {...props}
      currentDate={currentDate}
      selectedDate={selectedDate}
    />
  ),
  renderMonth: (props, month, year, selectedDate) => (
    <DateTimeMonth
      {...props}
      month={month}
      selectedDate={selectedDate}
      year={year}
    />
  ),
  renderYear: (props, year, selectedDate) => (
    <DateTimeYear {...props} selectedDate={selectedDate} year={year} />
  ),
  required: false,
  requiredIndicator: '*',
  selectOnBlur: null,
  styleProps: {},
  thousandSeparator: '',
  timeFormat: '',
  type: 'text',
  validate: [],
  validationColor: RED,
  validationMessage: '',
  valueKey: 'value',
}

Field.propTypes = {
  allowDecimal: boolPT(),
  allowNegative: boolPT(),
  clearOnBackspace: boolPT(),
  clearable: boolPT(),
  closeOnSelect: boolPT(),
  creatable: boolPT(),
  currentValue: currentValuePT(),
  customValidationMessage: funcPT(),
  dateFormat: dateFormatPT(),
  dateTimeOpen: boolPT(),
  decimalScale: numberPT(),
  decimalSeparator: stringPT(),
  disabled: boolPT(),
  disableOnClickOutside: boolPT(),
  hasValue: boolPT(),
  isClearable: boolPT(),
  isValidDate: funcPT(),
  joinValues: boolPT(),
  label: numberOrStringPT(),
  loadOptions: funcPT(),
  loadingPlaceholder: PT.oneOfType([PT.func, PT.string]),
  maxLength: numberPT(true),
  name: namePT(true),
  numberPrefix: numberOrStringPT(),
  onBlur: funcPT(),
  onChange: funcPT(),
  onFocus: funcPT(),
  optionLabelKey: stringPT(),
  optionValueKey: stringPT(),
  options: PT.arrayOf(
    PT.shape({
      label: PT.string.isRequired,
      value: numberOrStringPT(true),
    })
  ),
  placeholder: numberOrStringPT(),
  promptTextCreator: funcPT(),
  renderDay: funcPT(),
  renderMonth: funcPT(),
  renderYear: funcPT(),
  required: boolPT(),
  requiredIndicator: nodePT(),
  styleProps: stylePropsPT(),
  thousandSeparator: stringPT(),
  timeFormat: timeFormatPT(),
  type: fieldTypePT(),
  validate: validatePT(),
  validationColor: stringPT(),
  validationMessage: stringPT(),
  valueKey: stringPT(),
}

export default formValues(props => ({
  currentValue: props.name,
}))(Field)
