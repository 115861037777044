import {combineReducers} from 'redux'
import {
  FETCH_FORM_DESIGN_REQUEST,
  FETCH_FORM_DESIGN_SUCCESS,
  FETCH_FORM_DESIGN_FAILURE,
} from '../../../constants/actionTypes'

const sections = (state = null, action) => {
  switch (action.type) {
    case FETCH_FORM_DESIGN_SUCCESS:
      return action.payload
    default:
      return state
  }
}

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_FORM_DESIGN_REQUEST:
      return true
    case FETCH_FORM_DESIGN_SUCCESS:
    case FETCH_FORM_DESIGN_FAILURE:
      return false
    default:
      return state
  }
}

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case FETCH_FORM_DESIGN_FAILURE:
      return action.payload.message
    case FETCH_FORM_DESIGN_REQUEST:
    case FETCH_FORM_DESIGN_SUCCESS:
      return null
    default:
      return state
  }
}

const formDesign = combineReducers({
  standardFormSections: sections,
  isFetching,
  errorMessage,
})

export default formDesign
