import * as c from 'arena-config'
import makeFieldName from './makeFieldName'

const autofillRemainingFields = ({
  change,
  fieldNames,
  firstYearValue,
  formName,
  formValues,
}) => {
  for (let i = 0; i < fieldNames.length; i += 1) {
    const fieldName = fieldNames[i]
    const value = formValues[fieldName]
    if (value !== firstYearValue) change(formName, fieldName, firstYearValue)
  }
}

const prepareRemainingFieldNames = ({code, delimiter, duration, startYear}) => {
  const results = []
  for (let i = 1; i < duration; i += 1)
    results.push(makeFieldName(code, delimiter, startYear + i))

  return results
}

const subsequentFieldsAreEqual = (fieldNames, formValues) => {
  const fieldNameWithValue = fieldNames.find(
    fieldName => formValues[fieldName] !== undefined
  )
  if (fieldNameWithValue === undefined) return true
  return fieldNames.every(
    fieldName =>
      formValues[fieldName] === formValues[fieldNameWithValue] ||
      formValues[fieldName] === undefined
  )
}

export default (
  i,
  duration,
  {
    activeYears,
    change,
    code,
    delimiter,
    formName,
    formValues,
    isBulkEditingRow,
    isHovering,
    isManuallyShowingResouces,
    showAllResources,
    startYear,
    type,
    useSingleAmountInsteadOfYears,
  }
) => {
  const isFirstYear = i === 0
  const isLastYear = i === duration - 1

  const isFirstYearAndInactive = isFirstYear && !activeYears.first
  const isLastYearAndInactive = isLastYear && !activeYears.last
  const isRemainingYear = !isFirstYear && !isLastYear
  const year = startYear + i

  const isRemainingYearAndInactive = isRemainingYear && !activeYears.remaining
  const label =
    isHovering || (isManuallyShowingResouces && !showAllResources)
      ? year
      : undefined
  const name = makeFieldName(
    code,
    delimiter,
    year,
    useSingleAmountInsteadOfYears
  )

  const isInactive =
    isFirstYearAndInactive ||
    isLastYearAndInactive ||
    isRemainingYearAndInactive

  if (isInactive) {
    return null
  }

  const onBlur = event => {
    const {target} = event
    const {value} = target

    const shouldAutofill = !!value && i === 0 && type !== 'FYO'

    if (!shouldAutofill) {
      return null
    }

    const remainingFieldNames = prepareRemainingFieldNames({
      code,
      delimiter,
      duration,
      startYear,
    })

    if (
      !remainingFieldNames.length ||
      !subsequentFieldsAreEqual(remainingFieldNames, formValues)
    ) {
      return null
    }

    autofillRemainingFields({
      fieldNames: remainingFieldNames,
      formName,
      formValues,
      firstYearValue: value,
      change,
    })
  }

  return {
    label,
    name,
    onBlur,
    styleProps: {
      borderColor: isBulkEditingRow ? c.WHITE : c.DARK_GRAY,
      borderColorActive: isBulkEditingRow ? c.WHITE : c.DARK_GRAY,
      paddingBottom: '.375em',
      paddingTop: '.375em',
    },
    type: 'number',
  }
}
