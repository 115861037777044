import {Field} from '../../../arena-plugin-form'
import React from 'react'
import DataCell from '../DataCell'
import DataTable from '../DataTable/index'
import prepareAmountFields from '../../utils/prepareAmountFields'
import {amountsDefaultProps, amountsPropTypes} from './propTypes'

class Amounts extends React.PureComponent {
  render() {
    const {props} = this
    const results = []

    for (let i = 0; i < props.duration; i += 1) {
      const thing = prepareAmountFields(i, props.duration, props)
      results.push(
        <DataCell key={`Amount-${i}`} width={props.amountFieldWidth}>
          {!thing ? (
            <div />
          ) : (
            <Field
              {...thing}
              disabled={props.isLocked}
              enableFormatting={props.enableFormatting}
            />
          )}
        </DataCell>
      )
    }

    return <DataTable>{results}</DataTable>
  }
}

Amounts.defaultProps = amountsDefaultProps
Amounts.propTypes = amountsPropTypes

export default Amounts
